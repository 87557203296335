export enum ProjectSectionUnitFormType {
  CONSTRUCTION_RECORD = 'CONSTRUCTION_RECORD',
  CONSTRUCTION_SELF_CHECK = 'CONSTRUCTION_SELF_CHECK',
  SUPERVISE_SPOT_CHECK = 'SUPERVISE_SPOT_CHECK',
  SUPERVISE_RECORD = 'SUPERVISE_RECORD',
  MATERIAL_SELF_CHECK = 'MATERIAL_SELF_CHECK',
  MATERIAL_SPOT_CHECK = 'MATERIAL_SPOT_CHECK',
  STANDARD_SELF_TEST = 'STANDARD_SELF_TEST',
  STANDARD_SPOT_TEST = 'STANDARD_SPOT_TEST',
  STANDARD_AUDIT_TEST = 'STANDARD_AUDIT_TEST',
  PLAT_USER_AUTH_AGREEMENT = 'PLAT_USER_AUTH_AGREEMENT',
  OPEN_APP_API = 'OPEN_APP_API',
  MATERIAL_INSPECTION = 'MATERIAL_INSPECTION',
}

export const ProjectSectionUnitFormTypeMap = {
  CONSTRUCTION_RECORD: '施工记录',
  CONSTRUCTION_SELF_CHECK: '施工自检',
  SUPERVISE_SPOT_CHECK: '监理抽检',
  SUPERVISE_RECORD: '监理记录',
  MATERIAL_SELF_CHECK: '材料自检',
  MATERIAL_SPOT_CHECK: '材料抽检',
  STANDARD_SELF_TEST: '标准自测',
  STANDARD_SPOT_TEST: '标准抽测',
  STANDARD_AUDIT_TEST: '标准审核',
  PLAT_USER_AUTH_AGREEMENT: '平台用户授权协议',
  OPEN_APP_API: '开放应用API',
  MATERIAL_INSPECTION: '材料检验',
};

export const ProjectSectionUnitFormTypeMapLong = {
  CONSTRUCTION_RECORD: '施工记录',
  CONSTRUCTION_SELF_CHECK: '施工检测报告',
  SUPERVISE_SPOT_CHECK: '抽检检测报告',
  SUPERVISE_RECORD: '监理记录',
  MATERIAL_SELF_CHECK: '材料自检',
  MATERIAL_SPOT_CHECK: '材料抽检',
  STANDARD_SELF_TEST: '标准自测',
  STANDARD_SPOT_TEST: '标准抽测',
  STANDARD_AUDIT_TEST: '标准审核',
  PLAT_USER_AUTH_AGREEMENT: '平台用户授权协议',
  OPEN_APP_API: '开放应用API',
  MATERIAL_INSPECTION: '材料检验',
};
