<nz-tabset (nzSelectedIndexChange)="onTableSelectChange($event)" [nzSelectedIndex]="tabId"
	[nzTabBarExtraContent]="extraTemplate">
	<nz-tab *ngFor="let item of config;let configIdx = index" [nzClosable]="canAdd && configIdx !== 0"
		[nzTitle]="'第' + (configIdx + 1) + '页'">
		<div class="content" (scroll)="onScroll()" (scrollEnd)="onScrollEnd()">
			<table [style.width]="(item.width! * wRate) + 'pt'">
				<colgroup>
					<col span="1" *ngFor="let perWidth of item.colWidths"
						[style.width]="(perWidth / item.width!) * 100 + '%'">
				</colgroup>

				<tbody>
					<tr [style.height]="20 + 'px'"> </tr>
					<tr *ngFor="let row of item.rows; let rowIndex = index;"
						[style.height]="(!!row.dyHeight && +row.dyHeight > +row.height! ? row.dyHeight : (row.height! * hRate)) + 'pt'">
						<td *ngFor="let col of row.columns" [colSpan]="col.colSpan ?? 1" [rowSpan]="col.rowSpan ?? 1"
							[style.textAlign]="col.align ?? 'center'" [style.verticalAlign]="col.valign ?? 'middle'"
							[style.height]="(row.height! * hRate) + 'pt'" class=" {{col.borderStyle?.top ? 'border-top' : ''}} {{col.borderStyle?.right ? 'border-right' : ''}} 
								{{col.borderStyle?.bottom ? 'border-bottom' : ''}} {{col.borderStyle?.left ? 'border-left' : ''}} {{col.formItem?.hint ? 'hint' : ''}} {{col.wrapText ? 'wrap-text' : ''}}
								{{col.rowIndex === selectedRowIndex && col.colIndex === selectedColIndex ? 'active' : ''}} "
							[nzTooltipTitle]="col.formItem?.hint" [nzTooltipPlacement]="'top'" nz-tooltip>
							<div [ngSwitch]="col.formItem?.widget" [style.height]="'100%'" class="{{col.formItem?.musted && !disabled(col) && col.formItem?.widget !== FormItemWidgetType.EMPTY && submited && (!col.value || col.formItem?.widget === FormItemWidgetType.DATE && !col.valueDate) ? 'musted'
							: ''}} {{disabled(col) ? 'background-disable' : ''}} {{col.formItem?.widget}}">
								<div *ngSwitchCase="FormItemWidgetType.RADIO" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<span
										class=" {{!!col.formItem?.dataSourceObject.group && !radioGroup[col.formItem?.dataSourceObject.group] && col.formItem?.musted && submited ? 'color-danger' :'color-primary' }}"
										[style.width]="'100%'" [style.fontSize]="'16px'">
										{{col.value}}
									</span>
									<input class="color-primary" type="radio"
										[name]="col.formItem?.dataSourceObject?.group"
										(ngModelChange)="onRadioChange(configIdx, col.formItem?.dataSourceObject)"
										[value]="col.formItem?.dataSourceObject?.value" [(ngModel)]="col.valueRadio"
										[disabled]="disabled(col)" />
								</div>
								<input class="color-primary" type="checkbox" *ngSwitchCase="FormItemWidgetType.CHECKBOX"
									[(ngModel)]="col.valueChecked" (ngModelChange)="onCheckChange(col)"
									[disabled]="disabled(col)" />
								<select class="color-primary" [(ngModel)]="col.value"
									(ngModelChange)="onPureValueChange(configIdx, col)"
									*ngSwitchCase="FormItemWidgetType.SELECT" [style.height]="'100%'"
									[disabled]="disabled(col)">
									<option [value]="undefined" disabled>请选择</option>
									<option *ngFor="let item of col.formItem?.dataSourceList ?? []" [value]="item">
										{{item}}</option>
								</select>
								<app-location-cascader *ngSwitchCase="FormItemWidgetType.LOCATION"
									[locationId]="col.valueLocationId" [forSearch]="true"
									(locationChange)="onLocationChange($event, col)" [editable]="!disabled(col)">
								</app-location-cascader>
								<div *ngSwitchCase="FormItemWidgetType.NORMAL" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<!-- 注意！ 此处加paddingRight为8px是为了将textarea的滚动条加入到计算中 -->
									<p [id]="col.address"
										[style.fontSize]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate  + 'pt'"
										[style.width]="'100%'" [style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										*ngIf="col.type === FormItemType.VARIABLES"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[style.lineHeight]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.paddingRight]="'8px'"
										[innerHTML]="col.valueHtml ? col.valueHtml : col.value ?? '' | safeHtml ">
									</p>

									<textarea [id]="'ta'+col.address" class="color-primary"
										*ngIf="col.type === FormItemType.VARIABLES" [style.width]="'100%'"
										[style.height]="'100%'" [(ngModel)]="col.value"
										[style.fontSize]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.lineHeight]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate  + 'pt'"
										(blur)="onPureValueChange(configIdx, col)" [disabled]="disabled(col)">
									</textarea>

									<p *ngIf="col.type !== FormItemType.VARIABLES"
										[style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.lineHeight]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										[innerHTML]="col.value ?? '' | safeHtml ">
									</p>
									<img *ngFor="let item of col.images" [src]="'data:image/*;base64,' + item.data"
										alt="" [style.position]="'absolute'" [style.top]="item.top + 'pt'"
										[style.left]="item.left + 'pt'"
										[style.height]="(item.bottom! - item.top!) + 'pt'"
										[style.width]="(item.right! - item.left!) + 'pt'"
										[alt]="'dx1:' + item.dx1 + ',dy1:' + item.dy1 + ',dx2:' + item.dx2 + ',dy2:' + item.dy2 + ', item.left:' + item.left + ',item.right:' + item.right + ',item.top:' + item.top + ',item.bottom:' + item.bottom">
								</div>
								<div *ngSwitchCase="FormItemWidgetType.DATE" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'" [style.position]="'relative'">
									<!-- <i class="color-light">( 日期 )</i> -->

									<nz-date-picker [(ngModel)]="col.valueDate" [nzSuffixIcon]="''"
										[nzAllowClear]="true" (nzOnOk)="onDateChange(col, configIdx)"
										(ngModelChange)="onDateChange(col, configIdx)"
										[nzShowTime]="col.formItem?.dataSource?.includes('HH:mm') ? { nzFormat: 'HH:mm' } : false"
										[style.width]="'100%'"
										nzFormat="{{col.formItem?.dataSource ? col.formItem?.dataSource : 'yyyy-MM-dd'}}"
										*ngIf="!disabled(col)"></nz-date-picker>

									<span *ngIf="!disabledAddressList.includes(col.address ?? '')"
										class="color-primary">{{col.value}}</span>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.TIME" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<nz-time-picker [(ngModel)]="col.value" *ngif="!disabled(col)"></nz-time-picker>
									<!-- <i class="color-light">( 时间 )</i> -->
								</div>

								<div *ngSwitchCase="FormItemWidgetType.IMAGE" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<!-- TODO -->
									<!-- <img [src]="'data:image/*;base64,' + col.value" alt="">
									<input class="file" type="file" *ngIf="col.editable" multiple="multiple"
										(change)="upload($event, col.key!)" [(ngModel)]="col.value"
										[style.height]="(col.rowSpan ?? 1) * row.height! + 'pt'"
										[disabled]="disabled(col)"> -->
									<procedure-file [fileList]="col.imgFileIds ?? []"
										(fileChange)="onImageChange($event, col)" [disabled]="disabled(col)"
										[required]="!!col.formItem?.musted">
									</procedure-file>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.WEATHER" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<input type="text" [(ngModel)]="col.value" class="color-primary"
										[style.height]="(col.rowSpan ?? 1) * row.height! + 'pt'"
										(ngModelChange)="onPureValueChange(configIdx, col)" [disabled]="disabled(col)">
								</div>
								<div *ngSwitchCase="FormItemWidgetType.NUMERIC" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<input type="text" [(ngModel)]="col.value" class="color-primary" appNumberFormat
										[style.height]="(col.rowSpan ?? 1) * row.height! + 'pt'"
										(ngModelChange)="onPureValueChange(configIdx, col)" [disabled]="disabled(col)">
								</div>
								<div *ngSwitchCase="FormItemWidgetType.PERCENT" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'" [style.position]="'relative'">
									<input type="text" [(ngModel)]="col.value" class="color-primary percent"
										appNumberFormat [style.height]="(col.rowSpan ?? 1) * row.height! + 'pt'"
										(ngModelChange)="onPureValueChange(configIdx, col)" [disabled]="disabled(col)">
									<span class="percent">%</span>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.PROJECT" [style.height]="'100%'">
									<span class="color-primary" [style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[innerHTML]="col.value ?? '' | safeHtml "></span>
									<nz-cascader class="transparent"
										[nzOptions]="col.formItem?.dataSourceCascader ?? []"
										[(ngModel)]="col.valueCascader" [nzDisabled]="disableAll"
										(ngModelChange)="onProjectDataValueChange(configIdx, col)"></nz-cascader>

								</div>
								<div *ngSwitchCase="FormItemWidgetType.FLOW_CC_ONE" [style.height]="'100%'">
									<p class="color-primary" [style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[id]="col.address" [style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="'left'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[innerHTML]="col.valueHtml ?? '' | safeHtml "></p>
									<nz-select [(ngModel)]="col.valueSingle" class="transparent"
										(ngModelChange)="fakeSelectChange(col)" [nzDisabled]="disableAll">
										<nz-option
											*ngFor="let item of orgList | prccFilter: col.formItem?.dataSource ?? ''"
											[nzValue]="item.id"
											[nzLabel]="item.category === 'INSTITUTION' ? '[机构]' + item.name! : item.name!">
										</nz-option>
									</nz-select>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.FLOW_PRESENT_ONE" [style.height]="'100%'">
									<p class="color-primary" [id]="col.address"
										[style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'" [style.textAlign]="'left'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[innerHTML]="col.valueHtml ?? '' | safeHtml "></p>
									<nz-select [(ngModel)]="col.valueSingle" class="transparent"
										(ngModelChange)="fakeSelectChange(col)" [nzDisabled]="disableAll">
										<nz-option
											*ngFor="let item of orgList | prccFilter: col.formItem?.dataSource ?? ''"
											[nzValue]="item.id"
											[nzLabel]="item.category === 'INSTITUTION' ? '[机构]' + item.name! : item.name!">
										</nz-option>
									</nz-select>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.FLOW_CC" [style.height]="'100%'">
									<p class="color-primary" [style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[id]="col.address" [style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="'left'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[innerHTML]="col.valueHtml ?? '' | safeHtml "></p>
									<nz-select nzMode="multiple" [(ngModel)]="col.valueCascader" class="transparent"
										(ngModelChange)="multiSelectChange(col)" [nzDisabled]="disableAll">
										<nz-option
											*ngFor="let item of orgList | prccFilter: col.formItem?.dataSource ?? ''"
											[nzValue]="item.id"
											[nzLabel]="item.category === 'INSTITUTION' ? '[机构]' + item.name! : item.name!">
										</nz-option>
									</nz-select>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.FLOW_PRESENT" [style.height]="'100%'">
									<p class="color-primary" [id]="col.address"
										[style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'" [style.textAlign]="'left'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[innerHTML]="col.valueHtml ?? '' | safeHtml "></p>
									<nz-select nzMode="multiple" [(ngModel)]="col.valueCascader" class="transparent"
										(ngModelChange)="multiSelectChange(col)" [nzDisabled]="disableAll">
										<nz-option
											*ngFor="let item of orgList | prccFilter: col.formItem?.dataSource ?? ''"
											[nzValue]="item.id"
											[nzLabel]="item.category === 'INSTITUTION' ? '[机构]' + item.name! : item.name!">
										</nz-option>
									</nz-select>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.EMPTY" [style.height]="'100%'">
								</div>
								<div class="color-primary" *ngSwitchCase="FormItemWidgetType.DETECTION"
									[style.height]="'100%'">
									<detection-design *ngIf="col.formItem?.dataSource === DetectionType.DESIGN"
										[initValue]="col.value" [disable]="disableAll"
										(designValChange)="onDetectionDesignChange($event,configIdx, col)">
									</detection-design>

									<input type="text" [style.fontSize]="'12px'" [style.padding]="'0'"
										[style.textAlign]="'center'" [(ngModel)]="col.value"
										(ngModelChange)="onDetectionBiasChange(configIdx,col)"
										[disabled]="!!col.formItem?.defaultValue || disableAll"
										*ngIf="col.formItem?.dataSource === DetectionType.BIAS">

									<detection-real *ngIf="col.formItem?.dataSource === DetectionType.REAL"
										[disable]="disableAll"
										[val1Max]="!!col.formItem?.formula && detectionPagedGroup[configIdx][col.formItem?.formula!].val1Max ? detectionPagedGroup[configIdx][col.formItem?.formula!].val1Max! : defaultMax"
										[val2Max]="!!col.formItem?.formula && detectionPagedGroup[configIdx][col.formItem?.formula!].val2Max ? detectionPagedGroup[configIdx][col.formItem?.formula!].val2Max! : defaultMax"
										[val1Min]="!!col.formItem?.formula && detectionPagedGroup[configIdx][col.formItem?.formula!].val1Min ? detectionPagedGroup[configIdx][col.formItem?.formula!].val1Min! : defaultMin"
										[val2Min]="!!col.formItem?.formula && detectionPagedGroup[configIdx][col.formItem?.formula!].val2Min ? detectionPagedGroup[configIdx][col.formItem?.formula!].val2Min! : defaultMin"
										[twoLines]="!!col.formItem?.formula && detectionPagedGroup[configIdx][col.formItem?.formula!].designRealVal?.length === 2"
										[initValue]="col.value"
										(realValChange)="onDetectionRealChange($event,configIdx, col)">
									</detection-real>
									<!-- <format-data [style.fontSize]="'12px'" [style.justifyContent]="'center'"
										*ngIf="col.formItem?.dataSource === DetectionType.PASS"
										[contentStr]="col.value ?? ''" [patternStr]="col.width ? 'E+' + col.width : ''">
									</format-data> -->
									<input type="text" [style.fontSize]="'12px'" [style.padding]="'0'"
										[style.textAlign]="'center'" [(ngModel)]="col.value" [disabled]="disableAll"
										(ngModelChange)="onPureValueChange(configIdx, col)"
										*ngIf="col.formItem?.dataSource === DetectionType.PASS">
								</div>
								<div class="color-primary" *ngSwitchCase="FormItemWidgetType.DETECTION2"
									[style.height]="'100%'">
									<input *ngIf="!col.formItem?.dataSource?.includes('measuredPart')" type="text"
										[style.fontSize]="'12px'" [style.padding]="'0'" appNumberFormat
										[style.textAlign]="'center'" [(ngModel)]="col.value"
										(focus)="onJS101DataFocus(configIdx, col)"
										[disabled]="disableAll || !!col.formItem?.dataSource?.includes('deviation') || jsLock"
										(blur)="onJS101DataChange(configIdx, col)">
									<input *ngIf="!!col.formItem?.dataSource?.includes('measuredPart')" type="text"
										[style.fontSize]="'12px'" [style.padding]="'0'" [style.textAlign]="'center'"
										[(ngModel)]="col.value" [disabled]="disableAll || jsLock">
								</div>
								<div class="color-primary" *ngSwitchCase="FormItemWidgetType.DETECTION3"
									[style.height]="'100%'">
									<input *ngIf="!col.formItem?.dataSource?.includes('measuredPart')" type="text"
										[style.fontSize]="'12px'" [style.padding]="'0'" appNumberFormat
										[style.textAlign]="'center'" [(ngModel)]="col.value"
										[disabled]="disableAll || !!col.formItem?.dataSource?.includes('deviation') || jsLock"
										(blur)="onJS103DataChange(configIdx, col)">
									<input *ngIf="!!col.formItem?.dataSource?.includes('measuredPart')" type="text"
										[style.fontSize]="'12px'" [style.padding]="'0'" [style.textAlign]="'center'"
										[(ngModel)]="col.value" [disabled]="disableAll || jsLock">
								</div>
								<div class="color-primary" *ngSwitchCase="FormItemWidgetType.MEASURED_PROJECT"
									[style.height]="'100%'">
									<measured-display [formItemId]="col.formItem?.id" [fontSize]="col.fontSize ?? 10"
										[valueShow]="col.valueShow" [measuredProjects]="measuredProjects" [isZp]="isZp"
										(click)="measuredProjectEdit(col, configIdx)">
									</measured-display>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.SIGN" class="sign-seal">
									{{signAddressMap[col.address!] ? signAddressMap[col.address!] : '签字处'}}
								</div>
								<div *ngSwitchCase="FormItemWidgetType.SEAL" class="sign-seal">
									{{signAddressMap[col.address!] ? signAddressMap[col.address!] + '[盖章]' : '盖章处'}}
								</div>
								<div *ngSwitchCase="FormItemWidgetType.SIGN_SEAL" class="sign-seal">
									{{signAddressMap[col.address!] ? signAddressMap[col.address!] + '[签章]' : '盖章处'}}
								</div>
								<div *ngSwitchDefault [style.height]="'100%'" [style.width]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<p *ngIf="col.type === FormItemType.VARIABLES" [id]="col.address"
										class="{{col.formItem?.widget}}" [style.width]="'100%'"
										[style.whiteSpace]="'normal'"
										[style.fontSize]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[style.lineHeight]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate  + 'pt'"
										[style.paddingRight]="'8px'"
										[innerHTML]="col.valueHtml ? col.valueHtml : col.value ?? '' | safeHtml ">
									</p>

									<textarea [id]="'ta'+col.address" class="color-primary"
										*ngIf="col.type === FormItemType.VARIABLES" [style.width]="'100%'"
										[style.height]="'100%'" [(ngModel)]="col.value"
										[style.fontSize]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.lineHeight]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate  + 'pt'"
										(blur)="onPureValueChange(configIdx, col)" [disabled]="disabled(col)">
									</textarea>

									<span [style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.lineHeight]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										*ngIf="col.type !== FormItemType.VARIABLES"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[innerHTML]="col.value ?? '' | safeHtml "></span>

									<img *ngFor="let item of col.images" [src]="'data:image/*;base64,' + item.data"
										alt="" [style.position]="'absolute'" [style.top]="item.top + 'pt'"
										[style.left]="item.left + 'pt'"
										[style.height]="(item.bottom! - item.top!) + 'pt'"
										[style.width]="(item.right! - item.left!) + 'pt'"
										[alt]="'dx1:' + item.dx1 + ',dy1:' + item.dy1 + ',dx2:' + item.dx2 + ',dy2:' + item.dy2 + ', item.left:' + item.left + ',item.right:' + item.right + ',item.top:' + item.top + ',item.bottom:' + item.bottom">
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table class="attach" [style.width]="(item.width! * wRate) + 'pt'"
				*ngFor="let item of attachFormTable;let configIdx = index">
				<colgroup>
					<col span="1" *ngFor="let perWidth of item.colWidths"
						[style.width]="(perWidth / item.width!) * 100 + '%'">
				</colgroup>
				<tbody>
					<tr [style.height]="20 + 'px'">
					</tr>
					<tr *ngFor="let row of item.rows; let rowIndex = index;"
						[style.height]="(!!row.dyHeight && +row.dyHeight > +row.height! ? row.dyHeight : (row.height! * hRate)) + 'pt'">
						<td *ngFor="let col of row.columns" [colSpan]="col.colSpan ?? 1" [rowSpan]="col.rowSpan ?? 1"
							[style.textAlign]="col.align ?? 'center'" [style.verticalAlign]="col.valign ?? 'middle'"
							[style.height]="(row.height! * hRate) + 'pt'" class=" {{col.borderStyle?.top ? 'border-top' : ''}} {{col.borderStyle?.right ? 'border-right' : ''}} 
												{{col.borderStyle?.bottom ? 'border-bottom' : ''}} {{col.borderStyle?.left ? 'border-left' : ''}} {{col.formItem?.hint ? 'hint' : ''}} {{col.wrapText ? 'wrap-text' : ''}}
												{{col.rowIndex === selectedRowIndex && col.colIndex === selectedColIndex ? 'active' : ''}} "
							[nzTooltipTitle]="col.formItem?.hint" [nzTooltipPlacement]="'top'" nz-tooltip>
							<div [ngSwitch]="col.formItem?.widget" [style.height]="'100%'" class="{{col.formItem?.musted && !disabled(col) && col.formItem?.widget !== FormItemWidgetType.EMPTY && submited && (!col.value || col.formItem?.widget === FormItemWidgetType.DATE && !col.valueDate) ? 'musted'
											: ''}} {{disabled(col) ? 'background-disable' : ''}} {{col.formItem?.widget}}">
								<div *ngSwitchCase="FormItemWidgetType.NORMAL" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<!-- 注意！ 此处加paddingRight为8px是为了将textarea的滚动条加入到计算中 -->
									<p [id]="col.address"
										[style.fontSize]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate  + 'pt'"
										[style.width]="'100%'" [style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										*ngIf="col.type === FormItemType.VARIABLES"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[style.lineHeight]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.paddingRight]="'8px'"
										[innerHTML]="col.valueHtml ? col.valueHtml : col.value ?? '' | safeHtml ">
									</p>

									<textarea [id]="'ta'+col.address" class="color-primary"
										*ngIf="col.type === FormItemType.VARIABLES" [style.width]="'100%'"
										[style.height]="'100%'" [(ngModel)]="col.value"
										[style.fontSize]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.lineHeight]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate  + 'pt'">
													</textarea>

									<p *ngIf="col.type !== FormItemType.VARIABLES"
										[style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.lineHeight]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										[innerHTML]="col.value ?? '' | safeHtml ">
									</p>
									<img *ngFor="let item of col.images" [src]="'data:image/*;base64,' + item.data"
										alt="" [style.position]="'absolute'" [style.top]="item.top + 'pt'"
										[style.left]="item.left + 'pt'"
										[style.height]="(item.bottom! - item.top!) + 'pt'"
										[style.width]="(item.right! - item.left!) + 'pt'"
										[alt]="'dx1:' + item.dx1 + ',dy1:' + item.dy1 + ',dx2:' + item.dx2 + ',dy2:' + item.dy2 + ', item.left:' + item.left + ',item.right:' + item.right + ',item.top:' + item.top + ',item.bottom:' + item.bottom">
								</div>
								<div *ngSwitchCase="FormItemWidgetType.DATE" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'" [style.position]="'relative'">

									<nz-date-picker [(ngModel)]="col.valueDate" [nzSuffixIcon]="''"
										[nzAllowClear]="true" (nzOnOk)="onAttachDateChange(col)"
										(ngModelChange)="onAttachDateChange(col)"
										[nzShowTime]="col.formItem?.dataSource?.includes('HH:mm') ? { nzFormat: 'HH:mm' } : false"
										[style.width]="'100%'"
										nzFormat="{{col.formItem?.dataSource ? col.formItem?.dataSource : 'yyyy-MM-dd'}}"></nz-date-picker>
									<span class="color-primary">{{col.value}}</span>
								</div>

								<div *ngSwitchCase="FormItemWidgetType.IMAGE" [style.height]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<procedure-file [fileList]="col.imgFileIds ?? []" [limit]="1"
										(fileChange)="onAttachImageChange($event, col, configIdx)">
									</procedure-file>
								</div>
								<div *ngSwitchCase="FormItemWidgetType.EMPTY" [style.height]="'100%'">
								</div>
								<div *ngSwitchDefault [style.height]="'100%'" [style.width]="'100%'"
									[style.justifyContent]="col.align ?? 'center'">
									<p *ngIf="col.type === FormItemType.VARIABLES" [id]="col.address"
										class="{{col.formItem?.widget}}" [style.width]="'100%'"
										[style.whiteSpace]="'normal'"
										[style.fontSize]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[style.lineHeight]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate  + 'pt'"
										[style.paddingRight]="'8px'"
										[innerHTML]="col.valueHtml ? col.valueHtml : col.value ?? '' | safeHtml ">
									</p>

									<textarea [id]="'ta'+col.address" class="color-primary"
										*ngIf="col.type === FormItemType.VARIABLES" [style.width]="'100%'"
										[style.height]="'100%'" [(ngModel)]="col.value"
										[style.fontSize]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.lineHeight]="(col.dyFontSize ? col.dyFontSize : col.fontSize ?? 10) * fRate  + 'pt'">
													</textarea>

									<span [style.fontSize]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.lineHeight]="(col.fontSize ?? 10) * fRate + 'pt'"
										[style.fontWeight]="col.fontWeight ?? 'normal'"
										[style.textAlign]="col.align ?? 'center'"
										*ngIf="col.type !== FormItemType.VARIABLES"
										[style.writingMode]="col.textVertical ? 'vertical-rl' : 'horizontal-tb'"
										[innerHTML]="col.value ?? '' | safeHtml "></span>

									<img *ngFor="let item of col.images" [src]="'data:image/*;base64,' + item.data"
										alt="" [style.position]="'absolute'" [style.top]="item.top + 'pt'"
										[style.left]="item.left + 'pt'"
										[style.height]="(item.bottom! - item.top!) + 'pt'"
										[style.width]="(item.right! - item.left!) + 'pt'"
										[alt]="'dx1:' + item.dx1 + ',dy1:' + item.dy1 + ',dx2:' + item.dx2 + ',dy2:' + item.dy2 + ', item.left:' + item.left + ',item.right:' + item.right + ',item.top:' + item.top + ',item.bottom:' + item.bottom">
								</div>
							</div>
						</td>
					</tr>
					<tr [style.height]="20 + 'px'">
						<td colSpan="20">
							<span>第{{configIdx + 1}}页</span>
							<button class="color-primary" nz-button nzType="text" (click)="addAttachPage(configIdx)">
								加一页
							</button>
							<button *ngIf="attachFormTable.length > 1" class="color-primary" nz-button nzType="text"
								(click)="removeAttachPage(configIdx)">
								删除本页
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</nz-tab>
</nz-tabset>
<ng-template #extraTemplate>
	<button class="color-primary" *ngIf="canAdd && config.length < 6 && !disableAll" nz-button nzType="text"
		(click)="duplicate()">
		+同表同编号页面
	</button>
	<button class="color-primary" nz-button nzType="text" (click)="remove(tabId)"
		*ngIf="config.length > 1 && tabId !== 0 && canAdd && !disableAll">
		删除当前页面
	</button>
	<button class="color-primary" nz-button nzType="text" (click)="duplicateForm(tabId, true)" *ngIf="copyable">
		复制前一表单
	</button>
	<button class="color-primary" nz-button nzType="text" (click)="duplicateForm(tabId, false)" *ngIf="copyable">
		复制表单
	</button>
</ng-template>