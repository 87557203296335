export enum FormItemMeasuredProjectRefParamType {
  WITH_IN_FORM = 'WITH_IN_FORM',
  OUTSITE_FORM = 'OUTSITE_FORM',
  DESIGN = 'DESIGN',
}

export const FormItemMeasuredProjectRefParamTypeMap = {
  [FormItemMeasuredProjectRefParamType.WITH_IN_FORM]: '表内参数',
  [FormItemMeasuredProjectRefParamType.OUTSITE_FORM]: '表外参数',
  [FormItemMeasuredProjectRefParamType.DESIGN]: '设计值',
};

export const FormItemMeasuredProjectRefParamTypeOptions = [
  {
    value: FormItemMeasuredProjectRefParamType.WITH_IN_FORM,
    label:
      FormItemMeasuredProjectRefParamTypeMap[
        FormItemMeasuredProjectRefParamType.WITH_IN_FORM
      ],
  },
  {
    value: FormItemMeasuredProjectRefParamType.OUTSITE_FORM,
    label:
      FormItemMeasuredProjectRefParamTypeMap[
        FormItemMeasuredProjectRefParamType.OUTSITE_FORM
      ],
  },
  {
    value: FormItemMeasuredProjectRefParamType.DESIGN,
    label:
      FormItemMeasuredProjectRefParamTypeMap[
        FormItemMeasuredProjectRefParamType.DESIGN
      ],
  },
];
