export enum FileType {
  PDF = 'PDF',
  EXCEL = 'EXCEL',
  WORD = 'WORD',
  IMAGE = 'IMAGE',
  OTHER = 'OTHER',
}

export const FileTypeMap = {
  [FileType.PDF]: 'PDF',
  [FileType.EXCEL]: 'EXCEL',
  [FileType.WORD]: 'WORD',
  [FileType.IMAGE]: 'IMAGE',
  [FileType.OTHER]: 'OTHER',
};
