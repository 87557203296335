export enum FormHeaderHorizontal {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

export const FormHeaderHorizontalMap = {
  [FormHeaderHorizontal.LEFT]: '居左',
  [FormHeaderHorizontal.RIGHT]: '居右',
  [FormHeaderHorizontal.CENTER]: '居中',
};

export const FormHeaderHorizontalList = [
  {
    value: FormHeaderHorizontal.LEFT,
    label: FormHeaderHorizontalMap[FormHeaderHorizontal.LEFT],
  },
  {
    value: FormHeaderHorizontal.RIGHT,
    label: FormHeaderHorizontalMap[FormHeaderHorizontal.RIGHT],
  },
  {
    value: FormHeaderHorizontal.CENTER,
    label: FormHeaderHorizontalMap[FormHeaderHorizontal.CENTER],
  },
];
