import { FormItemMeasuredProjectOptions } from './form-item-measured-project-options.enum';
import { FormItemMeasuredProjectSpadType } from './form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectSpad } from './form-item-measured-project-spad.model';
import { FormItemMeasuredProjectAverageTypeType } from './form-item-measured-project-average-type-type.enum';
import { FormItemMeasuredProjectStatus } from './form-item-measured-project-status.enum';
import { RecordBase } from '../../providers/request/record-base.model';
export class FormItemMeasuredProject extends RecordBase {
  formId?: number;
  formItemId?: number;
  formTableId?: number;
  formItemAddress?: string;
  formItemColumnNum?: number;
  itemNo?: string;
  options?: FormItemMeasuredProjectOptions;
  category?: string;
  keywords?: string;
  spadSearchType?: string;
  spadKeywordsDesc?: string;
  spadDesc?: string;
  spadType?: FormItemMeasuredProjectSpadType;
  spadValues?: Array<FormItemMeasuredProjectSpad>;
  mfValue?: string;
  measuredValue?: string;
  // XXXValue?: string;
  remarkValue?: string;
  designValue?: string;
  averageValueType?: FormItemMeasuredProjectAverageTypeType;
  averageValue?: string;
  qaRate?: string;
  qualifiedJudge?: string;
  sortOrder?: number;
  status?: FormItemMeasuredProjectStatus;
  comment?: string;
  // 前端自用
  designValue1?: string;
  designValue2?: string;
  count?: number;
  defaultIndex?: number;
}
