<div class="alert-dialog">
	<div class="alert-dialog-header">{{title}}</div>
	<div class="alert-dialog-body">
		<div class="grid">
			<div class="row">
				<div class="column" [style.width]="'4rem'">
					<input [(ngModel)]="m" type="tel">
				</div>
				<div class="column">
					<button nz-button nzType="primary" (click)="resend()" [disabled]="countdown > 0">{{countdown > 0 ?
						countdown + 'S' : '重新发送'}}
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="alert-dialog-footer">
		<button nz-button nzType="primary" (click)="cancel()">取消</button>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!m || m.length !== 6">确定</button>
	</div>
</div>