import { Component, Input } from '@angular/core';
import copy from 'copy-text-to-clipboard';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogConfig } from '../../components/alert-dialog/dialog-config';
import { TableColumnOption } from '../../components/table-pro/table.config';
import { FormItemMeasuredProjectAverageTypeType } from '../../models/form/form-item-measured-project-average-type-type.enum';
import { FormItemMeasuredProjectInfo } from '../../models/form/form-item-measured-project-info.model';
import {
  FormItemMeasuredProjectRefParamType,
  FormItemMeasuredProjectRefParamTypeOptions,
} from '../../models/form/form-item-measured-project-ref-param-type.enum';
import {
  FormItemMeasuredProjectSpadType,
  FormItemMeasuredProjectSpadTypeOptions,
} from '../../models/form/form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectSpad } from '../../models/form/form-item-measured-project-spad.model';
import { GlobalService } from '../../providers/global.service';
import { OmFormItemMeasuredProjectService } from './../../services/om-form-item-measured-project.service';

@Component({
  selector: 'app-dialog-form-zj-fill',
  templateUrl: './dialog-form-zj-fill.component.html',
  styleUrls: ['./dialog-form-zj-fill.component.scss'],
})
export class DialogFormZjFillComponent {
  @Input()
  data!: DialogConfig<FormItemMeasuredProjectInfo>;

  editing: { [name: string]: boolean } = {};

  FormItemMeasuredProjectSpadType = FormItemMeasuredProjectSpadType;

  record: FormItemMeasuredProjectInfo = new FormItemMeasuredProjectInfo();

  FormItemMeasuredProjectSpadTypeOptions =
    FormItemMeasuredProjectSpadTypeOptions;

  FormItemMeasuredProjectRefParamTypeOptions =
    FormItemMeasuredProjectRefParamTypeOptions;

  descList: string[] = [];

  detectionInfo = new DetectionInfo();

  currentSpad = new FormItemMeasuredProjectSpad();

  recommandRemark = '';

  realVal1List: { value: string; hasError: boolean }[][] = [];

  realVal2List: { value: string; hasError: boolean }[][] = [];

  isMac = /macintosh|mac os x/i.test(navigator.userAgent);

  showExp = false;

  totalCount = 0;
  avg1 = '';
  avg2 = '';
  exp1 = '';
  exp2 = '';
  passRate = 0;

  showD1 = false;
  showD2 = false;

  precision = 0;

  onExpChange() {
    if (!!this.exp1 || !!this.exp2) {
      this.showExp = true;
    } else {
      this.showExp = false;
    }
  }

  FormItemMeasuredProjectRefParamType = FormItemMeasuredProjectRefParamType;

  // 特殊情况

  special = false;

  CXX: TableColumnOption[] = [
    {
      label: 'C15',
      value: 'C15',
    },
    {
      label: 'C20',
      value: 'C20',
    },
    {
      label: 'C25',
      value: 'C25',
    },
    {
      label: 'C30',
      value: 'C30',
    },
    {
      label: 'C35',
      value: 'C35',
    },
    {
      label: 'C40',
      value: 'C40',
    },
    {
      label: 'C45',
      value: 'C45',
    },
    {
      label: 'C50',
      value: 'C50',
    },
    {
      label: 'C55',
      value: 'C55',
    },
    {
      label: 'C60',
      value: 'C60',
    },
    {
      label: 'C65',
      value: 'C65',
    },
    {
      label: 'C70',
      value: 'C70',
    },
    {
      label: 'C75',
      value: 'C75',
    },
    {
      label: 'C80',
      value: 'C80',
    },
  ];

  MXX: TableColumnOption[] = [
    {
      label: 'M5',
      value: 'M5',
    },
    {
      label: 'M7.5',
      value: 'M7.5',
    },
    {
      label: 'M10',
      value: 'M10',
    },
    {
      label: 'M15',
      value: 'M15',
    },
    {
      label: 'M20',
      value: 'M20',
    },
    {
      label: 'M25',
      value: 'M25',
    },
    {
      label: 'M30',
      value: 'M30',
    },
  ];

  ZS: TableColumnOption[] = [
    {
      label: '≥Ⅱ类',
      value: '≥-2',
    },
  ];

  ZSV: TableColumnOption[] = [
    {
      label: 'Ⅰ类',
      value: '-1',
    },
    {
      label: 'Ⅱ类',
      value: '-2',
    },
    {
      label: 'Ⅲ类',
      value: '-3',
    },
  ];

  // 桩身完整性
  isZS = false;

  specialOptions: TableColumnOption[] = [];

  constructor(
    private globalService: GlobalService,
    private omFormItemMeasuredProjectService: OmFormItemMeasuredProjectService,
    private nzMessage: NzMessageService
  ) {}

  ngOnInit() {
    this.record = this.handleDataBeforeInit(this.data.data!);
    if (
      this.record.keywords?.includes('混凝土抗压强度') ||
      this.record.keywords?.includes('混凝土强度')
    ) {
      this.special = true;
      this.specialOptions = this.CXX;
    }
    if (
      this.record.keywords?.includes('砂浆强度') ||
      this.record.keywords?.includes('砂浆抗压强度')
    ) {
      this.special = true;
      this.specialOptions = this.MXX;
    }
    if (
      (this.record.keywords?.includes('混凝土抗压强度') ||
        this.record.keywords?.includes('混凝土强度')) &&
      (this.record.keywords?.includes('砂浆强度') ||
        this.record.keywords?.includes('砂浆抗压强度'))
    ) {
      this.special = true;
      this.specialOptions = [...this.CXX, ...this.MXX];
    }
    if (this.record.keywords?.includes('桩身完整性')) {
      this.isZS = true;
      this.record.designValue2 = '';
    }
  }

  // 计算全表合格率，统计对应的数据
  // !! 旧算法注释即可。不要删除
  // private calcAll() {
  //   let totalCount = 0;
  //   let passCount = 0;
  //   let sumReal1 = 0;
  //   let sumReal2 = 0;
  //   this.avg = '-';

  //   if (this.detectionInfo.biasError) {
  //     for (const row of this.realVal1List) {
  //       for (const cell of row) {
  //         cell.hasError = false;
  //       }
  //     }
  //     for (const row of this.realVal2List) {
  //       for (const cell of row) {
  //         cell.hasError = false;
  //       }
  //     }
  //   } else if (
  //     this.record.designValue2 === '' ||
  //     this.record.designValue2 === undefined
  //   ) {
  //     for (const row of this.realVal1List) {
  //       for (const cell of row) {
  //         if (cell.value === '') {
  //           cell.hasError = false;
  //           continue;
  //         }
  //         totalCount++;
  //         sumReal1 += +cell.value;
  //         if (
  //           +cell.value >= this.detectionInfo.val1Min! &&
  //           +cell.value <= this.detectionInfo.val1Max!
  //         ) {
  //           cell.hasError = false;
  //           passCount++;
  //         } else {
  //           cell.hasError = true;
  //         }
  //       }
  //     }
  //   } else {
  //     let maxRow = Math.max(this.realVal1List.length, this.realVal2List.length);
  //     for (let i = 0; i < maxRow; i++) {
  //       for (let j = 0; j < 10; j++) {
  //         const cell1 =
  //           this.realVal1List.length <= maxRow
  //             ? this.realVal1List[i][j]
  //             : undefined;
  //         const cell2 =
  //           this.realVal2List.length <= maxRow
  //             ? this.realVal2List[i][j]
  //             : undefined;
  //         if (!cell1 || !cell2) {
  //           // 如果其中1个不存在，可能出现两种情况。
  //           if (cell1) {
  //             if (cell1.value !== '') {
  //               // 如果存在的单元格存在值，尽管数据需要展示为错误，但是需要计入总数
  //               totalCount++;
  //               sumReal1 += +cell1.value;
  //             }
  //             cell1.hasError = true;
  //           }
  //           if (cell2) {
  //             if (cell2.value !== '') {
  //               // 如果存在的单元格存在值，尽管数据需要展示为错误，但是需要计入总数
  //               totalCount++;
  //               sumReal2 += +cell2.value;
  //             }
  //             cell2.hasError = true;
  //           }
  //           continue;
  //         }
  //         if (cell1.value === '' && cell2.value === '') {
  //           // 如果两个都是空，那么不需要进行比较，也不计入总数
  //           cell1.hasError = false;
  //           cell2.hasError = false;
  //           continue;
  //         }
  //         if (
  //           (cell1.value === '' && cell2.value !== '') ||
  //           (cell1.value !== '' && cell2.value === '')
  //         ) {
  //           // 如果第一个单元格为空，第二个单元格不为空，那么认为这一组数据存在，而且是错误的
  //           totalCount++;
  //           if (cell1.value !== '') {
  //             sumReal1 += +cell1.value;
  //           }
  //           if (cell2.value !== '') {
  //             sumReal2 += +cell2.value;
  //           }
  //           cell1.hasError = true;
  //           cell2.hasError = true;
  //           continue;
  //         }
  //         if (
  //           +cell1.value >= this.detectionInfo.val1Min! &&
  //           +cell1.value <= this.detectionInfo.val1Max! &&
  //           +cell2.value >= this.detectionInfo.val2Min! &&
  //           +cell2.value <= this.detectionInfo.val2Max!
  //         ) {
  //           cell1.hasError = false;
  //           cell2.hasError = false;
  //           passCount++;
  //         } else {
  //           cell1.hasError = true;
  //           cell2.hasError = true;
  //         }

  //         if (cell1.value !== '') {
  //           sumReal1 += +cell1.value;
  //         }
  //         if (cell2.value !== '') {
  //           sumReal2 += +cell2.value;
  //         }
  //         totalCount++;
  //       }
  //     }
  //   }

  //   // 根据下标进行计算

  //   this.totalCount = totalCount;
  //   this.passRate =
  //     totalCount === 0 ? 0 : Math.round((passCount / totalCount) * 10000) / 100;
  //   this.avg = `${
  //     totalCount === 0 ? '-' : Math.round((sumReal1 / totalCount) * 100) / 100
  //   }${
  //     this.record.designValue2
  //       ? `, ${
  //           totalCount === 0
  //             ? '-'
  //             : Math.round((sumReal2 / totalCount) * 100) / 100
  //         }`
  //       : ''
  //   }`;
  // }

  private calcAll() {
    let totalCount1 = 0;
    let totalCount2 = 0;
    let passCount = 0;
    let sumReal1 = 0;
    let sumReal2 = 0;

    let maxR = 0;

    if (this.detectionInfo.biasError) {
      for (const row of this.realVal1List) {
        for (const cell of row) {
          cell.hasError = false;
        }
      }
      for (const row of this.realVal2List) {
        for (const cell of row) {
          cell.hasError = false;
        }
      }
    } else {
      for (const row of this.realVal1List) {
        for (const col of row) {
          if (col.value !== '') {
            if (col.value.includes('.')) {
              maxR = Math.max(maxR, col.value.split('.')[1].length);
            }
            totalCount1++;
            sumReal1 += +col.value;
            if (
              +col.value >= this.detectionInfo.val1Min! &&
              +col.value <= this.detectionInfo.val1Max!
            ) {
              col.hasError = false;
              passCount++;
            } else {
              col.hasError = true;
            }
          } else {
            col.hasError = false;
          }
        }
      }
      for (const row of this.realVal2List) {
        for (const col of row) {
          if (col.value !== '') {
            if (col.value.includes('.')) {
              maxR = Math.max(maxR, col.value.split('.')[1].length);
            }
            totalCount2++;
            sumReal2 += +col.value;
            if (
              +col.value >= this.detectionInfo.val2Min! &&
              +col.value <= this.detectionInfo.val2Max!
            ) {
              col.hasError = false;
              passCount++;
            } else {
              col.hasError = true;
            }
          } else {
            col.hasError = false;
          }
        }
      }
    }

    // 根据下标进行计算

    let totalCount = totalCount1 + totalCount2;
    this.totalCount = totalCount;
    this.passRate =
      totalCount === 0 ? 0 : Math.round((passCount / totalCount) * 10000) / 100;
    const pow = Math.pow(10, maxR);
    this.avg1 =
      totalCount1 === 0
        ? ''
        : `${Math.round((sumReal1 / totalCount1) * pow) / pow}`;
    this.avg2 =
      totalCount2 === 0
        ? ''
        : `${Math.round((sumReal2 / totalCount2) * pow) / pow}`;
  }

  // 实测数据的输入框发生变化时，重新进行全表合格率计算、数量统计
  onReal1Blur(rowIdx: number, colIdx: number) {
    const val = this.realVal1List[rowIdx][colIdx].value;
    if (val !== '' && this.isNaN(+val)) {
      this.realVal1List[rowIdx][colIdx].value = '';
    }
    this.calcAll();
  }

  // 实测数据的输入框发生变化时，重新进行全表合格率计算、数量统计
  onReal2Blur(rowIdx: number, colIdx: number) {
    const val = this.realVal2List[rowIdx][colIdx].value;
    if (val !== '' && this.isNaN(+val)) {
      this.realVal2List[rowIdx][colIdx].value = '';
    }
    this.calcAll();
  }

  // 实测数据的输入框发生变化时，判断是否需要新增一行
  onReal1Change(rowIdx: number, colIdx: number) {
    // 这里判断是否需要新增一行。针对数据处理、合格率计算放到了onBlur中
    const val = this.realVal1List[rowIdx][colIdx].value;
    if (val === '') {
      return;
    }
    if (rowIdx === this.realVal1List.length - 1 && colIdx === 9) {
      this.realVal1List.push([]);
      for (let col = 0; col < 10; col++) {
        this.realVal1List[rowIdx + 1][col] = {
          value: '',
          hasError: false,
        };
      }
    }
  }

  // 实测数据的输入框发生变化时，判断是否需要新增一行
  onReal2Change(rowIdx: number, colIdx: number) {
    // 这里判断是否需要新增一行。针对数据处理、合格率计算放到了onBlur中
    const val = this.realVal2List[rowIdx][colIdx].value;
    if (val === '') {
      return;
    }
    if (rowIdx === this.realVal2List.length - 1 && colIdx === 9) {
      this.realVal2List.push([]);
      for (let col = 0; col < 10; col++) {
        this.realVal2List[rowIdx + 1][col] = {
          value: '',
          hasError: false,
        };
      }
    }
  }

  // 在输入框中按下上下左右箭头，实现焦点的移动。在输入框中按下ctrl+v，实现粘贴
  onRealInputKeyDown(
    event: KeyboardEvent,
    clz: string,
    rowIdx: number,
    colIdx: number
  ) {
    const inputElements = document.querySelectorAll(`.${clz} input`);
    if (event.key === 'ArrowDown') {
      if (rowIdx < this.realVal1List.length - 1) {
        // 向下1行
        (inputElements[rowIdx * 10 + colIdx + 10] as HTMLElement).focus();
      } else {
        // 回到第1行
        (inputElements[colIdx] as HTMLElement).focus();
      }
      return;
    }
    if (event.key === 'ArrowUp') {
      if (rowIdx > 0) {
        // 向上1行
        (inputElements[rowIdx * 10 + colIdx - 10] as HTMLElement).focus();
      } else {
        // 回到最后1行
        (
          inputElements[
            this.realVal1List.length * 10 - 10 + colIdx
          ] as HTMLElement
        ).focus();
      }
      return;
    }
    if (event.key === 'ArrowLeft') {
      if (colIdx > 0) {
        // 向左1列
        (inputElements[rowIdx * 10 + colIdx - 1] as HTMLElement).focus();
      } else {
        // 回到上1行最后1列
        if (rowIdx > 0) {
          (inputElements[(rowIdx - 1) * 10 + 9] as HTMLElement).focus();
        } else {
          // 回到最后1行最后1列
          (
            inputElements[this.realVal1List.length * 10 - 1] as HTMLElement
          ).focus();
        }
      }
      return;
    }
    if (event.key === 'ArrowRight') {
      if (colIdx < 9) {
        // 向右1列
        (inputElements[rowIdx * 10 + colIdx + 1] as HTMLElement).focus();
      } else {
        // 回到下1行第1列
        if (rowIdx < this.realVal1List.length - 1) {
          (inputElements[(rowIdx + 1) * 10] as HTMLElement).focus();
        } else {
          // 回到第1行第1列
          (inputElements[0] as HTMLElement).focus();
        }
      }
      return;
    }
    if (
      (event.key.toLowerCase() === 'v' && event.ctrlKey && !this.isMac) ||
      (event.key.toLowerCase() === 'v' && event.metaKey && this.isMac)
    ) {
      setTimeout(() => {
        const source = clz === 'real1' ? this.realVal1List : this.realVal2List;
        if (
          source[rowIdx][colIdx].value.includes('\n') ||
          source[rowIdx][colIdx].value.includes('\t') ||
          source[rowIdx][colIdx].value.includes(' ')
        ) {
          if (clz === 'real1') {
            this.realVal1List = this.onRealPaste(
              JSON.parse(JSON.stringify(source)),
              rowIdx,
              colIdx
            );
          } else {
            this.realVal2List = this.onRealPaste(
              JSON.parse(JSON.stringify(source)),
              rowIdx,
              colIdx
            );
          }
        }
      }, 100);
      return;
    }
  }

  // 对复制粘贴的值进行处理。特别处理了来自excel的粘贴
  onRealPaste(
    source: { value: string; hasError: boolean }[][],
    rowIdx: number,
    colIdx: number
  ) {
    let realValList = `${source[rowIdx][colIdx].value}`
      .toLowerCase()
      .replace(/mpa/g, '')
      .replace(/\n/g, ' ')
      .replace(/\t/g, ' ')
      .split(' ');

    let oldVals: string[] = [];
    for (let i = 0; i <= rowIdx; i++) {
      for (let j = 0; j < (i < rowIdx ? 10 : colIdx); j++) {
        oldVals.push(source[i][j].value);
      }
    }

    realValList = [...oldVals, ...realValList].filter(
      (v) => v === '' || (v !== '' && !isNaN(+v))
    );

    let rowCount = Math.ceil(realValList.length / 10);
    if (rowCount < 1) {
      rowCount = 1;
    }
    if (realValList.length % 10 === 0) {
      rowCount++;
    }

    let target: { value: string; hasError: boolean }[][] = [];
    for (let row = 0; row < rowCount; row++) {
      target[row] = [];
      for (let col = 0; col < 10; col++) {
        target[row][col] = {
          value: realValList[row * 10 + col] ?? '',
          hasError: false,
        };
      }
    }
    if (target[target.length - 1][9].value !== '') {
      target.push([]);
      for (let col = 0; col < 10; col++) {
        target[target.length - 1][col] = {
          value: '',
          hasError: false,
        };
      }
    }

    return target;
  }

  private handleDataBeforeInit(
    response: FormItemMeasuredProjectInfo
  ): FormItemMeasuredProjectInfo {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProjectInfo;
    if (data.designValue) {
      try {
        const designValues = data.designValue.split(' ');
        data.designValue1 = !!designValues[0].trim()
          ? designValues[0].trim()
          : '';
        data.designValue2 = !!designValues[1].trim()
          ? designValues[1].trim()
          : '';
      } catch (e) {}
    }
    if (
      data.averageValueType ===
      FormItemMeasuredProjectAverageTypeType.AVERAGE_VALUE
    ) {
      const averageValues = (data.averageValue ?? '').split(' ');
      this.avg1 = averageValues[0] ?? '';
      this.avg2 = averageValues[1] ?? '';
      this.showExp = false;
    } else {
      const expressionValues = (data.averageValue ?? '').split(' ');
      this.exp1 = expressionValues[0] ?? '';
      this.exp2 = expressionValues[1] ?? '';
      this.showExp = true;
    }
    this.parseRealData(data.measuredValue);
    let selected = false;
    console.log('实测值');
    if (data.spadValues && data.spadValues.length > 0) {
      for (let i = 0; i < data.spadValues.length; i++) {
        if (data.spadValues[i].selected) {
          selected = true;
          setTimeout(() => {
            // 为了解决ngModelChange的问题，延迟100ms
            this.selectBias(i);
          }, 100);
          break;
        }
      }
    }
    if (!selected && data.spadValue) {
      this.detectionInfo.biasStr = data.spadValue;
      this.onBiasChange();
    }
    return data;
  }

  // 再次进入编辑页面后，做一次实测数据的解析。将实测数据放到对应的位置，并且绘制表格
  private parseRealData(measuredValue?: string) {
    if (!!measuredValue) {
      const measuredValues = measuredValue
        .replaceAll('Ⅰ类', '-1')
        .replaceAll('Ⅱ类', '-2')
        .replaceAll('Ⅲ类', '-3')
        .split('\n');
      const m1 = measuredValues[0] ?? '';
      const m2 = measuredValues[1] ?? '';
      let m1List: string[] = [];
      let m2List: string[] = [];
      m1List = m1.split(' ');
      let rowCount = Math.ceil(m1List.length / 10);
      if (rowCount < 1) {
        rowCount = 1;
      }
      if (m1List.length % 10 === 0) {
        rowCount++;
      }
      for (let row = 0; row < rowCount; row++) {
        this.realVal1List[row] = [];
        for (let col = 0; col < 10; col++) {
          this.realVal1List[row][col] = {
            value: m1List[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
      m2List = m2.split(' ');
      rowCount = Math.ceil(m2List.length / 10);
      if (rowCount < 1) {
        rowCount = 1;
      }
      if (m2List.length % 10 === 0) {
        rowCount++;
      }
      for (let row = 0; row < rowCount; row++) {
        this.realVal2List[row] = [];
        for (let col = 0; col < 10; col++) {
          this.realVal2List[row][col] = {
            value: m2List[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
    } else {
      this.realVal1List = [[]];
      this.realVal2List = [[]];
      for (let col = 0; col < 10; col++) {
        this.realVal1List[0][col] = {
          value: '',
          hasError: false,
        };
        this.realVal2List[0][col] = {
          value: '',
          hasError: false,
        };
      }
    }
  }

  // 通过用户选择规定值或允许偏差值，计算实际值
  selectBias(index: number) {
    for (const item of this.record.spadValues!) {
      item.selected = false;
    }
    console.log('选择的值');
    this.record.spadValues![index].selected = true;
    this.detectionInfo.selectIndex = index;
    this.detectionInfo.biasStr = `${this.record.spadValues![index].spadValue}${
      this.record.spadValues![index].keywords
        ? `(${this.record.spadValues![index].keywords})`
        : ''
    }`;
    this.currentSpad = JSON.parse(
      JSON.stringify(this.record.spadValues![index])
    );
    if (
      this.currentSpad.refParamType &&
      // (this.currentSpad.refParamValue === undefined ||
      //   this.currentSpad.refParamValue === '') &&
      this.record.refParamValue !== undefined &&
      this.record.refParamValue !== ''
    ) {
      this.currentSpad.refParamValue = this.record.refParamValue;
    }

    this.parseBiasStr();
    this.calcAll();
  }

  onRefValueChange() {
    if (!this.special) {
      if (
        this.currentSpad.refParamType ===
        FormItemMeasuredProjectRefParamType.DESIGN
      ) {
        this.currentSpad.refParamValue = this.record.designValue1 ?? '';
      }
      this.parseBiasStr();
      this.calcAll();
    } else {
      this.detectionInfo.biasStr =
        this.record.designValue1?.replace('C', '≥').replace('M', '≥') ?? '';
      this.onBiasChange();
    }
  }

  // 用户输入bias信息后，进行解析。在初始化的时候也会做一次解析
  onBiasChange() {
    if (!this.detectionInfo.biasStr) {
      this.detectionInfo.biasError = true;
      this.detectionInfo.selectIndex = -1;
      this.calcAll();
      return;
    }
    if (this.record.spadValues && this.record.spadValues.length > 0) {
      for (let i = 0; i < this.record.spadValues.length; i++) {
        const item = this.record.spadValues[i];
        if (
          this.detectionInfo.biasStr ===
          `${item.spadValue}${item.keywords ? `(${item.keywords})` : ''}`
        ) {
          if (this.detectionInfo.selectIndex !== i) {
            this.selectBias(i);
          }
          return;
        }
      }
    }

    this.detectionInfo.selectIndex = -1;
    // 丢给服务端判断是否是符合标准的表达式，然后进行解析
    this.omFormItemMeasuredProjectService
      .parseSpadExpression(this.detectionInfo.biasStr)
      .subscribe((response) => {
        if (response) {
          for (const item of this.record.spadValues ?? []) {
            item.selected = false;
          }
          this.detectionInfo.selectIndex = -1;
          this.currentSpad = response;
          this.currentSpad.selected = true;
          this.currentSpad.userDefined = true;
          this.parseBiasStr();
          this.calcAll();
        }
      });
  }

  /**
   * 解析完整的bias信息。核心是将数据解析成对应的min和max
   *
   * @private
   * @return {*}
   * @memberof DialogFormZjFillComponent
   */
  private parseBiasStr() {
    this.detectionInfo.biasError = true;
    this.recommandRemark = `${this.record.designValue1 ?? ''}${
      this.record.designValue2 ? ` ${this.record.designValue2}` : ''
    }`;
    this.record.remarkValue = this.recommandRemark;
    if (!this.detectionInfo.biasStr) {
      // this.recommandRemark = '';
      // this.record.remarkValue = this.recommandRemark;
      return;
    }
    if (
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.USER_FILLIN ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.NOT_REQUIREMENT
    ) {
      // this.recommandRemark = '';
      // this.record.remarkValue = this.recommandRemark;
      // 用户自填项，或者无需填写。我们认为这是无法识别的信息。因此无法通过这个信息进行合格率的计算。对于这种情况，我们认为解析信息有误的。
      return;
    }
    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
    ) {
      // 允许偏差值。我们认为这是可以识别的信息。因此可以通过这个信息进行合格率的计算。
      // 允许偏差：±n 或『+n, -m』
      // this.recommandRemark = `${this.record.designValue1 ?? ''}${
      //   this.record.designValue2 ? ` ${this.record.designValue2}` : ''
      // }`;
      // this.record.remarkValue = this.recommandRemark;
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      try {
        // 允许偏差有两种情况，一种是±，一种是最大值和最小值
        let biasMin = 0;
        let biasMax = 0;
        if (this.currentSpad.spadValue!.includes('±')) {
          const bias = this.currentSpad.spadValue!.replace('±', '');
          this.precision = bias.includes('.') ? bias.split('.')[1].length : 0;
          if (this.currentSpad.refParamSymbol) {
            console.log(this.currentSpad.refParamSymbol);
            const abs = +eval(
              bias.replace(
                this.currentSpad.refParamSymbol,
                this.currentSpad.refParamValue!
              )
            );
            // 纯展示。没用
            this.currentSpad.calcDisplay = '±' + abs;
            biasMin = -abs;
            biasMax = abs;
          } else {
            biasMin = -+bias;
            biasMax = +bias;
          }
        }

        if (!this.record.designValue1) {
          // 如果没有设计值，那么无法计算最大值和最小值
          return;
        }
        if (this.currentSpad.spadValue!.includes(',')) {
          const bias = this.currentSpad.spadValue!.split(',');
          biasMin = Math.min(+bias[0], +bias[1]);
          biasMax = Math.max(+bias[0], +bias[1]);
          this.precision = biasMax.toString().includes('.')
            ? biasMax.toString().split('.')[1].length
            : 0;
        }
        this.detectionInfo.val1Min = +this.record.designValue1! + biasMin;
        this.detectionInfo.val1Max = +this.record.designValue1! + biasMax;
        if (
          this.record.designValue2 !== undefined &&
          this.record.designValue2 !== '' &&
          !isNaN(+this.record.designValue2)
        ) {
          this.detectionInfo.val2Min = +this.record.designValue2! + biasMin;
          this.detectionInfo.val2Max = +this.record.designValue2! + biasMax;
        }
        // 到这里确定没有错误
        this.detectionInfo.biasError = false;
      } catch (e) {
        return;
      }
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≥', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      if (!this.special) {
        try {
          if (!isNaN(+bias)) {
            this.record.designValue1 = bias;
          }
        } catch (e) {}
      }

      if (this.currentSpad.refParamSymbol) {
        console.log(this.currentSpad.refParamSymbol);
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.calcDisplay = '≥' + Math.round(abs * 100) / 100;
        this.detectionInfo.val1Min = +abs;
        this.detectionInfo.val1Max = Infinity;
        this.detectionInfo.val2Min = +abs;
        this.detectionInfo.val2Max = Infinity;
      } else {
        this.detectionInfo.val1Min = +bias;
        this.detectionInfo.val1Max = Infinity;
        this.detectionInfo.val2Min = +bias;
        this.detectionInfo.val2Max = Infinity;
      }

      this.detectionInfo.biasError = false;

      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE
    ) {
      // ≤ 规定值：≤ n

      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≤', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      if (!this.special) {
        try {
          if (!isNaN(+bias)) {
            this.record.designValue1 = bias;
          }
        } catch (e) {}
      }

      if (this.currentSpad.refParamSymbol) {
        console.log(this.currentSpad.refParamSymbol);
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.calcDisplay = '≤' + Math.round(abs * 100) / 100;
        this.detectionInfo.val1Min = -Infinity;
        this.detectionInfo.val1Max = +abs;
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +abs;
      } else {
        this.detectionInfo.val1Min = -Infinity;
        this.detectionInfo.val1Max = +bias;
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +bias;
      }

      this.detectionInfo.biasError = false;
      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE
    ) {
      // ~ 规定值：~ n
      const bias = this.currentSpad
        .spadValue!.replace('~', ' ')
        .replace('～', ' ')
        .split(' ');
      const min = Math.min(+bias[0], +bias[1]);
      const max = Math.max(+bias[0], +bias[1]);
      this.detectionInfo.val1Min = min;
      this.detectionInfo.val1Max = max;
      this.detectionInfo.val2Min = min;
      this.detectionInfo.val2Max = max;
      this.detectionInfo.biasError = false;

      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE
    ) {
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≥' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≥' + this.record.designValue2 : ''}`;
      // this.record.remarkValue = this.recommandRemark;
      // ≥ 设计值
      if (
        this.record.designValue1 === '' ||
        this.record.designValue1 === undefined ||
        isNaN(+this.record.designValue1)
      ) {
        // 如果没有设计值，那么无法计算最大值和最小值
        this.detectionInfo.biasError = true;
        return;
      }
      this.detectionInfo.val1Min = +this.record.designValue1!;
      this.detectionInfo.val1Max = Infinity;

      if (
        this.record.designValue2 !== undefined &&
        this.record.designValue2 !== '' &&
        !isNaN(+this.record.designValue2)
      ) {
        this.detectionInfo.val2Min = +this.record.designValue2!;
        this.detectionInfo.val2Max = Infinity;
      }
      this.detectionInfo.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE
    ) {
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≤' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≤' + this.record.designValue2 : ''}`;
      // this.record.remarkValue = this.recommandRemark;
      // ≤ 设计值
      if (
        this.record.designValue1 === '' ||
        this.record.designValue1 === undefined ||
        isNaN(+this.record.designValue1)
      ) {
        // 如果没有设计值，那么无法计算最大值和最小值
        this.detectionInfo.biasError = true;
        return;
      }
      this.detectionInfo.val1Min = -Infinity;
      this.detectionInfo.val1Max = +this.record.designValue1!;

      if (
        this.record.designValue2 !== undefined &&
        this.record.designValue2 !== '' &&
        !isNaN(+this.record.designValue2)
      ) {
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +this.record.designValue2!;
      }
      this.detectionInfo.biasError = false;
    }
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    setTimeout(() => {
      this.calcAll();
      this.submit();
    }, 100);
  }

  private handleDataBeforeConfirm(
    response: FormItemMeasuredProjectInfo
  ): FormItemMeasuredProjectInfo {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProjectInfo;
    data.designValue = `${!!data.designValue1 ? data.designValue1 : ''} ${
      !!data.designValue2 ? data.designValue2 : ''
    }`;
    if (this.currentSpad.userDefined) {
      data.spadValues?.push(this.currentSpad);
    } else {
      data.spadValues![this.detectionInfo.selectIndex!] = this.currentSpad;
    }
    const realValues1 = [];
    const devValues1 = [];
    for (const row of this.realVal1List) {
      for (const cell of row) {
        if (cell.value !== '') {
          realValues1.push(cell.value);
          if (
            this.currentSpad.spadType !==
            FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
          ) {
            devValues1.push(cell.value);
          } else {
            const value = +cell.value - +data.designValue1!;
            if (value > 0) {
              devValues1.push(`+${value.toFixed(this.precision)}`);
            } else {
              devValues1.push(`${value.toFixed(this.precision)}`);
            }
          }
        }
      }
    }
    const realValues2 = [];
    const devValues2 = [];
    if (
      this.record.designValue2 !== '' &&
      this.record.designValue2 !== undefined
    ) {
      for (const row of this.realVal2List) {
        for (const cell of row) {
          if (cell.value !== '') {
            realValues2.push(cell.value);
            if (
              this.currentSpad.spadType !==
              FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
            ) {
              devValues2.push(cell.value);
            } else {
              const value = +cell.value - +data.designValue2!;
              if (value > 0) {
                devValues2.push(`+${value.toFixed(this.precision)}`);
              } else {
                devValues2.push(`${value.toFixed(this.precision)}`);
              }
            }
          }
        }
      }
    }
    data.measuredValue = `${realValues1.join(' ')}${
      realValues2.length > 0 ? '\n' + realValues2.join(' ') : ''
    }`;
    data.deviationValue = `${devValues1.join(' ')}${
      devValues2.length > 0 ? '\n' + devValues2.join(' ') : ''
    }`;

    data.refParamType = this.currentSpad.refParamType;
    data.refParamName = this.currentSpad.refParamName;
    data.refParamSymbol = this.currentSpad.refParamSymbol;
    data.refParamVariable = this.currentSpad.refParamVariable;
    data.refParamValue = this.currentSpad.refParamValue;
    data.spadDesc = this.currentSpad.spadDesc;
    data.spadValue = this.currentSpad.spadValue;
    data.spadType = this.currentSpad.spadType;
    data.qaRate = this.passRate + '%';
    data.qualifiedJudge = '合格';
    data.remarkValue = `${data.designValue1 ?? ''}${
      data.designValue2 ? ` ${data.designValue2}` : ''
    }`;

    if (!!this.exp1 || !!this.exp2) {
      data.averageValue = `${this.exp1}${this.exp2 ? ` ${this.exp2}` : ''}`;
      data.averageValueType =
        FormItemMeasuredProjectAverageTypeType.TYPICAL_VALUE;
    } else {
      data.averageValue = `${this.avg1}${this.avg2 ? ` ${this.avg2}` : ''}`;
      data.averageValueType =
        FormItemMeasuredProjectAverageTypeType.AVERAGE_VALUE;
    }
    if (data.keywords?.includes('桩身完整性')) {
      data.remarkValue = data.remarkValue
        ?.replace('-1', 'Ⅰ类')
        .replace('-2', 'Ⅱ类')
        .replace('-3', 'Ⅲ类');
      data.measuredValue = data.measuredValue
        .replaceAll('-1', 'Ⅰ类')
        .replaceAll('-2', 'Ⅱ类')
        .replaceAll('-3', 'Ⅲ类');
      data.deviationValue = data.measuredValue;
      data.averageValue = '';
    }
    return data;
  }

  get canConfirm() {
    if (this.special && !this.record.designValue1) {
      return false;
    }
    return this.record.editable;
  }

  copy(str: string) {
    copy(str);
    this.nzMessage.info(`已复制${str}到剪贴板`);
  }

  private submit() {
    const data = this.handleDataBeforeConfirm(this.record);
    this.data.onConfirm!(data);
  }
}

export class DetectionInfo {
  designRealVal?: number[] = [];
  biasRealValMax?: number;
  biasRealValMin?: number;
  biasNeedDesign?: boolean = false;
  biasError?: boolean = false;
  realVal1List?: number[] = [];
  realVal2List?: number[] = [];
  passVal?: number = undefined;
  val1Min?: number;
  val1Max?: number;
  val2Min?: number;
  val2Max?: number;
  selectIndex?: number;
  biasStr?: string;
}
