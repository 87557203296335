import { Component, Input } from '@angular/core';
import copy from 'copy-text-to-clipboard';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogConfig } from '../../components/alert-dialog/dialog-config';
import { TableColumnOption } from '../../components/table-pro/table.config';
import { FimpAverageTypeType } from '../../models/form/fimp-average-type-type.enum';
import {
  FimpMeasuredValueFormat,
  FimpMeasuredValueFormatOptions,
} from '../../models/form/fimp-measured-value-format.enum';
import { FormItemMeasuredProjectInfo } from '../../models/form/form-item-measured-project-info.model';
import {
  FormItemMeasuredProjectRefParamType,
  FormItemMeasuredProjectRefParamTypeOptions,
} from '../../models/form/form-item-measured-project-ref-param-type.enum';
import {
  FormItemMeasuredProjectSpadType,
  FormItemMeasuredProjectSpadTypeOptions,
} from '../../models/form/form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectSpad } from '../../models/form/form-item-measured-project-spad.model';
import { WorkReportMatchDesignValueRequest } from '../../models/form/work-report-match-design-value-request.model';
import { WorkReportMeasuredValueInfo } from '../../models/form/work-report-measured-value-info.model';
import { ProjectSectionUnitForm } from '../../models/project/project-section-unit-form.model';
import { GlobalService } from '../../providers/global.service';
import { FileResponse } from '../../providers/request/file-response.model';
import { CommonService } from '../../services/common.service';
import { ProjectSectionUnitFormService } from '../../services/project-section-unit-form.service';
import { WorkReportService } from '../../services/work-report.service';
import { OmFormItemMeasuredProjectService } from './../../services/om-form-item-measured-project.service';
@Component({
  selector: 'app-dialog-form-zj-fill',
  templateUrl: './dialog-form-zj-fill.component.html',
  styleUrls: ['./dialog-form-zj-fill.component.scss'],
})
export class DialogFormZjFillComponent {
  @Input()
  data!: DialogConfig<FormItemMeasuredProjectInfo>;

  editing: { [name: string]: boolean } = {};

  FormItemMeasuredProjectSpadType = FormItemMeasuredProjectSpadType;

  record: FormItemMeasuredProjectInfo = new FormItemMeasuredProjectInfo();

  FormItemMeasuredProjectSpadTypeOptions =
    FormItemMeasuredProjectSpadTypeOptions;

  FormItemMeasuredProjectRefParamTypeOptions =
    FormItemMeasuredProjectRefParamTypeOptions;

  FimpMeasuredValueFormatOptions = FimpMeasuredValueFormatOptions;

  FimpMeasuredValueFormat = FimpMeasuredValueFormat;

  descList: string[] = [];

  detectionInfoList: DetectionGroupInfo[] = [];

  currentSpad = new FormItemMeasuredProjectSpad();

  recommandRemark = '';

  isMac = /macintosh|mac os x/i.test(navigator.userAgent);

  // showExp = false;

  totalCount = 0;
  passRate = 0;

  selectIndex?: number;
  biasStr?: string;
  biasError?: boolean = false;

  showDe = false;

  precision = 0;

  FormItemMeasuredProjectRefParamType = FormItemMeasuredProjectRefParamType;

  // 特殊情况

  special = false;

  CXX: TableColumnOption[] = [
    {
      label: 'C15',
      value: 'C15',
    },
    {
      label: 'C20',
      value: 'C20',
    },
    {
      label: 'C25',
      value: 'C25',
    },
    {
      label: 'C30',
      value: 'C30',
    },
    {
      label: 'C35',
      value: 'C35',
    },
    {
      label: 'C40',
      value: 'C40',
    },
    {
      label: 'C45',
      value: 'C45',
    },
    {
      label: 'C50',
      value: 'C50',
    },
    {
      label: 'C55',
      value: 'C55',
    },
    {
      label: 'C60',
      value: 'C60',
    },
    {
      label: 'C65',
      value: 'C65',
    },
    {
      label: 'C70',
      value: 'C70',
    },
    {
      label: 'C75',
      value: 'C75',
    },
    {
      label: 'C80',
      value: 'C80',
    },
  ];

  MXX: TableColumnOption[] = [
    {
      label: 'M5',
      value: 'M5',
    },
    {
      label: 'M7.5',
      value: 'M7.5',
    },
    {
      label: 'M10',
      value: 'M10',
    },
    {
      label: 'M15',
      value: 'M15',
    },
    {
      label: 'M20',
      value: 'M20',
    },
    {
      label: 'M25',
      value: 'M25',
    },
    {
      label: 'M30',
      value: 'M30',
    },
    {
      label: 'M35',
      value: 'M35',
    },
    {
      label: 'M40',
      value: 'M40',
    },
    {
      label: 'M45',
      value: 'M45',
    },
    {
      label: 'M50',
      value: 'M50',
    },
    {
      label: 'M55',
      value: 'M55',
    },
  ];

  ZS: TableColumnOption[] = [
    {
      label: 'Ⅱ类',
      value: '-2',
    },
  ];

  ZSV: TableColumnOption[] = [
    {
      label: 'Ⅰ类',
      value: '-1',
    },
    {
      label: 'Ⅱ类',
      value: '-2',
    },
    {
      label: 'Ⅲ类',
      value: '-3',
    },
  ];

  // 桩身完整性
  isZS = false;

  specialOptions: TableColumnOption[] = [];

  workReportList: ProjectSectionUnitForm[] = [];

  workReportMeasuredValueInfos: WorkReportMeasuredValueInfo[] = [];

  detectionMeasureInfo = new DetectionMeasureInfo();

  constructor(
    private globalService: GlobalService,
    private omFormItemMeasuredProjectService: OmFormItemMeasuredProjectService,
    private nzMessage: NzMessageService,
    private commonService: CommonService,
    private projectSectionUnitFormService: ProjectSectionUnitFormService,
    private workReportService: WorkReportService
  ) {}

  getForm(measuredValueFormat: FimpMeasuredValueFormat) {
    let code = '';
    switch (measuredValueFormat) {
      case FimpMeasuredValueFormat.SEE_JS101:
        code = 'JS101';
        break;
      case FimpMeasuredValueFormat.SEE_JS103:
        code = 'JS103';
        break;
      case FimpMeasuredValueFormat.SEE_GL127:
        code = 'GL127';
        break;
      case FimpMeasuredValueFormat.SEE_GL128:
        code = 'GL128';
        break;
      default:
        return;
    }
    this.projectSectionUnitFormService
      .findAll({
        formCode: code,
        projectId: this.globalService.projectId,
        sectionId: this.record.sectionId,
        sectionItemId: this.record.sectionItemId,
      })
      .subscribe((response: ProjectSectionUnitForm[]) => {
        const items: ProjectSectionUnitForm[] = [];
        for (const item of response ?? []) {
          if (item.workReportId) {
            items.push(item);
          }
        }
        this.workReportList = items.sort(
          (a, b) =>
            new Date(a.measuredDate ?? a.createTime!).getTime() -
            new Date(b.measuredDate ?? b.createTime!).getTime()
        );
        if (this.workReportList.length > 0 && !this.record.refWorkReportId) {
          this.record.refWorkReportId = `${
            this.workReportList[this.workReportList.length - 1].workReportId
          }`;
        }
        if (!!this.record.refWorkReportId) {
          this.onWorkReportChange();
        } else {
          this.parseBiasStr();
          this.calcAll();
        }
      });
  }

  onWorkReportChange() {
    if (!this.currentSpad.spadType) {
      if (this.record.refWorkReportMeasuredValueInfos) {
        this.parseWorkReportMeasuredValueInfos(
          this.record.refWorkReportMeasuredValueInfos ?? []
        );
      }
      return;
    }
    const data = JSON.parse(
      JSON.stringify(this.record)
    ) as FormItemMeasuredProjectInfo;

    data.refParamType = this.currentSpad.refParamType;
    data.refParamName = this.currentSpad.refParamName;
    data.refParamSymbol = this.currentSpad.refParamSymbol;
    data.refParamVariable = this.currentSpad.refParamVariable;
    data.refParamValue = this.currentSpad.refParamValue;
    data.refParamSpadValue = this.currentSpad.refParamSpadValue;
    data.spadDesc = this.currentSpad.spadDesc;
    data.spadValue = this.currentSpad.spadValue;
    data.spadType = this.currentSpad.spadType;

    this.workReportService
      .parseJsForm({
        projectId: this.globalService.projectId,
        sectionId: this.record.sectionId,
        sectionItemId: this.record.sectionItemId,
        formItemMeasuredProjectInfo: data,
      })
      .subscribe((response: FormItemMeasuredProjectInfo) => {
        console.log(response);
        this.parseWorkReportMeasuredValueInfos(
          response.refWorkReportMeasuredValueInfos ?? []
        );
        this.record.qaRate = response.qaRate;
        this.record.averageValue = response.averageValue;
        this.record.averageValueType = response.averageValueType;
      });
  }

  private parseWorkReportMeasuredValueInfos(
    data: WorkReportMeasuredValueInfo[]
  ) {
    this.workReportMeasuredValueInfos = data;
    for (const info of this.workReportMeasuredValueInfos) {
      for (const item of info.children ?? []) {
        if (item.key?.includes('Elevation')) {
          info[(item.key ?? '') + item.sortOrder] = item.value;
        } else {
          info[(item.key ?? '') + (item.direction ?? '')] = item.value;
        }
      }
    }
    this.parseBiasStr();
    this.calcAll();
  }

  upload(event: any) {
    const files = event.target['files'];
    if (files[0].type.indexOf('pdf') === -1) {
      return;
    }
    this.commonService
      .uploadFileWithoutProgress(files[0])
      .subscribe((url: FileResponse) => {
        event.target['value'] = '';
        if (url) {
          this.record.refReportFileId = url.fileId;
          this.record.refReportName = url.fileName;
        }
      });
  }

  clearReport() {
    this.record.refReportFileId = '';
    this.record.refReportName = '';
  }

  ngOnInit() {
    this.handleDataBeforeInit(this.data.data!);
  }

  measuredValueFormatChange() {
    this.clearReport();
    this.detectionInfoList = [];
    this.workReportMeasuredValueInfos = [];
    this.addGroup();
    this.record.refWorkReportId = undefined;
    this.detectionMeasureInfo = new DetectionMeasureInfo();
    this.getForm(this.record.measuredValueFormat!);
    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT
    ) {
      this.record.refReportMerge = true;
    }
  }

  addGroup() {
    if (!this.record.editAble) return;
    const group = new DetectionGroupInfo();
    this.detectionInfoList.push(group);
    if (this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
      group.realValDisplayList = this.parseRealData('');
      this.parseBiasStr();
      this.calcAll();
    }
  }

  clearGroup(index: number) {
    if (!this.record.editAble) return;
    this.detectionInfoList[index].part = '';
    this.detectionInfoList[index].realValTxt = '';
    this.detectionInfoList[index].biasValTxt = '';
    this.detectionInfoList[index].designValue = '';
    this.detectionInfoList[index].avg = '';
    this.detectionInfoList[index].exp = '';
    if (this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
      this.detectionInfoList[index].realValDisplayList = this.parseRealData('');
      this.parseBiasStr();
      this.calcAll();
    }
  }

  removeGroup(index: number) {
    if (!this.record.editAble) return;
    this.detectionInfoList.splice(index, 1);
    if (this.detectionInfoList.length === 1) {
      this.detectionInfoList[0].part = '';
    }
    if (this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
      this.parseBiasStr();
      this.calcAll();
    }
  }

  private calcAll() {
    let totalCount = 0;
    let passCount = 0;
    let maxR = 0;
    if (this.biasError) {
      for (const info of this.detectionInfoList) {
        for (const row of info.realValDisplayList) {
          for (const cell of row) {
            cell.hasError = false;
          }
        }
      }
    } else if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC
    ) {
      for (const info of this.detectionInfoList) {
        if (info.biasError) continue;
        let countGroup = 0;
        let sumReal = 0;
        for (const row of info.realValDisplayList) {
          for (const col of row) {
            if (col.value !== '') {
              if (col.value.includes('.')) {
                maxR = Math.max(maxR, col.value.split('.')[1].length);
              }
              countGroup++;
              sumReal += +col.value;
              if (+col.value >= info.valMin! && +col.value <= info.valMax!) {
                col.hasError = false;
                passCount++;
              } else {
                col.hasError = true;
              }
            } else {
              col.hasError = false;
            }
          }
        }
        totalCount += countGroup;
        const pow = Math.pow(10, maxR);
        info.avg =
          countGroup === 0
            ? ''
            : `${Math.round((sumReal / countGroup) * pow) / pow}`;
      }
      this.passRate =
        totalCount === 0
          ? 0
          : Math.round((passCount / totalCount) * 10000) / 100;
    } else if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL128 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL127
    ) {
      //   let total = 0;
      //   for (const info of this.workReportMeasuredValueInfos) {
      //     if (!this.detectionMeasureInfo.biasError) {
      //       if (
      //         this.detectionMeasureInfo.biasRealValMin! <=
      //           +info.deviationValue! &&
      //         this.detectionMeasureInfo.biasRealValMax! >= +info.deviationValue!
      //       ) {
      //         passCount++;
      //       }
      //     }
      //     total += +info.deviationValue!;
      //   }
      //   totalCount = this.workReportMeasuredValueInfos.length;
      //   this.detectionMeasureInfo.avg = this.record.averageValue;
      //   // this.detectionMeasureInfo.avg = `${
      //   //   totalCount > 0 ? Math.round(total / totalCount) : 0
      //   // }`;
      // } else if (
      //   this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103 ||
      //   this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL127
      // ) {
      //   let total = 0;
      //   for (const info of this.workReportMeasuredValueInfos) {
      //     if (!this.detectionMeasureInfo.biasError) {
      //       if (
      //         this.detectionMeasureInfo.biasRealValMin! <=
      //           +info.deviationValue! &&
      //         this.detectionMeasureInfo.biasRealValMax! >= +info.deviationValue!
      //       ) {
      //         passCount++;
      //       }
      //     }
      //     for (const item of info.children ?? []) {
      //       if (item.key === 'deviationElevation') {
      //         total += +item.value!;
      //       }
      //     }
      //   }
      totalCount = this.workReportMeasuredValueInfos.length;
    }

    // 根据下标进行计算
    this.totalCount = totalCount;
  }

  // 实测数据的输入框发生变化时，重新进行全表合格率计算、数量统计
  onRealBlur(groupIndex: number, rowIdx: number, colIdx: number) {
    const val =
      this.detectionInfoList[groupIndex].realValDisplayList[rowIdx][colIdx]
        .value;
    if (val !== '' && this.isNaN(+val)) {
      this.detectionInfoList[groupIndex].realValDisplayList[rowIdx][
        colIdx
      ].value = '';
    }
    this.calcAll();
  }

  // 实测数据的输入框发生变化时，重新进行全表合格率计算、数量统计
  // onReal2Blur(rowIdx: number, colIdx: number) {
  //   const val = this.realVal2List[rowIdx][colIdx].value;
  //   if (val !== '' && this.isNaN(+val)) {
  //     this.realVal2List[rowIdx][colIdx].value = '';
  //   }
  //   this.calcAll();
  // }

  // 实测数据的输入框发生变化时，判断是否需要新增一行
  onRealChange(groupIndex: number, rowIdx: number, colIdx: number) {
    // 这里判断是否需要新增一行。针对数据处理、合格率计算放到了onBlur中
    const val =
      this.detectionInfoList[groupIndex].realValDisplayList[rowIdx][colIdx]
        .value;
    if (val === '') {
      return;
    }
    if (
      rowIdx ===
        this.detectionInfoList[groupIndex].realValDisplayList.length - 1 &&
      colIdx === 9
    ) {
      this.detectionInfoList[groupIndex].realValDisplayList.push([]);
      for (let col = 0; col < 10; col++) {
        this.detectionInfoList[groupIndex].realValDisplayList[rowIdx + 1][col] =
          {
            value: '',
            hasError: false,
          };
      }
    }
  }

  // 实测数据的输入框发生变化时，判断是否需要新增一行
  // onReal2Change(rowIdx: number, colIdx: number) {
  //   // 这里判断是否需要新增一行。针对数据处理、合格率计算放到了onBlur中
  //   const val = this.realVal2List[rowIdx][colIdx].value;
  //   if (val === '') {
  //     return;
  //   }
  //   if (rowIdx === this.realVal2List.length - 1 && colIdx === 9) {
  //     this.realVal2List.push([]);
  //     for (let col = 0; col < 10; col++) {
  //       this.realVal2List[rowIdx + 1][col] = {
  //         value: '',
  //         hasError: false,
  //       };
  //     }
  //   }
  // }

  // 在输入框中按下上下左右箭头，实现焦点的移动。在输入框中按下ctrl+v，实现粘贴
  onRealInputKeyDown(
    event: KeyboardEvent,
    groupIndex: number,
    rowIdx: number,
    colIdx: number
  ) {
    const inputElements = document.querySelectorAll(`.real${groupIndex} input`);
    if (event.key === 'ArrowDown') {
      if (
        rowIdx <
        this.detectionInfoList[groupIndex].realValDisplayList.length - 1
      ) {
        // 向下1行
        (inputElements[rowIdx * 10 + colIdx + 10] as HTMLElement).focus();
      } else {
        // 回到第1行
        (inputElements[colIdx] as HTMLElement).focus();
      }
      return;
    }
    if (event.key === 'ArrowUp') {
      if (rowIdx > 0) {
        // 向上1行
        (inputElements[rowIdx * 10 + colIdx - 10] as HTMLElement).focus();
      } else {
        // 回到最后1行
        (
          inputElements[
            this.detectionInfoList[groupIndex].realValDisplayList.length * 10 -
              10 +
              colIdx
          ] as HTMLElement
        ).focus();
      }
      return;
    }
    if (event.key === 'ArrowLeft') {
      if (colIdx > 0) {
        // 向左1列
        (inputElements[rowIdx * 10 + colIdx - 1] as HTMLElement).focus();
      } else {
        // 回到上1行最后1列
        if (rowIdx > 0) {
          (inputElements[(rowIdx - 1) * 10 + 9] as HTMLElement).focus();
        } else {
          // 回到最后1行最后1列
          (
            inputElements[
              this.detectionInfoList[groupIndex].realValDisplayList.length *
                10 -
                1
            ] as HTMLElement
          ).focus();
        }
      }
      return;
    }
    if (event.key === 'ArrowRight') {
      if (colIdx < 9) {
        // 向右1列
        (inputElements[rowIdx * 10 + colIdx + 1] as HTMLElement).focus();
      } else {
        // 回到下1行第1列
        if (
          rowIdx <
          this.detectionInfoList[groupIndex].realValDisplayList.length - 1
        ) {
          (inputElements[(rowIdx + 1) * 10] as HTMLElement).focus();
        } else {
          // 回到第1行第1列
          (inputElements[0] as HTMLElement).focus();
        }
      }
      return;
    }
    if (
      (event.key.toLowerCase() === 'v' && event.ctrlKey && !this.isMac) ||
      (event.key.toLowerCase() === 'v' && event.metaKey && this.isMac)
    ) {
      setTimeout(() => {
        const source = this.detectionInfoList[groupIndex].realValDisplayList;
        if (
          source[rowIdx][colIdx].value.includes('\n') ||
          source[rowIdx][colIdx].value.includes('\t') ||
          source[rowIdx][colIdx].value.includes(' ')
        ) {
          this.detectionInfoList[groupIndex].realValDisplayList =
            this.onRealPaste(
              JSON.parse(JSON.stringify(source)),
              rowIdx,
              colIdx
            );
        }
      }, 100);
      return;
    }
  }

  // 对复制粘贴的值进行处理。特别处理了来自excel的粘贴
  onRealPaste(
    source: { value: string; hasError: boolean }[][],
    rowIdx: number,
    colIdx: number
  ) {
    let realValList = `${source[rowIdx][colIdx].value}`
      .toLowerCase()
      .replace(/mpa/g, '')
      .replace(/\n/g, ' ')
      .replace(/\t/g, ' ')
      .split(' ');

    let oldVals: string[] = [];
    for (let i = 0; i <= rowIdx; i++) {
      for (let j = 0; j < (i < rowIdx ? 10 : colIdx); j++) {
        oldVals.push(source[i][j].value);
      }
    }

    realValList = [...oldVals, ...realValList].filter(
      (v) => v === '' || (v !== '' && !isNaN(+v))
    );

    let rowCount = Math.ceil(realValList.length / 10);
    if (rowCount < 1) {
      rowCount = 1;
    }
    if (realValList.length % 10 === 0) {
      rowCount++;
    }

    let target: { value: string; hasError: boolean }[][] = [];
    for (let row = 0; row < rowCount; row++) {
      target[row] = [];
      for (let col = 0; col < 10; col++) {
        target[row][col] = {
          value: realValList[row * 10 + col] ?? '',
          hasError: false,
        };
      }
    }
    if (target[target.length - 1][9].value !== '') {
      target.push([]);
      for (let col = 0; col < 10; col++) {
        target[target.length - 1][col] = {
          value: '',
          hasError: false,
        };
      }
    }

    return target;
  }

  private handleDataBeforeInit(response: FormItemMeasuredProjectInfo) {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProjectInfo;

    if (!data.measuredValueFormat) {
      if (data.keywords?.includes('偏位')) {
        data.measuredValueFormat = data.isJL
          ? FimpMeasuredValueFormat.SEE_GL128
          : FimpMeasuredValueFormat.SEE_JS101;
      } else if (data.keywords?.includes('偏差')) {
        data.measuredValueFormat = data.isJL
          ? FimpMeasuredValueFormat.SEE_GL127
          : FimpMeasuredValueFormat.SEE_JS103;
      } else {
        data.measuredValueFormat = FimpMeasuredValueFormat.NUMERIC;
      }
    }

    this.FimpMeasuredValueFormatOptions = [];

    for (const item of FimpMeasuredValueFormatOptions ?? []) {
      if (
        !data.isJL &&
        (item.value === FimpMeasuredValueFormat.SEE_GL128 ||
          item.value === FimpMeasuredValueFormat.SEE_GL127)
      ) {
        continue;
      }
      if (
        data.isJL &&
        (item.value === FimpMeasuredValueFormat.SEE_JS101 ||
          item.value === FimpMeasuredValueFormat.SEE_JS103)
      ) {
        continue;
      }
      this.FimpMeasuredValueFormatOptions.push(item);
    }

    const detectionInfoList: DetectionGroupInfo[] = [];
    if (data.measuredValue) {
      try {
        const designValues = data.measuredValue.split('\n');
        for (let i = 0; i < designValues.length; i++) {
          detectionInfoList[i] = new DetectionGroupInfo();
        }
      } catch (e) {}
    }

    if (detectionInfoList.length === 0) {
      detectionInfoList.push(new DetectionGroupInfo());
    }

    if (data.designValue) {
      try {
        const designValues = data.designValue.replace(' ', '\n').split('\n');
        for (let i = 0; i < designValues.length; i++) {
          detectionInfoList[i].designValue = designValues[i] ?? '';
        }
      } catch (e) {}
    }

    const averageValues = (data.averageValue ?? '')
      .replace(' ', '\n')
      .split('\n');
    for (let i = 0; i < averageValues.length; i++) {
      if (detectionInfoList[i] !== undefined) {
        if (data.averageValueType === FimpAverageTypeType.AVERAGE_VALUE) {
          detectionInfoList[i].avg = averageValues[i] ?? '';
        } else {
          detectionInfoList[i].exp = averageValues[i] ?? '';
        }
      }
    }

    const measuredValues = (data.measuredValue ?? '').split('\n');
    for (let i = 0; i < measuredValues.length; i++) {
      if (data.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
        detectionInfoList[i].realValDisplayList = this.parseRealData(
          measuredValues[i]
        );
      } else {
        detectionInfoList[i].realValTxt = measuredValues[i] ?? '';
      }
    }
    if (data.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT) {
      const deviationValues = (data.deviationValue ?? '').split('\n');
      for (let i = 0; i < deviationValues.length; i++) {
        detectionInfoList[i].biasValTxt = deviationValues[i] ?? '';
      }
    }

    const partValues = (data.partValue ?? '').split('\n');
    for (let i = 0; i < partValues.length; i++) {
      if (detectionInfoList[i] !== undefined) {
        detectionInfoList[i].part = partValues[i] ?? '';
      }
    }

    if (data.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT) {
      data.refReportMerge = `${data.refReportMerge}` === 'true';
    } else {
      data.refReportMerge = '';
    }

    this.detectionInfoList = detectionInfoList;
    try {
      for (const index in this.record.designValueZjExistMap)
        this.detectionInfoList[+index].notMatch =
          !this.record.designValueZjExistMap[+index];
    } catch (e) {}
    this.record = data;

    if (
      this.record.keywords?.includes('混凝土强度') &&
      !this.record.keywords.includes('泡沫')
    ) {
      this.special = true;
      this.specialOptions = this.CXX;
    }
    if (
      this.record.keywords?.includes('砂浆强度') ||
      this.record.keywords?.includes('浆体强度')
    ) {
      this.special = true;
      this.specialOptions = this.MXX;
    }
    if (
      this.record.keywords?.includes('混凝土抗压强度或砂浆强度') ||
      this.record.keywords?.includes('混凝土或砂浆强度')
    ) {
      this.special = true;
      this.specialOptions = [...this.CXX, ...this.MXX];
    }
    if (this.record.keywords?.includes('桩身完整性')) {
      this.isZS = true;
    }

    let selected = false;

    if (
      (this.isZS || this.special) &&
      this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC
    ) {
      if (this.isZS) {
        this.record.spadValue = '≥-2';
      }
      if (this.special) {
        this.record.spadValue = '≥设计值';
      }
    } else if (data.spadValues && data.spadValues.length > 0) {
      for (let i = 0; i < data.spadValues.length; i++) {
        if (data.spadValues[i].selected) {
          selected = true;
          setTimeout(() => {
            // 为了解决ngModelChange的问题，延迟100ms
            this.selectBias(i);
          }, 100);
          break;
        }
      }
    }
    if (!selected && this.record.spadValue) {
      this.biasStr = this.record.spadValue;
      this.onBiasChange();
    }
  }

  // 再次进入编辑页面后，做一次实测数据的解析。将实测数据放到对应的位置，并且绘制表格
  private parseRealData(
    measuredValue: string
  ): { value: string; hasError: boolean }[][] {
    let realValList: { value: string; hasError: boolean }[][] = [[]];
    if (!!measuredValue) {
      // const measuredValues = measuredValue
      //   .replaceAll('Ⅰ类', '-1')
      //   .replaceAll('Ⅱ类', '-2')
      //   .replaceAll('Ⅲ类', '-3');
      let mList: string[] = [];
      mList = measuredValue
        .replaceAll('Ⅰ类', '-1')
        .replaceAll('Ⅱ类', '-2')
        .replaceAll('Ⅲ类', '-3')
        .split(' ');
      let rowCount = Math.ceil(mList.length / 10);
      if (rowCount < 1) {
        rowCount = 1;
      }
      if (mList.length % 10 === 0) {
        rowCount++;
      }
      for (let row = 0; row < rowCount; row++) {
        realValList[row] = [];
        for (let col = 0; col < 10; col++) {
          realValList[row][col] = {
            value: mList[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
    } else {
      for (let col = 0; col < 10; col++) {
        realValList[0][col] = {
          value: '',
          hasError: false,
        };
      }
    }
    return realValList;
  }

  // 通过用户选择规定值或允许偏差值，计算实际值
  selectBias(index: number) {
    if (!this.record.editAble) return;
    for (const item of this.record.spadValues!) {
      item.selected = false;
    }
    this.record.spadValues![index].selected = true;
    this.selectIndex = index;
    this.currentSpad = JSON.parse(
      JSON.stringify(this.record.spadValues![index])
    );
    this.biasStr = `${
      !!this.currentSpad.spadValue
        ? this.currentSpad.spadValue
        : this.currentSpad.spadDesc
    }${
      this.record.spadValues![index].keywords
        ? `(${this.record.spadValues![index].keywords})`
        : ''
    }`;
    if (
      this.currentSpad.refParamType &&
      this.record.refParamValue !== undefined &&
      this.record.refParamValue !== ''
    ) {
      this.currentSpad.refParamValue = this.record.refParamValue;
    }
    this.getForm(this.record.measuredValueFormat!);
    this.parseBiasStr();
    this.calcAll();
  }

  onRefValueChange(groupIndex?: number) {
    if (
      this.currentSpad.refParamType ===
      FormItemMeasuredProjectRefParamType.DESIGN
    ) {
      this.currentSpad.refParamValue = this.detectionInfoList
        .map((item) => item.designValue ?? '')
        .join('\n');
    }
    if (!this.currentSpad.refParamValue) {
      this.currentSpad.refParamSpadValue = undefined;
    }
    this.parseBiasStr();
    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL128 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL127
    ) {
      this.onWorkReportChange();
    } else {
      this.calcAll();
    }

    if (this.record.isJL && groupIndex !== undefined) {
      const req = new WorkReportMatchDesignValueRequest();
      req.projectId = this.globalService.projectId;
      req.sectionId = this.record.sectionId;
      req.sectionItemId = this.record.sectionItemId;
      req.formId = this.record.formId;
      req.designValue = this.detectionInfoList[groupIndex].designValue;
      req.formCode = this.record.formCode;
      req.formCopiesOrder = this.record.formCopiesOrder;
      req.keywords = this.record.keywords;
      this.workReportService.matchDesignValue(req).subscribe((response) => {
        this.detectionInfoList[groupIndex].notMatch =
          !response || !response.match;
      });
    }
  }

  // 用户输入bias信息后，进行解析。在初始化的时候也会做一次解析
  onBiasChange() {
    if (!this.biasStr) {
      this.biasError = true;
      for (const info of this.detectionInfoList) {
        info.biasError = true;
      }
      this.selectIndex = -1;
      this.calcAll();
      return;
    }
    if (this.record.spadValues && this.record.spadValues.length > 0) {
      for (let i = 0; i < this.record.spadValues.length; i++) {
        const item = this.record.spadValues[i];
        if (
          this.biasStr ===
          `${!!item.spadValue ? item.spadValue : item.spadDesc}${
            item.keywords ? `(${item.keywords})` : ''
          }`
        ) {
          if (this.selectIndex !== i) {
            this.selectBias(i);
          }
          return;
        }
      }
    }

    this.selectIndex = -1;
    // 丢给服务端判断是否是符合标准的表达式，然后进行解析
    this.omFormItemMeasuredProjectService
      .parseSpadExpression(this.biasStr)
      .subscribe((response) => {
        if (response) {
          for (const item of this.record.spadValues ?? []) {
            item.selected = false;
          }
          this.selectIndex = -1;
          this.currentSpad = response;
          this.currentSpad.selected = true;
          this.currentSpad.userDefined = true;
          this.getForm(this.record.measuredValueFormat!);
          this.parseBiasStr();
          this.calcAll();
        }
      });
  }

  /**
   * 解析完整的bias信息。核心是将数据解析成对应的min和max
   *
   * @private
   * @return {*}
   * @memberof DialogFormZjFillComponent
   */
  private parseBiasStr() {
    this.biasError = true;
    for (const info of this.detectionInfoList) {
      info.biasError = true;
    }
    // this.record.remarkValue = this.detectionInfoList
    //   .map((item) => item.designValue ?? '')
    //   .join('\n');
    // this.record.remarkValue = this.detectionInfoList
    //   .map((item) => item.designValue ?? '')
    //   .join('\n');
    if (!this.biasStr) {
      return;
    }
    if (
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.USER_FILLIN ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.NOT_REQUIREMENT
    ) {
      for (const info of this.detectionInfoList) {
        info.biasError = false;
      }
      // this.record.remarkValue = '';
      // 用户自填项，或者无需填写。我们认为这是无法识别的信息。因此无法通过这个信息进行合格率的计算。对于这种情况，我们认为解析信息有误的。
      return;
    }
    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
    ) {
      // 允许偏差值。我们认为这是可以识别的信息。因此可以通过这个信息进行合格率的计算。
      // 允许偏差：±n 或『+n, -m』
      // this.recommandRemark = `${this.record.designValue1 ?? ''}${
      //   this.record.designValue2 ? ` ${this.record.designValue2}` : ''
      // }`;
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      try {
        // 允许偏差有两种情况，一种是±，一种是最大值和最小值
        let biasMin = 0;
        let biasMax = 0;
        if (this.currentSpad.spadValue!.includes('±')) {
          const bias = this.currentSpad.spadValue!.replace('±', '');
          this.precision = bias.includes('.') ? bias.split('.')[1].length : 0;
          if (this.currentSpad.refParamSymbol) {
            const abs = +eval(
              bias.replace(
                this.currentSpad.refParamSymbol,
                this.currentSpad.refParamValue!
              )
            );
            // 纯展示计算所得。没用
            this.currentSpad.refParamSpadValue = '±' + abs;
            biasMin = -abs;
            biasMax = abs;
          } else {
            biasMin = -+bias;
            biasMax = +bias;
          }
        }

        // if (!this.record.designValue1) {
        //   // 如果没有设计值，那么无法计算最大值和最小值
        //   return;
        // }
        for (const info of this.detectionInfoList) {
          if (!info.designValue) {
            continue;
          }
          if (this.currentSpad.spadValue!.includes(',')) {
            const bias = this.currentSpad.spadValue!.split(',');
            biasMin = Math.min(+bias[0], +bias[1]);
            biasMax = Math.max(+bias[0], +bias[1]);
            this.precision = biasMax.toString().includes('.')
              ? biasMax.toString().split('.')[1].length
              : 0;
          }
          info.valMin = +info.designValue! + biasMin;
          info.valMax = +info.designValue! + biasMax;
          info.biasError = false;
        }

        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = biasMin;
        this.detectionMeasureInfo.biasRealValMax = biasMax;
        this.detectionMeasureInfo.biasError = false;
        // 到这里确定没有错误
        this.biasError = false;
      } catch (e) {
        return;
      }
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≥', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      // if (!this.special) {
      //   try {
      //     if (!isNaN(+bias)) {
      //       this.record.designValue1 = bias;
      //     }
      //   } catch (e) {}
      // }

      if (this.currentSpad.refParamSymbol) {
        console.log(this.currentSpad.refParamSymbol);
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.refParamSpadValue = '≥' + Math.round(abs * 100) / 100;
        for (const info of this.detectionInfoList) {
          info.valMin = +abs;
          info.valMax = Infinity;
          info.biasError = false;
        }
        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = +abs;
        this.detectionMeasureInfo.biasRealValMax = Infinity;
        this.detectionMeasureInfo.biasError = false;
      } else {
        for (const info of this.detectionInfoList) {
          info.valMin = +bias;
          info.valMax = Infinity;
          info.biasError = false;
        }
        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = +bias;
        this.detectionMeasureInfo.biasRealValMax = Infinity;
        this.detectionMeasureInfo.biasError = false;
      }
      this.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE
    ) {
      // ≤ 规定值：≤ n

      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≤', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      // if (!this.special) {
      //   try {
      //     if (!isNaN(+bias)) {
      //       this.record.designValue1 = bias;
      //     }
      //   } catch (e) {}
      // }

      if (this.currentSpad.refParamSymbol) {
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.refParamSpadValue = '≤' + Math.round(abs * 100) / 100;
        for (const info of this.detectionInfoList) {
          info.valMin = -Infinity;
          info.valMax = +abs;
          info.biasError = false;
        }
        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = -Infinity;
        this.detectionMeasureInfo.biasRealValMax = +abs;
        this.detectionMeasureInfo.biasError = false;
      } else {
        for (const info of this.detectionInfoList) {
          info.valMin = -Infinity;
          info.valMax = +bias;
          info.biasError = false;
        }
        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = -Infinity;
        this.detectionMeasureInfo.biasRealValMax = +bias;
        this.detectionMeasureInfo.biasError = false;
      }

      this.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE
    ) {
      // ~ 规定值：~ n
      const bias = this.currentSpad
        .spadValue!.replace('~', ' ')
        .replace('～', ' ')
        .split(' ');
      const min = Math.min(+bias[0], +bias[1]);
      const max = Math.max(+bias[0], +bias[1]);
      for (const info of this.detectionInfoList) {
        info.valMin = min;
        info.valMax = max;
        info.biasError = false;
      }
      this.biasError = false;

      this.detectionMeasureInfo = new DetectionMeasureInfo();
      this.detectionMeasureInfo.biasRealValMin = min;
      this.detectionMeasureInfo.biasRealValMax = max;
      this.detectionMeasureInfo.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≥' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≥' + this.record.designValue2 : ''}`;

      // ≥ 设计值
      // if (
      //   this.record.designValue1 === '' ||
      //   this.record.designValue1 === undefined ||
      //   isNaN(+this.record.designValue1)
      // ) {
      //   // 如果没有设计值，那么无法计算最大值和最小值
      //   this.biasError = true;
      //   return;
      // }
      for (const info of this.detectionInfoList) {
        info.biasError = true;
        if (this.currentSpad.refParamSymbol) {
          try {
            const value = +eval(
              this.currentSpad
                .spadValue!.replace('≥', '')
                .replace('设计值', info.designValue!)
                .replace(
                  this.currentSpad.refParamSymbol,
                  this.currentSpad.refParamValue!
                )
            );
            if (isNaN(value)) {
              continue;
            }
            info.valMax = Infinity;
            info.valMin = value;
            info.biasError = false;
          } catch (e) {
            continue;
          }
        } else {
          if (
            info.designValue === '' ||
            info.designValue === undefined ||
            isNaN(
              +info.designValue
                .replace('Ⅰ类', '-1')
                .replace('Ⅱ类', '-2')
                .replace('Ⅲ类', '-3')
                .replace('C', '')
                .replace('M', '')
            )
          ) {
            continue;
          }
          info.valMin = +info.designValue
            .replace('Ⅰ类', '-1')
            .replace('Ⅱ类', '-2')
            .replace('Ⅲ类', '-3')
            .replace('C', '')
            .replace('M', '');
          info.valMax = Infinity;
          info.biasError = false;
        }
      }
      this.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≤' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≤' + this.record.designValue2 : ''}`;
      // ≤ 设计值
      for (const info of this.detectionInfoList) {
        info.biasError = true;
        if (this.currentSpad.refParamSymbol) {
          try {
            const value = +eval(
              this.currentSpad
                .spadValue!.replace('≤', '')
                .replace('设计值', info.designValue!)
                .replace(
                  this.currentSpad.refParamSymbol,
                  this.currentSpad.refParamValue!
                )
            );
            if (isNaN(value)) {
              continue;
            }
            info.valMin = -Infinity;
            info.valMax = value;
            info.biasError = false;
          } catch (e) {
            continue;
          }
        } else {
          if (
            info.designValue === '' ||
            info.designValue === undefined ||
            isNaN(
              +info.designValue
                .replace('Ⅰ类', '-1')
                .replace('Ⅱ类', '-2')
                .replace('Ⅲ类', '-3')
                .replace('C', '')
                .replace('M', '')
            )
          ) {
            // 如果没有设计值，那么无法计算最大值和最小值
            info.biasError = true;
            continue;
          }
          info.valMin = -Infinity;
          info.valMax = +info.designValue
            .replace('Ⅰ类', '-1')
            .replace('Ⅱ类', '-2')
            .replace('Ⅲ类', '-3')
            .replace('C', '')
            .replace('M', '');
          info.biasError = false;
        }
      }
      this.biasError = false;
    }
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    setTimeout(() => {
      this.calcAll();
      this.submit();
    }, 100);
  }

  private handleDataBeforeConfirm(
    response: FormItemMeasuredProjectInfo
  ): FormItemMeasuredProjectInfo {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProjectInfo;
    data.designValue = this.detectionInfoList
      .map((item) => item.designValue ?? '')
      .join('\n');

    if (this.currentSpad.userDefined) {
      data.spadValues?.push(this.currentSpad);
    } else {
      data.spadValues![this.selectIndex!] = this.currentSpad;
    }

    const groupRealValues: string[] = [];
    const groupDevValues: string[] = [];
    const groupAvgValues: string[] = [];
    const groupExpValues: string[] = [];

    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT
    ) {
      for (const info of this.detectionInfoList) {
        const realValue: string[] = [];
        const devValues: string[] = [];
        if (
          this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC
        ) {
          for (const row of info.realValDisplayList) {
            for (const cell of row) {
              if (cell.value !== '') {
                realValue.push(cell.value);
                if (
                  this.currentSpad.spadType !==
                  FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
                ) {
                  devValues.push(cell.value);
                } else {
                  if (!info.designValue) {
                    devValues.push('');
                  } else {
                    const value = +cell.value - +info.designValue!;
                    if (value > 0) {
                      devValues.push(`+${value.toFixed(this.precision)}`);
                    } else {
                      devValues.push(`${value.toFixed(this.precision)}`);
                    }
                  }
                }
              }
            }
          }
          groupRealValues.push(realValue.join(' '));
          groupDevValues.push(devValues.join(' '));
        }
        if (
          this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT
        ) {
          groupRealValues.push(info.realValTxt ?? '');
          groupDevValues.push(info.biasValTxt ?? '');
        }
        groupAvgValues.push(info.avg ?? '');
        groupExpValues.push(info.exp ?? '');
      }
    }
    data.measuredValue = groupRealValues.join('\n');
    data.deviationValue = groupDevValues.join('\n');
    data.partValue = this.detectionInfoList
      .map((item) => item.part ?? '')
      .join('\n');

    let remarkValue = this.detectionInfoList
      .map((item) => item.designValue ?? '')
      .join('\n');

    if (remarkValue.replaceAll('\n', '').length === 0) {
      remarkValue = '';
    }
    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL127 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103
    ) {
      const group: {
        [key: string]: {
          realValue: string;
          designValue: string;
        };
      } = {};

      for (const item of this.workReportMeasuredValueInfos) {
        if (!group[item.value!]) {
          group[item.value!] = {
            realValue: '',
            designValue: '',
          };
        }
        for (const child of item.children ?? []) {
          if (child.key === 'measuredElevation') {
            group[item.value!].realValue += ' ' + child.value;
          }
          if (child.key === 'designElevation') {
            group[item.value!].designValue += ' ' + child.value;
          }
        }
      }
      const realValues: string[] = [];
      const designValues: string[] = [];
      for (const key in group) {
        realValues.push(group[key].realValue.trim());
        designValues.push(group[key].designValue.trim());
      }
      data.measuredValue = realValues.join('\n');
      data.designValue = designValues.join('\n');
    }
    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL128 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101
    ) {
      data.designValue = this.currentSpad.spadValue ?? '';
      data.measuredValue = this.workReportMeasuredValueInfos
        .map((item) => item.deviationValue)
        .join(' ');
      data.deviationValue = data.measuredValue;
    }

    if (
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE
    ) {
      if (remarkValue.length === 0 && groupRealValues.join('').length > 0) {
        remarkValue = this.currentSpad.spadValue ?? '';
        if (!!this.currentSpad.refParamSymbol) {
          remarkValue = !!this.currentSpad.refParamValue
            ? this.currentSpad.refParamSpadValue ?? ''
            : '';
        }
      }
    }

    data.remarkValue = remarkValue;

    data.refParamType = this.currentSpad.refParamType;
    data.refParamName = this.currentSpad.refParamName;
    data.refParamSymbol = this.currentSpad.refParamSymbol;
    data.refParamVariable = this.currentSpad.refParamVariable;
    data.refParamValue = this.currentSpad.refParamValue;
    data.refParamSpadValue = this.currentSpad.refParamSpadValue;
    data.spadDesc = this.currentSpad.spadDesc;
    data.spadValue = this.currentSpad.spadValue;
    data.spadType = this.currentSpad.spadType;
    if (this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
      data.qaRate =
        groupRealValues.join('').length > 0 ? this.passRate + '' : '';
    } else if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT
    ) {
      data.qaRate = '';
    }
    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT
    ) {
      data.refReportMerge = `${data.refReportMerge}`;
      data.measuredValue = '见报告';
      data.deviationValue = '见报告';
    } else {
      data.refReportMerge = '';
    }
    data.qualifiedJudge = groupRealValues.join('').length > 0 ? '合格' : '';

    if (groupExpValues.join('').length > 0) {
      data.averageValue = groupExpValues.join('\n');
      data.averageValueType = FimpAverageTypeType.TYPICAL_VALUE;
    } else {
      data.averageValue = groupAvgValues.join('\n');
      data.averageValueType = FimpAverageTypeType.AVERAGE_VALUE;
    }
    if (data.keywords?.includes('桩身完整性')) {
      data.designValue = data.designValue
        ?.replace('-1', 'Ⅰ类')
        .replace('-2', 'Ⅱ类')
        .replace('-3', 'Ⅲ类');
      data.remarkValue = data.remarkValue
        ?.replace('-1', 'Ⅰ类')
        .replace('-2', 'Ⅱ类')
        .replace('-3', 'Ⅲ类');
      data.averageValue = '';
      data.measuredValue = data.measuredValue
        .replaceAll('-1', 'Ⅰ类')
        .replaceAll('-2', 'Ⅱ类')
        .replaceAll('-3', 'Ⅲ类');
      data.deviationValue = data.measuredValue;
    }
    return data;
  }

  get canConfirm() {
    if (this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
      if (this.special && !this.biasStr) {
        return false;
      }
      if (
        this.currentSpad.spadType ===
          FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION ||
        this.currentSpad.spadType ===
          FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE ||
        this.currentSpad.spadType ===
          FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE
      ) {
        for (const info of this.detectionInfoList) {
          let hasReal = false;
          for (const row of info.realValDisplayList) {
            for (const cell of row) {
              if (cell.value) {
                hasReal = true;
              }
            }
          }
          if (!info.designValue && hasReal) {
            return false;
          }
        }
      }
    }
    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT
    ) {
      if (!this.record.refReportName || !this.record.refReportFileId) {
        return false;
      }
    }
    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL127 ||
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_GL128
    ) {
      if (!this.record.refWorkReportId) {
        return false;
      }
    }
    return this.record.editable;
  }

  copy(str: string) {
    copy(str);
    this.nzMessage.info(`已复制${str}到剪贴板`);
  }

  private submit() {
    const data = this.handleDataBeforeConfirm(this.record);
    console.log(data);
    this.data.onConfirm!(data);
  }
}

export class DetectionGroupInfo {
  designRealVal?: number[] = [];
  biasRealValMax?: number;
  biasRealValMin?: number;
  biasNeedDesign?: boolean = false;
  realValDisplayList: { value: string; hasError: boolean }[][] = [];
  passVal?: number = undefined;
  valMin?: number;
  valMax?: number;
  exp?: string;
  avg?: string;
  designValue?: string;
  biasError?: boolean = true;
  part?: string = '';
  realValTxt?: string = '';
  biasValTxt?: string = '';
  // 前段自用
  spec1?: string;
  spec2?: string;
  notMatch?: boolean;
}

export class DetectionMeasureInfo {
  biasRealValMax?: number;
  biasRealValMin?: number;
  avg?: string;
  biasError?: boolean = true;
}
