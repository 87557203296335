import { MaterialInspection } from '../inspection/material-inspection.model';
import { ProjectSectionUnitFormType } from '../project/project-section-unit-form-type.enum';

export class InitWorkReportRequest {
  projectId?: number;
  sectionId?: number;
  sectionItemId?: number;
  menuFormId?: number;
  formId?: number;
  formStarted?: boolean;
  formCompleted?: boolean;
  formSource?: ProjectSectionUnitFormType;
  thirdBusinessIds?: string;
  instructionId?: number;
  sn?: string;
  formCodeId?: number;
  formCodeVer?: string;
  formCodeSnId?: number;
  formCodeSn?: number;
  //   workProcedure?: WorkProcedure;
  materialInspections?: Array<MaterialInspection>;
  materialTypeCode?: string;
  userId?: number;
}
