import { FormItemMeasuredProjectSpadType } from './form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectRefParamType } from './form-item-measured-project-ref-param-type.enum';
export class FormItemMeasuredProjectSpad {
  keywords?: string;
  spadDesc?: string;
  spadValue?: string;
  spadType?: FormItemMeasuredProjectSpadType;
  refParamType?: FormItemMeasuredProjectRefParamType;
  refParamName?: string;
  refParamSymbol?: string;
  refParamVariable?: string;
  refParamValue?: string;
  // 引用参数-对应检测项目名称
  refParamKeywrods?: string;
  refParamFieldName?: string;
  selected?: boolean;
  defaulted?: boolean;
  userDefined?: boolean;
  // 前端自用
  calcDisplay?: string;
  spadError?: boolean;
}
