export class WorkReportMatchDesignValueRequest {
  projectId?: number;
  sectionId?: number;
  sectionItemId?: number;
  formId?: number;
  formCode?: string;
  formCopiesOrder?: number;
  keywords?: string;
  designValue?: string;
}
