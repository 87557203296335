import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ModalService, TransformableElement } from 'ng-devui';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, zip } from 'rxjs';
import { DialogSignPwdComponent } from 'src/app/modules/main/dialogs/operations-manage/dialog-sign-pwd/dialog-sign-pwd.component';
import { environment } from 'src/environments/environment';
import { FileType } from '../../models/form/file-type.enum';
import { SignAuthInfo } from '../../models/staff/sign-auth-info.model';
import { FileResponse } from '../../providers/request/file-response.model';
import { CommonService } from '../../services/common.service';
import { OmUserPersonService } from '../../services/om-user-person.service';
import { OpenApiSignOrderService } from '../../services/open-api-signorder.service';
import {
  AlertDialogComponent,
  AlertDialogType,
} from '../alert-dialog/alert-dialog.component';
import { FilePreviewTypeListMap } from '../file-preview-entry/file-preview-entry.component';
import { FilePreviewComponent } from '../file-preview/file-preview.component';
import { FlowInfo } from '../../models/form/flow-info.model';
import { SignPosType } from '../../models/form/sign-pos-type.enum';
import { WorkFlowBatchInfo } from '../../models/form/work-flow-batch-info.model';
import { WorkFlowBatchSignRequest } from '../../models/form/work-flow-batch-sign-request.model';
import { WorkFlowFileInfo } from '../../models/form/work-flow-file-info.model';
import { WorkFlowInfo } from '../../models/form/work-flow-info.model';
import { WorkFlowSignFieldInfo } from '../../models/form/work-flow-sign-field-info.model';
import {
  WorkFlowSignFieldType,
  WorkFlowSignFieldTypeMap,
} from '../../models/form/work-flow-sign-field-type.enum';
import { WorkFlowSignerInfo } from '../../models/form/work-flow-signer-info.model';
import { WorkFlowStatus } from '../../models/form/work-flow-status.enum';
import { WorkReportDocType } from '../../models/form/work-report-doc-type.enum';

@Component({
  selector: 'app-custom-sign',
  templateUrl: './custom-sign.component.html',
  styleUrls: ['./custom-sign.component.scss'],
})
export class CustomSignComponent {
  isDebug = false;

  FilePreviewTypeListMap = FilePreviewTypeListMap;
  FileType = FileType;
  transformableImageElementRef!: TransformableElement;

  WorkFlowSignFieldType = WorkFlowSignFieldType;

  currentAttachFileId: string | undefined = '';

  currentSignFileId: string | undefined = '';

  // currnetSignIndex = 0;

  environment = environment;

  rMap: { [key: string]: boolean } = {};

  height = 0;
  width = 0;
  index = 0;

  pdfZoom = 1;
  pdfPages = 1;
  pdfTotal = 0;

  inited = false;

  WorkFlowSignFieldTypeMap = WorkFlowSignFieldTypeMap;

  currentDragItem: WorkFlowSignFieldInfo | undefined = undefined;

  currentDragFieldPage: number | undefined = -1;
  currentDragFieldIndex: number | undefined = -1;

  currentWorkFlowInfo: WorkFlowInfo | undefined;
  workFlowInfoList: Array<WorkFlowInfo> = [];

  WorkReportDocType = WorkReportDocType;

  freeSigner: WorkFlowSignerInfo | undefined = undefined;

  normalWidgetMap: { [key: string]: Array<Array<WorkFlowSignFieldInfo>> } = {};

  currentWidgetMap: { [key: string]: Array<Array<WorkFlowSignFieldInfo>> } = {};

  attachFileListMap: { [key: string]: Array<WorkFlowFileInfo> } = {};

  SignPosType = SignPosType;

  currentFileType = FileType.PDF;

  workFlowMap: { [key: string]: WorkFlowInfo } = {};

  dateFieldCache: { [key: string]: WorkFlowSignFieldInfo } = {};

  waitAddressList: string[] = [];

  workFlowId: number | undefined;
  resp: any = {};
  singlePage = false;

  needSideBar = false;

  constructor(
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private openApiSignOrderService: OpenApiSignOrderService,
    private omUserPersonService: OmUserPersonService,
    private message: NzMessageService,
    private commonService: CommonService
  ) {}

  token = '';

  redirectUrl = '';
  ngOnInit(): void {
    this.parseQuery();
    this.startSign();
  }

  private startSign() {
    if (!this.token) {
      return;
    }
    this.normalWidgetMap = {};
    this.currentWidgetMap = {};
    this.workFlowMap = {};
    this.dateFieldCache = {};
    this.waitAddressList = [];
    this.rMap = {};
    this.openApiSignOrderService
      .getWorkFlowInfo(this.token)
      .subscribe((response: WorkFlowInfo) => {
        if (!response) {
          this.dismiss();
          return;
        }
        if (
          response.signAddAttach ||
          (response.attachFiles && response.attachFiles?.length > 0)
        ) {
          this.needSideBar = true;
        }
        this.currentWorkFlowInfo = undefined;
        if (response.sameGroupList && response.sameGroupList.length > 0) {
          const obs$: Observable<WorkFlowInfo>[] = [];
          for (const item of response.sameGroupList ?? []) {
            obs$.push(
              this.openApiSignOrderService.getWorkFlowInfoByWorkFlowId(
                this.token,
                item.workFlowId!
              )
            );
          }
          // this.workFlowInfo = response;
          zip(obs$).subscribe((data: WorkFlowInfo[]) => {
            this.workFlowInfoList = data;
            this.needSideBar = true;
            for (const item of this.workFlowInfoList) {
              item.waitSign = false;
              for (const signer of item.signers ?? []) {
                if (
                  item.status === WorkFlowStatus.CANCLE ||
                  item.status === WorkFlowStatus.REJECT
                ) {
                  signer.workFlowStatus = item.status;
                }
                if (signer.workFlowStatus === WorkFlowStatus.WAIT) {
                  item.waitSign = true;
                }
                if (this.inited) {
                  if (
                    signer.workFlowStatus === WorkFlowStatus.WAIT &&
                    this.currentWorkFlowInfo === undefined
                  ) {
                    this.currentWorkFlowInfo = item;
                  }
                } else {
                  if (item.workFlowId === response.workFlowId) {
                    this.currentWorkFlowInfo = item;
                  }
                }
              }
              this.workFlowMap[item.workFlowId!] = item;
            }
            if (this.currentWorkFlowInfo === undefined) {
              // this.dismiss();
              if (this.workFlowInfoList.length > 0) {
                this.chooseMain(0);
              }
            } else {
              this.init();
            }
          });
        } else {
          this.workFlowInfoList = [response];
          for (const item of this.workFlowInfoList) {
            item.waitSign = false;
            for (const signer of item.signers ?? []) {
              if (
                item.status === WorkFlowStatus.CANCLE ||
                item.status === WorkFlowStatus.REJECT
              ) {
                signer.workFlowStatus = item.status;
              }
              if (signer.workFlowStatus === WorkFlowStatus.WAIT) {
                item.waitSign = true;
                if (this.currentWorkFlowInfo === undefined) {
                  this.currentWorkFlowInfo = item;
                }
                break;
              }
            }
            this.workFlowMap[item.workFlowId!] = item;
          }
          this.chooseMain(0);
          if (this.currentWorkFlowInfo === undefined) {
            this.dismiss();
          } else {
            this.init();
          }
        }
      });
  }

  private init() {
    this.freeSigner = undefined;
    for (const item of this.currentWorkFlowInfo!.signers ?? []) {
      if (
        item.workFlowStatus === WorkFlowStatus.WAIT &&
        item.signPosType === SignPosType.FREE_LOCATION
      ) {
        this.freeSigner = item;
        break;
      }
    }
    for (const workFlowInfo of this.workFlowInfoList) {
      const normalFields: Array<WorkFlowSignFieldInfo> = [];
      let currentSigners: WorkFlowSignerInfo[] = [];
      for (const item of workFlowInfo.signers ?? []) {
        if (item.workFlowStatus === WorkFlowStatus.WAIT) {
          currentSigners.push(item);
        }
        if (item.workFlowStatus === WorkFlowStatus.FINISH) {
          normalFields.push(...item.signFields!);
        }
      }
      this.normalWidgetMap[workFlowInfo.workFlowId!] = [];
      this.currentWidgetMap[workFlowInfo.workFlowId!] = [];
      this.attachFileListMap[workFlowInfo.workFlowId!] = [];
      this.normalWidgetMap[workFlowInfo.workFlowId!].fill(
        [],
        0,
        workFlowInfo.signFiles!.length
      );
      for (const item of normalFields) {
        const currentPage = +item.signFieldPosition!.posPage! - 1;
        if (!this.normalWidgetMap[workFlowInfo.workFlowId!][currentPage]) {
          this.normalWidgetMap[workFlowInfo.workFlowId!][currentPage] = [];
        }
        this.normalWidgetMap[workFlowInfo.workFlowId!][currentPage].push(item);
        if (item.signFieldType === WorkFlowSignFieldType.DATE_FIELD) {
          this.dateFieldCache[item.address!] = item;
          // 缓存数据处理
          if (!!item.content && item.content.indexOf('=') === -1) {
            item.content = item.content
              .replace('年', '.')
              .replace('月', '.')
              .replace('日', '');
            item.contentDate = moment(item.content, 'YYYY.M.D')
              .toDate()
              .getTime();
            this.onFieldDateChange(item);
          }
        }
      }
      console.log(this.normalWidgetMap);
      for (const currentSigner of currentSigners) {
        for (const item of currentSigner?.signFields ?? []) {
          item.flowId = currentSigner.flowId;
          item.flowType = currentSigner.flowType;
          item.signNodeOrder = currentSigner.signNodeOrder;
          item.singerOrder = currentSigner.singerOrder;

          const currentPage = +item.signFieldPosition!.posPage! - 1;
          if (!this.currentWidgetMap[workFlowInfo.workFlowId!][currentPage]) {
            this.currentWidgetMap[workFlowInfo.workFlowId!][currentPage] = [];
          }
          try {
            item.dataSourceObj = JSON.parse(item.dataSource!);
            if (item.dataSourceObj.key) {
              item.dataSourceObj.group = item.dataSourceObj.key;
            }
          } catch (e) {}
          if (item.signFieldType === WorkFlowSignFieldType.DATE_FIELD) {
            this.dateFieldCache[item.address!] = item;
            // TODO 特殊情况特殊处理 1
            if (!!item.content) {
              if (item.content === 'TODAY') {
                item.contentDate = Date.now();
                this.onFieldDateChange(item);
              } else if (item.content.indexOf('=') === -1) {
                item.contentDate = moment(
                  item.content,
                  item.dataSource ?? 'YYYY年MM月DD日'
                )
                  .toDate()
                  .getTime();
                this.onFieldDateChange(item);
              } else {
                this.waitAddressList.push(item.content.replace('=', ''));
              }
            }
          }
          this.currentWidgetMap[workFlowInfo.workFlowId!][currentPage].push(
            item
          );
        }
      }
    }
    this.changeDateWithWaitAddress();
    this.inited = true;
    setTimeout(() => {
      this.elementRef.nativeElement.parentElement.style.background =
        'transparent';
      this.resize();
      this.changePreview(0);
    }, 200);
  }

  private changeDateWithWaitAddress() {
    for (const key in this.currentWidgetMap) {
      for (const page of this.currentWidgetMap[key]) {
        for (const field of page ?? []) {
          if (field.signFieldType === WorkFlowSignFieldType.DATE_FIELD) {
            if (!!field.content && field.content.indexOf('=') === 0) {
              const dateField =
                this.dateFieldCache[field.content.replace('=', '')!];
              if (!!dateField && !!dateField.contentDate) {
                field.contentDate = dateField.contentDate;
                this.onFieldDateChange(field);
              }
            }
          }
        }
      }
    }
  }

  chooseMain(index: number) {
    this.currentAttachFileId = undefined;
    this.currentFileType = FileType.PDF;
    this.currentWorkFlowInfo = this.workFlowInfoList[index];
    this.freeSigner = undefined;
    for (const item of this.currentWorkFlowInfo.signers ?? []) {
      if (
        item.workFlowStatus === WorkFlowStatus.WAIT &&
        item.signPosType === SignPosType.FREE_LOCATION
      ) {
        this.freeSigner = item;
        break;
      }
    }
  }

  chooseAttach(index: number) {
    this.currentAttachFileId =
      this.currentWorkFlowInfo!.attachFiles![index].fileId!;
    this.currentFileType =
      this.currentWorkFlowInfo!.attachFiles![index].fileType!;
  }

  parseQuery(): void {
    const params = new URLSearchParams(
      window.location.href.split('?')[1] ?? ''
    );
    this.token = params.get('token') ?? '';
    this.redirectUrl = params.get('redirectUrl') ?? '';
  }

  previewAttachment(index: number) {
    const fileList: string[] = [];
    for (const item of this.currentWorkFlowInfo?.appendFiles ?? []) {
      fileList.push(item.fileId!);
    }
    if (fileList.length === 0) {
      return;
    }
    const results = this.modalService.open({
      backdropCloseable: false,
      component: FilePreviewComponent,
      onClose: () => {},
      data: {
        fileList,
        index,
        disabled: true,
        onDismiss: () => {
          results.modalInstance.hide();
        },
      },
    });
  }

  onFileNameChange(index: number) {
    this.attachFileListMap[this.currentWorkFlowInfo!.workFlowId!] = JSON.parse(
      JSON.stringify(this.currentWorkFlowInfo?.appendFiles ?? [])
    );
  }

  uploadAppend(event: any) {
    const files: FileList = event.target['files'];
    const fileList = Array.from(files);
    zip(
      fileList.map((file: File) => {
        return this.commonService.uploadFileWithoutProgress(file);
      })
    ).subscribe((res: FileResponse[]) => {
      event.target['value'] = '';
      // TODO 待补充
      if (this.currentWorkFlowInfo!.appendFiles === undefined) {
        this.currentWorkFlowInfo!.appendFiles = [];
      }
      for (const url of res) {
        this.currentWorkFlowInfo!.appendFiles.push({
          fileName: url.fileName,
          // fileSource: FileSource.MANUAL,
          fileId: url.fileId,
          fileType: url.fileType,
        });
      }
      this.attachFileListMap[this.currentWorkFlowInfo!.workFlowId!] =
        JSON.parse(JSON.stringify(this.currentWorkFlowInfo?.appendFiles ?? []));
    });
  }

  ngAfterViewInit(): void {
    window.addEventListener('resize', () => {
      this.resize();
    });
  }

  dismiss() {
    if (this.transformableImageElementRef) {
      this.transformableImageElementRef.removeElementListener();
    }
    location.href = this.redirectUrl;
  }

  onFieldDateChange(field: WorkFlowSignFieldInfo) {
    if (field.contentDate === undefined) {
      return;
    }
    const d = new Date(field.contentDate);
    field.content =
      field.signFieldPosition!.signFieldWidth! > 80
        ? `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
        : `${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`;
    field.format =
      field.signFieldPosition!.signFieldWidth! > 80
        ? `YYYY年M月D日`
        : 'YYYY.M.D';
    if (this.waitAddressList.indexOf(field.address!) !== -1) {
      this.changeDateWithWaitAddress();
    }
  }

  onInput($event: any, field: WorkFlowSignFieldInfo, page: number) {
    field.content = $event.target['textContent']!;
    field.signFieldPosition!.signFieldHeight = $event.target.clientHeight;
    this.rePosition(field, page);
  }

  // onSignFileIndexChange(index: number) {
  //   this.currnetSignIndex = index;
  //   this.currentSignFileId = this.currentWorkFlowInfo.signFiles![index].fileId;
  // }

  allowDrop(event: any) {
    event.preventDefault();
  }

  onDrop(event: any, pageIndex: number) {
    event.preventDefault();
    // console.log(event.draggable);
    // if (!event.draggable) {
    //   return;
    // }
    if (this.currentDragFieldPage === -1 || this.currentDragFieldIndex === -1) {
      if (this.currentDragItem === undefined) {
        return;
      }
      this.currentDragItem.signFieldPosition = {
        posPage: (pageIndex + 1).toString(),
        top: event.offsetY - this.currentDragItem.fieldHeight! / 2,
        left: event.offsetX - this.currentDragItem.fieldWidth! / 2,
        signFieldWidth: this.currentDragItem.fieldWidth,
        signFieldHeight: this.currentDragItem.fieldHeight,
        signFieldFontName: this.currentDragItem.fieldFontName,
        signFieldFontSize: this.currentDragItem.fieldFontSize,
      };
      this.currentDragItem.fileId =
        this.currentWorkFlowInfo!.signFiles![0].fileId;
      this.currentDragItem.content = '';
      this.currentDragItem.dataSource = '';
      this.currentDragItem.dataSourceObj = undefined;
      this.currentDragItem.dragable = true;
      this.currentDragItem.flowId = this.freeSigner?.flowId;
      this.currentDragItem.flowType = this.freeSigner?.flowType;
      this.currentDragItem.signNodeOrder = this.freeSigner?.signNodeOrder;
      this.currentDragItem.singerOrder = this.freeSigner?.singerOrder;
      if (
        !this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][pageIndex]
      ) {
        this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][
          pageIndex
        ] = [];
      }
      if (
        this.currentDragItem.signFieldType === WorkFlowSignFieldType.DATE_FIELD
      ) {
        this.currentDragItem.contentDate = Date.now();
        this.onFieldDateChange(this.currentDragItem);
      }
      this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][
        pageIndex
      ].push(this.currentDragItem);
      this.currentDragItem = undefined;
      this.changeDateWithWaitAddress();
    } else {
      const field =
        this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][
          this.currentDragFieldPage!
        ][this.currentDragFieldIndex!];
      if (event.target.parentElement.className.indexOf('sign-file') !== -1) {
        field.signFieldPosition!.top =
          event.offsetY - field.signFieldPosition!.signFieldHeight! / 2;
        field.signFieldPosition!.left =
          event.offsetX - field.signFieldPosition!.signFieldWidth! / 2;
        this.rePosition(field, pageIndex);
      }
      this.currentDragFieldPage = -1;
      this.currentDragFieldIndex = -1;
    }
  }

  private rePosition(field: WorkFlowSignFieldInfo, pageIndex: number) {
    if (field.signFieldPosition!.top! < 0) {
      field.signFieldPosition!.top = 0;
    }
    if (field.signFieldPosition!.left! < 0) {
      field.signFieldPosition!.left = 0;
    }
    if (
      field.signFieldPosition!.top! +
        field.signFieldPosition!.signFieldHeight! >
      this.currentWorkFlowInfo!.signFiles![0].imageInfos![pageIndex].pdfHeight!
    ) {
      field.signFieldPosition!.top =
        this.currentWorkFlowInfo!.signFiles![0].imageInfos![pageIndex]
          .pdfHeight! - field.signFieldPosition!.signFieldHeight!;
    }
    if (
      field.signFieldPosition!.left! +
        field.signFieldPosition!.signFieldWidth! >
      this.currentWorkFlowInfo!.signFiles![0].imageInfos![pageIndex].pdfWidth!
    ) {
      field.signFieldPosition!.left =
        this.currentWorkFlowInfo!.signFiles![0].imageInfos![pageIndex]
          .pdfWidth! - field.signFieldPosition!.signFieldWidth!;
    }
  }

  onDragStart(item: WorkFlowSignFieldInfo) {
    this.currentDragItem = JSON.parse(JSON.stringify(item));
  }

  onDragableMoveStart(page: number, index: number, dragable?: boolean) {
    if (!!dragable) {
      this.currentDragFieldPage = page;
      this.currentDragFieldIndex = index;
    }
  }

  removeDragable(page: number, index: number) {
    this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][page].splice(
      index,
      1
    );
  }

  onPageLoad(event: any) {
    this.pdfTotal = event.source.pdfDocument._pdfInfo.numPages;
    this.zoomIn();
    this.zoomOut();
    this.resize();
  }

  prePage() {
    if (this.pdfPages > 1) {
      this.pdfPages--;
    }
  }

  nextPage() {
    if (this.pdfPages < this.pdfTotal) {
      this.pdfPages++;
    }
  }

  originSize() {
    this.pdfZoom = 1;
  }

  zoomIn() {
    this.pdfZoom += 0.1;
    if (this.pdfZoom > 2) {
      this.pdfZoom = 2;
    }
  }

  zoomOut() {
    this.pdfZoom -= 0.1;
    if (this.pdfZoom < 0.5) {
      this.pdfZoom = 0.5;
    }
  }

  next() {
    throw new Error('Method not implemented.');
  }

  canConfirmSign() {
    return true;
  }

  signing = false;

  sign(isAll: boolean) {
    if (this.signing) {
      this.message.info('签字已经提交，请稍候...');
      return;
    }
    const content = this.signErrorHint(isAll);
    if (!!content) {
      const result = this.modalService.open({
        backdropCloseable: false,
        component: AlertDialogComponent,
        onClose: () => {},
        data: {
          title: '签字未完成',
          content,
          confirmBtnText: '确认',
          cancelBtnText: '确认',
          contentStyle: 'danger',
          type: AlertDialogType.simple,
          onCancel: () => {
            console.log('cancel');
            result.modalInstance.hide();
          },
        },
      });
      return;
    }
    this.openApiSignOrderService
      .getSignAuthInfo(this.token)
      .subscribe((info: SignAuthInfo) => {
        if (info.needSignAuth) {
          this.showPwdDialog(info, isAll);
        } else {
          this.signComfirm(isAll);
        }
      });
  }

  private showPwdDialog(info: SignAuthInfo, isAll: boolean) {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: DialogSignPwdComponent,
      onClose: () => {},
      data: {
        data: {
          signAuthMode: info.signAuthMode,
          authFlowId: info.authFlowId,
          token: this.token,
        },
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          this.signComfirm(isAll);
          results.modalInstance.hide();
        },
      },
    });
  }

  private signComfirm(isAll: boolean) {
    this.signing = true;
    const req = this.handleBeforeSign(isAll);
    this.openApiSignOrderService
      .batchConfirmSign(req)
      .subscribe((data: boolean) => {
        this.signing = false;
        this.inited = false;
        for (const info of req.workFlowBatchInfos ?? []) {
          if (this.attachFileListMap[info.workFlowId!]) {
            this.attachFileListMap[info.workFlowId!] = [];
          }
        }
        this.startSign();
      });
  }

  removeAttachment(index: number) {
    this.currentWorkFlowInfo?.appendFiles?.splice(index, 1);
    this.attachFileListMap[this.currentWorkFlowInfo!.workFlowId!] = JSON.parse(
      JSON.stringify(this.currentWorkFlowInfo?.appendFiles ?? [])
    );
  }

  signErrorHint(isAll: boolean) {
    let title = '';
    let ids = Object.keys(this.currentWidgetMap);
    if (!isAll) {
      ids = [this.currentWorkFlowInfo!.workFlowId!.toString()];
    }

    const flowMap: { [key: string]: WorkFlowInfo } = {};
    for (const flow of this.workFlowInfoList) {
      flowMap[flow.workFlowId!] = flow;
    }

    for (const id of ids) {
      let hasSignSeal = false;
      let remarkEmpty = false;
      let radioEmpty = false;
      let dateFilled = true;
      if (!flowMap[id].waitSign) {
        continue;
      }
      for (const pW of this.currentWidgetMap[id]) {
        if (!pW) {
          continue;
        }
        for (const field of pW) {
          if (
            field.signFieldType === WorkFlowSignFieldType.SIGN_FIELD ||
            field.signFieldType === WorkFlowSignFieldType.SEAL_FIELD
          ) {
            hasSignSeal = true;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.REMARK_FIELD &&
            !!field.musted &&
            !field.content
          ) {
            remarkEmpty = true;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.DATE_FIELD &&
            !!field.musted &&
            !field.contentDate
          ) {
            dateFilled = false;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.RADIO_FIELD &&
            !!field.musted
          ) {
            if (this.rMap[field.dataSourceObj.group] === undefined) {
              radioEmpty = true;
            }
          }
        }
      }
      if (!hasSignSeal) {
        title = `${
          isAll ? '[' + this.workFlowMap[id].signFiles![0].fileName + ']' : ''
        }请至少拖拽一处您的签字或盖章`;
      }
      if (remarkEmpty) {
        title = `${
          isAll ? '[' + this.workFlowMap[id].signFiles![0].fileName + ']' : ''
        }请填写完所有的备注`;
      }
      if (radioEmpty) {
        title = `${
          isAll ? '[' + this.workFlowMap[id].signFiles![0].fileName + ']' : ''
        }请勾选完所有的单选框`;
      }
      if (!dateFilled) {
        title = `${
          isAll ? '[' + this.workFlowMap[id].signFiles![0].fileName + ']' : ''
        }请填写完所有的日期`;
      }
      if (title !== '') {
        console.log(id);
        break;
      }
    }
    return title;
  }

  handleBeforeSign(isAll: boolean): WorkFlowBatchSignRequest {
    // TODO
    // const obj: WorkFlowBatchSignRequest = JSON.parse(
    //   JSON.stringify(this.currentSigner)
    // );
    const obj = new WorkFlowBatchSignRequest();
    obj.token = this.token;
    obj.workFlowBatchInfos = [];

    let ids = Object.keys(this.currentWidgetMap);
    if (!isAll) {
      ids = [this.currentWorkFlowInfo!.workFlowId!.toString()];
    }
    for (const id of ids) {
      const info = new WorkFlowBatchInfo();
      info.workFlowId = +id;
      info.flowInfos = [] as FlowInfo[];
      info.attachFiles = this.attachFileListMap[id] ?? [];

      const flowInfoMap: { [key: string]: FlowInfo } = {};
      for (const pW of this.currentWidgetMap[id]) {
        if (!pW) {
          continue;
        }
        for (const field of pW) {
          if (!flowInfoMap[field.flowId!]) {
            flowInfoMap[field.flowId!] = new FlowInfo();
            flowInfoMap[field.flowId!].flowId = field.flowId;
            flowInfoMap[field.flowId!].flowType = field.flowType;
            flowInfoMap[field.flowId!].signNodeOrder = field.signNodeOrder;
            flowInfoMap[field.flowId!].singerOrder = field.singerOrder;
            flowInfoMap[field.flowId!].signFields = [];
          }
          const item = JSON.parse(
            JSON.stringify(field)
          ) as WorkFlowSignFieldInfo;
          if (item.signFieldType === WorkFlowSignFieldType.DATE_FIELD) {
            try {
              const d = new Date(item.contentDate!);

              item.content =
                item.signFieldPosition!.signFieldWidth! > 80
                  ? `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
                  : `${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`;
              field.format =
                field.signFieldPosition!.signFieldWidth! > 80
                  ? `YYYY年M月D日`
                  : 'YYYY.M.D';
            } catch (e) {}
            console.log(item);
          }
          if (item.signFieldType === WorkFlowSignFieldType.RADIO_FIELD) {
            if (
              this.rMap[item.dataSourceObj.group] !== undefined &&
              item.dataSourceObj.value.toString() ===
                this.rMap[item.dataSourceObj.group].toString()
            ) {
              item.content = '☑';
            } else {
              item.content = '□';
            }
          }
          flowInfoMap[field.flowId!].signFields!.push(item);
        }
      }
      for (const key in flowInfoMap) {
        info.flowInfos.push(flowInfoMap[key]);
      }
      if (info.flowInfos.length > 0) {
        obj.workFlowBatchInfos.push(info);
      }
    }
    return obj;
  }

  changePreview(index: number) {
    this.index = index;
    this.pdfZoom = 1;
    this.pdfPages = 1;
    if (this.transformableImageElementRef) {
      this.transformableImageElementRef.removeElementListener();
    }
    setTimeout(() => {
      this.resize();
      const e = this.getImgElement();
      if (e) {
        let scale = 1;
        if (e.clientHeight > e.clientWidth) {
          scale = this.height / e.clientHeight;
        } else {
          scale = this.width / e.clientWidth;
        }
        if (scale > 1) {
          scale = 1;
        }
        this.transformableImageElementRef = new TransformableElement(e, {
          zoom: scale,
        });
      }
    }, 200);
  }

  private getImgElement(): HTMLElement {
    return this.elementRef.nativeElement.querySelector('img.preview');
  }

  resize() {
    this.height = document.body.clientHeight;
    this.width = document.body.clientWidth - 300;
  }

  reject() {
    if (this.signing) {
      return;
    }
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '退回',
        content: '请输入退回理由',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.text,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: (msg: string) => {
          if (!!msg) {
            this.openApiSignOrderService
              .rejectSign({
                token: this.token,
                workFlowId: this.currentWorkFlowInfo!.workFlowId,
                rejectReason: msg,
              })
              .subscribe((data: boolean) => {
                if (data) {
                  this.dismiss();
                }
              });
            results.modalInstance.hide();
          }
        },
      },
    });
  }
}
