export enum SignAuthMode {
  // SIGN_PWD = 'SIGN_PWD',
  SMS_CODE = 'SMS_CODE',
  // PSN_FACE = 'PSN_FACE',
  // PSN_FINGNER_PRINT = 'PSN_FINGNER_PRINT',
}

export const SignAuthModeMap = {
  // [SignAuthMode.SIGN_PWD]: '密码',
  [SignAuthMode.SMS_CODE]: '短信验证码',
  // [SignAuthMode.PSN_FACE]: '人脸识别',
  // [SignAuthMode.PSN_FINGNER_PRINT]: '指纹识别',
};

export const SignAuthModeList = [
  // {
  //   value: SignAuthMode.SIGN_PWD,
  //   label: SignAuthModeMap[SignAuthMode.SIGN_PWD],
  // },
  {
    value: SignAuthMode.SMS_CODE,
    label: SignAuthModeMap[SignAuthMode.SMS_CODE],
  },
  // {
  //   value: SignAuthMode.PSN_FACE,
  //   label: SignAuthModeMap[SignAuthMode.PSN_FACE],
  // },
  // {
  //   value: SignAuthMode.PSN_FINGNER_PRINT,
  //   label: SignAuthModeMap[SignAuthMode.PSN_FINGNER_PRINT],
  // },
];
