import { TemplateRef, Type, ViewContainerRef } from '@angular/core';

export class ComponentCreaterUtil {
  static create<C extends Popover>(
    componentType: Type<C>,
    viewContainerRef: ViewContainerRef,
    data: any
  ) {
    const componentRef = viewContainerRef.createComponent<C>(componentType);
    componentRef.instance.data = data;

    return componentRef.instance;
  }
}

export class Popover {
  templateRef!: TemplateRef<any>;
  data!: any;
}
