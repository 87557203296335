import { OrderType } from '../../providers/request/order-type.enum';
import { SearchRequest } from '../../providers/request/search-request.model';
import { AuthStatus } from './auth-status.enum';
import { OrganizationSealUserInfo } from './organization-seal-user-info.model';
import { OrganizationType } from './organization-type.enum';
import { SealStyle } from './seal-style.enum';
export class OrganizationExtSearchRequest extends SearchRequest {
  sectionId?: number;
  organizationId?: number;
  organizationType?: OrganizationType;
  blImgId?: string;
  taxId?: string;
  corporatePhone?: string;
  corporateName?: string;
  agentName?: string;
  agentIdCardNo?: string;
  agentPhone?: string;
  authStatus?: AuthStatus;
  sealStyle?: SealStyle;
  sealUsers?: Array<OrganizationSealUserInfo>;
  comment?: string;
  organizationTypeOrder?: OrderType;
  sectionIdOrder?: OrderType;
}
