<div class="dialog">
	<div class="dialog-header">{{!!record.sectionItemId ? '' : '选择' + ProjectItemTypeMap[type!] + '+'}}抽检项目</div>
	<div class="dialog-body">
		<div class="grid">
			<div class="row validate" *ngIf="!record?.sectionItemId">
				<div class="column no-border">
					<wbs-unit-cascader [unitCascaderValue]="unitCascaderValue" class="color-primary"
						[style.width]="60 + 'rem'" [type]="type"
						(unitCascaderValueChange)="onUnitCascaderValueChange($event)"></wbs-unit-cascader>
				</div>
			</div>
			<div class="row" *ngIf="needSegment">
				<div class="column no-border">
					<nz-cascader [nzOptions]="unitTreeNodes" [(ngModel)]="layerCascaderValue"
						[nzPlaceHolder]="'请选择' + layer ? '分层' : '分段'">
					</nz-cascader>
				</div>
			</div>
			<div class="row" *ngIf="history.length > 0">
				<p>历史记录</p>
			</div>
			<div class="row" *ngFor="let item of history; let index = index" (click)="chooseHistory(index)">
				<div class="column no-border">
					<wbs-unit-cascader [unitCascaderValue]="item.unitCascaderValue!" [disabled]="true"
						class="color-primary" [style.width]="60 + 'rem'" [type]="type"></wbs-unit-cascader>
					<div class="shield">

					</div>
				</div>
			</div>
			<div class="row" *ngIf="unitCascaderValue.length > 0">
				<div class="column background-page" [style.width]="60 + 'rem'">
					选择抽检项目
				</div>
			</div>
			<div class="row" *ngFor="let item of subFormList; let index = index">
				<div class="column {{selecedIndex === index ? 'color-primary' : ''}} clickable"
					[style.width]="2 + 'rem'" (click)="selecedIndex = index">
					{{selecedIndex === index ? '◉' : '○'}}
				</div>
				<div class="column {{selecedIndex === index ? 'color-primary' : ''}} text-left no-border-left clickable"
					[style.width]="58 + 'rem'" (click)="selecedIndex = index">
					{{item.code}} {{item.name}}
				</div>
			</div>
			<div class="row" *ngIf="subFormList.length === 0 && unitCascaderValue.length > 0">
				<div class="column" [style.width]="60 + 'rem'">
					没有找到任何抽检项目表单
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
	</div>
</div>