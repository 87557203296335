import { FormItemMeasuredProjectOptions } from './form-item-measured-project-options.enum';
import { FormItemMeasuredProjectSpadType } from './form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectSpad } from './form-item-measured-project-spad.model';
import { FormItemMeasuredProjectAverageTypeType } from './form-item-measured-project-average-type-type.enum';
import { FormItemMeasuredProjectStatus } from './form-item-measured-project-status.enum';
import { FormItemMeasuredProjectRefParamType } from './form-item-measured-project-ref-param-type.enum';
import { DetectionInfo } from '../../biz-zj-zp/dialog-form-zj-fill/dialog-form-zj-fill.component';
export class FormItemMeasuredProjectInfo {
  [key: string]: any;
  formItemIds?: Array<number>;
  fieldFormItemIdMap?: { [key: string]: string };
  id?: number;
  formId?: number;
  formItemId?: number;
  formTableId?: number;
  formItemAddress?: string;
  formItemColumnNum?: number;
  itemNo?: string;
  options?: FormItemMeasuredProjectOptions;
  category?: string;
  keywords?: string;
  spadSearchType?: string;
  spadKeywordsDesc?: string;
  spadDesc?: string;
  spadType?: FormItemMeasuredProjectSpadType;
  spadValues?: Array<FormItemMeasuredProjectSpad>;
  refParamType?: FormItemMeasuredProjectRefParamType;
  refParamName?: string;
  refParamSymbol?: string;
  refParamVariable?: string;
  refParamValue?: string;
  mfValue?: string;
  spadValue?: string;
  measuredValue?: string;
  deviationValue?: string;
  designValue?: string;
  remarkValue?: string;
  averageValueType?: FormItemMeasuredProjectAverageTypeType;
  averageValue?: string;
  qaRate?: string;
  qualifiedJudge?: string;
  sortOrder?: number;
  status?: FormItemMeasuredProjectStatus;
  comment?: string;
  // 前端自用
  designValue1?: string;
  designValue2?: string;
  editable?: boolean;
  message?: string;
}
