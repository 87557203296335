import { FormItemMeasuredProjectSpadType } from './form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectRefParamType } from './form-item-measured-project-ref-param-type.enum';
export class FormItemMeasuredProjectSpOrAddv {
  keywords?: string;
  spadDesc?: string;
  spadValue?: string;
  spadType?: FormItemMeasuredProjectSpadType;
  refParamType?: FormItemMeasuredProjectRefParamType;
  refParamName?: string;
  refParamSymbol?: string;
  refParamVariable?: string;
  selected?: boolean;
  defaulted?: boolean;
}
