import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Popover } from 'src/app/modules/shared/utils/component-creater.util';

@Component({
  selector: 'app-simple-popover',
  templateUrl: './simple-popover.component.html',
  styleUrls: ['./simple-popover.component.scss'],
})
export class SimplePopoverComponent implements OnInit, Popover {
  @ViewChild('templateRef') templateRef!: TemplateRef<any>;
  @Input() data!: string;
  maxWidth = 72;
  maxValueItem = 0;
  ngOnInit(): void {
    console.log(this.data);
    this.maxWidth = this.data.length * 16 < 72 ? 72 : this.data.length * 16;
  }
}
