import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/zh-cn';
import { ModalService } from 'ng-devui';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogFormZjFillComponent } from '../../biz-zj-zp/dialog-form-zj-fill/dialog-form-zj-fill.component';
import {
  AlignMap,
  JustifyMap,
} from '../../models/form/cell-alignment-type.enum';
import { DetectionType } from '../../models/form/detection.enum';
import { EditableWorkReportRequest } from '../../models/form/editable-work-report-request.model';
import { ExcelTableRowColumn } from '../../models/form/excel-table-row-column.model';
import { ExcelTable } from '../../models/form/excel-table.model';
import { FormItemMeasuredProjectInfo } from '../../models/form/form-item-measured-project-info.model';
import { FormItemType } from '../../models/form/form-item-type.enum';
import { FormItemWidgetType } from '../../models/form/form-item-widget-type.enum';
import { FormPagesType } from '../../models/form/form-pages-type.enum';
import { WidgetData } from '../../models/form/widget-data.model';
import { WorkReport } from '../../models/form/work-report.model';
import { OrganizationListRequest } from '../../models/staff/organization-list-request.model';
import { Organization } from '../../models/staff/organization.model';
import { NzTreeNode } from '../../models/tree-node.model';
import { WeatherInfo } from '../../models/weather/weather-info.model';
import { EventService, EventType } from '../../providers/event.service';
import { GlobalService } from '../../providers/global.service';
import { CommonService } from '../../services/common.service';
import { StaffOrganizationService } from '../../services/staff-organization.service';
import { WorkReportService } from '../../services/work-report.service';
import {
  AlertDialogComponent,
  AlertDialogType,
} from '../alert-dialog/alert-dialog.component';
import { FilePreviewType } from '../file-preview-entry/file-preview-entry.component';
import { LocationUtil } from '../location-cascader/location.model';

@Component({
  selector: 'spreadsheet-form',
  templateUrl: './spreadsheet-form.component.html',
  styleUrls: ['./spreadsheet-form.component.scss'],
})
export class SpreadsheetFormComponent {
  static ON_SCROLL = 'ON_SCROLL';
  selectedRowIndex = -1;
  selectedColIndex = -1;
  tabId = -1;

  AlignMap = AlignMap;
  JustifyMap = JustifyMap;
  FormItemType = FormItemType;
  DetectionType = DetectionType;

  fileType = FilePreviewType.Image;

  weatherSource: string[] = [];

  copyCache: ExcelTable = {};

  detectionPagedGroup: { [key: string]: DetectionInfo }[] = [];
  detection2PagedGroup: { [key: string]: Detection2Info }[] = [];
  detection3PagedGroup: { [key: string]: Detection3Info }[] = [];

  editing: { [key: string]: boolean } = {};

  waitAddressList: string[] = [];

  _submited = false;

  isZp = false;

  initDate = Date.now();

  initMd5 = '';

  startAutoSave = false;

  _disableAll = false;

  detectionEditable = true;

  // @Input()
  sectionId: number | undefined;

  // @Input()
  sectionItemId: number | undefined;

  attachFormTable: ExcelTable[] = [];

  @Input()
  set disableAll(value: boolean) {
    this._disableAll = value;
  }

  get disableAll() {
    return this._disableAll;
  }

  _jsLock = false;

  @Input()
  set jsLock(value: boolean) {
    this._jsLock = value;
  }

  get jsLock() {
    return this._jsLock;
  }

  @Input()
  set editableWorkReportRequest(value: EditableWorkReportRequest) {
    this._editableWorkReportRequest = value;
    this.isZp = !!this._editableWorkReportRequest.formCode
      ?.toLowerCase()
      ?.includes('zp');
  }

  get editableWorkReportRequest() {
    return this._editableWorkReportRequest;
  }

  _editableWorkReportRequest = new EditableWorkReportRequest();

  @Input()
  set submited(value: boolean) {
    this._submited = value;
  }

  get submited() {
    return this._submited;
  }

  varCache: { [key: string]: any }[] = [];

  colMap: { [key: string]: ExcelTableRowColumn }[] = [];

  edit(key: string) {
    this.editing[key] = true;
  }

  onBlur() {
    this.editing = {};
  }

  get defaultMax() {
    return Infinity;
  }

  get defaultMin() {
    return -Infinity;
  }

  duplicate() {
    const configCopy = this.handleBind(
      JSON.parse(JSON.stringify(this._config[0]))
    );
    this._config = this.parseConfig([...this._config, configCopy]);
    this.handleWaitAddress();
    this.onChange.emit(this.config);
  }

  private handleBind(table: ExcelTable) {
    for (const item of table.rows ?? []) {
      for (const col of item.columns ?? []) {
        if (col.formItem?.type === FormItemType.VARIABLES) {
          if (!col.formItem.expanded) {
            col.value = '';
          }
        }
      }
    }
    return table;
  }

  onTableSelectChange(index: number) {
    this.tabId = index;
    this.events.broadcast(EventType.REDRAW_TABLE);
  }

  remove(index: number) {
    console.log(index);
    if (index === 0) {
      return;
    }
    this._config.splice(index, 1);
    this.onChange.emit(this._config);
  }

  get wRate() {
    if (!this._config) {
      return 1;
    }
    if (this.isVertical) {
      if (this._config[0].height! > 800) {
        return 1.05;
      } else {
        return 1.01;
      }
    } else {
      if (this._config[0].height! > 550) {
        return 1.08;
      } else {
        return 1.02;
      }
    }
  }

  get hRate() {
    if (!this._config) {
      return 1;
    }
    if (this.isVertical) {
      if (this._config[0].height! > 800) {
        return 0.85;
      } else {
        return 0.97;
      }
    } else {
      if (this._config[0].height! > 550) {
        return 0.85;
      } else {
        return 0.97;
      }
    }
  }

  fRate = 1;

  get isVertical() {
    if (!this._config) {
      return true;
    }
    return this._config[0].height! > this._config[0].width!;
  }

  @Output()
  onChange = new EventEmitter<ExcelTable[]>();

  @Output()
  onDuplicate = new EventEmitter<boolean>();

  @Output()
  onFlowDisableChange = new EventEmitter<{
    address: string;
    disabled: boolean;
  }>();

  @Output()
  onCCChange = new EventEmitter<{
    ids?: string[];
    musted?: boolean;
    init?: boolean;
  }>();

  @Output()
  onPresentChange = new EventEmitter<{
    ids?: string[];
    musted?: boolean;
    init?: boolean;
  }>();

  @Output()
  onProjectDataChange = new EventEmitter<ExcelTableRowColumn>();

  FormItemWidgetType = FormItemWidgetType;

  disabled = (col: ExcelTableRowColumn) => {
    return (
      this.disabledAddressList.includes(col.address!) ||
      !!col.disable ||
      !!this._disableAll
    );
  };

  @Input()
  set config(value: WorkReport) {
    if (!value) {
      return;
    }
    this.sectionId = value.sectionId;
    this.sectionItemId = value.sectionItemId;
    this.copyable = value.formCopyable ?? false;
    this.canAdd = value.formPagesType === FormPagesType.SCALABLE;
    if (!!value.attachForm?.formData) {
      this.attachFormTable = this.initAttachForm(value.attachForm.formData);
    }
    if (this.orgList.length > 0) {
      this.initConf(value.form?.formData ?? []);
    } else {
      this.staffOrganizationService
        .findAll<OrganizationListRequest>({
          projectId: this.globalService.projectId,
          projectSections: this.globalService.userInfo.projectSections ?? [],
        })
        .subscribe((res: Organization[]) => {
          this.orgList = res ?? [];
          for (const org of this.orgList) {
            this.orgMap[org.id!] = org;
          }
          this.initConf(value.form?.formData ?? []);
        });
    }
  }

  private initConf(value: ExcelTable[]) {
    if (!value) {
      return;
    }
    this.waitAddressList = [];
    this._config = this.parseConfig(value);
    this.handleWaitAddress();
    if (value.length > 0) {
      this.tabId = this._config[0].tableId!;
    }
    this.copyCache = JSON.parse(JSON.stringify(this._config[0]));
    this.initDate = Date.now();
    this.startAutoSave = false;
  }

  get config(): ExcelTable[] {
    return this._config;
  }

  @Input()
  set measuredProjects(value: FormItemMeasuredProjectInfo[]) {
    this._measuredProjects = value;
  }

  get measuredProjects() {
    return this._measuredProjects;
  }

  private _measuredProjects: FormItemMeasuredProjectInfo[] = [];

  @Input()
  set projectData(value: WidgetData[]) {
    if (value) {
      this.projectWidgetData = value;
      this._config = this.parseConfig(this.config);
    }
  }

  @Input()
  set flowKey(value: string) {
    console.log('flowKey', value);
    this._flowKey = value;
  }

  get flowKey() {
    return this._flowKey;
  }

  _flowKey = '';

  // @Input()
  copyable = false;

  projectWidgetData: WidgetData[] = [];

  scrolling = false;

  onScroll() {
    this.scrolling = true;
    this.events.broadcast(SpreadsheetFormComponent.ON_SCROLL);
  }

  onScrollEnd() {
    this.scrolling = false;
  }

  private getNodes(item: WidgetData): NzTreeNode[] {
    if (!item.child || item.child.length === 0) {
      return [
        {
          label: item.value,
          value: item.value,
          code: item.code,
          isLeaf: true,
        },
      ];
    }
    let nodes: NzTreeNode[] = [];
    for (const child of item.child) {
      nodes = nodes.concat(this.getNodes(child));
    }
    return nodes;
  }

  private buildTreeNodes(item: WidgetData, code?: string): NzTreeNode {
    const node: NzTreeNode = {
      label: item.value,
      value: item.value,
      children: [],
      isLeaf: false,
    };
    for (const child of item.child ?? []) {
      if (code && code.indexOf(child.code!) === -1) {
        continue;
      }
      node.children!.push(this.buildTreeNodes(child));
    }
    if (node.children?.length === 0) {
      node.isLeaf = true;
    }
    return node;
  }

  private initAttachForm(config: ExcelTable[]) {
    for (const table of config ?? []) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (!!col.formItem && col.formItem.type === FormItemType.VARIABLES) {
            if (col.formItem.widget === FormItemWidgetType.DATE) {
              if (!!col.value) {
                col.value = col.value
                  .replaceAll('<br/>', '\n')
                  .replaceAll('&ensp;', ' ');
                col.valueHtml = col.value
                  .replaceAll('\n', '<br/>')
                  .replaceAll(' ', '&ensp;');

                let ds = col.value
                  .replace('年', '-')
                  .replace('月', '-')
                  .replace('日', '')
                  .replace('一', '')
                  .replace('二', '')
                  .replace('三', '')
                  .replace('四', '')
                  .replace('五', '')
                  .replace('六', '')
                  .replace('日', '')
                  .replace('分', '')
                  .replace('时', ':')
                  .replace(' 星期', '');
                if (ds[ds.length - 1] === ':') {
                  ds += '00';
                }
                try {
                  if (moment(ds).isValid()) {
                    col.valueDate = new Date(moment(ds).toDate());
                    console.log(col.valueDate);
                  }
                } catch (error) {
                  col.valueDate = new Date();
                }
              }
            }
            if (
              col.formItem.widget === FormItemWidgetType.PERCENT ||
              col.formItem.widget === FormItemWidgetType.NUMERIC ||
              col.formItem.widget === FormItemWidgetType.NORMAL
            ) {
              if (col.value === '') {
                col.value = col.formItem.defaultValue ?? '';
              }
              if (!!col.value) {
                col.value = col.value
                  .replaceAll('<br/>', '\n')
                  .replaceAll('&ensp;', ' ');
                col.valueHtml = col.value
                  .replaceAll('\n', '<br/>')
                  .replaceAll(' ', '&ensp;');
              }
            }
          }
        }
      }
    }
    return config;
  }

  addAttachPage(index: number) {
    const page = JSON.parse(JSON.stringify(this.attachFormTable[index]));
    for (const row of page.rows ?? []) {
      for (const col of row.columns ?? []) {
        if (col.formItem?.type === FormItemType.VARIABLES) {
          col.value = '';
          col.valueDate = undefined;
          col.valueHtml = '';
          col.imgFileIds = [];
        }
      }
    }
    this.attachFormTable.splice(index + 1, 0, page);
  }

  removeAttachPage(index: number) {
    if (this.attachFormTable.length === 1) {
      return;
    }
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除第' + (index + 1) + '页',
        content: '确认删除？',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.confirm,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          results.modalInstance.hide();
          this.attachFormTable.splice(index, 1);
        },
      },
    });
  }

  private parseConfig(config: ExcelTable[]) {
    let page = 0;
    for (const table of config ?? []) {
      if (!this.varCache[page]) {
        this.varCache[page] = {};
        this.colMap[page] = {};
        this.detectionPagedGroup[page] = {};
        this.detection2PagedGroup[page] = {};
        this.detection3PagedGroup[page] = {};
      }
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          this.colMap[page][col.address!] = col;
          if (!!col.formItem && col.formItem.type === FormItemType.VARIABLES) {
            if (col.formItem.widget === FormItemWidgetType.WORK_PROCEDURE) {
              console.log(col);
              if (col.formItem.dataSource === 'workProcedureDate') {
                col.formItem.widget = FormItemWidgetType.DATE;
                col.formItem.dataSource = col.formItem.format;
              }
              if (col.formItem.dataSource === 'workProcedureImage') {
                col.formItem.widget = FormItemWidgetType.IMAGE;
              }
            }
            if (col.formItem.widget === FormItemWidgetType.RADIO) {
              try {
                col.formItem.dataSourceObject = JSON.parse(
                  col.formItem.dataSource!
                );
                if (col.formItem.defaultValue === col.address && !col.value) {
                  col.valueRadio = col.address;
                  col.value = '☑';
                }
              } catch (error) {}
              if (col.value && col.value === '☑') {
                col.valueRadio = col.address;
                if (!this.radioGroup[page]) {
                  this.radioGroup[page] = {};
                }
                if (!!col.formItem.dataSourceObject.group && col.value) {
                  this.radioGroup[page][col.formItem.dataSourceObject.group] =
                    col.address!;
                }
              } else {
                col.valueRadio = '';
                col.value = '□';
              }
            }
            if (col.formItem.widget === FormItemWidgetType.SELECT) {
              if (!col.value) {
                col.value = col.formItem.defaultValue ?? '';
              }
              col.formItem.dataSourceList =
                col.formItem.dataSource?.split(',') ?? [];
            }
            if (col.formItem.widget === FormItemWidgetType.CHECKBOX) {
              if (!!col.formItem.defaultValue) {
                col.valueChecked = true;
                if (col.valueChecked?.toString() === 'true') {
                  col.value = '☑';
                } else {
                  col.value = '□';
                }
              }
            }
            if (col.formItem.widget === FormItemWidgetType.DATE) {
              if (!!col.formItem.defaultValue) {
                if (col.formItem.defaultValue === 'TODAY') {
                  const result = moment(
                    col.value,
                    col.formItem?.dataSource ?? 'YYYY年MM月DD日'
                  )
                    .locale('zh_cn')
                    .isValid();
                  if (!result) {
                    const d = new Date();
                    col.valueDate = d;
                    col.value = moment(d.toISOString())
                      .locale('zh_cn')
                      .format(col.formItem?.dataSource ?? 'YYYY年MM月DD日');
                  } else {
                    col.valueDate = moment(
                      col.value,
                      col.formItem?.dataSource ?? 'YYYY年MM月DD日'
                    ).toDate();
                  }
                } else if (col.formItem.defaultValue.indexOf('=') > -1) {
                  this.waitAddressList.push(
                    col.formItem.defaultValue.replace('=', '')
                  );
                }
              }
              if (!!col.value) {
                col.value = col.value
                  .replaceAll('<br/>', '\n')
                  .replaceAll('&ensp;', ' ');
                col.valueHtml = col.value
                  .replaceAll('\n', '<br/>')
                  .replaceAll(' ', '&ensp;');

                let ds = col.value
                  .replace('年', '-')
                  .replace('月', '-')
                  .replace('日', '')
                  .replace('一', '')
                  .replace('二', '')
                  .replace('三', '')
                  .replace('四', '')
                  .replace('五', '')
                  .replace('六', '')
                  .replace('日', '')
                  .replace('分', '')
                  .replace('时', ':')
                  .replace(' 星期', '');
                if (ds[ds.length - 1] === ':') {
                  ds += '00';
                }
                try {
                  if (moment(ds).isValid()) {
                    col.valueDate = new Date(moment(ds).toDate());
                    console.log(col.valueDate);
                  }
                } catch (error) {}
              }
            }
            if (
              col.formItem.widget === FormItemWidgetType.PERCENT ||
              col.formItem.widget === FormItemWidgetType.NUMERIC ||
              col.formItem.widget === FormItemWidgetType.NORMAL
            ) {
              if (col.value === '') {
                col.value = col.formItem.defaultValue ?? '';
              }
              if (!!col.value) {
                col.value = col.value
                  .replaceAll('<br/>', '\n')
                  .replaceAll('&ensp;', ' ');
                col.valueHtml = col.value
                  .replaceAll('\n', '<br/>')
                  .replaceAll(' ', '&ensp;');
              }
              this.onPureValueChange(-1, col);
            }
            if (col.formItem.widget === FormItemWidgetType.WEATHER) {
              this.weatherSource.push(col.formItem.formula ?? '');
              if (!col.value) {
                this.commonService
                  .getWeather(
                    moment(new Date().toISOString())
                      .locale('zh_cn')
                      .format('YYYY-MM-DD')
                  )
                  .subscribe((res: WeatherInfo) => {
                    if (res) {
                      col.value = col.formItem?.dataSource
                        ? res[col.formItem.dataSource]
                        : '';
                    }
                  });
              }
            }
            if (col.formItem.widget === FormItemWidgetType.MATERIAL) {
              col.formItem.dataSourceCascader = [];
              if (this.projectWidgetData.length > 0) {
                const treeNode: NzTreeNode[] = [];
                for (const item of this.projectWidgetData) {
                  if (
                    col.formItem.dataSource &&
                    col.formItem.dataSource.indexOf(item.code!) > -1
                  ) {
                    treeNode.push(
                      this.buildTreeNodes(item, col.formItem.dataSource)
                    );
                  }
                }
                col.formItem.dataSourceCascader = treeNode;
              }
            }
            if (col.formItem.widget === FormItemWidgetType.PROJECT) {
              col.formItem.dataSourceCascader = [];

              if (this.projectWidgetData.length > 0) {
                let treeNode: NzTreeNode[] = [];
                for (const item of this.projectWidgetData) {
                  // console.log(col.formItem.dataSource);
                  treeNode = treeNode.concat(this.getNodes(item));
                }
                // console.log(treeNode);
                if (col.formItem.dataSource) {
                  const filterStr = col.formItem.dataSource.replace(
                    'nameOrProjectOrgName',
                    ''
                  );
                  col.formItem.dataSourceCascader = treeNode.filter((node) =>
                    node.code?.includes(filterStr)
                  );
                } else {
                  col.formItem.dataSourceCascader = treeNode;
                }

                // if (treeNode.length > 0) {
                //   if (treeNode.length === 1) {
                //     if (
                //       treeNode[0].children &&
                //       treeNode[0].children.length > 0
                //     ) {
                //       col.formItem.dataSourceCascader = treeNode[0].children;
                //     } else {
                //       col.formItem.dataSourceCascader = treeNode;
                //     }
                //   } else {
                //     col.formItem.dataSourceCascader = treeNode;
                //   }
                // } else {
                //   col.formItem.dataSourceCascader = [];
                // }
              }
            }
            if (
              col.formItem.widget === FormItemWidgetType.WBS ||
              col.formItem.widget === FormItemWidgetType.NORMAL
            ) {
              this.onPureValueChange(-1, col);
            }
            if (col.formItem.widget === FormItemWidgetType.DETECTION) {
              if (
                col.formItem.formula &&
                !this.detectionPagedGroup[page][col.formItem.formula]
              ) {
                this.detectionPagedGroup[page][col.formItem.formula!] = {
                  designRealVal: [],
                  biasRealValMin: -Infinity,
                  biasRealValMax: +Infinity,
                  realVal1List: [],
                  realVal2List: [],
                  val1Min: -Infinity,
                  val1Max: +Infinity,
                  val2Min: -Infinity,
                  val2Max: +Infinity,
                  biasError: true,
                };
              }
              if (col.formItem.dataSource === DetectionType.PASS) {
                if (col.value && col.value.indexOf('%') > -1) {
                  col.valuePercent = col.value.replaceAll('%', '');
                }
              }
              if (col.formItem.dataSource === DetectionType.BIAS) {
                if (!col.value) {
                  col.value = col.formItem.defaultValue ?? '';
                }
                this.onDetectionBiasChange(page, col);
              }
            }
            if (col.formItem.widget === FormItemWidgetType.DETECTION2) {
              if (
                col.formItem.formula &&
                !this.detection2PagedGroup[page][col.formItem.formula]
              ) {
                this.detection2PagedGroup[page][col.formItem.formula!] = {};
                const addresses = col.formItem.formula
                  .replaceAll(' ', '')
                  .replace('Ext(', '')
                  .replace(')', '')
                  .split(',');
                this.detection2PagedGroup[page][
                  col.formItem.formula!
                ].xDAddress = addresses[0];
                this.detection2PagedGroup[page][
                  col.formItem.formula!
                ].xRAddress = addresses[1];
                this.detection2PagedGroup[page][
                  col.formItem.formula!
                ].xTAddress = addresses[2];
                this.detection2PagedGroup[page][
                  col.formItem.formula!
                ].yDAddress = addresses[3];
                this.detection2PagedGroup[page][
                  col.formItem.formula!
                ].yRAddress = addresses[4];
                this.detection2PagedGroup[page][
                  col.formItem.formula!
                ].yTAddress = addresses[5];
              }
              if (
                col.formItem.formula &&
                this.detection2PagedGroup[page][col.formItem.formula]
              ) {
                if (
                  col.address ===
                  this.detection2PagedGroup[page][col.formItem.formula!]
                    .xDAddress
                ) {
                  this.detection2PagedGroup[page][col.formItem.formula!].xD =
                    col.value ?? '';
                }
                if (
                  col.address ===
                  this.detection2PagedGroup[page][col.formItem.formula!]
                    .xRAddress
                ) {
                  this.detection2PagedGroup[page][col.formItem.formula!].xR =
                    col.value ?? '';
                }
                if (
                  col.address ===
                  this.detection2PagedGroup[page][col.formItem.formula!]
                    .xTAddress
                ) {
                  this.detection2PagedGroup[page][col.formItem.formula!].xT =
                    col.value ?? '';
                }
                if (
                  col.address ===
                  this.detection2PagedGroup[page][col.formItem.formula!]
                    .yDAddress
                ) {
                  this.detection2PagedGroup[page][col.formItem.formula!].yD =
                    col.value ?? '';
                }
                if (
                  col.address ===
                  this.detection2PagedGroup[page][col.formItem.formula!]
                    .yRAddress
                ) {
                  this.detection2PagedGroup[page][col.formItem.formula!].yR =
                    col.value ?? '';
                }
                if (
                  col.address ===
                  this.detection2PagedGroup[page][col.formItem.formula!]
                    .yTAddress
                ) {
                  this.detection2PagedGroup[page][col.formItem.formula!].yT =
                    col.value ?? '';
                }
              }
            }
            if (col.formItem.widget === FormItemWidgetType.DETECTION3) {
              if (
                col.formItem.formula &&
                !this.detection3PagedGroup[page][col.formItem.formula]
              ) {
                this.detection3PagedGroup[page][col.formItem.formula!] = {};
                const addresses = col.formItem.formula
                  .replaceAll(' ', '')
                  .replace('Ext(', '')
                  .replace(')', '')
                  .split(',');
                this.detection3PagedGroup[page][
                  col.formItem.formula!
                ].hDAddress = addresses[0];
                this.detection3PagedGroup[page][
                  col.formItem.formula!
                ].hRAddress = addresses[1];
                this.detection3PagedGroup[page][
                  col.formItem.formula!
                ].hTAddress = addresses[2];
              }
              if (
                col.formItem.formula &&
                this.detection3PagedGroup[page][col.formItem.formula]
              ) {
                if (
                  col.address ===
                  this.detection3PagedGroup[page][col.formItem.formula!]
                    .hDAddress
                ) {
                  this.detection3PagedGroup[page][col.formItem.formula!].hD =
                    col.value ?? '';
                }
                if (
                  col.address ===
                  this.detection3PagedGroup[page][col.formItem.formula!]
                    .hRAddress
                ) {
                  this.detection3PagedGroup[page][col.formItem.formula!].hR =
                    col.value ?? '';
                }
                if (
                  col.address ===
                  this.detection3PagedGroup[page][col.formItem.formula!]
                    .hTAddress
                ) {
                  this.detection3PagedGroup[page][col.formItem.formula!].hT =
                    col.value ?? '';
                }
              }
            }
            if (
              col.formItem.widget === FormItemWidgetType.FLOW_CC ||
              col.formItem.widget === FormItemWidgetType.FLOW_PRESENT ||
              col.formItem.widget === FormItemWidgetType.FLOW_CC_ONE ||
              col.formItem.widget === FormItemWidgetType.FLOW_PRESENT_ONE
            ) {
              if (col.value) {
                if (col.value.indexOf('[') === 0) {
                  try {
                    col.valueCascader = JSON.parse(col.value);
                  } catch (error) {
                    // TODO
                    col.valueCascader = [];
                  }
                } else {
                  try {
                    col.valueCascader = [];
                    const orgNames = col.value.split('\n');
                    for (const orgName of orgNames) {
                      const org = this.orgList.find((o) => o.name === orgName);
                      if (org) {
                        col.valueCascader.push(org.id);
                      }
                    }
                  } catch (error) {
                    col.valueCascader = [];
                  }
                }
              } else {
                col.valueCascader = [];
              }
              console.warn(col.valueCascader);
              // col.oldValueCascader = '[]';
              col.oldValueCascader = JSON.stringify(col.valueCascader);
              if (
                (col.formItem.widget === FormItemWidgetType.FLOW_CC_ONE ||
                  col.formItem.widget ===
                    FormItemWidgetType.FLOW_PRESENT_ONE) &&
                col.valueCascader &&
                col.valueCascader.length > 0
              ) {
                col.valueSingle = col.valueCascader[0];
              }
              setTimeout(() => {
                this.multiSelectChange(col, true);
              }, 1000);
            }
            if (!col.formItem.widget && !!col.value) {
              col.value = col.value
                .replaceAll('<br/>', '\n')
                .replaceAll('&ensp;', ' ');
              col.valueHtml = col.value
                .replaceAll('\n', '<br/>')
                .replaceAll(' ', '&ensp;');
              this.onPureValueChange(-1, col);
            }
            if (
              this.disabledAddressList.includes(col.address!) ||
              !!col.disable
            ) {
              col.value = '';
            }
            this.varCache[page][col.address!] = col.value;
          }
        }
      }
      page++;
    }
    setTimeout(() => {
      this.handleWaitAddress();
    }, 100);
    return config;
  }

  private handleWaitAddress() {
    let p = 0;
    for (const table of this.config ?? []) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (!!col.formItem && col.formItem.type === FormItemType.VARIABLES) {
            if (
              col.formItem.widget === FormItemWidgetType.DATE &&
              !!col.formItem.defaultValue &&
              col.formItem.defaultValue.indexOf('=') === 0
            ) {
              const tarAddress = col.formItem.defaultValue.replace('=', '');
              if (
                !!this.varCache[p][tarAddress] &&
                !!this.colMap[p][tarAddress]
              ) {
                col.value = this.varCache[p][tarAddress];
                col.valueDate = this.colMap[p][tarAddress].valueDate;
              }
            }
            if (
              (col.formItem.widget === FormItemWidgetType.FLOW_CC ||
                col.formItem.widget === FormItemWidgetType.FLOW_PRESENT ||
                col.formItem.widget === FormItemWidgetType.FLOW_CC_ONE ||
                col.formItem.widget === FormItemWidgetType.FLOW_PRESENT_ONE) &&
              !!col.formItem.defaultValue &&
              col.formItem.defaultValue.indexOf('=') === 0 &&
              col.valueCascader?.length === 0
            ) {
              const tarAddress = col.formItem.defaultValue.replace('=', '');
              if (
                !!this.varCache[p][tarAddress] &&
                !!this.colMap[p][tarAddress]
              ) {
                col.valueRef = this.varCache[p][tarAddress];
                this.multiSelectChange(col);
              }
            }
          }
        }
      }
      p++;
    }
  }

  private _disabledAddressList: string[] = [];
  @Input()
  set disabledAddressList(value: string[]) {
    this._disabledAddressList = value;
    console.log(this.config);
    this.config = this.parseConfig(this.config);
  }

  get disabledAddressList(): string[] {
    return this._disabledAddressList;
  }

  @Input()
  set canAdd(addable: boolean) {
    this._canAdd = addable;
  }

  get canAdd() {
    return this._canAdd;
  }

  _canAdd = false;

  private _config!: ExcelTable[];

  orgList: Organization[] = [];

  orgMap: { [key: number]: Organization } = {};

  constructor(
    private commonService: CommonService,
    private events: EventService,
    private staffOrganizationService: StaffOrganizationService,
    private globalService: GlobalService,
    private modalService: ModalService,
    private message: NzMessageService,
    private workReportService: WorkReportService
  ) {}

  signAddressMap: { [key: string]: string } = {};

  subsciption = (event: any) => {
    // console.log(event);
    this.signAddressMap[event['address']] = event['content'];
  };

  ngOnInit(): void {
    this.events.on(EventType.SignUserSelected, this.subsciption);
  }

  ngOnDestroy(): void {
    this.events.destroyListener(EventType.SignUserSelected, this.subsciption);
  }

  duplicateForm(tabId: number, isLast: boolean) {
    this.onDuplicate.emit(isLast);
  }

  // upload(event: any, key: string) {
  //   const files = event.target['files'];
  //   const files$: Observable<FileResponse>[] = [];
  //   for (const file of files) {
  //     files$.push(this.commonService.uploadFileWithoutProgress(file));
  //   }
  //   zip(files$).subscribe((urls: FileResponse[]) => {
  //     event.target['value'] = '';
  //     // if (url) {
  //     //   console.log(url);
  //     // this.result[key] = url;
  //     // }
  //     console.log(urls);
  //   });
  // }

  onImageChange(fileUrls: string[], cell: ExcelTableRowColumn) {
    if (fileUrls.length === 0) {
      cell.imgFileIds = [];
    } else {
      cell.imgFileIds = fileUrls;
    }
  }

  onAttachImageChange(
    fileUrls: string[],
    cell: ExcelTableRowColumn,
    tableIndex: number
  ) {
    if (fileUrls.length === 0) {
      cell.imgFileIds = [];
    } else {
      cell.imgFileIds = fileUrls;
    }

    this.copyAttachInfo(cell, tableIndex);
  }

  private copyAttachInfo(cell: ExcelTableRowColumn, tableIndex: number) {
    const args: string[] = cell.formItem?.formula
      ? cell.formItem?.formula
          .replace('Copy(', '')
          .replace(')', '')
          .split(',')
          .map((a) => a.trim())
      : [];
    if (args.length !== 3 && args.length !== 4) {
      return;
    }
    let sourceTable = tableIndex;
    let sourceRowStart = parseInt(args[0]) - 1;
    let targetTable = tableIndex;
    let targetRowStart = parseInt(args[1]) - 1;
    let count = parseInt(args[2]);
    let scaned = 0;
    if (args.length === 4 && args[3] === 'true') {
      if (tableIndex === 0) {
        return;
      }
      sourceTable = tableIndex - 1;
    }
    while (scaned < count) {
      for (
        let i = 0;
        i <
        this.attachFormTable[sourceTable].rows![sourceRowStart + scaned]
          .columns!.length;
        i++
      ) {
        const sourceCell =
          this.attachFormTable[sourceTable].rows![sourceRowStart + scaned]
            .columns![i];
        const targetCell =
          this.attachFormTable[targetTable].rows![targetRowStart + scaned]
            .columns![i];
        if (
          targetCell.formItem?.type === FormItemType.VARIABLES &&
          targetCell.formItem?.widget !== FormItemWidgetType.IMAGE
        ) {
          if (targetCell.formItem?.widget === FormItemWidgetType.DATE) {
            if (!targetCell.valueDate) {
              targetCell.valueDate = sourceCell.valueDate;
              targetCell.value = sourceCell.value;
            }
          }
          if (
            targetCell.formItem?.widget === FormItemWidgetType.NORMAL &&
            !targetCell.value
          ) {
            targetCell.value = sourceCell.value;
            if (targetCell.formItem?.formula === 'AddOne()') {
              try {
                if (!isNaN(parseInt(targetCell.value!))) {
                  targetCell.value = (
                    parseInt(targetCell.value!) + 1
                  ).toString();
                }
              } catch (error) {}
            }
          }
        }
      }
      scaned++;
      // this.attachFormTable[targetTable].rows![targetRowStart + scaned].columns!.forEach(
    }
  }

  onJS101DataFocus(page: number, cell: ExcelTableRowColumn) {
    const group = this.detection2PagedGroup[page][cell.formItem!.formula!];
    if (!group) {
      return;
    }
    if (cell.address === group.xRAddress) {
      if (group.xD && !group.xR) {
        group.xR = parseInt(group.xD).toString();
        cell.value = group.xR;
      }
    }
    if (cell.address === group.xDAddress) {
      if (group.xR && !group.xD) {
        group.xD = parseInt(group.xR).toString();
        cell.value = group.xD;
      }
    }
    if (cell.address === group.yRAddress) {
      if (group.yD && !group.yR) {
        group.yR = parseInt(group.yD).toString();
        cell.value = group.yR;
      }
    }
    if (cell.address === group.yDAddress) {
      if (group.yR && !group.yD) {
        group.yD = parseInt(group.yR).toString();
        cell.value = group.yD;
      }
    }
  }

  onJS101DataChange(page: number, cell: ExcelTableRowColumn) {
    console.log(cell);
    const group = this.detection2PagedGroup[page][cell.formItem!.formula!];
    if (!group) {
      return;
    }
    if (cell.address === group.xDAddress) {
      group.xD = cell.value ?? '';
      if (!!group.xD) {
        group.xD = parseFloat(group.xD).toFixed(3);
      }
      // if (!group.xR && group.xD) {
      //   group.xR = parseInt(group.xD).toFixed(3);
      // }
    }
    if (cell.address === group.xRAddress) {
      group.xR = cell.value ?? '';
      if (!!group.xR) {
        group.xR = parseFloat(group.xR).toFixed(3);
      }
      // if (!group.xD && group.xR) {
      //   group.xD = parseInt(group.xR).toFixed(3);
      // }
    }
    if (!!group.xD && !!group.xR) {
      const result =
        Math.round(+group.xR * 1000) - Math.round(+group.xD * 1000);
      group.xT = result > 0 ? `+${result}` : `${result}`;
    } else {
      group.xT = '';
    }
    if (cell.address === group.yDAddress) {
      group.yD = cell.value ?? '';
      if (!!group.yD) {
        group.yD = parseFloat(group.yD).toFixed(3);
      }
      // if (!group.yR && group.yD) {
      //   group.yR = parseInt(group.yD).toFixed(3);
      // }
    }
    if (cell.address === group.yRAddress) {
      group.yR = cell.value ?? '';
      if (!!group.yR) {
        group.yR = parseFloat(group.yR).toFixed(3);
      }
      // if (!group.yD && group.yR) {
      //   group.yD = parseInt(group.yR).toFixed(3);
      // }
    }
    if (!!group.yD && !!group.yR) {
      const result =
        Math.round(+group.yR * 1000) - Math.round(+group.yD * 1000);
      group.yT = result > 0 ? `+${result}` : `${result}`;
    } else {
      group.yT = '';
    }
    // for (const t of this.config) {
    for (const r of this.config[page].rows ?? []) {
      for (const c of r.columns ?? []) {
        if (c.formItem?.formula === cell.formItem!.formula) {
          if (c.address === group.xDAddress) {
            c.value = group.xD;
          }
          if (c.address === group.xRAddress) {
            c.value = group.xR;
          }
          if (c.address === group.xTAddress) {
            c.value = group.xT;
          }
          if (c.address === group.yDAddress) {
            c.value = group.yD;
          }
          if (c.address === group.yRAddress) {
            c.value = group.yR;
          }
          if (c.address === group.yTAddress) {
            c.value = group.yT;
          }
        }
      }
    }
    // }
  }

  onJS103DataChange(page: number, cell: ExcelTableRowColumn) {
    const group = this.detection3PagedGroup[page][cell.formItem!.formula!];
    if (!group) {
      return;
    }
    if (cell.address === group.hDAddress) {
      group.hD = cell.value ?? '';
      if (!!group.hD) {
        group.hD = parseFloat(group.hD).toFixed(3);
      }
    }
    if (cell.address === group.hRAddress) {
      group.hR = cell.value ?? '';
      if (!!group.hR) {
        group.hR = parseFloat(group.hR).toFixed(3);
      }
    }
    if (!!group.hD && !!group.hR) {
      const result =
        Math.round(+group.hR * 1000) - Math.round(+group.hD * 1000);
      group.hT = result > 0 ? `+${result}` : `${result}`;
    } else {
      group.hT = '';
    }
    // for (const t of this.config) {
    for (const r of this.config[page].rows ?? []) {
      for (const c of r.columns ?? []) {
        if (c.formItem?.formula === cell.formItem!.formula) {
          if (c.address === group.hDAddress) {
            c.value = group.hD;
          }
          if (c.address === group.hRAddress) {
            c.value = group.hR;
          }
          if (c.address === group.hTAddress) {
            c.value = group.hT;
          }
        }
      }
    }
    // }
  }

  copy(configIndex: number, rowIndex: number) {
    for (const r of this._config[configIndex].rows ?? []) {
      for (const c of r.columns ?? []) {
        if (c.firstRow! < rowIndex && c.lastRow! >= rowIndex) {
          c.lastRow!++;
          c.rowSpan!++;
        }
      }
    }
    const rowCopy = JSON.parse(
      JSON.stringify(this._config[configIndex].rows![rowIndex])
    );
    this._config[configIndex].rows!.splice(rowIndex, 0, rowCopy);
  }

  onPureValueChange(page: number, cell: ExcelTableRowColumn) {
    const p = document.querySelector(`#${cell.address}`);
    if ((cell.formItem?.formula ?? '').indexOf('DH') === -1) {
      if (p && p['innerHTML']) {
        this.shinkFont(cell.address!, cell.fontSize ?? 10);
      }
    } else {
      cell.valueHtml = cell.value
        ?.replaceAll('\n', '<br/>')
        .replaceAll(' ', '&ensp;');
      if (p) {
        setTimeout(() => {
          for (const t of this.config) {
            for (const r of t.rows ?? []) {
              for (const c of r.columns ?? []) {
                if (c.address === cell.address) {
                  r.dyHeight = (p.clientHeight + 20) / this.hRate;
                }
              }
            }
          }
        }, 100);
      }
    }
    if (!!cell.formItem?.expanded && page >= 0) {
      for (let i = 0; i < this.config.length; i++) {
        if (i === page) {
          continue;
        }
        for (const row of this.config[i].rows ?? []) {
          for (const col of row.columns ?? []) {
            if (col.address === cell.address) {
              col.value = cell.value;
              col.valueHtml = cell.valueHtml;
            }
          }
        }
      }
    }
    this.handleFormula();
  }

  private shinkFont(address: string, targetFont: number) {
    if (targetFont <= 8) return;
    // console.log('shinkFont');
    const p = document.getElementById(`${address}`);
    const ta = document.querySelector(`#ta${address}`);
    if (p && ta) {
      const pList: HTMLElement[] = [];
      for (let i = targetFont; i > 7; i--) {
        const ele = document.createElement('p');
        ele.innerText = p.innerText;
        ele.style.fontSize = `${i}pt`;
        ele.style.fontFamily = '宋体';
        ele.style.width = p.clientWidth + 'px';
        ele.style.lineHeight = `${i}pt`;
        ele.style.whiteSpace = 'normal';
        ele.style.wordBreak = 'break-all';
        ele.style.paddingRight = '8px';
        ele.style.opacity = '0';
        document.body.appendChild(ele);
        pList.push(ele);
      }
      setTimeout(() => {
        let last: number = -1;
        for (let i = 0; i < pList.length; i++) {
          const ele = pList[i];
          if (ele.clientHeight <= ta.clientHeight && last < 0) {
            last = i;
          }
          document.body.removeChild(ele);
        }
        for (const t of this.config) {
          for (const r of t.rows ?? []) {
            for (const c of r.columns ?? []) {
              if (c.address === address) {
                if (last === -1) {
                  c.dyFontSize = 8;
                } else {
                  c.dyFontSize = targetFont - last;
                }
              }
            }
          }
        }
      }, 100);
    }
  }

  onDateChange(cell: ExcelTableRowColumn, page: number) {
    if (!cell.valueDate) {
      cell.value = '';
      return;
    }
    // if (!this.disableAll) {
    //   this.events.broadcast(EventType.START_AUTO_SAVE, this.flowKey);
    // }
    const d = new Date(cell.valueDate);
    cell.value = moment(d.toISOString())
      .locale('zh_cn')
      .format(cell.formItem?.dataSource ?? 'YYYY年MM月DD日');
    this.varCache[page][cell.address!] = cell.value;
    this.colMap[page][cell.address!].valueDate = d;
    if (this.weatherSource.includes(cell.address!)) {
      this.commonService
        .getWeather(
          moment(d.toISOString()).locale('zh_cn').format('YYYY-MM-DD')
        )
        .subscribe((res: WeatherInfo) => {
          this.onWeatherChange(res, cell.address!);
        });
    }
    if (!!cell.formItem?.expanded) {
      for (let i = 0; i < this.config.length; i++) {
        if (i === page) {
          continue;
        }
        for (const row of this.config[i].rows ?? []) {
          for (const col of row.columns ?? []) {
            if (col.address === cell.address) {
              col.value = cell.value;
              col.valueDate = cell.valueDate;
              this.varCache[i][cell.address!] = cell.value;
              this.colMap[i][cell.address!].valueDate = d;
            }
          }
        }
      }
    }
    if (this.waitAddressList.includes(cell.address!)) {
      this.handleWaitAddress();
    }
  }

  onAttachDateChange(cell: ExcelTableRowColumn) {
    if (!cell.valueDate) {
      cell.value = '';
      return;
    }
    const d = new Date(cell.valueDate);
    cell.value = moment(d.toISOString())
      .locale('zh_cn')
      .format(cell.formItem?.dataSource ?? 'YYYY年MM月DD日');
  }

  onWeatherChange(info: WeatherInfo, address: string) {
    for (const table of this._config) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (
            !!col.formItem &&
            col.formItem.type === FormItemType.VARIABLES &&
            col.formItem.widget === FormItemWidgetType.WEATHER &&
            col.formItem.formula === address
          ) {
            if (info) {
              col.value = col.formItem.dataSource
                ? info[col.formItem.dataSource]
                : '';
            } else {
              col.value = '';
            }
          }
        }
      }
    }
    this.handleFormula();
  }

  radioGroup: { [key: string]: string }[] = [];

  onRadioChange(page: number, dataSourceObject: any) {
    for (const row of this._config[page].rows ?? []) {
      for (const col of row.columns ?? []) {
        if (
          col.formItem?.widget === FormItemWidgetType.RADIO &&
          !!col.formItem.dataSourceObject &&
          col.formItem.dataSourceObject.group === dataSourceObject.group
        ) {
          col.valueRadio = dataSourceObject.value;
          this.radioGroup[dataSourceObject.group] = dataSourceObject.value;
          if (col.valueRadio === col.address) {
            col.value = '☑';
          } else {
            col.value = '□';
          }
        }
      }
    }
    this.handleFormula();
  }

  onCheckChange(cell: ExcelTableRowColumn) {
    if (cell.valueChecked?.toString() === 'true') {
      cell.value = '☑';
    } else {
      cell.value = '□';
    }
    this.handleFormula();
  }

  onLocationChange(locationId: string, cell: ExcelTableRowColumn) {
    cell.valueLocationId = locationId;
    cell.value = LocationUtil.getLocationName(locationId);
    this.handleFormula();
  }

  fakeSelectChange(cell: ExcelTableRowColumn) {
    cell.valueCascader = cell.valueSingle ? [cell.valueSingle] : [];
    this.multiSelectChange(cell);
  }

  multiSelectChange(cell: ExcelTableRowColumn, init?: boolean) {
    cell.valueHtml = '';
    const nameList = [];
    if (cell.valueCascader) {
      if (cell.valueCascader?.length > 0) {
        for (const item of cell.valueCascader) {
          nameList.push(this.orgMap[item].name);
        }
      } else if (cell.valueCascader.length === 0 && !!cell.valueRef) {
        for (const id in this.orgMap) {
          if (this.orgMap[id].name === cell.valueRef) {
            nameList.push(this.orgMap[id].name);
            cell.valueCascader = [id];
          }
        }
      }
    }
    cell.valueHtml = nameList.join('<br/>');
    cell.value = cell.valueHtml;
    this.handleFormula(init);
  }

  onProjectDataValueChange(page: number, cell: ExcelTableRowColumn) {
    // if (!this.disableAll) {
    //   this.events.broadcast(EventType.START_AUTO_SAVE, this.flowKey);
    // }
    if (cell.valueCascader && cell.valueCascader.length > 0) {
      cell.value = cell.valueCascader[cell.valueCascader.length - 1];
    }
    if (!!cell.formItem?.expanded && page >= 0) {
      for (let i = 0; i < this.config.length; i++) {
        if (i === page) {
          continue;
        }
        for (const row of this.config[i].rows ?? []) {
          for (const col of row.columns ?? []) {
            if (col.address === cell.address) {
              col.value = cell.value;
              col.valueCascader = cell.valueCascader;
            }
          }
        }
      }
    }
    this.handleWaitAddress();
    if (cell.formItem?.dataSource?.includes('projectOrg.')) {
      this.onProjectDataChange.emit(cell);
    }
  }

  measuredProjectEdit(cell: ExcelTableRowColumn, confIdx: number) {
    if (this.disableAll) {
      return;
    }
    // this.events.broadcast(EventType.STOP_AUTO_SAVE);
    let data: FormItemMeasuredProjectInfo | undefined = undefined;
    for (const item of this.measuredProjects) {
      if (item.formItemIds && item.formItemIds.includes(cell.formItem!.id!)) {
        data = JSON.parse(JSON.stringify(item));
        break;
      }
    }
    if (!data) {
      this.message.error('当前表单配置有问题，请联系运营');
      return;
    }

    this.workReportService
      .editable(this.editableWorkReportRequest)
      .subscribe((res) => {
        data!.editable = !!res.editable;
        data!.message = res.message ?? '';
        data!.sectionId = this.sectionId;
        data!.sectionItemId = this.sectionItemId;
        const results = this.modalService.open({
          backdropCloseable: false,
          component: DialogFormZjFillComponent,
          onClose: () => {
            // this.events.broadcast(EventType.START_AUTO_SAVE, this.flowKey);
          },
          data: {
            data,
            onCancel: () => {
              results.modalInstance.hide();
            },
            onConfirm: (data: FormItemMeasuredProjectInfo) => {
              results.modalInstance.hide();
              this.events.broadcast(EventType.SAVE_MEASURE, data);
            },
          },
        });
      });
  }

  onDetectionDesignChange(
    designVal: number[],
    page: number,
    cell: ExcelTableRowColumn
  ) {
    // if (!this.disableAll) {
    //   this.events.broadcast(EventType.START_AUTO_SAVE, this.flowKey);
    // }
    cell.value = designVal.join(' ');
    const group = cell.formItem?.formula ?? '';
    if (group) {
      this.detectionPagedGroup[page][group].designRealVal = designVal;
    }
    cell.value = designVal.join(' ');
    this.onDectionChange(page, group);
  }

  onDetectionRealChange(
    event: {
      realVals: [number[], number[]];
      passVal?: number;
    },
    page: number,
    cell: ExcelTableRowColumn
  ) {
    // console.log(event);
    // if (!this.disableAll) {
    //   this.events.broadcast(EventType.START_AUTO_SAVE, this.flowKey);
    // }
    const group = cell.formItem?.formula ?? '';
    if (group) {
      this.detectionPagedGroup[page][group].realVal1List = event.realVals[0];
      this.detectionPagedGroup[page][group].realVal2List = event.realVals[1];
      this.detectionPagedGroup[page][group].passVal = event.passVal;
      if (this.detectionPagedGroup[page][group].designRealVal?.length === 2) {
        cell.value =
          event.realVals[0].join(' ') + '\n' + event.realVals[1].join(' ');
      } else {
        cell.value = event.realVals[0].join(' ');
      }
    } else {
      cell.value = event.realVals[0].join(' ');
    }

    this.onDectionChange(page, group);
  }

  onDetectionPassChange(page: number, cell: ExcelTableRowColumn) {
    if (!!cell.valuePercent && !isNaN(+cell.valuePercent)) {
      cell.value = cell.valuePercent + '%';
      if (cell.formItem?.formula) {
        this.detectionPagedGroup[page][cell.formItem.formula].passVal =
          +cell.valuePercent;
      }
    } else {
      cell.value = '';
      if (cell.formItem?.formula) {
        this.detectionPagedGroup[page][cell.formItem.formula].passVal =
          undefined;
      }
    }
    this.handleFormula();
  }

  onDetectionBiasChange(page: number, cell: ExcelTableRowColumn) {
    // if (!this.disableAll) {
    //   this.events.broadcast(EventType.START_AUTO_SAVE, this.flowKey);
    // }
    const group = cell.formItem?.formula ?? '';
    let hasError = false;
    if (!group) {
      hasError = true;
    }
    let valStr = cell.formItem?.defaultValue
      ? cell.formItem?.defaultValue
          .replaceAll('&ensp;', ' ')
          .replaceAll('，', ',')
          .replaceAll('＋', '+')
          .replaceAll('－', '-')
          .replaceAll('，', ',')
          .replaceAll('<br/>', ' ')
      : '';
    if (valStr === '' || !group) {
      hasError = true;
    } else if (valStr.indexOf('±') > -1) {
      try {
        const val = valStr.replaceAll(' ', '').split('±')[1].trim();
        if (!isNaN(+val)) {
          this.detectionPagedGroup[page][group].biasRealValMin = -+val;
          this.detectionPagedGroup[page][group].biasRealValMax = +val;
          this.detectionPagedGroup[page][group].biasNeedDesign = true;
        } else {
          hasError = true;
        }
      } catch (error) {
        hasError = true;
      }
    } else if (valStr.indexOf('≥') > -1) {
      const value = valStr.replaceAll('≥', '').trim();
      if (!isNaN(+value)) {
        this.detectionPagedGroup[page][group].biasRealValMin = +value;
        this.detectionPagedGroup[page][group].biasNeedDesign = false;
      } else {
        hasError = true;
      }
    } else if (valStr.indexOf('≤') > -1) {
      const value = valStr.replaceAll('≤', '').trim();
      if (!isNaN(+value)) {
        this.detectionPagedGroup[page][group].biasRealValMax = +value;
        this.detectionPagedGroup[page][group].biasNeedDesign = false;
      } else {
        hasError = true;
      }
    } else if (valStr.indexOf(',') > -1) {
      try {
        let vals: string[] = [];
        vals = valStr.replaceAll(' ', '').split(',');
        if (vals.length !== 2) {
          hasError = true;
        } else {
          if (isNaN(+vals[0]) || isNaN(+vals[1])) {
            hasError = true;
          } else {
            this.detectionPagedGroup[page][group].biasRealValMin =
              +vals[0] > +vals[1] ? +vals[1] : +vals[0];
            this.detectionPagedGroup[page][group].biasRealValMax =
              +vals[1] > +vals[0] ? +vals[1] : +vals[0];
            this.detectionPagedGroup[page][group].biasNeedDesign = true;
          }
        }
      } catch (error) {
        hasError = true;
      }
    } else {
      hasError = true;
    }
    if (hasError) {
      if (!group) {
        return;
      }
      this.detectionPagedGroup[page][group].biasRealValMin = -Infinity;
      this.detectionPagedGroup[page][group].biasRealValMax = +Infinity;
      this.detectionPagedGroup[page][group].biasNeedDesign = false;
      this.detectionPagedGroup[page][group].biasError = true;
    } else {
      this.detectionPagedGroup[page][group].biasError = false;
    }
    this.onDectionChange(page, group);
  }

  onDectionChange(page: number, group: string) {
    const info: DetectionInfo = this.detectionPagedGroup[page][group];
    if (!group || !info) {
      return;
    }
    if (info.designRealVal?.length === 0 || info.biasError) {
      info.val1Max = +Infinity;
      info.val1Min = -Infinity;
      info.val2Max = +Infinity;
      info.val2Min = -Infinity;
      return;
    }
    if (info.designRealVal) {
      if (info.designRealVal.length > 0) {
        if (info.biasNeedDesign) {
          info.val1Max = info.designRealVal[0] + info.biasRealValMax!;
          info.val1Min = info.designRealVal[0] + info.biasRealValMin!;
        } else {
          info.val1Max = info.biasRealValMax!;
          info.val1Min = info.biasRealValMin!;
        }
      }
      if (info.designRealVal.length > 1) {
        if (info.biasNeedDesign) {
          info.val2Max = info.designRealVal[1] + info.biasRealValMax!;
          info.val2Min = info.designRealVal[1] + info.biasRealValMin!;
        } else {
          info.val2Max = info.biasRealValMax!;
          info.val2Min = info.biasRealValMin!;
        }
      }
    }
    // for (const table of this.config) {
    for (const row of this.config[page].rows ?? []) {
      for (const col of row.columns ?? []) {
        if (
          col.formItem?.formula === group &&
          col.formItem.widget === FormItemWidgetType.DETECTION &&
          col.formItem.dataSource === DetectionType.PASS
        ) {
          if (
            !info.biasError &&
            info.passVal !== undefined &&
            !isNaN(info.passVal)
          ) {
            col.value = info.passVal + '%';
            col.valuePercent = info.passVal + '';
          } else {
            col.value = '';
            col.valuePercent = '';
          }
          this.handleFormula();
          return;
        }
      }
    }
    // }
  }

  submit() {
    this.onChange.emit(this.config);
  }

  private handleFormula(init?: boolean) {
    if (!this.config) return;

    // if (Math.abs(Date.now() - this.initDate) < 5000) {
    //   this.initMd5 = Md5.hashStr(JSON.stringify(this._config));
    // } else {
    //   if (
    //     this.initMd5 !== Md5.hashStr(JSON.stringify(this._config)) &&
    //     !this.startAutoSave
    //   ) {
    //     this.startAutoSave = true;
    //     this.events.broadcast(EventType.START_AUTO_SAVE, this.flowKey);
    //   }
    // }

    let p = 0;
    for (const table of this.config) {
      if (!this.varCache[p]) {
        this.varCache[p] = {};
      }
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (!!col.formItem && col.formItem.type === FormItemType.VARIABLES) {
            this.varCache[p][col.address!] = col.value;
          }
        }
      }
      p++;
    }
    p = 0;
    for (const table of this.config ?? []) {
      for (const row of table.rows ?? []) {
        for (const col of row.columns ?? []) {
          if (col.formItem?.formula) {
            const formulas = col.formItem.formula.split(';');
            for (const formula of formulas) {
              if (formula.indexOf('Disable') > -1) {
                const [key, value] = formula
                  .replace('Disable(', '')
                  .replace(')', '')
                  .split('=');
                col.disable = this.varCache[p][key] === value;
                this.onFlowDisableChange.emit({
                  address: col.address!,
                  disabled: this.varCache[p][key] === value,
                });
              }
              if (formula.indexOf('Avg') > -1) {
                const [start, end] = formula
                  .replace('Avg(', '')
                  .replace(')', '')
                  .split(':');
                const keys = this.getKeyList(start, end);
                let sum = 0;
                let count = 0;
                for (const key of keys) {
                  if (this.varCache[p][key] && !isNaN(+this.varCache[p][key])) {
                    sum += +this.varCache[p][key];
                    count++;
                  }
                }
                const avg = count !== 0 ? sum / count : 0;
                this.varCache[p][col.address!] = avg;
                col.value = avg.toString();
              }
            }
          }
          if (
            col.formItem?.widget === FormItemWidgetType.FLOW_PRESENT ||
            col.formItem?.widget === FormItemWidgetType.FLOW_PRESENT_ONE
          ) {
            if (JSON.stringify(col.valueCascader) !== col.oldValueCascader) {
              this.onPresentChange.emit({
                ids: col.valueCascader,
                musted: col.formItem?.musted,
              });
              col.oldValueCascader = JSON.stringify(col.valueCascader);
            }
            if (init) {
              this.onPresentChange.emit({
                ids: col.valueCascader,
                musted: col.formItem?.musted,
                init: true,
              });
            }
          }
          if (
            col.formItem?.widget === FormItemWidgetType.FLOW_CC ||
            col.formItem?.widget === FormItemWidgetType.FLOW_CC_ONE
          ) {
            if (JSON.stringify(col.valueCascader) !== col.oldValueCascader) {
              this.onCCChange.emit({
                ids: col.valueCascader,
                musted: col.formItem?.musted,
              });
              col.oldValueCascader = JSON.stringify(col.valueCascader);
            }
            if (init) {
              this.onCCChange.emit({
                ids: col.valueCascader,
                musted: col.formItem?.musted,
                init: true,
              });
            }
          }
        }
      }
      p++;
    }
  }

  private getKeyList(start: string, end: string): string[] {
    const startRow = +start.match(/\d+/)![0];
    const startCol = start.match(/[a-zA-Z]+/)![0];
    const endRow = +end.match(/\d+/)![0];
    const endCol = end.match(/[a-zA-Z]+/)![0];
    const keys: string[] = [];
    for (let i = startRow; i <= endRow; i++) {
      for (let j = startCol.charCodeAt(0); j <= endCol.charCodeAt(0); j++) {
        keys.push(String.fromCharCode(j) + i);
      }
    }
    return keys;
  }
}

export class DetectionInfo {
  designRealVal?: number[] = [];
  biasRealValMax?: number;
  biasRealValMin?: number;
  biasNeedDesign?: boolean = false;
  biasError?: boolean = false;
  realVal1List?: number[] = [];
  realVal2List?: number[] = [];
  passVal?: number = undefined;
  val1Min?: number;
  val1Max?: number;
  val2Min?: number;
  val2Max?: number;
  selectIndex?: number;
  biasStr?: string;
}

export class Detection2Info {
  xD?: string;
  xDAddress?: string;
  xR?: string;
  xRAddress?: string;
  xT?: string;
  xTAddress?: string;
  yD?: string;
  yDAddress?: string;
  yR?: string;
  yRAddress?: string;
  yT?: string;
  yTAddress?: string;
}

export class Detection3Info {
  hD?: string;
  hDAddress?: string;
  hR?: string;
  hRAddress?: string;
  hT?: string;
  hTAddress?: string;
}
