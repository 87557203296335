export enum ProjectSectionUnitFormType {
  CONSTRUCTION_RECORD = 'CONSTRUCTION_RECORD',
  CONSTRUCTION_SELF_CHECK = 'CONSTRUCTION_SELF_CHECK',
  SUPERVISE_SPOT_CHECK = 'SUPERVISE_SPOT_CHECK',
  SUPERVISE_RECORD = 'SUPERVISE_RECORD',
  MATERIAL_SELF_CHECK = 'MATERIAL_SELF_CHECK',
  MATERIAL_SPOT_CHECK = 'MATERIAL_SPOT_CHECK',
  STANDARD_SELF_TEST = 'STANDARD_SELF_TEST',
  STANDARD_SPOT_TEST = 'STANDARD_SPOT_TEST',
  STANDARD_AUDIT_TEST = 'STANDARD_AUDIT_TEST',
  PLAT_USER_AUTH_AGREEMENT = 'PLAT_USER_AUTH_AGREEMENT',
  OPEN_APP_API = 'OPEN_APP_API',
  MATERIAL_INSPECTION = 'MATERIAL_INSPECTION',
}
