export enum FormItemMeasuredProjectSpadType {
  ALLOWABLE_DEVIATION = 'ALLOWABLE_DEVIATION',
  GTE_SPECIFIED_VALUE = 'GTE_SPECIFIED_VALUE',
  LTE_SPECIFIED_VALUE = 'LTE_SPECIFIED_VALUE',
  RANGE_SPECIFIED_VALUE = 'RANGE_SPECIFIED_VALUE',
  GTE_DESIGN_VALUE = 'GTE_DESIGN_VALUE',
  LTE_DESIGN_VALUE = 'LTE_DESIGN_VALUE',
  USER_FILLIN = 'USER_FILLIN',
  NOT_REQUIREMENT = 'NOT_REQUIREMENT',
}

export const FormItemMeasuredProjectSpadTypeMap = {
  [FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION]: '允许偏差',
  [FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE]: '≥规定值',
  [FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE]: '≤规定值',
  [FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE]: '~规定值',
  [FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE]: '≥设计值',
  [FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE]: '≤设计值',
  [FormItemMeasuredProjectSpadType.USER_FILLIN]: '用户自填',
  [FormItemMeasuredProjectSpadType.NOT_REQUIREMENT]: '不要求',
};

export const FormItemMeasuredProjectSpadTypeOptions = [
  {
    label:
      FormItemMeasuredProjectSpadTypeMap[
        FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
      ],
    value: FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION,
  },
  {
    label:
      FormItemMeasuredProjectSpadTypeMap[
        FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE
      ],
    value: FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE,
  },
  {
    label:
      FormItemMeasuredProjectSpadTypeMap[
        FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE
      ],
    value: FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE,
  },
  {
    label:
      FormItemMeasuredProjectSpadTypeMap[
        FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE
      ],
    value: FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE,
  },
  {
    label:
      FormItemMeasuredProjectSpadTypeMap[
        FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE
      ],
    value: FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE,
  },
  {
    label:
      FormItemMeasuredProjectSpadTypeMap[
        FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE
      ],
    value: FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE,
  },
  {
    label:
      FormItemMeasuredProjectSpadTypeMap[
        FormItemMeasuredProjectSpadType.USER_FILLIN
      ],
    value: FormItemMeasuredProjectSpadType.USER_FILLIN,
  },
  {
    label:
      FormItemMeasuredProjectSpadTypeMap[
        FormItemMeasuredProjectSpadType.NOT_REQUIREMENT
      ],
    value: FormItemMeasuredProjectSpadType.NOT_REQUIREMENT,
  },
];
