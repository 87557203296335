import { WorkReportStatus } from './work-report-status.enum';
import { FormFlowType } from './form-flow-type.enum';
import { OrderType } from '../../providers/request/order-type.enum';
import { SearchRequest } from '../../providers/request/search-request.model';
import { FormType } from './form-type.enum';
import { OrganizationProjectSection } from '../staff/organization-project-section.model';
export class WorkReportSearchRequest extends SearchRequest {
  sectionIds?: Array<number> = [];
  projectId?: number;
  sectionId?: number;
  sectionItemId?: number;
  sectionItemName?: string;
  parentMenuId?: number;
  menuId?: number;
  materialBatchCode?: string;
  sn?: string;
  code?: string;
  name?: string;
  category?: string;
  constructionOrgName?: string;
  supervisionOrgName?: string;
  codeName?: string;
  filledDate?: number;
  filledUserId?: number;
  filledUserMobile?: string;
  filledUserName?: string;
  filledOrgName?: string;
  publishUserId?: number;
  publishUserMobile?: string;
  publishUserName?: string;
  publishOrgName?: string;
  formId?: number;
  formType?: FormType;
  formDate?: number;
  status?: WorkReportStatus;
  replyStatus?: boolean;
  instructionId?: number;
  instructionReplyId?: number;
  nextExecuteUserId?: number;
  nextExecuteUserName?: string;
  orginFiles?: string;
  pdfFiles?: string;
  signFiles?: string;
  extField1?: string;
  extField2?: string;
  extField3?: string;
  extField4?: string;
  extField5?: string;
  extField6?: string;
  extField7?: string;
  extField8?: string;
  extField9?: string;
  extField10?: string;
  attachFiles?: string;
  comment?: string;
  type?: FormFlowType;
  executorId?: number;
  executorName?: number;
  counterSigned?: boolean;
  readMark?: boolean;
  readTime?: number;
  operateTime?: number;
  published?: boolean;
  enable?: boolean;
  display?: boolean;
  statusOrder?: OrderType;
  snOrder?: OrderType;
  filledDateOrder?: OrderType;
  extField1Order?: OrderType;
  extField2Order?: OrderType;
  extField3Order?: OrderType;
  extField4Order?: OrderType;
  extField5Order?: OrderType;
  extField6Order?: OrderType;
  extField7Order?: OrderType;
  extField8Order?: OrderType;
  extField9Order?: OrderType;
  extField10Order?: OrderType;
  categoryOrder?: OrderType;
  constructionOrgNameOrder?: OrderType;
  supervisionOrgNameOrder?: OrderType;
  codeOrder?: OrderType;
  nameOrder?: OrderType;
}
