<div class="entry" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
	<div *ngFor="let fileId of fileList; let index = index" class="cover" [style.width]="calc" [style.height]="calc"
		(click)="preview(index)">
		<img [src]="environment.fileHost + fileId" alt="">
	</div>

	<div class="progress" *ngIf="progress.uploading" [style.width]="(100 - progress.progress) + '%'">
	</div>

	<div class="add {{required ? 'color-danger' : 'color-primary'}}"
		*ngIf="fileList.length === 0 && !progress.uploading && !disabled">
		<span nz-icon nzType="plus-circle" nzTheme="outline"></span>
		{{addText}}
	</div>

	<input class="file" type="file" accept="image/*" *ngIf="!disabled && fileList.length === 0"
		(change)="onFileChange($event)" [multiple]="+limit > 1" [disabled]="disabled">
</div>