import { OmFormItemFlowService } from 'src/app/modules/shared/services/om-form-item-flow.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import 'moment/locale/zh-cn';
import { ModalService } from 'ng-devui';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, catchError, throwError, zip } from 'rxjs';
import {
  AlertDialogComponent,
  AlertDialogType,
} from 'src/app/modules/shared/components/alert-dialog/alert-dialog.component';
import { FilePreviewType } from 'src/app/modules/shared/components/file-preview-entry/file-preview-entry.component';
import { ExcelTable } from 'src/app/modules/shared/models/form/excel-table.model';
import { FileSource } from 'src/app/modules/shared/models/form/file-source.enum';
import { FileType } from 'src/app/modules/shared/models/form/file-type.enum';
import { FormFlowInfo } from 'src/app/modules/shared/models/form/form-flow-info.model';
import {
  FormFlowType,
  FormFlowTypeMap,
} from 'src/app/modules/shared/models/form/form-flow-type.enum';
import { FormHeaderListRequest } from 'src/app/modules/shared/models/form/form-header-list-request.model';
import { FormHeader } from 'src/app/modules/shared/models/form/form-header.model';
import { FormItemType } from 'src/app/modules/shared/models/form/form-item-type.enum';
import {
  FormItemWidgetType,
  FormItemWidgetTypeList,
  FormItemWidgetTypeMap,
} from 'src/app/modules/shared/models/form/form-item-widget-type.enum';
import { UserInfo } from 'src/app/modules/shared/models/form/user-info.model';
import { WidgetData } from 'src/app/modules/shared/models/form/widget-data.model';
import { WorkFlowSignFieldType } from 'src/app/modules/shared/models/form/work-flow-sign-field-type.enum';
import {
  WorkFlowSignerTaskType,
  WorkFlowSignerTaskTypeList,
} from 'src/app/modules/shared/models/form/work-flow-signer-task-type.enum';
import { WorkFlowHistoryStatusMap } from 'src/app/modules/shared/models/form/work-flow-status.enum';
import { WorkFlowTaskInfo } from 'src/app/modules/shared/models/form/work-flow-task-info.model';
import { WorkFlowTask } from 'src/app/modules/shared/models/form/work-flow-task.model';
import { WorkReportDocType } from 'src/app/modules/shared/models/form/work-report-doc-type.enum';
import { WorkReportStatus } from 'src/app/modules/shared/models/form/work-report-status.enum';
import { WorkReportUploadResponse } from 'src/app/modules/shared/models/form/work-report-upload-response.model';
import { WorkReport } from 'src/app/modules/shared/models/form/work-report.model';
import { DepartmentListRequest } from 'src/app/modules/shared/models/staff/department-list-request.model';
import { Department } from 'src/app/modules/shared/models/staff/department.model';
import { OrganizationCategory } from 'src/app/modules/shared/models/staff/organization-category.enum';
import { OrganizationExtListRequest } from 'src/app/modules/shared/models/staff/organization-ext-list-request.model';
import { OrganizationExtSearchRequest } from 'src/app/modules/shared/models/staff/organization-ext-search-request.model';
import { OrganizationListRequest } from 'src/app/modules/shared/models/staff/organization-list-request.model';
import { OrganizationMember } from 'src/app/modules/shared/models/staff/organization-member.model';
import {
  OrganizationType,
  OrganizationTypeMap,
} from 'src/app/modules/shared/models/staff/organization-type.enum';
import { Organization } from 'src/app/modules/shared/models/staff/organization.model';
import { Post } from 'src/app/modules/shared/models/staff/post.model';
import { UserListRequest } from 'src/app/modules/shared/models/staff/user-list-request.model';
import { User } from 'src/app/modules/shared/models/staff/user.model';
import { NzTreeNode } from 'src/app/modules/shared/models/tree-node.model';
import {
  EventService,
  EventType,
} from 'src/app/modules/shared/providers/event.service';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { FileResponse } from 'src/app/modules/shared/providers/request/file-response.model';
import { CommonService } from 'src/app/modules/shared/services/common.service';
import { OmFormHeaderService } from 'src/app/modules/shared/services/om-form-header.service';
import { OmFormItemService } from 'src/app/modules/shared/services/om-form-item.service';
import { OmUserOrgService } from 'src/app/modules/shared/services/om-user-org.service';
import { StaffDepartmentService } from 'src/app/modules/shared/services/staff-department.service';
import { StaffOrganizationMemberService } from 'src/app/modules/shared/services/staff-organization-member.service';
import { StaffOrganizationService } from 'src/app/modules/shared/services/staff-organization.service';
import { StaffPostService } from 'src/app/modules/shared/services/staff-post.service';
import { StaffUserService } from 'src/app/modules/shared/services/staff-user.service';
import { WorkFlowTaskService } from 'src/app/modules/shared/services/work-flow-task.service';
import { WorkReportService } from 'src/app/modules/shared/services/work-report.service';
import { environment } from 'src/environments/environment';
import { DialogGl105DateComponent } from '../../../dialogs/qa/dialog-gl105-date/dialog-gl105-date.component';
import { CustomMenuFormFillDialogComponent } from '../custom-menu-form-fill-dialog/custom-menu-form-fill-dialog.component';
import { FormPagesType } from './../../../../shared/models/form/form-pages-type.enum';
import {
  WorkFlowActionType,
  WorkFlowActionTypeChangeableList,
  WorkFlowActionTypeMap,
} from './../../../../shared/models/form/work-flow-action-type.enum';
import { FormFlow } from 'src/app/modules/shared/models/form/form-flow.model';
import { FormItemMeasuredProjectInfo } from 'src/app/modules/shared/models/form/form-item-measured-project-info.model';
import { ExcelTableRowColumn } from 'src/app/modules/shared/models/form/excel-table-row-column.model';
import { ProjectSectionItem } from 'src/app/modules/shared/models/project/project-section-item.model';
import { ProjectSectionItemService } from 'src/app/modules/shared/services/project-section-item.service';
import { ProjectItemType } from 'src/app/modules/shared/models/project/project-item-type.enum';
import { DialogCopyReportComponent } from '../../../dialogs/dialog-copy-report/dialog-copy-report.component';
import { ProjectSectionService } from 'src/app/modules/shared/services/project-section.service';
import { ProjectSectionListRequest } from 'src/app/modules/shared/models/project/project-section-list-request.model';
import { ProjectSection } from 'src/app/modules/shared/models/project/project-section.model';

@Component({
  selector: 'app-custom-menu-form-detail',
  templateUrl: './custom-menu-form-detail.component.html',
  styleUrls: ['./custom-menu-form-detail.component.scss'],
})
export class CustomMenuFormDetailComponent {
  searchRequest = new OrganizationExtSearchRequest();

  environment = environment;

  workReport?: WorkReport;

  FilePreviewType = FilePreviewType;

  WorkReportDocType = WorkReportDocType;

  FormItemWidgetTypeList = FormItemWidgetTypeList;

  FormFlowTypeMap = FormFlowTypeMap;

  FormFlowType = FormFlowType;

  postTreeNodeFull: NzTreeNode[] = [];

  orgTypeMap: { [key: string]: Organization[] } = {};

  WorkFlowSignerTaskTypeList = WorkFlowSignerTaskTypeList;

  WorkFlowSignerTaskType = WorkFlowSignerTaskType;

  userList: User[] = [];

  disabledAddress: string[] = [];

  FormPagesType = FormPagesType;

  projectWidgetData: WidgetData[] = [];

  orgList: Organization[] = [];

  orgMap: { [key: string]: Organization } = {};
  departmentMap: { [key: string]: Department } = {};
  postMap: { [key: string]: Post } = {};
  userData: User[] = [];

  orgMemberMap: { [key: string]: OrganizationMember } = {};
  orgMemberInfoMap: { [key: string]: OrganizationMember[] } = {};

  submited = false;

  needFill = false;

  hideLeft = false;

  hideRight = false;

  centerWidth = 760;

  submitable = false;

  resp: any = {};

  WorkFlowActionTypeChangeableList = WorkFlowActionTypeChangeableList;

  WorkFlowActionTypeMap = WorkFlowActionTypeMap;

  WorkFlowHistoryStatusMap = WorkFlowHistoryStatusMap;

  historyList: WorkFlowTaskInfo[] = [];

  hideHistoryFlowId: number[] = [];

  interval: any = undefined;

  sectionMap: { [key: string]: ProjectSection } = {};

  // autoSave = false;
  // autoSaveTime = 600;

  editablePost = '';

  formItemMeasuredProjects: Array<FormItemMeasuredProjectInfo> = [];

  // stopAutoSaveSubsciption = () => this.stopAutoSave();

  // startAutoSaveSubsciption = () => this.startAutoSave();

  forceSaveSubsciption = (data: FormItemMeasuredProjectInfo) =>
    this.saveMeasure(data);

  // key 为formItemId， value 为显示的值
  measureDataMap: { [key: string]: string } = {};

  is027 = false;

  saveMeasure(data: FormItemMeasuredProjectInfo) {
    for (let i = 0; i < this.formItemMeasuredProjects.length; i++) {
      if (this.formItemMeasuredProjects[i].id === data.id) {
        this.formItemMeasuredProjects[i] = data;
        if (data.fieldFormItemIdMap) {
          console.log('data.fieldFormItemIdMap: ', data.fieldFormItemIdMap);
          for (const key of Object.keys(data.fieldFormItemIdMap)) {
            // 这个key是measuredValue, 对应的值是formItemId
            if (
              key === 'measuredValue' &&
              this.workReport?.code?.includes('ZP')
            ) {
              continue;
            }
            if (
              key === 'deviationValue' &&
              this.workReport?.code?.includes('ZJ')
            ) {
              continue;
            }
            this.measureDataMap[data.fieldFormItemIdMap[key]!.toString()] =
              data[key];
          }
        }
        break;
      }
    }
    this.save(true);
  }

  constructor(
    private modalService: ModalService,
    private workReportService: WorkReportService,
    private workFLowTaskService: WorkFlowTaskService,
    private omFormItemFlowService: OmFormItemFlowService,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private omUserOrgService: OmUserOrgService,
    private staffOrganizationService: StaffOrganizationService,
    private staffOrganizationMemberService: StaffOrganizationMemberService,
    private staffDepartmentService: StaffDepartmentService,
    private staffPostService: StaffPostService,
    private staffUserService: StaffUserService,
    private globalService: GlobalService,
    private omFormItemService: OmFormItemService,
    private events: EventService,
    private omFormHeaderService: OmFormHeaderService,
    private nzMessageService: NzMessageService,
    private projectSectionItemService: ProjectSectionItemService,
    private projectSectionService: ProjectSectionService
  ) {
    this.isOp =
      this.globalService.userInfo.organizationType === OrganizationType.OPERATE;
  }

  isOp = false;

  // private startAutoSave() {
  //   if (this.autoSave) {
  //     return;
  //   }
  //   this.autoSaveTime = 600;
  //   this.autoSave = true;
  //   this.interval = setInterval(() => {
  //     this.save(true);
  //   }, this.autoSaveTime * 1000);
  //   const countdown = setInterval(() => {
  //     this.autoSaveTime--;
  //     if (this.autoSaveTime === 0) {
  //       clearInterval(countdown);
  //     }
  //   }, 1000);
  // }

  // private stopAutoSave() {
  //   clearInterval(this.interval);
  //   this.autoSave = false;
  // }

  ngOnInit(): void {
    // this.events.on(EventType.START_AUTO_SAVE, this.startAutoSaveSubsciption);
    // this.events.on(EventType.STOP_AUTO_SAVE, this.stopAutoSaveSubsciption);
    this.events.on(EventType.SAVE_MEASURE, this.forceSaveSubsciption);
    // this.startAutoSave();
    this.projectSectionService
      .findAll<ProjectSectionListRequest>({
        projectId: this.globalService.projectId,
      })
      .subscribe((res) => {
        for (const item of res ?? []) {
          this.sectionMap[item.id!] = item;
        }
      });
    this.getForm();
    this.getAllUser();
  }

  ngOnDestroy(): void {
    // this.stopAutoSave();
    // this.events.destroyListener(
    //   EventType.START_AUTO_SAVE,
    //   this.stopAutoSaveSubsciption
    // );
    // this.events.destroyListener(
    //   EventType.STOP_AUTO_SAVE,
    //   this.stopAutoSaveSubsciption
    // );
    this.events.destroyListener(
      EventType.SAVE_MEASURE,
      this.forceSaveSubsciption
    );
  }

  getAllUser() {
    this.staffUserService
      .findAll<UserListRequest>({
        projectId: this.globalService.projectId,
      })
      .subscribe((res) => {
        this.userData = res;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.resize();
    }, 100);
    window.addEventListener('resize', () => {
      this.resize();
    });
  }

  hideLeftChange(v: boolean) {
    this.hideLeft = v;
    this.resize();
  }

  hideRightChange(v: boolean) {
    this.hideRight = v;
    this.resize();
  }

  private resize() {
    const width = document.body.clientWidth - 10;
    let c = width;
    if (!this.hideLeft) {
      c -= 300;
    }
    if (!this.hideRight) {
      c -= 310;
    }
    this.centerWidth = c;
  }

  private handleSubmitable(flow: FormFlowInfo, hasId?: boolean) {
    console.log('handleSubmitable: ', flow);
    let org = '';
    let dep = '';
    let post = '';
    if (!hasId) {
      // 不存在单号，说明是新建
      if (flow.organizationType) {
        org = OrganizationTypeMap[flow.organizationType];
        // 有单位类型
        if (flow.departmentId && +flow.departmentId !== -1) {
          // 有单位类型 有部门
          if (flow.postId && +flow.postId !== -1) {
            // 有单位类型 有部门 有岗位
            dep = '/' + (this.departmentMap[flow.departmentId]?.name ?? '');
            if (flow.departmentId === -97) {
              dep = '/履约';
            }
            if (flow.departmentId === -96) {
              dep = '/专监';
            }
            if (flow.departmentId === -95) {
              dep = '/对口专监';
            }
            post = '/' + (this.postMap[flow.postId]?.name ?? '');
            if (
              ((flow.postId === this.globalService.userInfo?.postId &&
                (flow.departmentId ===
                  this.globalService.userInfo.departmentId ||
                  +flow.departmentId < 0)) ||
                (this.globalService.userInfo?.postId2 &&
                  flow.departmentId ===
                    this.globalService.userInfo.departmentId2) ||
                +flow.departmentId < 0) &&
              flow.organizationType ===
                this.globalService.userInfo?.organizationType
            ) {
              // 岗位和单位类型匹配
              this.submitable = true;
            }
          } else if (+flow.departmentId < 0) {
            // 有单位类型 有虚拟部门 无岗位
            if (+flow.departmentId === -97) {
              dep = '/履约';
              // 履约
              if (
                this.globalService.userInfo?.performance &&
                this.globalService.userInfo.organizationType ===
                  OrganizationType.CONSTRUCTION
              ) {
                // 匹配履约人员和建设单位
                this.submitable = true;
              }
            } else if (
              +flow.departmentId === -96 ||
              +flow.departmentId === -95
            ) {
              dep = +flow.departmentId === -96 ? '/专监' : '/对口专监';
              // 专监/对口专监
              if (
                !!this.globalService.userInfo.postName &&
                this.globalService.userInfo.organizationType ===
                  OrganizationType.SUPERVISE &&
                (this.globalService.userInfo.postName!.indexOf('专监') > -1 ||
                  this.globalService.userInfo.postName!.indexOf(
                    '试验检测工程师'
                  ) > -1)
              ) {
                this.submitable = true;
              }
            } else {
              dep = '';
              // 持章人、填写人等无效部门
              if (
                this.globalService.userInfo.organizationType ===
                flow.organizationType
              ) {
                this.submitable = true;
              }
            }
          } else {
            dep = '/' + (this.departmentMap[flow.departmentId]?.name ?? '');
            // 有单位类型 有部门 无岗位
            if (
              (flow.departmentId ===
                this.globalService.userInfo?.departmentId ||
                flow.departmentId ===
                  this.globalService.userInfo?.departmentId2) &&
              flow.organizationType ===
                this.globalService.userInfo?.organizationType
            ) {
              // 匹配单位类型和部门
              this.submitable = true;
            }
          }
        } else {
          // 有单位类型 无部门
          if (
            flow.organizationType ===
            this.globalService.userInfo?.organizationType
          ) {
            this.submitable = true;
          }
        }
      } else {
        // 无单位类型
        this.submitable = true;
      }
    } else {
      if (flow.organizationId) {
        org = this.orgMap[flow.organizationId]?.name ?? '';
        // 有单位
        if (flow.departmentId && +flow.departmentId !== -1) {
          // 有单位 有部门
          if (flow.postId && +flow.postId !== -1) {
            dep = '/' + (this.departmentMap[flow.departmentId]?.name ?? '');
            if (flow.departmentId === -97) {
              dep = '/履约';
            }
            if (flow.departmentId === -96) {
              dep = '/专监';
            }
            if (flow.departmentId === -95) {
              dep = '/对口专监';
            }
            post = '/' + (this.postMap[flow.postId]?.name ?? '');
            // 有单位 有部门 有岗位
            if (
              ((flow.postId === this.globalService.userInfo?.postId &&
                (flow.departmentId ===
                  this.globalService.userInfo.departmentId ||
                  +flow.departmentId < 0)) ||
                (this.globalService.userInfo?.postId2 &&
                  flow.departmentId ===
                    this.globalService.userInfo.departmentId2) ||
                +flow.departmentId < 0) &&
              flow.organizationType ===
                this.globalService.userInfo?.organizationType
            ) {
              // 岗位和单位匹配
              this.submitable = true;
            }
          } else if (+flow.departmentId < 0) {
            // 有单位类型 有虚拟部门 无岗位
            if (+flow.departmentId === -97) {
              dep = '/履约';
              // 履约
              if (
                this.globalService.userInfo?.performance &&
                this.globalService.userInfo.organizationId ===
                  flow.organizationId
              ) {
                // 匹配履约人员和建设单位ID
                this.submitable = true;
              }
            } else if (
              +flow.departmentId === -96 ||
              +flow.departmentId === -95
            ) {
              dep = +flow.departmentId === -96 ? '/专监' : '/对口专监';
              // 专监/对口专监
              if (
                !!this.globalService.userInfo.postName &&
                this.globalService.userInfo.organizationId ===
                  flow.organizationId &&
                (this.globalService.userInfo.postName!.indexOf('专监') > -1 ||
                  this.globalService.userInfo.postName!.indexOf(
                    '试验检测工程师'
                  ) > -1)
              ) {
                this.submitable = true;
              }
            } else {
              dep = '';
              // 持章人、填写人等无效部门
              if (
                this.globalService.userInfo.organizationId ===
                flow.organizationId
              ) {
                this.submitable = true;
              }
            }
          } else {
            dep = '/' + (this.departmentMap[flow.departmentId]?.name ?? '');
            // 有单位 有部门 无岗位
            if (
              (flow.departmentId ===
                this.globalService.userInfo?.departmentId ||
                flow.departmentId ===
                  this.globalService.userInfo?.departmentId2) &&
              flow.organizationId ===
                this.globalService.userInfo?.organizationId
            ) {
              // 匹配单位和部门
              this.submitable = true;
            }
          }
        } else {
          // 有单位 无部门
          if (
            flow.organizationId === this.globalService.userInfo?.organizationId
          ) {
            this.submitable = true;
          }
        }
      } else {
        // 无单位
        this.submitable = true;
      }
    }
    this.editablePost = org + dep + post;
  }

  private calcExecOrderSpan() {
    if (
      !this.workReport?.form?.formFlows ||
      this.workReport?.form?.formFlows?.length === 0
    ) {
      return;
    }
    for (const flow of this.workReport.form.formFlows) {
      flow.execOrderSpan = undefined;
      flow.execOrderSpanHeight = undefined;
    }
    let idx = this.workReport.form.formFlows.length - 1;

    while (idx > 0) {
      const flow = this.workReport.form.formFlows[idx];
      const prev = this.workReport.form.formFlows[idx - 1];

      if (flow.execOrder === prev.execOrder) {
        prev.execOrderSpanHeight =
          (flow.execOrderSpanHeight ?? flow.userInfoList!.length) +
          prev.userInfoList!.length;
        flow.execOrderSpanHeight = undefined;

        prev.execOrderSpan = (flow.execOrderSpan ?? 1) + 1;
        flow.execOrderSpan = undefined;
      }
      // 可能有问题，execOrderSpan这个干嘛的没有管，你再看看
      // flow.execOrder = idx;
      // if (+flow.execOrder! - +prev.execOrder! > 1) {
      //   flow.execOrder = +prev.execOrder! + 1;
      // }
      idx--;
    }
    let jump = 0;
    let execOrder = 0;
    for (
      let index = 0;
      index < this.workReport.form.formFlows.length;
      index++
    ) {
      const flow = this.workReport.form.formFlows[index];
      if (jump > 0) {
        jump--;
        flow.execOrder = execOrder - 1;
        continue;
      }
      if (flow.execOrderSpan) {
        flow.execOrder = execOrder;
        jump = flow.execOrderSpan - 1;
        execOrder++;
        continue;
      } else {
        flow.execOrder = execOrder;
      }
      execOrder++;
    }
  }

  prccChange(
    e: { ids?: string[]; musted?: boolean; init?: boolean },
    isCC: boolean
  ) {
    const [ids, musted, init] = [e.ids, e.musted, e.init];
    if (!ids) {
      return;
    }
    // 检查一次是不是已经存在新增的
    if (!!init) {
      let count = 0;
      for (const item of this.workReport?.form?.formFlows ?? []) {
        if (
          item.step === (isCC ? FormFlowType.CC : FormFlowType.PRESENT) &&
          item.newAdded &&
          item.auto
        ) {
          count++;
        }
      }
      if (count === ids.length) {
        return;
      }
    }
    console.warn(ids);

    const cache: number[] = [];
    const cacheOrgList: OrganizationMember[] = [];
    for (const id of ids) {
      for (const org of this.orgList) {
        if (org.id?.toString() === id.toString()) {
          if (org.category === OrganizationCategory.COMPANY) {
            cache.push(org.id!);
            const orgInfo = this.orgMap[org.id!];
            const member = new OrganizationMember();
            member.memberId = org.id!;
            member.projectId = orgInfo.projectId;
            member.organizationId = undefined;
            member.memberName = orgInfo.name;
            cacheOrgList.push(member);
          } else {
            cache.push(org.mainMemberId!);
            cacheOrgList.push(this.orgMemberMap[org.mainMemberId!]);
            // 确认了一下，发送监理通知确认过，只发给主单位的联系人： 20241017
            // for (const key in this.orgMemberMap) {
            //   const value = this.orgMemberMap[key];
            //   if (value.organizationId == org.id) {
            //     if (value.memberId !== org.mainMemberId) {
            //       cache.push(value.memberId!);
            //       cacheOrgList.push(value);
            //     }
            //   }
            // }
          }
        }
      }
    }
    for (const item of this.workReport?.form?.formFlows ?? []) {
      if (
        item.step === (isCC ? FormFlowType.CC : FormFlowType.PRESENT) &&
        item.newAdded &&
        item.auto
      ) {
        if (!cache.includes(item.organizationId!)) {
          item.needRm = true;
          continue;
        } else {
          const i = cache.indexOf(item.organizationId!);
          cache.splice(i, 1);
          cacheOrgList.splice(i, 1);
        }
      }
    }
    for (let i = 0; i < cache.length; i++) {
      this.addPrCC(true, isCC, -1, cache[i], cacheOrgList[i], musted);
    }
    this.workReport!.form!.formFlows = this.workReport?.form?.formFlows?.filter(
      (item) => !item.needRm
    );
    this.calcExecOrderSpan();
  }

  actionTypeChange(index: number) {
    console.log('actionTypeChange: ', index);
    // 修改执行顺序
    const flow = this.workReport?.form?.formFlows![index]!;
    const prev = this.workReport?.form?.formFlows![index - 1];
    const next = this.workReport?.form?.formFlows![index + 1];
    let actNum = 0;
    if (flow.actionType !== WorkFlowActionType.SIGN_SYNC) {
      if (next && next.execOrder === flow.execOrder) {
        actNum += 1;
      }
      if (prev && prev.execOrder === flow.execOrder) {
        actNum += 1;
        this.workReport!.form!.formFlows![index].execOrder!++;
      }
    } else {
      if (
        next &&
        next.execOrder !== flow.execOrder &&
        next.actionType === WorkFlowActionType.SIGN_SYNC
      ) {
        actNum -= 1;
      }
      if (
        prev &&
        prev.execOrder !== flow.execOrder &&
        prev.actionType === WorkFlowActionType.SIGN_SYNC
      ) {
        actNum -= 1;
        this.workReport!.form!.formFlows![index].execOrder!--;
      }
    }

    for (
      let i = index + 1;
      i < (this.workReport!.form!.formFlows!.length ?? 0);
      i++
    ) {
      this.workReport!.form!.formFlows![i].execOrder! += actNum;
    }

    // 修改人员信息

    if (
      flow.actionType !== WorkFlowActionType.ALL &&
      flow.actionType !== WorkFlowActionType.ANY
    ) {
      if (this.workReport!.form!.formFlows![index].userInfoList?.length! > 1) {
        this.workReport!.form!.formFlows![index].userInfoList = [
          this.workReport!.form!.formFlows![index].userInfoList![0],
        ];
      }
    } else {
      if (this.workReport!.form!.formFlows![index].userInfoList?.length === 1) {
        const user = this.workReport!.form!.formFlows![index].userInfoList![0];
        if (user.userId) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          this.workReport!.form!.formFlows![index].userInfoList?.push(userInfo);
        }
      }
    }
    this.calcExecOrderSpan();
  }

  merge(index: number) {
    const flow = this.workReport?.form?.formFlows![index];
    const next = this.workReport?.form?.formFlows![index + 1];
    if (!next) {
      return;
    }
    next.execOrder = flow!.execOrder;
    for (
      let i = index + 2;
      i < (this.workReport!.form!.formFlows!.length ?? 0);
      i++
    ) {
      this.workReport!.form!.formFlows![i].execOrder! -= 1;
    }
    this.calcExecOrderSpan();
  }

  split(index: number) {
    for (
      let i = index + 1;
      i < (this.workReport!.form!.formFlows!.length ?? 0);
      i++
    ) {
      this.workReport!.form!.formFlows![i].execOrder! += 1;
    }
    this.calcExecOrderSpan();
  }

  download(history: WorkFlowTask) {
    const downloadLink = document.createElement('a');
    const fileUrl = environment.fileHost + history.fileId!;
    downloadLink.href = fileUrl;
    downloadLink.target = '_blank';
    downloadLink.download = history.fileId ?? '';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  read(history: WorkFlowTask) {
    window.open(environment.fileHost + history.fileId, '_blank');
  }

  projectDataChange(cell: ExcelTableRowColumn) {
    this.workReportService
      .matchHistoryFormFlows(this.workReport!)
      .subscribe((data: Array<FormFlowInfo>) => {
        if (!!data && data.length > 0) {
          this.workReport!.form!.formFlows = data;
          // if (
          //   cell.formItem?.dataSource?.includes('projectOrg.CONSTRUCTION.name')
          // ) {
          //   let orgId: number | undefined = undefined;
          //   for (const org of this.orgList) {
          //     if (org.name === cell.value) {
          //       orgId = org.id;
          //       break;
          //     }
          //   }
          //   for (const flow of this.workReport!.form!.formFlows) {
          //     if (
          //       flow.organizationType === OrganizationType.CONSTRUCTION &&
          //       flow.departmentId?.toString() !== '-99'
          //     ) {
          //       flow.primaryOrganizationId = orgId;
          //     }
          //   }
          // }
          this.handleFlowInfo(
            data.find((item) => item.step === FormFlowType.FILL_IN)
          );
        }
      });
  }

  private getOrganizationDepartment(): void {
    const projectSections = this.workReport!.sectionId
      ? [
          {
            sectionId: this.workReport!.sectionId,
          },
        ]
      : undefined;
    if (this.is027) {
      for (const projectSection of this.globalService.orgInfo.projectSections ??
        []) {
        if (
          !projectSection.beamYardSectionTag &&
          `${projectSection.sectionId}` !== `${this.workReport!.sectionId}`
        ) {
          projectSections?.push({
            sectionId: projectSection.sectionId!,
          });
        }
      }
    }
    zip(
      this.staffOrganizationService.findAll<OrganizationListRequest>({
        projectId: this.globalService.projectId,
        projectSections,
      }),
      this.staffDepartmentService.findAll<DepartmentListRequest>({
        projectId: this.globalService.projectId,
      }),
      this.staffPostService.findAll({ type: null }),
      this.omFormItemService.getWidgetData({
        projectId: this.globalService.projectId,
        sectionId: this.workReport!.sectionId,
        type: FormItemWidgetType.PROJECT,
      }),
      this.staffUserService.findAll<UserListRequest>({
        projectSections,
        projectId: this.globalService.projectId,
        // hasPartTimeJob: true,
      }),
      this.staffOrganizationMemberService.findAll({
        projectId: this.globalService.projectId,
        projectSections,
      }),
      this.omUserOrgService.findAll<OrganizationExtListRequest>({
        projectId: this.globalService.projectId,
        projectSections,
      })
    ).subscribe(
      ([orgs, departments, posts, widgetData, users, orgMembers, orgExts]) => {
        // 0. 在构造树之前获取其他数据
        this.userList = users;
        this.projectWidgetData = widgetData ?? [];
        // 1. 计算履约人员数量
        const orgSealUserIdMap: { [key: string]: number[] } = {};
        const orgSealUserMap: { [key: string]: User[] } = {};
        for (const orgExt of orgExts ?? []) {
          if (orgExt.sealUsers && orgExt.sealUsers.length > 0) {
            orgSealUserIdMap[orgExt.organizationId!] = orgExt.sealUsers.map(
              (info) => info.userId!
            );
          }
        }
        const performanceCountMap: { [key: string]: number } = {};
        const postUserMap: { [key: string]: User[] } = {};
        for (const user of users) {
          if (user.performance) {
            performanceCountMap[
              `${user.organizationId}#${user.departmentId}#${user.postId}`
            ] = performanceCountMap[
              `${user.organizationId}#${user.departmentId}#${user.postId}`
            ]
              ? performanceCountMap[
                  `${user.organizationId}#${user.departmentId}#${user.postId}`
                ] + 1
              : 1;
          }
          if (!postUserMap[user.postId!]) {
            postUserMap[user.postId!] = [];
          }
          postUserMap[user.postId!].push(user);
          if (orgSealUserIdMap[user.organizationId!]?.includes(user.id!)) {
            if (!orgSealUserMap[user.organizationId!]) {
              orgSealUserMap[user.organizationId!] = [];
            }
            let exIdx = -1;
            for (
              let i = 0;
              i < orgSealUserMap[user.organizationId!].length;
              i++
            ) {
              if (orgSealUserMap[user.organizationId!][i].id === user.id) {
                exIdx = i;
                break;
              }
            }
            if (exIdx === -1) {
              orgSealUserMap[user.organizationId!].push(user);
            } else {
              if (user.mainPost) {
                orgSealUserMap[user.organizationId!][exIdx] = user;
              }
            }
          }
        }
        // 2. 获取机构成员数据
        for (const org of orgMembers ?? []) {
          this.orgMemberMap[org.memberId!] = org;
        }

        // 3. 构造树

        const orgMap: { [key: string]: Organization } = {};
        for (const org of orgs) {
          org.departmentList = [];
          orgMap[org.id!] = org;
        }
        // this.orgMap = orgMap;
        // TODO: 可能出现一种情况，建设单位拉不到

        const departmentMap: { [key: string]: Department } = {};
        const departMapByOrgType: { [key: string]: Department[] } = {};
        for (const department of departments) {
          if (!departMapByOrgType[department.organizationType!]) {
            departMapByOrgType[department.organizationType!] = [];
          }
          department.postList = [];
          departmentMap[department.id!] = department;
          departMapByOrgType[department.organizationType!].push(department);
        }

        const postMap: { [key: string]: Post } = {};
        for (const post of posts) {
          if (post.departmentId && departmentMap[post.departmentId!]) {
            // TODO 建设单位似乎没有部门
            departmentMap[post.departmentId!].postList!.push(post);
          }
          postMap[post.id!] = post;
        }

        for (const department of departments) {
          if (
            !!department.organizationId &&
            orgMap[department.organizationId!]
          ) {
            orgMap[department.organizationId!].departmentList!.push(department);
          }
        }

        this.orgList = orgs;

        this.departmentMap = departmentMap;
        this.postMap = postMap;
        this.orgMap = orgMap;

        // const treeNodesForType: { [key: string]: NzTreeNode[] } = {};
        const treeNodesForAll: NzTreeNode[] = [];
        for (const org of orgs) {
          if (org.category === OrganizationCategory.INSTITUTION) {
            continue;
          }
          if (org.type === OrganizationType.CONSTRUCTION && this.is027) {
            let isCurrentSectionOrg = false;
            for (const section of org.projectSections ?? []) {
              if (`${section.sectionId}` === `${this.workReport!.sectionId}`) {
                isCurrentSectionOrg = true;
                break;
              }
            }
            if (
              !isCurrentSectionOrg &&
              `${org.id}` !== `${this.globalService.orgInfo.id}`
            ) {
              continue;
            }
          }
          // ORG层的值结构为 单位类型#单位ID
          const tNode: NzTreeNode = {
            value: `${org.type}#${org.id}`,
            label: org.shortName ?? org.name,
            children: [],
          };
          for (const department of departMapByOrgType[org.type!] ?? []) {
            const dNode: NzTreeNode = {
              value: `${org.type}#${org.id}#${department.id}`,
              label: department.name,
              children: [],
            };
            for (const post of department.postList ?? []) {
              if (post.name === '专监' || post.name === '对口专监') {
                continue;
              }
              const pNode: NzTreeNode = {
                value: `${org.type}#${org.id}#${department.id}#${post.id}`,
                label: `${post.name}${post.performance ? '[履约]' : ''}`,
                children: [],
              };
              for (const user of postUserMap[post.id!] ?? []) {
                if (
                  user.organizationId === org.id &&
                  user.departmentId === department.id
                ) {
                  pNode.children?.push({
                    value: `${org.type}#${org.id}#${department.id}#${post.id}#${user.id}`,
                    label: `${user.name}${post.performance ? '[履约]' : ''}`,
                    isLeaf: true,
                  });
                }
              }
              if (pNode.children?.length === 0) {
                pNode.children?.push({
                  value: `-`,
                  label: `请添加人员`,
                  isLeaf: true,
                  disabled: true,
                });
              }
              dNode.children!.push(pNode);
            }
            if (dNode.children!.length > 0) {
              tNode.children!.push(dNode);
            }
          }

          // 特殊处理单位类型
          if (org.type === OrganizationType.SUPERVISE) {
            // 专监 -96
            const zjNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-96`,
              label: '专监',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.SUPERVISE
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (
                  post.name &&
                  (post.name.indexOf('专监') > -1 ||
                    post.name.indexOf('试验检测工程师') > -1) &&
                  post.name !== '对口专监' &&
                  post.name !== '专监'
                ) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-96#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-96#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  zjNode.children!.push(pNode);
                }
              }
            }
            if (zjNode.children!.length > 0) {
              tNode.children!.push(zjNode);
            }
            // 对口专监 -95
            const dkNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-95`,
              label: '对口专监',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.SUPERVISE
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (
                  post.name &&
                  (post.name.indexOf('专监') > -1 ||
                    post.name.indexOf('试验检测工程师') > -1) &&
                  post.name !== '对口专监' &&
                  post.name !== '专监'
                ) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-95#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-95#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  dkNode.children!.push(pNode);
                }
              }
            }
            if (dkNode.children!.length > 0) {
              tNode.children!.push(dkNode);
            }
          }

          // 履约人员 -97
          if (org.type === OrganizationType.CONSTRUCTION) {
            const pfNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-97`,
              label: '履约',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.CONSTRUCTION
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (!!post.performance) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-97#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-97#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  pfNode.children!.push(pNode);
                }
              }
            }
            if (pfNode.children!.length > 0) {
              tNode.children!.push(pfNode);
            }
          }

          // 持章人 -98
          const czNode: NzTreeNode = {
            value: `${org.type}#${org.id}#-98`,
            label: '持章人',
            children: [],
          };
          for (const user of orgSealUserMap[org.id!] ?? []) {
            czNode.children?.push({
              value: `${org.type}#${org.id}#-98#${user.postId}#${user.id}`,
              label: `${user.name}${
                postMap[user.postId!]?.performance ? '[履约]' : ''
              }`,
              isLeaf: true,
            });
          }
          if (czNode.children?.length === 0) {
            czNode.children?.push({
              value: `-`,
              label: `请添加人员`,
              isLeaf: true,
              disabled: true,
            });
          }

          tNode.children!.push(czNode);

          if (tNode.children!.length > 0) {
            treeNodesForAll.push(tNode);
          }
        }
        this.postTreeNodeFull = treeNodesForAll;

        for (const flow of this.workReport?.form?.formFlows ?? []) {
          if (flow.step === FormFlowType.FILL_IN) {
            // TODO : 权限控制
            this.handleSubmitable(flow, !!this.workReport?.id);
          }
        }
      }
    );
  }

  userInfoChange(user: UserInfo, flowIdx: number, userIdx: number) {
    console.log(
      'userInfoChange: ',
      user,
      ', flowIdx: ',
      flowIdx,
      ', userIdx: ',
      userIdx
    );
    const flow = this.workReport!.form!.formFlows![flowIdx];
    flow.userInfoList![userIdx] = user;
    if (
      flow.actionType === WorkFlowActionType.ANY ||
      flow.actionType === WorkFlowActionType.ALL
    ) {
      if (user.userId) {
        if (flow.userInfoList!.length === userIdx + 1) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          flow.userInfoList!.push(userInfo);
        }
      } else {
        flow.userInfoList!.splice(userIdx, 1);
      }
    }

    if (flow.address) {
      let display: string[] = [];
      for (const user of flow.userInfoList ?? []) {
        display.push(user.userName!);
      }
      const content = flow.execOrder + display.join('、');
      setTimeout(() => {
        this.events.broadcast(EventType.SignUserSelected, {
          address: flow.address,
          content,
        });
      }, 100);
    }
    this.calcExecOrderSpan();
  }

  getHistory() {
    if (!!this.workReport?.copyWorkReportId) {
      return this.workFLowTaskService.listWorkFlowTaskByCopyWorkOrderId(
        this.workReport.copyWorkReportId
      );
    } else {
      return this.workFLowTaskService.listWorkFlowTaskByWorkOrderId(
        this.workReport?.id!
      );
    }
  }

  findSectionType(isLast: boolean) {
    this.projectSectionItemService
      .findById(this.workReport!.sectionItemId!)
      .subscribe((response: ProjectSectionItem) => {
        if (response && response.type === ProjectItemType.SECTION) {
          this.listSame(isLast, true);
          return;
        }
        this.listSame(isLast);
      });
  }

  duplicate(isLast: boolean) {
    if (!!this.workReport!.sectionItemId) {
      this.findSectionType(isLast);
    } else {
      this.listSame(isLast);
    }
  }

  listSame(isLast: boolean, isSection?: boolean) {
    this.workReportService
      .listSame(this.workReport!)
      .subscribe((res: WorkReport[]) => {
        if (res.length === 0) {
          this.nzMessageService.error('未找到可以复制的报告');
          return;
        }
        if (res.length === 1) {
          if (res[0].id === this.workReport!.id) {
            this.nzMessageService.error('未找到可以复制的报告');
            return;
          }
        }
        const workReports: { value: number; label: string; date: number }[] =
          [];
        for (const item of res ?? []) {
          let label = item.remark ?? '';
          if (isSection) {
            label = `[${this.sectionMap[item.sectionId!]?.name ?? ''}]${label}`;
          }
          workReports.push({
            value: item.id!,
            label,
            date: new Date(item.filledDate!).getTime(),
          });
        }
        if (isLast) {
          this.chooseDuplicateReport(
            res[0].id! === this.workReport!.id ? res[1].id! : res[0].id!
          );
        } else {
          const results = this.modalService.open({
            component: DialogCopyReportComponent,
            onClose: () => {},
            data: {
              data: {
                workReports,
                isSection,
              },
              onCancel: () => {
                results.modalInstance.hide();
              },
              onConfirm: (data: number) => {
                results.modalInstance.hide();
                if (!!data) {
                  this.chooseDuplicateReport(data);
                }
              },
            },
          });
        }
      });
  }

  private chooseDuplicateReport(sourceWorkReportId: number) {
    this.workReportService
      .copy({
        sourceWorkReportId,
        targetWorkReport: this.workReport,
      })
      .subscribe((res: Array<ExcelTable>) => {
        this.workReport!.form!.formData = res ?? [];
      });
  }

  getForm() {
    this.getWorkOrder().subscribe((res: WorkReport) => {
      this.is027 = res.projectTypeCode === '2001.001.005.027';
      this.omFormItemFlowService
        .findAll({
          step: FormFlowType.FILL_IN,
          formId: res.form!.formId!,
        })
        .subscribe((flows: FormFlow[]) => {
          const fillIn = flows[0] ?? undefined;
          if (res) {
            this.workReport = res;
            if (this.workReport.code === 'GL046') {
              this.workReport.filledUserId = this.globalService.userInfo?.id;
            }
            if (this.activatedRoute.snapshot.data['title'] === '报表添加') {
              this.workReport.isInit = true;
            }
            this.formItemMeasuredProjects =
              this.workReport.form?.formItemMeasuredProjects ?? [];
            document.title = this.workReport.code + ' ' + this.workReport.name;
            this.omFormHeaderService
              .findAll<FormHeaderListRequest>({
                formId: this.workReport.form!.formId!,
              })
              .subscribe((headers: FormHeader[]) => {
                if (headers && headers.length > 0) {
                  this.needFill = true;
                }
              });
            this.handleDisabledAddress(this.workReport.form!.formFlows ?? []);
            try {
              if (!!this.workReport.orginFiles) {
                this.workReport.orginFilesList = JSON.parse(
                  this.workReport.orginFiles
                );
              }
            } catch (error) {}
            try {
              if (!!this.workReport.pdfFiles) {
                this.workReport.pdfFilesList = JSON.parse(
                  this.workReport.pdfFiles
                );
              }
            } catch (error) {}
            try {
              if (!!this.workReport.signFiles) {
                this.workReport.signFilesList = JSON.parse(
                  this.workReport.signFiles
                );
              }
            } catch (error) {}
            this.handleFlowInfo(fillIn);

            if (this.workReport.id || this.workReport.copyWorkReportId) {
              this.getHistory().subscribe((res: WorkFlowTaskInfo[]) => {
                // TODO: 有可能出现多个历史, 根据workFlowId去分组
                this.historyList = res;
                for (const flow of this.historyList) {
                  flow.execOrderSpan = undefined;
                  // TODO 测试用
                  // flow.executorName = 'test';

                  // flow.executorProperties = "履约;持章人;专监";
                  // flow.comment = "这是测试这是测试这是测试这是测试这是测试这是测试这是测试这是测试";
                  // flow.executorPost = "这是测试这是测试这是测试这是测试这是测试这是测试这是测试这是测试";
                  flow.nameLabel = flow.executorName;
                  if (flow.executorProperties) {
                    const list = flow.executorProperties.split(';');
                    for (const property of list) {
                      const str = '[' + property + ']';
                      flow.nameLabel = flow.nameLabel + str;
                    }
                  }
                }
                let idx = this.historyList.length - 1;

                while (idx > 0) {
                  const flow = this.historyList[idx];
                  const prev = this.historyList[idx - 1];

                  if (flow.execOrder === prev.execOrder) {
                    prev.execOrderSpan = (flow.execOrderSpan ?? 1) + 1;
                    flow.execOrderSpan = undefined;
                  }
                  idx--;
                }
                for (let i = 0; i < this.historyList.length; i++) {
                  const cur = this.historyList[i];
                  const next = this.historyList[i + 1];
                  cur.hide = true;
                  if (cur.workFlowId !== next?.workFlowId) {
                    cur.hiddenTrigger = true;
                  }
                }
              });
            }

            // !! 特殊处理 JTYS104

            if (this.workReport.code === 'JTYS104') {
              for (const flow of this.workReport.form?.formFlows ?? []) {
                if (
                  flow.organizationType === OrganizationType.CONSTRUCTION &&
                  flow.step !== FormFlowType.FILL_IN
                ) {
                  flow.organizationId = undefined;
                }
              }
              for (const section of this.globalService.orgInfo
                .projectSections ?? []) {
                this.workReport.sectionId = section.sectionId;
                break;
              }
            }
            this.calcExecOrderSpan();
            this.getOrganizationDepartment();
          }
        });
    });
  }

  handleFlowInfo(fillIn: FormFlow | undefined) {
    for (const flow of this.workReport!.form?.formFlows ?? []) {
      if (flow.step === FormFlowType.FILL_IN && fillIn) {
        flow.organizationType = fillIn.organizationType ?? undefined;
        flow.departmentId = fillIn.departmentId ?? undefined;
        flow.postId = fillIn.postId ?? undefined;
      }

      if (!flow.userInfoList || flow.userInfoList?.length === 0) {
        if (flow.step === FormFlowType.FILL_IN) {
          const userInfo = new UserInfo();
          userInfo.organizationType =
            this.globalService.userInfo?.organizationType;
          userInfo.organizationId = this.globalService.userInfo?.organizationId;
          userInfo.departmentId = this.globalService.userInfo?.departmentId;
          userInfo.postId = this.globalService.userInfo?.postId;
          userInfo.userId = this.globalService.userInfo?.id;
          userInfo.userName = this.globalService.userInfo?.name;
          userInfo.performance = this.globalService.userInfo?.performance;
          userInfo.postPerformance =
            this.globalService.userInfo?.postPerformance;
          flow.userInfoList = [userInfo];
        } else {
          const userInfo = new UserInfo();
          userInfo.organizationType = flow.organizationType;
          userInfo.organizationId = flow.organizationId;
          userInfo.departmentId = flow.departmentId;
          userInfo.postId = flow.postId;
          flow.userInfoList = [userInfo];
        }
      }
      if (
        flow.actionType === WorkFlowActionType.ANY ||
        flow.actionType === WorkFlowActionType.ALL
      ) {
        if (flow.userInfoList[flow.userInfoList.length - 1].userId) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          flow.userInfoList.push(userInfo);
        }
      }
      if (flow.address) {
        let display: string[] = [];
        for (const user of flow.userInfoList ?? []) {
          display.push(user.userName!);
        }
        const content = flow.execOrder + ' ' + display.join('、');
        setTimeout(() => {
          this.events.broadcast(EventType.SignUserSelected, {
            address: flow.address,
            content,
          });
        }, 100);
      }
    }
  }

  trig(history: WorkFlowTask) {
    const cur = JSON.parse(JSON.stringify(this.historyList));
    for (const item of cur) {
      if (item.workFlowId === history.workFlowId) {
        item.hide = !item.hide;
      }
    }
    this.historyList = cur;
  }

  removeFlow(index: number) {
    const flow = this.workReport?.form?.formFlows![index];
    if (
      flow?.newAdded ||
      (!flow?.newAdded &&
        flow?.step !== FormFlowType.PRESENT &&
        flow?.step !== FormFlowType.CC)
    ) {
      return;
    }
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除' + (flow?.step === FormFlowType.CC ? '抄送' : '呈递'),
        content: '确认删除？',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.confirm,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          results.modalInstance.hide();
          this.workReport!.form!.formFlows!.splice(index, 1);
          this.calcExecOrderSpan();
        },
      },
    });
  }

  addPrCC(
    auto: boolean,
    isCC: boolean,
    index: number,
    id?: number,
    org?: OrganizationMember,
    musted?: boolean
  ) {
    const flow = new FormFlowInfo();
    flow.step = isCC ? FormFlowType.CC : FormFlowType.PRESENT;
    flow.newAdded = true;
    flow.enable = true;
    flow.formEnable = true;
    flow.auto = auto;
    flow.musted = musted;
    flow.signTaskType = WorkFlowSignerTaskType.ALL;
    if (id) {
      flow.organizationId = id;
      flow.organizationType = this.orgList.find((o) => +o.id! === +id!)?.type;
    }
    if (this.workReport?.form?.formFlows) {
      if (index === -1) {
        const last =
          this.workReport.form.formFlows[
            this.workReport.form.formFlows.length - 1
          ];
        flow.execOrder = last.execOrder! + 1;
        this.workReport.form.formFlows.push(flow);
      } else {
        const prev = this.workReport.form.formFlows[index];
        const next = this.workReport.form.formFlows[index + 1];

        flow.execOrder = prev.execOrder! + 1;
        let actNum = 1;
        if (prev && next && prev.execOrder === next.execOrder) {
          actNum += 1;
        }
        for (
          let i = index + 1;
          i < this.workReport.form.formFlows.length;
          i++
        ) {
          this.workReport.form.formFlows[i].execOrder! += actNum;
        }
        this.workReport.form.formFlows.splice(index + 1, 0, flow);
      }
    } else {
      this.workReport!.form!.formFlows = [flow];
      flow.execOrder = 1;
    }

    if (org?.userId) {
      this.staffUserService.findById(org.userId).subscribe((res: User) => {
        const userInfo = new UserInfo();
        userInfo.organizationType = res.organizationType;
        userInfo.organizationId = res.organizationId;
        userInfo.departmentId = res.departmentId;
        userInfo.postId = res.postId;
        userInfo.userId = res.id;
        userInfo.userName = res.name;
        userInfo.performance = res.performance;
        userInfo.postPerformance = res.postPerformance;
        flow.userInfoList = [userInfo];
      });
    } else {
      flow.userInfoList = [new UserInfo()];
    }
    this.calcExecOrderSpan();
  }

  private handleDisabledAddress(formFlowInfos: FormFlowInfo[]) {
    const disabledAddressSet = new Set<string>();
    for (const flow of formFlowInfos) {
      if (
        flow.step === FormFlowType.SIGN ||
        flow.step === FormFlowType.SIGN_SEAL ||
        flow.step === FormFlowType.SEAL
      ) {
        for (const item of flow.addressList ?? []) {
          if (
            item.signFieldType === WorkFlowSignFieldType.SIGN_FIELD ||
            item.signFieldType === WorkFlowSignFieldType.SEAL_FIELD
          ) {
            flow.musted = !!item.musted;
          }
          disabledAddressSet.add(item.address!);
        }
        disabledAddressSet.add(flow.address!);
      }
    }
    this.disabledAddress = Array.from(disabledAddressSet);
  }

  onFlowDisableChange($event: { address: string; disabled: boolean }) {
    for (const flow of this.workReport?.form?.formFlows ?? []) {
      if (flow.address === $event.address) {
        flow.enable = !$event.disabled;
        flow.formEnable = !$event.disabled;
      }
    }
  }

  private getWorkOrder(): Observable<WorkReport> {
    const data = this.activatedRoute.snapshot.data;
    if (data['title'] === '报表添加') {
      return this.workReportService.init(this.globalService.formInitCache!);
    } else if (data['title'] === '报表修正') {
      return this.workReportService.copyById(
        this.activatedRoute.snapshot.params['id']
      );
    } else {
      return this.workReportService.findById(
        this.activatedRoute.snapshot.params['id']
      );
    }
  }

  onChange(config: ExcelTable[]) {
    this.workReport!.form!.formData = config;
  }

  back(workReportId?: number) {
    // if (window.opener) {
    //   window.opener.postMessage(Object.assign({ workReportId }, this.resp));
    // }
    // window.close();

    if (workReportId) {
      this.workReportService
        .findById(workReportId)
        .subscribe((workReport: WorkReport) => {
          if (workReport.status !== WorkReportStatus.FINISH) {
            for (const user of workReport.nextExcuteUsers ?? []) {
              if (user.id === this.globalService.userInfo.userId) {
                // this.openInTab(`#/custom-form/sign/${workReport.workFlowKey}`);
                this.router.navigate(
                  ['../..', 'sign', workReport.workFlowKey],
                  {
                    relativeTo: this.activatedRoute,
                  }
                );
                return;
              }
            }
          }
          window.close();
        });
    } else {
      window.close();
    }
  }

  refresh(id: number) {
    // location.href = '/#/custom-form/detail/' + id;
    // this.router.navigate(['../..', 'detail', id], {
    //   relativeTo: this.activatedRoute,
    // });
    this.workReportService.findById(id).subscribe((res: WorkReport) => {
      this.router.navigate(['../..', 'sign', res.workFlowKey], {
        relativeTo: this.activatedRoute,
      });
      setTimeout(() => {
        location.reload();
      }, 100);
    });
    // location.reload();
  }

  upload(event: any, docType: WorkReportDocType) {
    const files = event.target['files'];
    this.workReportService
      .uploadFileWithoutProgress(files[0], docType)
      .subscribe((url: WorkReportUploadResponse) => {
        // event.target['value'] = '';
        if (url) {
          this.workReport!.numbersOfPdfPages = url.numbersOfPdfPages;
          this.workReport!.orginFilesList = [url.fileId!];
          this.workReport!.orginFiles = JSON.stringify(
            this.workReport!.orginFilesList
          );
          this.workReport!.pdfFilesList = [url.pdfFileId!];
          this.workReport!.pdfFiles = JSON.stringify(
            this.workReport?.pdfFilesList
          );
          this.workReport!.docType = docType;
          this.workReport!.docId = url.fileId;
          this.workReport!.docName = url.fileName;
          if (docType === WorkReportDocType.PDF_SIGNED) {
            this.workReport!.signFilesList = [url.pdfFileId!];
            this.workReport!.signFiles = JSON.stringify(
              this.workReport?.signFilesList
            );
          } else {
            this.workReport!.signFilesList = [];
            this.workReport!.signFiles = '[]';
          }
        }
      });
  }

  checkBeforeConfirm(): string {
    if (this.workReport!.docType !== WorkReportDocType.DEFAULT) {
      return '';
    }
    const groupMap: { [key: string]: string | undefined } = {};
    for (const item of this.workReport!.form!.formData ?? []) {
      for (const row of item.rows ?? []) {
        for (const cell of row.columns ?? []) {
          if (this.disabledAddress.includes(cell.address!)) {
            continue;
          }
          if (
            !!cell.formItem &&
            !!cell.formItem.musted &&
            !!cell.formItem.widget &&
            cell.formItem.type !== FormItemType.CONSTANT &&
            cell.formItem.widget !== FormItemWidgetType.EMPTY
          ) {
            if (cell.formItem?.widget === FormItemWidgetType.RADIO) {
              if (!groupMap[cell.formItem?.dataSourceObject.group]) {
                groupMap[cell.formItem?.dataSourceObject.group] = '';
              }
              if (cell.value === '☑') {
                groupMap[cell.formItem?.dataSourceObject.group] = cell.address;
              }
            } else if (cell.formItem?.widget === FormItemWidgetType.IMAGE) {
              if (!cell.imgFileIds || cell.imgFileIds.length === 0) {
                return `必填的${
                  FormItemWidgetTypeMap[cell.formItem?.widget]
                }未填写`;
              }
            } else if (cell.formItem?.widget === FormItemWidgetType.DATE) {
              if (!cell.value) {
                return `必填的${
                  FormItemWidgetTypeMap[cell.formItem?.widget]
                }未填写`;
              }
              let ds = cell.value
                .replace('年', '-')
                .replace('月', '-')
                .replace('日', '')
                .replace('一', '')
                .replace('二', '')
                .replace('三', '')
                .replace('四', '')
                .replace('五', '')
                .replace('六', '')
                .replace('日', '')
                .replace('分', '')
                .replace('时', ':')
                .replace(' 星期', '');
              if (ds[ds.length - 1] === ':') {
                ds += '00';
              }

              if (!moment(ds).isValid()) {
                return `必填的日期不合法`;
              }
            } else if (!cell.value) {
              return `必填的${
                FormItemWidgetTypeMap[cell.formItem?.widget]
              }未填写`;
            }
          }
        }
      }
    }
    for (const key in groupMap) {
      if (!groupMap[key]) {
        return `必填的单选未选择`;
      }
    }
    return '';
  }

  get canConfirm(): boolean {
    if (!this.submitable) {
      return false;
    }
    if (this.workReport?.docType === WorkReportDocType.PDF_SIGNED) {
      return true;
    }
    for (const item of this.workReport!.form!.formFlows ?? []) {
      if (!item.musted || !item.enable || !item.formEnable) {
        continue;
      }
      if (
        item.step === FormFlowType.SIGN ||
        item.step === FormFlowType.SIGN_SEAL ||
        item.step === FormFlowType.SEAL
      ) {
        if (!item.userInfoList || item.userInfoList.length === 0) {
          return false;
        }
        if (!item.userInfoList[0].userId) {
          return false;
        }
      }
    }
    return true;
  }

  confirm() {
    if (this.submiting || !this.canConfirm || !this.submitable) {
      return;
    }
    this.submited = true;
    const content = this.checkBeforeConfirm();
    if (!!content) {
      const result = this.modalService.open({
        backdropCloseable: false,
        component: AlertDialogComponent,
        onClose: () => {},
        data: {
          title: '填写未完成',
          content,
          confirmBtnText: '确认',
          cancelBtnText: '确认',
          contentStyle: 'danger',
          type: AlertDialogType.simple,
          onCancel: () => {
            result.modalInstance.hide();
          },
        },
      });
      return;
    }
    if (
      this.workReport!.numbersOfPdfPages &&
      this.workReport!.numbersOfPdfPages > 0
    ) {
      const results = this.modalService.open({
        backdropCloseable: false,
        component: this.needFill
          ? CustomMenuFormFillDialogComponent
          : DialogGl105DateComponent,
        onClose: () => {},
        data: {
          data: this.workReport,
          onCancel: () => {
            results.modalInstance.hide();
          },
          onConfirm: (data: WorkReport) => {
            results.modalInstance.hide();
            this.workReport = data;
            this.submit();
          },
        },
      });
    } else {
      this.submit();
    }
  }

  previewAttachment(fileId: string) {
    window.open(environment.fileHost + fileId, '_blank');
  }

  uploadAttachment(event: any) {
    const files: FileList = event.target['files'];
    const fileList = Array.from(files);
    zip(
      fileList.map((file: File) => {
        let needConvertToPdf = false;
        const fileName = file.name.toLowerCase();
        if (
          fileName.includes('doc') ||
          fileName.includes('docx') ||
          fileName.includes('xls') ||
          fileName.includes('xlsx') ||
          fileName.includes('ppt') ||
          fileName.includes('pptx')
        ) {
          needConvertToPdf = true;
        }
        return this.commonService.uploadFileWithoutProgress(
          file,
          needConvertToPdf
        );
      })
    ).subscribe((res: FileResponse[]) => {
      event.target['value'] = '';
      if (this.workReport!.attachs === undefined) {
        this.workReport!.attachs = [];
      }
      for (const url of res) {
        this.workReport!.attachs!.push({
          fileName: url.fileName,
          fileSource: FileSource.MANUAL,
          fileId: url.pdfFileId ? url.pdfFileId : url.fileId,
          fileType: url.pdfFileId ? FileType.PDF : url.fileType,
        });
      }
    });
  }

  removeAttachment(index: number) {
    this.workReport!.attachs!.splice(index, 1);
  }

  submiting = false;

  removeDraft() {
    if (this.submiting) {
      return;
    }
    if (!this.workReport!.id) {
      window.close();
      return;
    }
    this.submiting = true;
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除',
        content: this.workReport!.fileCountersign
          ? '会签单删除后，主文件依然保留。确认删除？'
          : '删除后，此报表所有记录均被删除！',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.prompt,
        onCancel: () => {
          this.submiting = false;
          results.modalInstance.hide();
        },
        onConfirm: () => {
          this.workReportService
            .remove(this.workReport!.id!)
            .pipe(
              catchError((err) => {
                this.submiting = false;
                return throwError(err);
              })
            )
            .subscribe((res: number) => {
              this.submiting = false;
              if (res) {
                this.back();
              }
            });
          results.modalInstance.hide();
        },
      },
    });
  }

  private handleDataSourceCascader(node: NzTreeNode) {
    if (node.children && node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        node.children[i] = this.handleDataSourceCascader(node.children[i]);
      }
    }
    node.parent = undefined;
    return node;
  }

  private handleBeforeSubmit(isDraft?: boolean) {
    this.workReport!.form!.formItemMeasuredProjects =
      this.formItemMeasuredProjects;
    if (!isDraft) {
      for (const spad of this.formItemMeasuredProjects) {
        if (
          spad.keywords?.includes('桩身完整性') &&
          spad.fieldFormItemIdMap &&
          spad.fieldFormItemIdMap['measuredValue']
        ) {
          let sp = (spad.measuredValue ?? '')
            .replaceAll('-1', 'Ⅰ类')
            .replaceAll('-2', 'Ⅱ类')
            .replaceAll('-3', 'Ⅲ类');
          this.measureDataMap[spad.fieldFormItemIdMap['measuredValue']] = sp;
        }
      }
    }
    for (const table of this.workReport?.form?.formData ?? []) {
      for (const row of table.rows ?? []) {
        for (const cell of row.columns ?? []) {
          if (cell.type === FormItemType.VARIABLES) {
            if (cell.formItem?.widget === FormItemWidgetType.PROJECT) {
              if (
                cell.formItem?.dataSourceCascader &&
                cell.formItem?.dataSourceCascader.length > 0
              ) {
                for (
                  let i = 0;
                  i < cell.formItem.dataSourceCascader.length;
                  i++
                ) {
                  cell.formItem.dataSourceCascader[i] =
                    this.handleDataSourceCascader(
                      cell.formItem.dataSourceCascader[i]
                    );
                }
              } else {
                cell.formItem.dataSourceCascader = [];
              }
            }
          }
        }
      }
    }
    const workReport = JSON.parse(
      JSON.stringify(this.workReport)
    ) as WorkReport;
    for (const table of workReport?.form?.formData ?? []) {
      for (const row of table.rows ?? []) {
        for (const cell of row.columns ?? []) {
          if (cell.type === FormItemType.VARIABLES) {
            if (cell.formItem?.widget === FormItemWidgetType.PERCENT) {
              if (!isDraft && !!cell.value) {
                cell.value = cell.value + '%';
              }
            }
            if (cell.formItem?.widget === FormItemWidgetType.PROJECT) {
              cell.formItem.dataSourceCascader = [];
            }
            if (
              cell.formItem?.widget === FormItemWidgetType.FLOW_CC ||
              cell.formItem?.widget === FormItemWidgetType.FLOW_PRESENT ||
              cell.formItem?.widget === FormItemWidgetType.FLOW_CC_ONE ||
              cell.formItem?.widget === FormItemWidgetType.FLOW_PRESENT_ONE
            ) {
              if (!isDraft) {
                cell.value = cell.valueHtml;
              } else {
                cell.value = JSON.stringify(cell.valueCascader ?? []);
              }
            }
            if (
              cell.formItem?.id &&
              this.measureDataMap[cell.formItem?.id] !== undefined
            ) {
              cell.value = this.measureDataMap[cell.formItem?.id];
            }
          }
        }
      }
    }
    for (const flow of workReport?.form?.formFlows ?? []) {
      if (flow.step === FormFlowType.FILL_IN) {
        const userInfo = new UserInfo();
        userInfo.organizationType =
          this.globalService.userInfo?.organizationType;
        userInfo.organizationId = this.globalService.userInfo?.organizationId;
        userInfo.departmentId = this.globalService.userInfo?.departmentId;
        userInfo.postId = this.globalService.userInfo?.postId;
        userInfo.userId = this.globalService.userInfo?.id;
        userInfo.userName = this.globalService.userInfo?.name;
        userInfo.performance = this.globalService.userInfo?.performance;
        userInfo.postPerformance = this.globalService.userInfo?.postPerformance;
        userInfo.organizationName =
          this.globalService.userInfo?.organizationName;
        userInfo.organizationShortName =
          this.globalService.userInfo?.organizationShortName;
        userInfo.departmentName = this.globalService.userInfo?.departmentName;
        userInfo.postName = this.globalService.userInfo?.postName;
        flow.userInfoList = [userInfo];
      }
      if (
        flow.actionType === WorkFlowActionType.ANY ||
        flow.actionType === WorkFlowActionType.ALL
      ) {
        flow.userInfoList?.pop();
      }
      flow.userIds = [];
      for (const user of flow.userInfoList ?? []) {
        if (user.userId) {
          flow.userIds.push(user.userId);
        }
      }
      // flow.userInfoList = (flow.userInfoList ?? []).filter((u) => !!u.userId);
    }
    return workReport!;
  }

  save(auto?: boolean) {
    if (this.submiting || !this.submitable) {
      return;
    }
    this.submiting = true;
    if (!!this.workReport?.id) {
      this.workReportService
        .update(this.handleBeforeSubmit(true))
        .pipe(
          catchError((err) => {
            this.submiting = false;
            return throwError(err);
          })
        )
        .subscribe((res: boolean) => {
          this.submiting = false;
          if (res) {
            if (auto) {
              this.nzMessageService.success('自动保存');
            } else {
              this.nzMessageService.success('保存成功');
              // this.back();
            }
          }
        });
    } else {
      this.workReport!.projectId = this.globalService.projectId;
      this.workReportService
        .create(this.handleBeforeSubmit(true))
        .pipe(
          catchError((err) => {
            this.submiting = false;
            return throwError(err);
          })
        )
        .subscribe((res: WorkReport) => {
          this.submiting = false;
          if (res) {
            if (auto) {
              this.nzMessageService.success('自动保存');
            } else {
              this.nzMessageService.success('保存成功');
              // this.back();
            }
            this.refresh(res.id!);
          }
        });
    }
  }

  submit() {
    this.submiting = true;
    this.workReport!.projectId = this.globalService.projectId;
    this.workReportService
      .submit(this.handleBeforeSubmit())
      .pipe(
        catchError((err) => {
          this.submiting = false;
          return throwError(err);
        })
      )
      .subscribe((res: number) => {
        this.submiting = false;
        if (res) {
          this.back(res);
        }
      });
  }
}
