import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../models/login/login-form.model';
import { LoginInfo } from '../models/login/login-info.model';
import { CheckSignAuthRequest } from '../models/staff/check-sign-auth-request.model';
import { PlatUser } from '../models/staff/plat-user.model';
import { SignAuthInfo } from '../models/staff/sign-auth-info.model';
import { SmsCodeType } from '../models/staff/sms-code-type.enum';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';

@Injectable({
  providedIn: 'root',
})
export class OmUserPersonService extends RequestService<PlatUser> {
  static PATH = '/om/user/person';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, OmUserPersonService.PATH, globalService.debugUrl);
  }

  login(loginForm: LoginForm) {
    return this.httpClient.post<LoginInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/login`,
      loginForm
    );
  }

  loginWithoutPwd(token: string) {
    return this.httpClient.post<LoginInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/loginWithoutPwd`,
      {
        token,
      }
    );
  }

  openLoginWithoutPwd(
    appId: string,
    mobile: string,
    signature: string,
    timestamp: string
  ) {
    return this.httpClient.post<LoginInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/openLoginWithoutPwd`,
      {
        appId,
        mobile,
        signature,
        timestamp,
        signatureAlgorithm: 'md5',
      }
    );
  }

  getPlatUser() {
    return this.httpClient.get<PlatUser>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/getPlatUser`
    );
  }

  getSignAuthInfo() {
    return this.httpClient.get<SignAuthInfo>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/getSignAuthInfo`
    );
  }

  sendSmsCode(type: SmsCodeType) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/sendSmsCode`,
      {
        type,
      }
    );
  }

  phoneSmsCodeResend(authFlowId: string) {
    return this.httpClient.get<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/auth/phoneSmsCodeResend/${authFlowId}`
    );
  }

  checkSignAuthInfo(info: CheckSignAuthRequest) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/checkSignAuthInfo`,
      info
    );
  }

  batchAuth(ids: number[]) {
    return this.httpClient.post<boolean>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmUserPersonService.PATH}/batchAuth`,
      {
        ids,
      }
    );
  }
}
