import { Component, Input } from '@angular/core';
import { Form } from '../../models/form/form.model';
import { ProjectItemType } from '../../models/project/project-item-type.enum';
import {
  FormCodeWbsHistory,
  GlobalService,
} from '../../providers/global.service';
import { ProjectWbsService } from '../../services/project-wbs.service';
import { DialogConfig } from '../alert-dialog/dialog-config';
import { ProjectItemTypeMap } from './../../models/project/project-item-type.enum';

@Component({
  selector: 'app-dialog-wbs-choose',
  templateUrl: './dialog-wbs-choose.component.html',
  styleUrls: ['./dialog-wbs-choose.component.scss'],
})
export class DialogWbsChooseComponent {
  // TODO 增加历史记录
  @Input()
  data!: DialogConfig<WbsChooseConfig>;

  editing: { [name: string]: boolean } = {};

  unitCascaderValue: any[] = [];

  type: ProjectItemType = ProjectItemType.SECTION;

  ProjectItemTypeMap = ProjectItemTypeMap;

  history: FormCodeWbsHistory[] = [];

  needSegment = false;
  isLJ = false;

  onUnitCascaderValueChange(event: any[]) {
    this.unitCascaderValue = event;
    // TODO!! 请勿删除。本入口暂时没有分段分层需要特殊处理的表单。但是如果未来需要，可以参考下面的代码
    // if (this.needSegment) {
    //   console.log('needSegment ');
    //   const searchRequest = new ProjectSectionUnitListRequest();
    //   searchRequest.projectId = this.globalService.projectId;
    //   searchRequest.sectionItemType = ProjectItemType.PARTITION;
    //   searchRequest.sectionId = +[this.unitCascaderValue[0]];
    //   searchRequest.sectionSectionItemId =
    //     this.unitCascaderValue[this.unitCascaderValue.length - 1];
    //   this.projectWbsService.findAll(searchRequest).subscribe((res) => {
    //     console.log(res);
    //     // const s: ProjectSectionUnitInfo[] = [];
    //   });
    // }
  }

  constructor(
    private globalService: GlobalService,
    private projectWbsService: ProjectWbsService
  ) {}

  private handleOldHistory() {
    const history = this.globalService.getFormCodeWbsHistory(
      this.data.data!.formCode!
    );
    if (!!history) {
      const newHistory: FormCodeWbsHistory[] = [];
      for (const item of history) {
        if (item.length === 0) {
          continue;
        }
        newHistory.push(item);
      }
      this.globalService.clearFormCodeWbsHistory(this.data.data!.formCode!);
      this.globalService.setFormCodeWbsHistoryV2(
        this.data.data!.formCode!,
        newHistory
      );
    }
  }

  ngOnInit() {
    if (this.data.data) {
      this.handleOldHistory();
      this.type = this.data.data.projectItemType ?? ProjectItemType.SECTION;
      this.history =
        this.globalService.getFormCodeWbsHistoryV2(this.data.data.formCode!) ??
        [];
      console.log(this.history);
      // TODO!! 请勿删除。本入口暂时没有分段分层需要特殊处理的表单。但是如果未来需要，可以参考下面的代码
      // if (!!this.data.data.form && this.type === ProjectItemType.SECTION) {
      //   const form = this.data.data.form;
      //   if (
      //     form.type === FormType.CONSTRUCTION_RECORD ||
      //     form.type === FormType.STANDARD_INSPECTION ||
      //     form.type === FormType.PROJECT_INSPECTION
      //   ) {
      //     this.needSegment = true;
      //   }
      //   if (
      //     form.type === FormType.PROJECT_SUPERVISE &&
      //     (form.code?.includes('GL128') ||
      //       form.code?.includes('GL127') ||
      //       form.code?.includes('JL040') ||
      //       form.code?.includes('JL041'))
      //   ) {
      //     this.needSegment = true;
      //   }
      // }
    }
  }

  onBlur() {
    this.editing = {};
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  chooseHistory(index: number) {
    const history = JSON.parse(
      JSON.stringify(this.history[index])
    ) as FormCodeWbsHistory;
    this.unitCascaderValue = history.unitCascaderValue ?? [];
  }

  get canConfirm() {
    return this.unitCascaderValue.length > 0;
  }

  private submit() {
    let contain = false;
    for (const item of this.history) {
      if (
        JSON.stringify(item.unitCascaderValue) ===
        JSON.stringify(this.unitCascaderValue)
      ) {
        contain = true;
        break;
      }
    }
    if (!contain) {
      this.history.push({
        unitCascaderValue: this.unitCascaderValue,
      });
      this.history = this.history.slice(-5);
      this.globalService.setFormCodeWbsHistoryV2(
        this.data.data!.formCode!,
        this.history
      );
    }
    this.data.onConfirm!(this.unitCascaderValue);
  }
}

export class WbsChooseConfig {
  projectItemType?: ProjectItemType;
  formCode?: string;
  form?: Form;
}
