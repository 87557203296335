import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ModalService, LoadingService } from 'ng-devui';
import { NzMessageService } from 'ng-zorro-antd/message';
import { zip, Observable } from 'rxjs';
import { SimplePopoverComponent } from 'src/app/modules/main/components/popover/simple-popover/simple-popover.component';
import {
  DialogSubFormChooseData,
  DialogSubFormChooseComponent,
  DialogSubFormChooseResult,
} from 'src/app/modules/main/dialogs/projects-manage/dialog-sub-form-choose/dialog-sub-form-choose.component';
import { CustomMenuFormFillDialogComponent } from 'src/app/modules/main/pages/custom-menu-form/custom-menu-form-fill-dialog/custom-menu-form-fill-dialog.component';
import {
  AlertDialogComponent,
  AlertDialogType,
} from 'src/app/modules/shared/components/alert-dialog/alert-dialog.component';
import { DialogWbsChooseComponent } from 'src/app/modules/shared/components/dialog-wbs-choose/dialog-wbs-choose.component';
import { FilePreviewType } from 'src/app/modules/shared/components/file-preview-entry/file-preview-entry.component';
import {
  TableConfig,
  TableColumnType,
  TableColumnOption,
  TableColumnConfig,
} from 'src/app/modules/shared/components/table-pro/table.config';
import { HostDirective } from 'src/app/modules/shared/directives/host.directive';
import { FormHeaderFormat } from 'src/app/modules/shared/models/form/form-header-format.enum';
import { FormHeaderHorizontal } from 'src/app/modules/shared/models/form/form-header-horizontal.enum';
import { FormHeaderListRequest } from 'src/app/modules/shared/models/form/form-header-list-request.model';
import { FormHeaderSearch } from 'src/app/modules/shared/models/form/form-header-search.enum';
import { FormHeaderSortType } from 'src/app/modules/shared/models/form/form-header-sort-type.enum';
import { FormHeader } from 'src/app/modules/shared/models/form/form-header.model';
import { FormItemWidgetType } from 'src/app/modules/shared/models/form/form-item-widget-type.enum';
import { Form } from 'src/app/modules/shared/models/form/form.model';
import { SignUrlInfo } from 'src/app/modules/shared/models/form/sign-url-info.model';
import { SubFormRouterListRequest } from 'src/app/modules/shared/models/form/sub-form-router-list-request.model';
import { SubFormRouter } from 'src/app/modules/shared/models/form/sub-form-router.model';
import { WorkReportDocType } from 'src/app/modules/shared/models/form/work-report-doc-type.enum';
import { WorkReportSearchRequest } from 'src/app/modules/shared/models/form/work-report-search-request.model';
import {
  WorkReportStatusMap,
  WorkReportStatusColorMap,
  WorkReportStatus,
  WorkReportStatusList,
} from 'src/app/modules/shared/models/form/work-report-status.enum';
import { WorkReport } from 'src/app/modules/shared/models/form/work-report.model';
import { LoginInfo } from 'src/app/modules/shared/models/login/login-info.model';
import { MenuAudit } from 'src/app/modules/shared/models/menu/menu-audit.model';
import { MenuFormListRequest } from 'src/app/modules/shared/models/menu/menu-form-list-request.model';
import { MenuForm } from 'src/app/modules/shared/models/menu/menu-form.model';
import { MenuType } from 'src/app/modules/shared/models/menu/menu-type.enum';
import { Menu } from 'src/app/modules/shared/models/menu/menu.model';
import { ProjectItemTypeIndexList } from 'src/app/modules/shared/models/project/project-item-type.enum';
import { ProjectSectionListRequest } from 'src/app/modules/shared/models/project/project-section-list-request.model';
import { ProjectSection } from 'src/app/modules/shared/models/project/project-section.model';
import { OrganizationListRequest } from 'src/app/modules/shared/models/staff/organization-list-request.model';
import { OrganizationProjectListRequest } from 'src/app/modules/shared/models/staff/organization-project-list-request.model';
import { OrganizationProject } from 'src/app/modules/shared/models/staff/organization-project.model';
import { OrganizationType } from 'src/app/modules/shared/models/staff/organization-type.enum';
import { Organization } from 'src/app/modules/shared/models/staff/organization.model';
import { PlatUser } from 'src/app/modules/shared/models/staff/plat-user.model';
import { User } from 'src/app/modules/shared/models/staff/user.model';
import {
  EventService,
  EventType,
} from 'src/app/modules/shared/providers/event.service';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { OrderType } from 'src/app/modules/shared/providers/request/order-type.enum';
import { Page } from 'src/app/modules/shared/providers/request/page.type';
import { OmFormEntryService } from 'src/app/modules/shared/services/om-form-entry.service';
import { OmFormHeaderService } from 'src/app/modules/shared/services/om-form-header.service';
import { OmFormService } from 'src/app/modules/shared/services/om-form.service';
import { OmMenuFormService } from 'src/app/modules/shared/services/om-menu-form.service';
import { OmMenuService } from 'src/app/modules/shared/services/om-menu.service';
import { OmUserPersonService } from 'src/app/modules/shared/services/om-user-person.service';
import { ProjectSectionService } from 'src/app/modules/shared/services/project-section.service';
import { ProjectSubformService } from 'src/app/modules/shared/services/project-sub-form.service';
import { StaffOrganizationProjectService } from 'src/app/modules/shared/services/staff-organization-project.service';
import { StaffOrganizationService } from 'src/app/modules/shared/services/staff-organization.service';
import { StaffUserService } from 'src/app/modules/shared/services/staff-user.service';
import { WorkFlowUserService } from 'src/app/modules/shared/services/work-flow-user.service';
import { WorkReportService } from 'src/app/modules/shared/services/work-report.service';
import { ComponentCreaterUtil } from 'src/app/modules/shared/utils/component-creater.util';

@Component({
  selector: 'app-open-form',
  templateUrl: './open-form.component.html',
  styleUrls: ['./open-form.component.scss'],
})
export class OpenFormComponent {
  @ViewChild(HostDirective, { static: true }) hiddenHost!: HostDirective;

  config?: TableConfig = new TableConfig();

  columnType = TableColumnType;

  searchRequest = new WorkReportSearchRequest();

  recordList: WorkReport[] = [];

  sectionMap: { [id: number]: ProjectSection } = {};

  sectionOptions: TableColumnOption[] = [];

  currentCheckList: boolean[] = [];

  calcResult = '';

  menuFormList: MenuForm[] = [];

  optionMap: { [key: string]: TableColumnOption[] } = {};

  currentMenu?: Menu;

  fileType = FilePreviewType.PDF;

  /**
   * 分页器配置
   * @memberof AdminComponent
   */
  pager = this.globalService.recoverPager();
  timeout: any = undefined;

  totalWidth = 0;

  WorkOrderStatusMap = WorkReportStatusMap;

  WorkOrderStatusColorMap = WorkReportStatusColorMap;

  WorkOrderStatus = WorkReportStatus;

  get userId() {
    return this.globalService.userInfo.userId;
  }

  auditsWriteableMap: { [key: string]: boolean } = {};

  menuIdAuditsMap: { [key: string]: MenuAudit } = {};

  headerList: FormHeader[] = [];

  firstSortOrderKey: string = '';

  constructOrgList: { label: string; value: string }[] = [];

  superviseOrgList: { label: string; value: string }[] = [];

  needSectionId = true;

  firstSortOrderKeyChange($event: string) {
    this.firstSortOrderKey = $event;
  }

  constructor(
    private events: EventService,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private modalService: ModalService,
    private omFormEntryService: OmFormEntryService,
    private message: NzMessageService,
    private workReportService: WorkReportService,
    private workFlowUserService: WorkFlowUserService,
    private omMenuService: OmMenuService,
    private omMenuFormService: OmMenuFormService,
    private loadingService: LoadingService,
    private projectSectionService: ProjectSectionService,
    private omFormService: OmFormService,
    private projectSubformService: ProjectSubformService,
    private omFormHeaderService: OmFormHeaderService,
    private omUserPersonService: OmUserPersonService,
    private staffUserService: StaffUserService,
    private staffOrganizationService: StaffOrganizationService,
    private staffOrganizationProjectService: StaffOrganizationProjectService
  ) {}

  ngOnInit(): void {
    // TODO 从路由中获取menuId 并查询menu
    // 进行免密登录
    this.loginWithoutPassword();
  }

  loginWithoutPassword(): void {
    const params = new URLSearchParams(
      window.location.href.split('?')[1] ?? ''
    );
    this.omUserPersonService
      .openLoginWithoutPwd(
        params.get('appId') ?? '',
        params.get('mobile') ?? '',
        params.get('signature') ?? '',
        params.get('timestamp') ?? ''
      )
      .subscribe((loginOutInfo: LoginInfo) => {
        console.log(loginOutInfo);
        if (loginOutInfo) {
          this.globalService.token = loginOutInfo.token!;
          this.prepareUserInfo(loginOutInfo);
        } else {
          this.message.error('登录失败');
        }
      });
  }

  private prepareUserInfo(info: LoginInfo) {
    zip(
      this.omUserPersonService.getPlatUser(),
      this.staffUserService.findById(info.uid!),
      this.staffOrganizationService.findById(info.organizationId!),
      this.staffOrganizationProjectService.findAll<OrganizationProjectListRequest>(
        {
          organizationId: info.organizationId,
          projectId: this.globalService.projectId,
        }
      ),
      this.omMenuService.getUserMenus()
    ).subscribe(
      (
        responses: [PlatUser, User, Organization, OrganizationProject[], Menu[]]
      ) => {
        this.globalService.userInfo = Object.assign(responses[0], responses[1]);
        const org = responses[2];
        if (responses[3].length > 0) {
          org.projectSections = responses[3][0].projectSections;
        }
        this.globalService.orgInfo = org;
        this.globalService.openLogin(info);
        const auditMenuMap: { [key: string]: MenuAudit } = {};
        for (const menu of responses[4]) {
          for (const child of menu.child ?? []) {
            if (child.menuAudits) {
              for (const audit of child.menuAudits) {
                auditMenuMap[audit.menuId!] = audit;
              }
            }
          }
        }
        this.menuIdAuditsMap = auditMenuMap;
        if (!org.projectSections || org.projectSections?.length === 0) {
          this.message.error('未分配标段');
          return;
        }
        this.getAllSection();
      }
    );
  }

  getAllSection() {
    zip(
      this.staffOrganizationService.findAll<OrganizationListRequest>({
        projectId: this.globalService.projectId,
        type: OrganizationType.CONSTRUCTION,
        projectSections:
          this.globalService.userInfo.organizationType ===
          OrganizationType.DEVELOPMENT
            ? undefined
            : this.globalService.orgInfo.projectSections,
      }),
      this.staffOrganizationService.findAll<OrganizationListRequest>({
        projectId: this.globalService.projectId,
        type: OrganizationType.SUPERVISE,
        projectSections:
          this.globalService.userInfo.organizationType ===
          OrganizationType.DEVELOPMENT
            ? undefined
            : this.globalService.orgInfo.projectSections,
      }),
      this.projectSectionService.findAll<ProjectSectionListRequest>({
        projectId: this.globalService.projectId,
      })
    ).subscribe(([consturctOrgList, superviseOrgList, sectionList]) => {
      const orgc: { label: string; value: string }[] = [];
      for (const item of consturctOrgList ?? []) {
        orgc.push({
          label: item.name!,
          value: item.name!,
        });
      }
      this.constructOrgList = orgc;

      const orgs: { label: string; value: string }[] = [];
      for (const item of superviseOrgList ?? []) {
        orgs.push({
          label: item.name!,
          value: item.name!,
        });
      }
      this.superviseOrgList = orgs;

      let sectionIds = (this.globalService.orgInfo.projectSections ?? []).map(
        (item) => item.sectionId
      );
      if (
        this.globalService.userInfo.organizationType ===
        OrganizationType.DEVELOPMENT
      ) {
        sectionIds = [];
      }
      for (const item of sectionList ?? []) {
        this.sectionMap[item.id!] = item;
        if (!sectionIds || sectionIds?.length === 0) {
          this.sectionOptions.push({
            label: item.name!,
            value: item.id!,
          });
        } else {
          if (sectionIds.includes(item.id!)) {
            this.sectionOptions.push({
              label: item.name!,
              value: item.id!,
            });
          }
        }
      }
      this.onChange();
    });
  }

  onChange() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.config = new TableConfig();
      this.recordList = [];
    }
    this.omMenuService
      .findById(this.activatedRoute.snapshot.params['menuId'])
      .subscribe((data: Menu) => {
        this.currentMenu = data;
      });
    this.omMenuFormService
      .findAll<MenuFormListRequest>({
        menuId: this.activatedRoute.snapshot.params['menuId'],
      })
      .subscribe((data: MenuForm[]) => {
        this.menuFormList = data;
        for (const menuForm of this.menuFormList) {
          if (
            menuForm.menuId &&
            this.menuIdAuditsMap[menuForm.menuId] &&
            this.menuIdAuditsMap[menuForm.menuId].writAble
          ) {
            this.auditsWriteableMap[menuForm.menuId!] = true;
          }
        }

        let menuFormId = this.menuFormList[0].id;
        for (const menuForm of this.menuFormList) {
          if (!!menuForm.formId) {
            menuFormId = menuForm.id;
            break;
          }
        }

        this.omFormHeaderService
          .findAll<FormHeaderListRequest>({
            menuFormId,
          })
          .subscribe((headers: FormHeader[]) => {
            const obj$: Observable<string[]>[] = [];
            const optKeys: string[] = [];
            console.log(headers);
            headers.sort((a, b) => a.order! - b.order!);
            for (const header of headers) {
              if (header.search === FormHeaderSearch.ENUM) {
                obj$.push(
                  this.workReportService.listEnumValues({
                    fieldName: header.fieldName!,
                    menuId: this.activatedRoute.snapshot.params['menuId'],
                    projectId: this.globalService.projectId,
                  })
                );
                optKeys.push(header.fieldName!);
              }
            }
            zip(...obj$).subscribe((res: string[][]) => {
              for (let i = 0; i < res.length; i++) {
                const opts: TableColumnOption[] = [];
                for (const item of res[i]) {
                  opts.push({
                    label: item,
                    value: item,
                  });
                }
                this.optionMap[optKeys[i]] = opts;
              }
              this.initConfig(headers);
            });
          });
      });
  }

  getAlign(h: FormHeaderHorizontal) {
    switch (h) {
      case FormHeaderHorizontal.LEFT:
        return 'left';
      case FormHeaderHorizontal.RIGHT:
        return 'right';
      default:
        return 'center';
    }
  }

  getType(h: FormHeaderFormat): TableColumnType {
    switch (h) {
      case FormHeaderFormat.MONEY:
        return TableColumnType.amount;
      case FormHeaderFormat.NUMBER:
        return TableColumnType.digital;
      default:
        return TableColumnType.text;
    }
  }

  private initConfig(headers: FormHeader[]) {
    console.log(headers);
    const headersMap: { [key: string]: FormHeader } = {};
    for (const header of headers) {
      if (header.hidden) continue;
      headersMap[header.fieldName!] = header;
    }
    const fixedColumns: TableColumnConfig[] = [];
    this.headerList = headers;
    // TODO： 针对监理指令和监理指令回复进行特殊处理
    // if (this.currentMenu?.name === 'SUPERVISE_ORDER') {」
    for (const header of headers) {
      if (header.hidden) {
        continue;
      }
      if (header.fieldName === 'sectionId') {
        fixedColumns.push({
          name: header.headerName!,
          key: 'sectionId',
          type: TableColumnType.options,
          options: this.sectionOptions,
          align: this.getAlign(header.horizontal!),
          width: !!header.width ? header.width : 100,
          queryable: true,
          queryKey: 'sectionId',
          patternStr: 'E+' + (!!header.width ? header.width : 100),
          sortable: header.sortType !== FormHeaderSortType.NONE,
          sortDefault:
            header.sortType === FormHeaderSortType.ASC
              ? OrderType.ASC
              : OrderType.DESC,
          sortOrder: header.sortOrder,
          sortKey: header.fieldName + 'Order',
        });
      } else if (header.fieldName === 'constructionOrgName') {
        fixedColumns.push({
          name: header.headerName!,
          key: header.fieldName,
          type: TableColumnType.text,
          queryType: TableColumnType.options,
          options: this.constructOrgList,
          align: this.getAlign(header.horizontal!),
          width: !!header.width ? header.width : 100,
          queryable: true,
          queryKey: header.fieldName,
          patternStr: 'E+' + (!!header.width ? header.width : 100),
          sortable: header.sortType !== FormHeaderSortType.NONE,
          sortDefault:
            header.sortType === FormHeaderSortType.ASC
              ? OrderType.ASC
              : OrderType.DESC,
          sortOrder: header.sortOrder,
          sortKey: header.fieldName + 'Order',
        });
      } else if (header.fieldName === 'supervisionOrgName') {
        fixedColumns.push({
          name: header.headerName!,
          key: header.fieldName,
          type: TableColumnType.text,
          queryType: TableColumnType.options,
          options: this.superviseOrgList,
          align: this.getAlign(header.horizontal!),
          width: !!header.width ? header.width : 100,
          queryable: true,
          queryKey: header.fieldName,
          patternStr: 'E+' + (!!header.width ? header.width : 100),
          sortable: header.sortType !== FormHeaderSortType.NONE,
          sortDefault:
            header.sortType === FormHeaderSortType.ASC
              ? OrderType.ASC
              : OrderType.DESC,
          sortOrder: header.sortOrder,
          sortKey: header.fieldName + 'Order',
        });
      } else if (header.fieldName === 'sn') {
        fixedColumns.push({
          name: header.headerName!,
          key: 'sn',
          type: TableColumnType.text,
          align: this.getAlign(header.horizontal!),
          width: !!header.width ? header.width : 100,
          queryable: true,
          queryType: TableColumnType.text,
          queryKey: 'sn',
          dynamicPatternStr: (row: number) =>
            'E+' +
            (!!header.width ? header.width : 100) +
            '@@' +
            this.getColor(this.recordList[row].status!),
          sortable: header.sortType !== FormHeaderSortType.NONE,
          sortDefault:
            header.sortType === FormHeaderSortType.ASC
              ? OrderType.ASC
              : OrderType.DESC,
          sortOrder: header.sortOrder,
          sortKey: header.fieldName + 'Order',
          preview: {
            style: 'primary',
            callback: (row: number) => {
              this.signCustom(row);
            },
          },
        });
      } else if (header.headerName === '来文编号') {
        fixedColumns.push({
          name: header.headerName!,
          key: header.fieldName!,
          type: TableColumnType.text,
          align: this.getAlign(header.horizontal!),
          width: !!header.width ? header.width : 100,
          queryable: true,
          queryType: TableColumnType.text,
          queryKey: header.fieldName!,
          patternStr: 'E+' + (!!header.width ? header.width : 100),
          sortable: header.sortType !== FormHeaderSortType.NONE,
          sortDefault:
            header.sortType === FormHeaderSortType.ASC
              ? OrderType.ASC
              : OrderType.DESC,
          sortOrder: header.sortOrder,
          sortKey: header.fieldName + 'Order',
          preview: {
            style: 'primary',
            callback: (row: number) => {
              this.signCustom(row);
            },
          },
        });
      } else {
        const col: TableColumnConfig = {
          name: header.headerName!,
          key: header.fieldName!,
          type: this.getType(header.format!),
          options: this.optionMap[header.fieldName!] ?? [],
          align: this.getAlign(header.horizontal!),
          width: !!header.width ? header.width : 100,
          queryable: header.search !== FormHeaderSearch.CLOSE,
          queryType:
            header.search === FormHeaderSearch.ENUM
              ? TableColumnType.options
              : TableColumnType.text,
          queryKey: header.fieldName!,
          patternStr:
            'E+' +
            (!!header.width ? header.width : 100) +
            (header.pattern ? '@@' + this.patternMap(header.pattern!) : ''),
          sortable: header.sortType !== FormHeaderSortType.NONE,
          sortDefault:
            header.sortType === FormHeaderSortType.ASC
              ? OrderType.ASC
              : OrderType.DESC,
          sortOrder: header.sortOrder,
          sortKey: header.fieldName + 'Order',
          nanString: '-',
        };
        if (
          this.currentMenu?.name === '监理指令' &&
          header.headerName === '回复单编号'
        ) {
          col.preview = {
            style: 'primary',
            callback: (row: number) => {
              this.viewReportOnlyById(this.recordList[row].replyId);
            },
          };
        }
        if (
          this.currentMenu?.name === '监理指令回复' &&
          header.headerName === '指令单编号'
        ) {
          col.preview = {
            style: 'primary',
            callback: (row: number) => {
              this.viewReportOnlyById(this.recordList[row].receiveId);
            },
          };
        }
        fixedColumns.push(col);
      }
    }
    const actions: TableColumnConfig[] = [
      {
        name: '状态',
        key: 'status',
        type: TableColumnType.options,
        width: 80,
        queryable: true,
        queryKey: 'status',
        options: WorkReportStatusList,
        sortable: true,
        sortKey: 'statusOrder',
        sortDefault: OrderType.ASC,
        patternStr:
          'CFF0000@@L000000_MATCHF_"修改稿"@@L7f7f7f_MATCHF_"完成"@@L922d2e_MATCHF_"待签字"@@L922d2e_MATCHF_"待盖章"@@L922d2e_MATCHF_"待回复"',
        bodyPopoverComponentKey: 'simplePop',
      },
      {
        name: '待执行者',
        key: 'nextExecuteUserName',
        type: TableColumnType.text,
        width: 80,
        queryable: true,
        queryKey: 'nextExecuteUserName',
        dynamicPatternStr: (row) =>
          this.recordList[row].nextExecuteUserId ===
          this.globalService.userInfo.userId
            ? 'C2b58cd@@E+80'
            : 'E+80',
      },
      {
        name: '文件',
        key: 'fileUrls',
        type: TableColumnType.imgAction,
        align: 'left',
        width: 60,
        cover: (row: number) => {
          if (this.recordList[row].status === WorkReportStatus.DRAFT) {
            return './assets/imgs/img-p.png';
          }
          return this.recordList[row].docType === WorkReportDocType.PDF_SIGNED
            ? './assets/imgs/img-pdf.png'
            : './assets/imgs/img-pdf-e.png';
        },
        view: (row: number) => {
          if (this.recordList[row].status === WorkReportStatus.DRAFT) {
            // if (
            //   this.userId !== this.recordList[row].filledUserId &&
            //   this.currentMenu?.name !== '监理指令回复'
            // ) {
            //   this.message.error('不可编辑他人修改稿');
            //   return;
            // }
            this.edit(row);
          } else {
            this.signCustom(row);
          }
        },
      },
      {
        name: '操作',
        key: '',
        type: TableColumnType.actions,
        width: 60,
        dynamicActions: [
          {
            name: (row: number) => {
              const item = this.recordList[row];
              if (item.status === WorkReportStatus.DRAFT) {
                return '修改';
              }
              if (
                item.status === WorkReportStatus.WAIT_REPLY &&
                item.nextExecuteUserId === this.userId
              ) {
                return '回复';
              }
              if (item.status === WorkReportStatus.FINISH && item.docId) {
                return '补充';
              }
              if (
                item.status === WorkReportStatus.WAIT_SIGN &&
                item.nextExecuteUserId === this.userId
              ) {
                return '签字';
              }

              if (
                item.status === WorkReportStatus.WAIT_SEAL &&
                item.nextExecuteUserId === this.userId
              ) {
                return '盖章';
              }

              if (
                item.status === WorkReportStatus.WAIT_COUNTER_SIGN &&
                item.nextExecuteUserId === this.userId
              ) {
                return '会签';
              }

              return '查看';
            },
            style: () => 'primary',
            callback: (row: number) => {
              const item = this.recordList[row];
              if (this.recordList[row].status === WorkReportStatus.DRAFT) {
                // if (
                //   this.userId !== item.filledUserId &&
                //   this.currentMenu?.name !== '监理指令回复'
                // ) {
                //   this.message.error('不可编辑他人修改稿');
                //   return;
                // }
                this.edit(row);
                return;
              }
              // if (
              //   this.recordList[row].status === WorkReportStatus.WAIT_REPLY &&
              //   this.recordList[row].nextExecuteUserId === this.userId
              // ) {
              //   // if (
              //   //   this.userId !== item.filledUserId &&
              //   //   this.currentMenu?.name !== '监理指令回复'
              //   // ) {
              //   //   this.message.error('不可编辑他人修改稿');
              //   //   return;
              //   // }
              //   this.reply(row);
              //   return;
              // }
              if (
                this.recordList[row].status === WorkReportStatus.FINISH &&
                this.recordList[row].docId
              ) {
                this.fill(row);
                return;
              }

              this.signCustom(row);
            },
          },
        ],
      },
      {
        name: '日志',
        key: 'log',
        type: TableColumnType.text,
        width: 64,
        align: 'left',
        patternStr: 'E+64@@C7F7F7F',
        separator: true,
      },
      // 其他
    ];
    let columns: TableColumnConfig[] = [];
    if (this.currentMenu?.name === '监理指令') {
      columns = [...fixedColumns];
      columns.splice(5, 0, ...actions);
    } else {
      columns = [...fixedColumns, ...actions];
    }
    this.config = {
      hasIndex: true,
      columns,
      // headerSize: 12,
      // freezeColumn: 5,
    };

    this.totalWidth = this.config.columns.reduce(
      (acc, cur) => acc + cur.width,
      56
    );
    this.resetSearch();
  }

  getColor(status: WorkReportStatus) {
    switch (status) {
      case WorkReportStatus.DRAFT:
        return 'C000000';
      case WorkReportStatus.WAIT_SIGN:
        return 'C922d2e';
      case WorkReportStatus.WAIT_SEAL:
        return 'C922d2e';
      case WorkReportStatus.WAIT_REPLY:
        return 'C922d2e';
      case WorkReportStatus.WAIT_COUNTER_SIGN:
        return 'C922d2e';
      case WorkReportStatus.FINISH:
        return 'C2b58cd';
      default:
        return 'CFF0000';
    }
    // return WorkReportStatusColorMap[status];
  }

  patternMap(p: string) {
    if (p === 'bold') {
      return 'B';
    }
    if (p === 'gray') {
      return 'C7F7F7F';
    }
    return '';
  }

  ngOnDestroy(): void {
    this.hiddenHost.viewContainerRef.clear();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  searchRecord() {
    const loading = this.loadingService.open();
    // if (this.needSectionId) {
    //   this.searchRequest.projectSections =
    //     this.globalService.userInfo.organizationType ===
    //       OrganizationType.OPERATE ||
    //     this.globalService.userInfo.organizationType ===
    //       OrganizationType.DEVELOPMENT
    //       ? undefined
    //       : this.globalService.orgInfo.projectSections;
    // }
    this.workReportService
      .search(this.searchRequest, this.pager.pageIndex - 1, this.pager.pageSize)
      .subscribe((data: Page<WorkReport>) => {
        this.globalService.savePager(this.pager);
        this.currentCheckList = [];
        this.hiddenHost.viewContainerRef.clear();
        this.recordList = data.content;
        this.pager.total = data.totalElements;
        for (const record of this.recordList) {
          const keys = Object.keys(record);
          for (const key of keys) {
            if (record[key] === 'NaN-NaN-NaN') {
              record[key] = '';
            }
          }
          record.codeName = record.code + ' ' + record.name;
          for (const user of record.nextExcuteUsers ?? []) {
            if (user.id === this.userId) {
              record.nextExecuteUserName = user.name;
              record.nextExecuteUserId = user.id;
              break;
            }
          }
          if (!record.nextExecuteUserId && record.nextExcuteUsers?.length) {
            record.nextExecuteUserName = record.nextExcuteUsers[0].name;
            record.nextExecuteUserId = record.nextExcuteUsers[0].id;
          }
          try {
            if (record.pdfFiles) {
              record.pdfFilesList = JSON.parse(record.pdfFiles);
            } else {
              record.pdfFilesList = [];
            }
          } catch (error) {
            record.pdfFilesList = [];
          }
          try {
            if (record.signFiles) {
              record.signFilesList = JSON.parse(record.signFiles);
            } else {
              record.signFilesList = [];
            }
          } catch (error) {
            record.signFilesList = [];
          }
          if (record.status === WorkReportStatus.DRAFT) {
            record.fileUrls = [];
          } else {
            record.fileUrls =
              !!record.signFilesList && record.signFilesList.length > 0
                ? record.signFilesList
                : record.pdfFilesList;
          }
          record.log = `新增 ${
            record.filledDate
              ? moment(new Date(record.filledDate).toISOString())
                  .locale('zh_cn')
                  .format('yyyy-MM-DD HH:mm:ss')
              : ''
          } ${record.filledUserName}`;

          if (!!record.rejectReason) {
            record.simplePop =
              ComponentCreaterUtil.create<SimplePopoverComponent>(
                SimplePopoverComponent,
                this.hiddenHost.viewContainerRef,
                record.rejectReason
                  ? record.rejectReason
                  : record.cancleReason
                  ? record.cancleReason
                  : ''
              );
          }
        }
        console.log(this.recordList);
        loading.loadingInstance.close();
      });
  }

  checkChange(checks: boolean[]) {
    this.currentCheckList = checks;
  }

  pageIndexChange(index: number) {
    this.pager.pageIndex = index;
    this.searchRecord();
  }

  pageSizeChange(size: number) {
    this.pager.pageSize = size;
    this.pager.pageIndex = 1;
    this.searchRecord();
  }

  add(menuForm: MenuForm) {
    if (!menuForm.menuId || !this.auditsWriteableMap[menuForm.menuId!]) {
      this.message.error('没有该表单权限');
      return;
    }
    if (!menuForm.formId) {
      this.message.error('表单不存在');
      return;
    }
    zip(
      this.projectSubformService.findAll<SubFormRouterListRequest>({
        typeFormCode: menuForm.formCode!.trim(),
        projectId: this.globalService.projectId,
      }),
      this.omFormService.findById(menuForm.formId!)
    ).subscribe((responses: [SubFormRouter[], Form]) => {
      const form = responses[1];
      const subFormList = responses[0];
      let hasWbs = false;
      let maxIndex = -1;
      const typeList = ProjectItemTypeIndexList.map((item) => item.toString());
      for (const item of form.formItems ?? []) {
        if (item.widget === FormItemWidgetType.WBS) {
          hasWbs = true;
          try {
            const level = item.dataSource?.split('.')[0];
            if (level) {
              console.log(level);
              const index = typeList.indexOf(level);
              if (maxIndex < index) {
                maxIndex = index;
              }
            }
          } catch (error) {}
        }
        if (item.widget === FormItemWidgetType.PROJECT && item.dataSource) {
          if (
            item.dataSource.indexOf('projectOrg.CONSTRUCTION') > -1 ||
            item.dataSource.indexOf('projectContract.CONSTRUCTION') > -1 ||
            item.dataSource.indexOf('projectOrg.SUPERVISE') > -1 ||
            item.dataSource.indexOf('projectContract.SUPERVISE') > -1
          ) {
            hasWbs = true;
            if (maxIndex === -1) {
              maxIndex = 0;
            }
          }
        }
      }

      let sectionId = undefined;
      if (
        maxIndex === 0 &&
        this.globalService.orgInfo?.projectSections &&
        this.globalService.orgInfo?.projectSections.length === 1
      ) {
        sectionId = this.globalService.orgInfo?.projectSections[0].sectionId;
        hasWbs = false;
        maxIndex = -1;
      }
      if (subFormList.length > 0) {
        const data = new DialogSubFormChooseData();
        data.formCode = menuForm.formCode!;
        const results = this.modalService.open({
          backdropCloseable: false,
          component: DialogSubFormChooseComponent,
          onClose: () => {},
          data: {
            data,
            onCancel: () => {
              results.modalInstance.hide();
            },
            onConfirm: (res: DialogSubFormChooseResult) => {
              this.globalService.formInitCache = {
                projectId: this.globalService.projectId,
                sectionId: res.sectionId,
                sectionItemId: res.sectionItemId,
                menuFormId: menuForm.id,
                formId: res.formId,
              };
              // this.router.navigate(['add', menuForm.id!], {
              //   relativeTo: this.activatedRoute,
              // });
              this.openInTab(`#/custom-form/add/${menuForm.id}`);
              results.modalInstance.hide();
            },
          },
        });
      } else if (hasWbs && maxIndex > -1) {
        const results = this.modalService.open({
          backdropCloseable: false,
          component: DialogWbsChooseComponent,
          onClose: () => {},
          data: {
            data: ProjectItemTypeIndexList[maxIndex],
            onCancel: () => {
              results.modalInstance.hide();
            },
            onConfirm: (res: any[]) => {
              this.globalService.formInitCache = {
                projectId: this.globalService.projectId,
                sectionId: res[0],
                sectionItemId: res.length > 1 ? res[res.length - 1] : undefined,
                menuFormId: menuForm.id,
                formId: menuForm.formId,
              };
              // this.router.navigate(['add', menuForm.id!], {
              //   relativeTo: this.activatedRoute,
              // });
              this.openInTab(`#/custom-form/add/${menuForm.id}`);
              results.modalInstance.hide();
            },
          },
        });
      } else {
        this.globalService.formInitCache = {
          projectId: this.globalService.projectId,
          sectionId: sectionId,
          menuFormId: menuForm.id,
          formId: menuForm.formId,
        };
        this.openInTab(`#/custom-form/add/${menuForm.id}`);
        // this.router.navigate(['add', menuForm.id!], {
        //   relativeTo: this.activatedRoute,
        // });
      }
    });
  }

  private openInTab(url: string) {
    const target = window.open(url, '_blank');
    setTimeout(() => {
      target?.postMessage({
        type: 'redir',
        data: window.location.href,
      });
    }, 1000);
    // target?.postMessage('redir', window.location.href);
  }

  addByWorkOrder(row: number) {
    this.openInTab(`#/custom-form/copy/${this.recordList[row].id}`);
  }

  edit(row: number) {
    this.openInTab(`#/custom-form/detail/${this.recordList[row].id}`);
  }

  reply(row: number) {
    this.workReportService
      .reply(this.recordList[row].id!)
      .subscribe((orderId: number) => {
        this.viewReportOnlyById(orderId);
      });
  }

  fill(row: number) {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: CustomMenuFormFillDialogComponent,
      onClose: () => {},
      data: {
        data: JSON.parse(JSON.stringify(this.recordList[row])),
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: (res: any[]) => {
          this.searchRecord();
          results.modalInstance.hide();
        },
      },
    });
  }

  sign(row: number) {
    const results = this.loadingService.open();
    this.workReportService
      .getSignUrl(this.recordList[row].id!)
      .subscribe((info: SignUrlInfo) => {
        if (info) {
          window.open(info.shortUrl, '_blank');
        }
        results.loadingInstance.close();
      });
  }

  reject(row: number) {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '退回',
        content: '请输入退回理由',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.text,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: (msg: string) => {
          if (!!msg) {
            this.workFlowUserService
              .rejectSign({
                workFlowId: this.recordList[row].workFlowId!,
                rejectReason: msg,
              })
              .subscribe((data: boolean) => {
                this.searchRecord();
              });
            results.modalInstance.hide();
          }
        },
      },
    });
  }

  cancel(row: number) {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '撤回',
        content: '请输入撤回理由',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.text,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: (msg: string) => {
          if (!!msg) {
            this.workFlowUserService
              .cancleSign({
                workFlowId: this.recordList[row].workFlowId!,
                cancleReason: msg,
              })
              .subscribe((data: boolean) => {
                this.searchRecord();
              });
            results.modalInstance.hide();
          }
        },
      },
    });
  }

  // view(row: number) {
  // this.workReportService
  //   .findById(this.recordList[row].id!)
  //   .subscribe((workReport: WorkReport) => {
  //     if (workReport.status === WorkReportStatus.DRAFT) {
  //       if (this.globalService.userInfo.id === workReport.filledUserId) {
  //         this.edit(row);
  //       } else {
  //         this.message.error('修改稿状态不可预览');
  //       }
  //       return;
  //     } else {
  //       this.openInTab(`#/custom-form/preview/${workReport.id}`);
  //     }
  //   });
  // }

  viewReportOnlyById(id?: number) {
    if (!id) {
      // this.message.error('文件不存在，不可预览');
      return;
    }
    this.workReportService.findById(id!).subscribe((workReport: WorkReport) => {
      // if (workReport.status === WorkReportStatus.DRAFT) {
      //   this.message.error('修改稿状态不可预览');
      //   return;
      // } else {
      this.openInTab(`#/custom-form/sign/${workReport.workFlowKey}`);
      // }
    });
  }

  signCustom(row: number) {
    this.openInTab(`#/custom-form/sign/${this.recordList[row].workFlowKey}`);
  }

  remove(row: number) {
    // this.removeRecord(row);
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除',
        content: '确认删除？',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.confirm,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          this.removeRecord(row);
          results.modalInstance.hide();
        },
      },
    });
  }

  calcResultChange(result: string) {
    this.calcResult = result;
  }

  queryPanelStateChange(isOpen: boolean) {
    console.log(isOpen);
    if (isOpen === false) {
      this.resetSearch();
    }
  }

  resetSearch(force?: boolean) {
    if (!force) {
      this.timeout = setTimeout(() => {
        this.events.broadcast(EventType.PageCacheSearch);
      }, 1000);
      return;
    }
    this.searchRequest = new WorkReportSearchRequest();
    this.searchRequest.projectId = this.globalService.projectId;
    this.searchRequest.menuId = this.activatedRoute.snapshot.params['menuId'];
    const sectionIds: number[] = [];
    for (const section of this.globalService.orgInfo.projectSections ?? []) {
      sectionIds.push(section.sectionId!);
    }
    this.searchRequest.sectionIds =
      this.globalService.userInfo.organizationType ===
      OrganizationType.DEVELOPMENT
        ? undefined
        : sectionIds;
    // this.searchRequest.statusOrder = OrderType.ASC;
    // this.searchRequest.filledDateOrder = OrderType.DESC;
    // this.searchRequest.orderedSortFields = [
    //   'snOrder',
    //   'statusOrder',
    //   'filledDateOrder',
    // ];
    this.searchRequest.orderedSortFields = [];
    for (const header of this.headerList) {
      if (header.sortType === FormHeaderSortType.NONE) continue;
      if (header.sortType) {
        if (header.sortType === FormHeaderSortType.ASC) {
          this.searchRequest[header.fieldName + 'Order'] = OrderType.ASC;
        }
        if (header.sortType === FormHeaderSortType.DESC) {
          this.searchRequest[header.fieldName + 'Order'] = OrderType.DESC;
        }
      }
      this.searchRequest.orderedSortFields[header.sortOrder!] =
        header.fieldName! + 'Order';
    }
    if (this.searchRequest.orderedSortFields.length > 0) {
      this.searchRequest.orderedSortFields.pop();
    }
    this.searchRequest.orderedSortFields.push('statusOrder');
    this.searchRequest.statusOrder = OrderType.ASC;
    this.pager.pageIndex = 1;
    this.searchRecord();
  }

  private prepareSearch(data: any) {
    this.searchRequest = new WorkReportSearchRequest();
    this.searchRequest.projectId = this.globalService.projectId;
    const sectionIds: number[] = [];
    for (const section of this.globalService.orgInfo.projectSections ?? []) {
      sectionIds.push(section.sectionId!);
    }
    this.searchRequest.sectionIds =
      this.globalService.userInfo.organizationType ===
      OrganizationType.DEVELOPMENT
        ? undefined
        : sectionIds;
    this.searchRequest.orderedSortFields = [];
    for (const header of this.headerList) {
      if (header.sortType === FormHeaderSortType.NONE) continue;
      if (header.sortType) {
        if (header.sortType === FormHeaderSortType.ASC) {
          this.searchRequest[header.fieldName + 'Order'] = OrderType.ASC;
        }
        if (header.sortType === FormHeaderSortType.DESC) {
          this.searchRequest[header.fieldName + 'Order'] = OrderType.DESC;
        }
      }
      this.searchRequest.orderedSortFields[header.sortOrder!] =
        header.fieldName! + 'Order';
    }
    if (this.searchRequest.orderedSortFields.length > 0) {
      this.searchRequest.orderedSortFields.pop();
    }
    this.searchRequest.orderedSortFields.push('statusOrder');
    this.searchRequest.statusOrder = OrderType.ASC;
    if (!!this.firstSortOrderKey) {
      for (let i = 0; i < this.searchRequest.orderedSortFields.length; i++) {
        if (
          this.searchRequest.orderedSortFields[i] === this.firstSortOrderKey
        ) {
          this.searchRequest.orderedSortFields.splice(i, 1);
          break;
        }
      }
      this.searchRequest.orderedSortFields.unshift(this.firstSortOrderKey);
    }
    this.searchRequest.menuId = this.activatedRoute.snapshot.params['menuId'];
    Object.assign(this.searchRequest, data);
  }

  searchClick(data: any) {
    this.prepareSearch(data);
    this.searchRecord();
  }

  afterEdit(data: { row: number; key: string }) {
    const { row, key } = data;
    this.editRecord(row);
  }

  batchChangeEnable() {
    let selectCount = 0;
    let selectedIndex = -1;
    const ids: number[] = [];
    for (let i = 0; i < this.currentCheckList.length; i++) {
      if (this.currentCheckList[i]) {
        selectedIndex = i;
        selectCount++;
        ids.push(this.recordList[i].id!);
      }
    }
    if (selectCount === 0) {
      return;
    }

    let content = `选中的${selectCount}个项目`;

    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '启停选中',
        content,
        hint: '批量设置为',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'primary',
        type: AlertDialogType.option,
        options: [
          {
            value: 1,
            name: '启用',
          },
          {
            value: 0,
            name: '停用',
          },
        ],
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: (result: number) => {
          // this.updateRecordBatched({
          //   idList: ids,
          //   enabled: +result,
          // });
          results.modalInstance.hide();
        },
      },
    });
  }

  batchRemove() {
    let selectCount = 0;
    let selectedIndex = -1;
    const ids: number[] = [];
    for (let i = 0; i < this.currentCheckList.length; i++) {
      if (this.currentCheckList[i]) {
        selectedIndex = i;
        selectCount++;
        ids.push(this.recordList[i].id!);
      }
    }
    if (selectCount === 0) {
      return;
    }

    let content = `确认删除选中的${selectCount}个项目`;

    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除选中',
        content,
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.prompt,
        onCancel: () => {
          console.log('cancel');
          results.modalInstance.hide();
        },
        onConfirm: () => {
          this.removeRecordBatched(ids);
          results.modalInstance.hide();
        },
      },
    });
  }

  // private updateRecordBatched(param: UpdateRequest) {
  //   this.omWorkOrderEntryService.updateBatch(param).subscribe(() => {
  //     this.searchRecord();
  //   });
  // }

  private removeRecordBatched(ids: number[]) {
    // this.omWorkOrderEntryService
    //   .removeBatch(ids)
    //   .subscribe((data: number) => {
    //     this.searchRecord();
    //   });
  }

  private removeRecord(index: number) {
    this.workReportService
      .remove(this.recordList[index].id!)
      .subscribe((data: number) => {
        this.searchRecord();
      });
  }

  private handleDataBeforeUpdate(WorkOrder: WorkReport): WorkReport {
    const data = JSON.parse(JSON.stringify(WorkOrder));
    // data.policyFile = JSON.stringify(data.policyFileList);
    return data;
  }

  private editRecord(index: number) {
    this.omFormEntryService
      .update(this.handleDataBeforeUpdate(this.recordList[index]))
      .subscribe(() => {
        this.searchRecord();
      });
  }
}
