import { Injectable } from '@angular/core';
import { LocationData } from '../components/location-cascader/location.data';
import {
  LocationInfo,
  LocationModel,
} from '../components/location-cascader/location.model';
import { LoginForm } from '../models/login/login-form.model';
import { LoginInfo } from '../models/login/login-info.model';
import { MenuAudit } from '../models/menu/menu-audit.model';
import { ProjectSectionItem } from '../models/project/project-section-item.model';
import { OrganizationProject } from '../models/staff/organization-project.model';
import { Organization } from '../models/staff/organization.model';
import { PlatUser } from '../models/staff/plat-user.model';
import { LocalStorageService } from './local-storage.service';

import * as localForage from 'localforage';
import { InitWorkReportRequest } from '../models/form/init-work-report-request.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private static USER = 'zhgd_user';
  private static ORG = 'zhgd_org';
  private static ORG_PROJECT = 'zhgd_org_project';
  private static TOKEN = 'zhgd_token';
  private static LOGIN = 'zhgd_login';
  private static SELECT_ORG_CACHE = 'zhgd_select_org_cache';
  private static SELECT_UNIT_CACHE = 'zhgd_select_unit_cache';
  private static AUDIT_VERSION = 'zhgd_audit_version';
  private static AUDIT_URL_MAP = 'zhgd_audit_url_map';
  private static AUDIT_MENU_MAP = 'zhgd_audit_menu_map';
  private static MENU_LIST = 'zhgd_menu_list';
  private static WBS_TITLE_TREE = 'zhgd_wbs_title_tree';
  private static WBS_TITLE_TREE_VERSION = 'zhgd_wbs_title_tree_version';
  private static WBS_TREE_ROOT = 'zhgd_wbs_tree_root';
  private static WBS_TREE_ROOT_VERSION = 'zhgd_wbs_tree_root_version';
  private static PASSWORD_FREE = 'zhgd_pwd_free';
  private static FORM_INIT_CACHE = 'zhgd_form_init_cache';

  shiftPressing = false;
  ctrlPressing = false;
  metaPressing = false;
  halfBottom = false;

  private _searchConfig: any = {};
  private _treeCache: any = {};
  private _pager: any = {};

  s12: any = {};
  s13: any = {};
  s14: any = {};
  s16: any = {};

  _locationData: LocationModel[] = [];

  // _wbsTreeRoot?: ProjectSectionItem = undefined;

  // _wbsTitleTree: NzTreeNode[] = [];

  // _formInitCache?: InitWorkReportRequest = undefined;

  get mit(): string {
    if (window.location.href.indexOf('hzsksc.com') > -1) {
      return '浙ICP备2023053663号-1';
    }
    // if (window.location.href.indexOf('hzsksc.com') > -1) { {
    //   return '浙ICP备2023053663号-2A';
    // }
    return '浙ICP备2023053663号-1';
  }

  get formInitCache() {
    const result = this.localStorage.getData(GlobalService.FORM_INIT_CACHE);
    this.localStorage.clearData(GlobalService.FORM_INIT_CACHE);
    return result ? result : {};
  }

  set formInitCache(value: InitWorkReportRequest) {
    this.localStorage.setData(GlobalService.FORM_INIT_CACHE, value);
  }

  saveSearchConfig(value: any) {
    const key = location.href;
    this._searchConfig[key] = value;
  }

  saveTreeCache(value: any) {
    const key = location.href;
    this._treeCache[key] = value;
  }

  savePager(value: any) {
    const key = location.href;
    this._pager[key] = value;
  }

  recoverSearchConfig() {
    const key = location.href;
    return this._searchConfig[key];
  }

  recoverTreeCache() {
    const key = location.href;
    return this._treeCache[key];
  }

  recoverPager(): any {
    const key = location.href;
    return (
      this._pager[key] ?? {
        total: 1,
        pageIndex: 1,
        pageSize: 100,
        pageSizeOptions: [10, 20, 30, 50, 100, 200],
      }
    );
  }

  resetSearchConfig() {
    this._searchConfig = {};
    this._pager = {};
    this._treeCache = {};
  }

  get locationData(): LocationModel[] {
    return this._locationData;
  }

  constructor(private localStorage: LocalStorageService) {
    this.initLocation();
  }

  get isLogin(): boolean {
    return !!this.localStorage.getData(GlobalService.TOKEN);
  }

  get userInfo(): PlatUser {
    return this.localStorage.getData(GlobalService.USER);
  }

  get orgInfo(): Organization {
    return this.localStorage.getData(GlobalService.ORG);
  }

  get orgProjectInfo(): OrganizationProject {
    return this.localStorage.getData(GlobalService.ORG_PROJECT);
  }

  get projectId(): number {
    return 1004;
  }

  get token(): string {
    return this.localStorage.getData(GlobalService.TOKEN);
  }

  get auditVersion(): string {
    return this.localStorage.getData(GlobalService.AUDIT_VERSION);
  }

  get loginInfo(): LoginForm {
    return this.localStorage.getData(GlobalService.LOGIN);
  }

  get selectOrgCache(): any[] {
    return this.localStorage.getData(GlobalService.SELECT_ORG_CACHE);
  }

  get selectUnitCache(): any[] {
    return this.localStorage.getData(GlobalService.SELECT_UNIT_CACHE);
  }

  async getWbsTreeRoot(): Promise<ProjectSectionItem | null> {
    // return this.localStorage.getData(GlobalService.WBS_TREE_ROOT);
    return await localForage.getItem(GlobalService.WBS_TREE_ROOT);
  }

  get wbsTreeRootVersion(): string {
    return this.localStorage.getData(GlobalService.WBS_TREE_ROOT_VERSION);
  }

  get wbsTitleTree(): ProjectSectionItem {
    return this.localStorage.getData(GlobalService.WBS_TITLE_TREE);
  }

  get wbsTitleTreeVersion(): string {
    return this.localStorage.getData(GlobalService.WBS_TITLE_TREE_VERSION);
  }

  get menuIdAuditsMap(): { [key: string]: MenuAudit } {
    return this.localStorage.getData(GlobalService.AUDIT_MENU_MAP);
  }

  get urlAuditMap(): { [key: string]: MenuAudit } {
    return this.localStorage.getData(GlobalService.AUDIT_URL_MAP);
  }

  get menuList(): {
    title: string;
    icon?: string;
    open: boolean;
    children: { title: string; url: string }[];
  }[] {
    return this.localStorage.getData(GlobalService.MENU_LIST);
  }

  set passwordFree(free: boolean) {
    this.localStorage.setData(GlobalService.PASSWORD_FREE, free);
  }

  get passwordFree(): boolean {
    return this.localStorage.getData(GlobalService.PASSWORD_FREE);
  }

  set token(token: string) {
    this.localStorage.setData(GlobalService.TOKEN, token);
  }

  set auditVersion(version: string) {
    this.localStorage.setData(GlobalService.AUDIT_VERSION, version);
  }

  set orgInfo(org: Organization) {
    this.localStorage.setData(GlobalService.ORG, org);
  }

  set orgProjectInfo(info: OrganizationProject) {
    this.localStorage.setData(GlobalService.ORG_PROJECT, info);
  }

  set loginInfo(info: LoginForm) {
    this.localStorage.setData(GlobalService.LOGIN, info);
  }

  set userInfo(user: PlatUser) {
    this.localStorage.setData(GlobalService.USER, user);
  }

  set selectOrgCache(cache: any[]) {
    this.localStorage.setData(GlobalService.SELECT_ORG_CACHE, cache);
  }

  set selectUnitCache(cache: any[]) {
    this.localStorage.setData(GlobalService.SELECT_UNIT_CACHE, cache);
  }

  async setWbsTreeRoot(wbsTreeRoot: ProjectSectionItem) {
    await localForage.setItem(GlobalService.WBS_TREE_ROOT, wbsTreeRoot);
  }

  set wbsTreeRootVersion(version: string) {
    this.localStorage.setData(GlobalService.WBS_TREE_ROOT_VERSION, version);
  }

  set wbsTitleTree(wbsTitleTree: ProjectSectionItem) {
    this.localStorage.setData(GlobalService.WBS_TITLE_TREE, wbsTitleTree);
  }

  set wbsTitleTreeVersion(version: string) {
    this.localStorage.setData(GlobalService.WBS_TITLE_TREE_VERSION, version);
  }

  set menuIdAuditsMap(audits: { [key: string]: MenuAudit }) {
    this.localStorage.setData(GlobalService.AUDIT_MENU_MAP, audits);
  }

  set urlAuditMap(audits: { [key: string]: MenuAudit }) {
    this.localStorage.setData(GlobalService.AUDIT_URL_MAP, audits);
  }

  set menuList(
    menuList: {
      title: string;
      icon?: string;
      open: boolean;
      children: { title: string; url: string }[];
    }[]
  ) {
    this.localStorage.setData(GlobalService.MENU_LIST, menuList);
  }

  logout(): void {
    const free = this.passwordFree;
    setTimeout(async () => {
      await localForage.clear();
      this.localStorage.clearAll();
      if (!free) {
        location.href = '/#/signin';
      } else {
        location.href = 'http://qyaq.nettaxi.cn:9191/login';
      }
    }, 100);
  }

  clearLoginInfo(): void {
    this.localStorage.clearAll();
  }

  login(loginInfo: LoginInfo) {
    this.localStorage.setData(GlobalService.LOGIN, loginInfo);
    this.localStorage.setData(GlobalService.TOKEN, loginInfo.token);
    // location.reload();
    location.href = '/#/home/notice';
  }

  openLogin(loginInfo: LoginInfo) {
    this.localStorage.setData(GlobalService.LOGIN, loginInfo);
    this.localStorage.setData(GlobalService.TOKEN, loginInfo.token);
  }

  set debugIp(ip: string) {
    this.localStorage.setData('debug_ip', ip);
  }

  get debugIp(): string {
    return this.localStorage.getData('debug_ip');
  }

  get debugUrl(): string {
    return this.localStorage.getData('debug_ip')
      ? `http://${this.localStorage.getData('debug_ip')}`
      : '';
  }

  get debugWs(): string {
    return this.localStorage.getData('debug_ip')
      ? `ws://${this.localStorage.getData('debug_ip')}/push`
      : '';
  }

  private initLocation() {
    const mapL1 = new Map<string, LocationModel>();
    const mapL2 = new Map<string, LocationModel>();
    const listL3 = new Array<LocationInfo>();
    for (const location of LocationData) {
      if (location.level === 1) {
        mapL1.set(location.id, {
          label: location.name,
          value: location.id,
          level: location.level,
          isLeaf: false,
          children: [],
        });
      }
      if (location.level === 2) {
        mapL2.set(location.id, {
          label: location.name,
          value: location.id,
          isLeaf: false,
          level: location.level,
          parentId: location.parentId,
          children: [],
        });
      }
      if (location.level === 3) {
        listL3.push(location);
      }
    }
    for (const location of listL3) {
      const l2 = mapL2.get(location.parentId!);
      if (l2) {
        l2.children!.push({
          label: location.name,
          value: location.id,
          level: location.level,
          parentId: location.parentId,
          isLeaf: true,
        });
      }
    }
    const l2 = Array.from(mapL2.values());
    for (const location of l2) {
      const l1 = mapL1.get(location.parentId!);
      if (l1) {
        l1.children!.push(location);
      }
    }

    this._locationData = Array.from(mapL1.values());
  }

  writeAbleByUrl(url: string): boolean {
    if (this.userInfo.admin) {
      return true;
    }
    return !!this.urlAuditMap[url]?.writAble;
  }
}
