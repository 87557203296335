import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzTreeNode } from '../../models/tree-node.model';
import { EventService, EventType } from '../../providers/event.service';
import { GlobalService } from '../../providers/global.service';
import { ProjectItemType } from '../../models/project/project-item-type.enum';
import { ProjectSectionItem } from '../../models/project/project-section-item.model';
import { OrganizationType } from '../../models/staff/organization-type.enum';

@Component({
  selector: 'wbs-cascader',
  templateUrl: './wbs-cascader.component.html',
  styleUrls: ['./wbs-cascader.component.scss'],
})
export class WbsCascaderComponent {
  isBeamUser = false;

  async onChange() {
    const section = await this.globalService.getWbsTitleTree();
    const sectionIds: number[] = [];
    for (const item of this.globalService.orgInfo.projectSections ?? []) {
      if (!this.isBeamUser && item.beamYardSectionTag) {
        continue;
      }
      sectionIds.push(item.sectionId!);
    }
    const treeNodes: NzTreeNode[] = [];
    for (const item of section?.child ?? []) {
      if (
        !sectionIds.includes(item.sectionId!) &&
        this.globalService.orgInfo.type !== OrganizationType.OPERATE
      ) {
        continue;
      }
      let filteBeamYard = false;
      for (const section of this.globalService.orgInfo.projectSections ?? []) {
        if (`${item.sectionId}` === `${section.sectionId}`) {
          filteBeamYard = !!section.beamYardSectionTag;
          break;
        }
      }
      if (!item.includeBeamYard && filteBeamYard) {
        continue;
      }
      treeNodes.push(this.buildTreeNodes(item, filteBeamYard));
    }
    this.unitTreeNodes = treeNodes;
    if (
      !this.globalService.selectUnitCache ||
      this.globalService.selectUnitCache.length === 0
    ) {
      if (
        !!this.globalService.orgInfo.projectSections &&
        this.globalService.orgInfo.projectSections.length > 0
      ) {
        this.globalService.selectUnitCache = [
          ProjectItemType.PROJECT_SECTION +
            '#' +
            this.globalService.orgInfo.projectSections[0].sectionId,
          ProjectItemType.PROJECT_SECTION +
            '#' +
            this.globalService.orgInfo.projectSections[0].sectionId,
        ];
      } else {
        this.globalService.selectUnitCache = [
          this.unitTreeNodes[0].value,
          this.unitTreeNodes[0].value,
        ];
      }
    }
    if (this._unitCascaderValue.length === 0) {
      this._unitCascaderValue = this.globalService.selectUnitCache;
      this.unitCascaderValueChange.emit(this._unitCascaderValue);
    }
  }

  subsciption = async () => await this.onChange();

  @Input()
  set unitCascaderValue(value: any[]) {
    this._unitCascaderValue = value;
  }

  get unitCascaderValue(): any[] {
    return this._unitCascaderValue;
  }

  _unitCascaderValue: any[] = [];

  @Output() unitCascaderValueChange = new EventEmitter<any[]>();

  unitTreeNodes: NzTreeNode[] = [];

  constructor(
    private globalService: GlobalService,
    private events: EventService
  ) {
    this.isBeamUser = !!this.globalService.hasBeam;
  }

  async ngOnInit() {
    this.events.on(EventType.WbsTitleChangeEvent, this.subsciption);
    await this.onChange();
  }

  ngOnDestroy(): void {
    this.events.destroyListener(
      EventType.WbsTitleChangeEvent,
      this.subsciption
    );
  }

  onUnitCascaderValueChange() {
    if (this._unitCascaderValue.length !== 0) {
      this.unitCascaderValueChange.emit(this._unitCascaderValue);
    }
  }

  onUnitCascaderClear() {
    this.unitCascaderValue = [];
    this.onUnitCascaderValueChange();
  }

  private buildTreeNodes(
    item: ProjectSectionItem,
    beamFilter?: boolean
  ): NzTreeNode {
    const node: NzTreeNode = {
      label: (item.code ?? '') + ' ' + item.name,
      value: `${item.type}#${
        item.type === ProjectItemType.SECTION ||
        item.type === ProjectItemType.PROJECT_SECTION
          ? item.id
          : item.code
      }`,
      children: item.child
        ? [
            {
              label: '全部',
              value: `${item.type}#${
                item.type === ProjectItemType.SECTION ||
                item.type === ProjectItemType.PROJECT_SECTION
                  ? item.id
                  : item.code
              }`,
              isLeaf: true,
            },
          ]
        : [],
      isLeaf: false,
      hasBeam: !!item.includeBeamYard,
    };
    for (const child of item.child ?? []) {
      if (!child.includeBeamYard && beamFilter) {
        continue;
      }
      node.children!.push(this.buildTreeNodes(child, beamFilter));
    }
    if (node.children?.length === 0) {
      node.isLeaf = true;
    }
    return node;
  }
}
