<div class="dialog">
	<div class="dialog-header">【{{record.keywords}}】- 实测值
	</div>
	<div class="dialog-body">
		<div class="grid">
			<div class="row">
				<div class="column background-page text-right" [style.width]="120 + 'px'">
					设计值
				</div>
				<div class="column background-module" [style.width]="100 + 'px'">
					<input class="color-primary" type="text" [placeholder]="'输入设计值'" [(ngModel)]="record.designValue1"
						(blur)="onRefValueChange()" [disabled]="!record.editable" *ngIf="!special">
					<nz-select class="require" [(ngModel)]="record.designValue1" [nzPlaceHolder]="'请选择'" nzAllowClear
						(ngModelChange)="onRefValueChange()" [disabled]="!record.editable" *ngIf="special">
						<nz-option *ngFor="let item of specialOptions" [nzLabel]="item.label" [nzValue]="item.value">
						</nz-option>
					</nz-select>
				</div>
				<div class="column background-module" [style.width]="100 + 'px'">
					<input class="color-primary" type="text" [placeholder]="'输入设计值2'"
						[disabled]="currentSpad.refParamType === FormItemMeasuredProjectRefParamType.DESIGN || special || isZS || !record.editable"
						[(ngModel)]="record.designValue2" (blur)="onRefValueChange()">
				</div>
				<div class="column background-module color-light text-left" [style.width]="200 + 'px'">
					按设计值的个数填写，无则不填
				</div>
				<div class="column background-module" [style.width]="80 + 'px'">
					点击复制
				</div>
				<div class="column background-module" [style.width]="35 + 'px'" (click)="copy('±')">
					±
				</div>
				<div class="column background-module" [style.width]="35 + 'px'" (click)="copy('≥')">
					≥
				</div>
				<div class="column background-module" [style.width]="35 + 'px'" (click)="copy('≤')">
					≤
				</div>
				<div class="column background-module" [style.width]="35 + 'px'" (click)="copy('~')">
					~
				</div>
			</div>
			<div class="row">
				<div class="column background-page text-right" [style.width]="120 + 'px'">
					规定值或允许偏差
				</div>
				<div class="column background-module" [style.width]="200 + 'px'" *ngIf="!special && !isZS">
					<input class="color-primary require" type="text" [placeholder]="'输入规定值或允许偏差'"
						[(ngModel)]="detectionInfo.biasStr" nz-dropdown
						[nzDropdownMenu]="record.editable ? biasSelect : null" [nzPlacement]="'bottomLeft'"
						(blur)="onBiasChange()" [disabled]="!record.editable">
					<nz-dropdown-menu #biasSelect="nzDropdownMenu">
						<ul nz-menu>
							<li nz-menu-item *ngFor="let item of record.spadValues; let index = index"
								(click)="selectBias(index)">
								{{item.spadValue}}{{item.keywords ? '(' + item.keywords + ')' : ''}}
							</li>
						</ul>
					</nz-dropdown-menu>
				</div>
				<div class="column background-module" [style.width]="200 + 'px'" *ngIf="isZS">
					<nz-select class="require" [(ngModel)]="detectionInfo.biasStr" [nzPlaceHolder]="'选择类型'" nzAllowClear
						(ngModelChange)="onBiasChange()" [disabled]="!record.editable">
						<nz-option *ngFor="let item of ZS" [nzLabel]="item.label" [nzValue]="item.value">
						</nz-option>
					</nz-select>
				</div>
				<div class="column background-module" [style.width]="200 + 'px'" *ngIf="special">
					<input class="color-primary" type="text" [placeholder]="'请选择设计值'"
						[(ngModel)]="detectionInfo.biasStr" [disabled]="true">
				</div>
				<div class="column background-module color-light text-left" [style.width]="420 + 'px'"
					*ngIf="!currentSpad.refParamSymbol">
					可手工输入（清空可再次从配置中读取）
				</div>
				<div class="column background-page" [style.width]="180 + 'px'" *ngIf="currentSpad.refParamSymbol">
					{{currentSpad.refParamSymbol}} {{currentSpad.refParamName}}
				</div>
				<div class="column background-module" [style.width]="100 + 'px'" *ngIf="currentSpad.refParamSymbol">
					<input class="color-primary" type="text" appDigitalFormat [(ngModel)]="currentSpad.refParamValue"
						(blur)="onRefValueChange()" [disabled]="!record.editable">
				</div>
				<div class="column background-page text-right" [style.width]="70 + 'px'"
					*ngIf="currentSpad.refParamSymbol">
					计算所得
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" *ngIf="currentSpad.refParamSymbol">
					{{currentSpad.calcDisplay}}
				</div>
			</div>
			<!-- <div class="row">
				<div class="column background-page text-right" [style.width]="120 + 'px'">
					原表备注
				</div>
				<div class="column background-module" [style.width]="200 + 'px'">
					<input class="color-primary" type="text" [placeholder]="'输入规定值或允许偏差'"
						[(ngModel)]="record.remarkValue" nz-dropdown [nzDropdownMenu]="descSelect"
						[nzPlacement]="'bottomLeft'">
					<nz-dropdown-menu #descSelect="nzDropdownMenu">
						<ul nz-menu *ngIf="!!recommandRemark">
							<li nz-menu-item (click)="record.remarkValue = recommandRemark">{{recommandRemark}}</li>
						</ul>
					</nz-dropdown-menu>
				</div>
				<div class="column background-module color-light text-left" [style.width]="420 + 'px'">
					可手工输入（清空可再次从配置中读取）
				</div>
			</div> -->
			<!-- <div class="row">
				<div class="column background-page text-right" [style.width]="120 + 'px'">
					实测值统计
				</div>
				<div class="column background-module color-light" [style.width]="155 + 'px'">
					个数：{{totalCount1}}
				</div>
				<div class="column background-module color-light" [style.width]="155 + 'px'">
					平均值：{{avg1}}
				</div>
				<div class="column background-module color-light" [style.width]="155 + 'px'">
					代表值：-
				</div>
				<div class="column background-module color-light" [style.width]="155 + 'px'">
					合格率：{{detectionInfo.biasError ? '-' : (passRate + '%')}}
				</div>
			</div> -->
		</div>
		<div class="grid">
			<div class="row">
				<div class="column background-page" [style.width]="320 + 'px'">
					实测值{{!record.designValue2 ? '' : '(设计值=' + (record.designValue1 ?? '') + ')'}}
				</div>
				<div class="column background-page" [style.width]="140 + 'px'">
					平均值：{{showExp ? '' : isZS ? '-' : avg1}}
				</div>
				<div class="column background-page text-right" [style.width]="70 + 'px'">
					代表值：
				</div>
				<div class="column background-page" [style.width]="70 + 'px'">
					<input class="color-primary" type="text" [(ngModel)]="exp1" (blur)="onExpChange()"
						[disabled]="!record.editable">
				</div>
				<div class="column background-page" [style.width]="140 + 'px'">
					<label *ngIf="currentSpad.spadType === FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION"
						nz-checkbox [(ngModel)]="showD1">显示偏差</label>
				</div>
			</div>
			<div class="row">
				<div class="column background-module" [style.width]="40 + 'px'" [style.height]="20 + 'px'">
					行列
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					1
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					2
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					3
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					4
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					5
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					6
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					7
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					8
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					9
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					10
				</div>
			</div>
			<div class="row real1" *ngFor="let valrow of realVal1List; let rowIdx = index;">
				<div class="column background-module" [style.width]="40 + 'px'">
					{{rowIdx + 1}}
				</div>
				<div class="column background-module" *ngFor="let item of valrow; let colIdx = index;"
					[style.width]="70 + 'px'">
					<input *ngIf="!isZS" class="center {{item.hasError ? 'color-danger' : 'color-primary'}}" type="text"
						[(ngModel)]="item.value" (ngModelChange)="onReal1Change(rowIdx, colIdx)"
						[style.height]="showD1 ? '50%' : '100%'" (blur)="onReal1Blur(rowIdx, colIdx)"
						(keydown)="onRealInputKeyDown($event, 'real1' , rowIdx, colIdx)" [disabled]="!record.editable">
					<select *ngIf="isZS" class="center {{item.hasError ? 'color-danger' : 'color-primary'}}"
						[(ngModel)]="item.value" (ngModelChange)="onReal1Blur(rowIdx, colIdx)"
						[disabled]="!record.editable">
						<option value="undefined">请选择</option>
						<option [value]="item.value" *ngFor="let item of ZSV">{{item.label}}</option>
					</select>
					<div class="cell center background-module {{item.hasError ? 'color-danger' : ''}}" *ngIf="showD1">
						{{item.value !== undefined && item.value !== '' && record.designValue1 !== undefined &&
						record.designValue1 !== '' ? ((+item.value > +record.designValue1) ? '+' : '' ) + (+item.value -
						+record.designValue1).toFixed(precision) : ''}}
					</div>
				</div>
			</div>
		</div>

		<div class="grid" *ngIf="!!record.designValue2">
			<div class="row">
				<div class="column background-page" [style.width]="320 + 'px'">
					实测值 {{!record.designValue2 ? '' : '(设计值=' + record.designValue2 + ')'}}
				</div>
				<div class="column background-page" [style.width]="140 + 'px'">
					平均值：{{showExp ? '' : isZS ? '-' : avg2}}
				</div>
				<div class="column background-page text-right" [style.width]="70 + 'px'">
					代表值：
				</div>
				<div class="column background-page" [style.width]="70 + 'px'">
					<input class="color-primary" type="text" [(ngModel)]="exp2" (blur)="onExpChange()">
				</div>
				<div class="column background-page" [style.width]="140 + 'px'">
					<label *ngIf="currentSpad.spadType === FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION"
						nz-checkbox [(ngModel)]="showD2">显示偏差</label>
				</div>
			</div>
			<div class="row">
				<div class="column background-module" [style.width]="40 + 'px'" [style.height]="20 + 'px'">
					行列
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					1
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					2
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					3
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					4
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					5
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					6
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					7
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					8
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					9
				</div>
				<div class="column background-module" [style.width]="70 + 'px'" [style.height]="20 + 'px'">
					10
				</div>
			</div>
			<div class="row real2" *ngFor="let valrow of realVal2List; let rowIdx = index;">
				<div class="column background-module" [style.width]="40 + 'px'">
					{{rowIdx + 1}}
				</div>
				<div class="column background-module" *ngFor="let item of valrow; let colIdx = index;"
					[style.width]="70 + 'px'">
					<input *ngIf="!isZS" class="center {{item.hasError ? 'color-danger' : 'color-primary'}}" type="text"
						[(ngModel)]="item.value" (ngModelChange)="onReal2Change(rowIdx, colIdx)"
						[style.height]="showD2 ? '50%' : '100%'" (blur)="onReal2Blur(rowIdx, colIdx)"
						(keydown)="onRealInputKeyDown($event, 'real2', rowIdx, colIdx)" [disabled]="!record.editable">
					<select *ngIf="isZS" class="center {{item.hasError ? 'color-danger' : 'color-primary'}}"
						[(ngModel)]="item.value" (ngModelChange)="onReal2Blur(rowIdx, colIdx)"
						[disabled]="!record.editable">
						<option value="undefined">请选择</option>
						<option [value]="item.value" *ngFor="let item of ZSV">{{item.label}}</option>
					</select>
					<div class="cell center background-module {{item.hasError ? 'color-danger' : ''}}" *ngIf="showD2">
						{{item.value !== undefined && item.value !== '' && record.designValue2 !== undefined &&
						record.designValue2 !== '' ? ((+item.value > +record.designValue2) ? '+' : '' ) + (+item.value -
						+record.designValue2).toFixed(precision) : ''}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-footer">
		<button nz-button nzType="default" (click)="cancel()">取消</button>
		<span class="color-light" *ngIf="record.editable">实测值（个数：{{totalCount}}；合格率：{{passRate}}%）</span>
		<span class="color-danger" *ngIf="!record.editable">{{record.message}}</span>
		<button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
	</div>
</div>