import { Component, Input } from '@angular/core';
import { DialogConfig } from '../../components/alert-dialog/dialog-config';
import { FimpAverageTypeType } from '../../models/form/fimp-average-type-type.enum';
import {
  FimpMeasuredValueFormat,
  FimpMeasuredValueFormatOptions,
} from '../../models/form/fimp-measured-value-format.enum';
import { FormItemMeasuredProjectInfo } from '../../models/form/form-item-measured-project-info.model';
import {
  FormItemMeasuredProjectRefParamType,
  FormItemMeasuredProjectRefParamTypeOptions,
} from '../../models/form/form-item-measured-project-ref-param-type.enum';
import {
  FormItemMeasuredProjectSpadType,
  FormItemMeasuredProjectSpadTypeOptions,
} from '../../models/form/form-item-measured-project-spad-type.enum';
import { WorkReportMeasuredValueInfo } from '../../models/form/work-report-measured-value-info.model';
import {
  DetectionGroupInfo,
  DetectionMeasureInfo,
} from '../dialog-form-zj-fill/dialog-form-zj-fill.component';

@Component({
  selector: 'app-dialog-form-js604-fill',
  templateUrl: './dialog-form-js604-fill.component.html',
  styleUrls: ['./dialog-form-js604-fill.component.scss'],
})
export class DialogFormJs604FillComponent {
  @Input()
  data!: DialogConfig<FormItemMeasuredProjectInfo>;

  editing: { [name: string]: boolean } = {};

  FormItemMeasuredProjectSpadType = FormItemMeasuredProjectSpadType;

  record: FormItemMeasuredProjectInfo = new FormItemMeasuredProjectInfo();

  FormItemMeasuredProjectSpadTypeOptions =
    FormItemMeasuredProjectSpadTypeOptions;

  FormItemMeasuredProjectRefParamTypeOptions =
    FormItemMeasuredProjectRefParamTypeOptions;

  FimpMeasuredValueFormatOptions = FimpMeasuredValueFormatOptions;

  FimpMeasuredValueFormat = FimpMeasuredValueFormat;

  descList: string[] = [];

  detectionInfoList: DetectionGroupInfo[] = [];

  isMac = /macintosh|mac os x/i.test(navigator.userAgent);

  selectIndex?: number;

  FormItemMeasuredProjectRefParamType = FormItemMeasuredProjectRefParamType;

  workReportMeasuredValueInfos: WorkReportMeasuredValueInfo[] = [];

  detectionMeasureInfo = new DetectionMeasureInfo();

  constructor() {}

  clearReport() {
    this.record.refReportFileId = '';
    this.record.refReportName = '';
  }

  ngOnInit() {
    this.handleDataBeforeInit(this.data.data!);
  }

  measuredValueFormatChange() {
    this.clearReport();
    this.detectionInfoList = [];
    this.workReportMeasuredValueInfos = [];
    this.addGroup();
    this.record.refWorkReportId = undefined;
    this.detectionMeasureInfo = new DetectionMeasureInfo();
  }

  addGroup() {
    if (!this.record.editAble) return;
    const group = new DetectionGroupInfo();
    this.detectionInfoList.push(group);
    group.realValDisplayList = this.parseRealData('');
  }

  // 再次进入编辑页面后，做一次实测数据的解析。将实测数据放到对应的位置，并且绘制表格
  private parseRealData(
    measuredValue: string
  ): { value: string; hasError: boolean }[][] {
    let realValList: { value: string; hasError: boolean }[][] = [[]];
    if (!!measuredValue) {
      // const measuredValues = measuredValue
      //   .replaceAll('Ⅰ类', '-1')
      //   .replaceAll('Ⅱ类', '-2')
      //   .replaceAll('Ⅲ类', '-3');
      let mList: string[] = [];
      mList = measuredValue
        .replaceAll('Ⅰ类', '-1')
        .replaceAll('Ⅱ类', '-2')
        .replaceAll('Ⅲ类', '-3')
        .split(' ');
      let rowCount = Math.ceil(mList.length / 10);
      if (rowCount < 1) {
        rowCount = 1;
      }
      if (mList.length % 10 === 0) {
        rowCount++;
      }
      for (let row = 0; row < rowCount; row++) {
        realValList[row] = [];
        for (let col = 0; col < 10; col++) {
          realValList[row][col] = {
            value: mList[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
    } else {
      for (let col = 0; col < 10; col++) {
        realValList[0][col] = {
          value: '',
          hasError: false,
        };
      }
    }
    return realValList;
  }

  clearGroup(index: number) {
    if (!this.record.editAble) return;
    this.detectionInfoList[index].part = '';
    this.detectionInfoList[index].realValTxt = '';
    this.detectionInfoList[index].biasValTxt = '';
    this.detectionInfoList[index].designValue = '';
    this.detectionInfoList[index].avg = '';
    this.detectionInfoList[index].exp = '';
    this.detectionInfoList[index].realValDisplayList = this.parseRealData('');
  }

  removeGroup(index: number) {
    if (!this.record.editAble) return;
    this.detectionInfoList.splice(index, 1);
    if (this.detectionInfoList.length === 1) {
      this.detectionInfoList[0].part = '';
    }
  }

  // 实测数据的输入框发生变化时，重新进行全表合格率计算、数量统计
  onRealBlur(groupIndex: number, rowIdx: number, colIdx: number) {
    const val =
      this.detectionInfoList[groupIndex].realValDisplayList[rowIdx][colIdx]
        .value;
    if (val !== '' && this.isNaN(+val)) {
      this.detectionInfoList[groupIndex].realValDisplayList[rowIdx][
        colIdx
      ].value = '';
    }
  }

  // 实测数据的输入框发生变化时，重新进行全表合格率计算、数量统计
  // onReal2Blur(rowIdx: number, colIdx: number) {
  //   const val = this.realVal2List[rowIdx][colIdx].value;
  //   if (val !== '' && this.isNaN(+val)) {
  //     this.realVal2List[rowIdx][colIdx].value = '';
  //   }
  //   this.calcAll();
  // }

  // 实测数据的输入框发生变化时，判断是否需要新增一行
  onRealChange(groupIndex: number, rowIdx: number, colIdx: number) {
    // 这里判断是否需要新增一行。针对数据处理、合格率计算放到了onBlur中
    const val =
      this.detectionInfoList[groupIndex].realValDisplayList[rowIdx][colIdx]
        .value;
    if (val === '') {
      return;
    }
    if (
      rowIdx ===
        this.detectionInfoList[groupIndex].realValDisplayList.length - 1 &&
      colIdx === 9
    ) {
      this.detectionInfoList[groupIndex].realValDisplayList.push([]);
      for (let col = 0; col < 10; col++) {
        this.detectionInfoList[groupIndex].realValDisplayList[rowIdx + 1][col] =
          {
            value: '',
            hasError: false,
          };
      }
    }
  }

  // 实测数据的输入框发生变化时，判断是否需要新增一行
  // onReal2Change(rowIdx: number, colIdx: number) {
  //   // 这里判断是否需要新增一行。针对数据处理、合格率计算放到了onBlur中
  //   const val = this.realVal2List[rowIdx][colIdx].value;
  //   if (val === '') {
  //     return;
  //   }
  //   if (rowIdx === this.realVal2List.length - 1 && colIdx === 9) {
  //     this.realVal2List.push([]);
  //     for (let col = 0; col < 10; col++) {
  //       this.realVal2List[rowIdx + 1][col] = {
  //         value: '',
  //         hasError: false,
  //       };
  //     }
  //   }
  // }

  // 在输入框中按下上下左右箭头，实现焦点的移动。在输入框中按下ctrl+v，实现粘贴
  onRealInputKeyDown(
    event: KeyboardEvent,
    groupIndex: number,
    rowIdx: number,
    colIdx: number
  ) {
    const inputElements = document.querySelectorAll(`.real${groupIndex} input`);
    if (event.key === 'ArrowDown') {
      if (
        rowIdx <
        this.detectionInfoList[groupIndex].realValDisplayList.length - 1
      ) {
        // 向下1行
        (inputElements[rowIdx * 10 + colIdx + 10] as HTMLElement).focus();
      } else {
        // 回到第1行
        (inputElements[colIdx] as HTMLElement).focus();
      }
      return;
    }
    if (event.key === 'ArrowUp') {
      if (rowIdx > 0) {
        // 向上1行
        (inputElements[rowIdx * 10 + colIdx - 10] as HTMLElement).focus();
      } else {
        // 回到最后1行
        (
          inputElements[
            this.detectionInfoList[groupIndex].realValDisplayList.length * 10 -
              10 +
              colIdx
          ] as HTMLElement
        ).focus();
      }
      return;
    }
    if (event.key === 'ArrowLeft') {
      if (colIdx > 0) {
        // 向左1列
        (inputElements[rowIdx * 10 + colIdx - 1] as HTMLElement).focus();
      } else {
        // 回到上1行最后1列
        if (rowIdx > 0) {
          (inputElements[(rowIdx - 1) * 10 + 9] as HTMLElement).focus();
        } else {
          // 回到最后1行最后1列
          (
            inputElements[
              this.detectionInfoList[groupIndex].realValDisplayList.length *
                10 -
                1
            ] as HTMLElement
          ).focus();
        }
      }
      return;
    }
    if (event.key === 'ArrowRight') {
      if (colIdx < 9) {
        // 向右1列
        (inputElements[rowIdx * 10 + colIdx + 1] as HTMLElement).focus();
      } else {
        // 回到下1行第1列
        if (
          rowIdx <
          this.detectionInfoList[groupIndex].realValDisplayList.length - 1
        ) {
          (inputElements[(rowIdx + 1) * 10] as HTMLElement).focus();
        } else {
          // 回到第1行第1列
          (inputElements[0] as HTMLElement).focus();
        }
      }
      return;
    }
    if (
      (event.key.toLowerCase() === 'v' && event.ctrlKey && !this.isMac) ||
      (event.key.toLowerCase() === 'v' && event.metaKey && this.isMac)
    ) {
      setTimeout(() => {
        const source = this.detectionInfoList[groupIndex].realValDisplayList;
        if (
          source[rowIdx][colIdx].value.includes('\n') ||
          source[rowIdx][colIdx].value.includes('\t') ||
          source[rowIdx][colIdx].value.includes(' ')
        ) {
          this.detectionInfoList[groupIndex].realValDisplayList =
            this.onRealPaste(
              JSON.parse(JSON.stringify(source)),
              rowIdx,
              colIdx
            );
        }
      }, 100);
      return;
    }
  }

  // 对复制粘贴的值进行处理。特别处理了来自excel的粘贴
  onRealPaste(
    source: { value: string; hasError: boolean }[][],
    rowIdx: number,
    colIdx: number
  ) {
    let realValList = `${source[rowIdx][colIdx].value}`
      .toLowerCase()
      .replace(/mpa/g, '')
      .replace(/\n/g, ' ')
      .replace(/\t/g, ' ')
      .split(' ');

    let oldVals: string[] = [];
    for (let i = 0; i <= rowIdx; i++) {
      for (let j = 0; j < (i < rowIdx ? 10 : colIdx); j++) {
        oldVals.push(source[i][j].value);
      }
    }

    realValList = [...oldVals, ...realValList].filter(
      (v) => v === '' || (v !== '' && !isNaN(+v))
    );

    let rowCount = Math.ceil(realValList.length / 10);
    if (rowCount < 1) {
      rowCount = 1;
    }
    if (realValList.length % 10 === 0) {
      rowCount++;
    }

    let target: { value: string; hasError: boolean }[][] = [];
    for (let row = 0; row < rowCount; row++) {
      target[row] = [];
      for (let col = 0; col < 10; col++) {
        target[row][col] = {
          value: realValList[row * 10 + col] ?? '',
          hasError: false,
        };
      }
    }
    if (target[target.length - 1][9].value !== '') {
      target.push([]);
      for (let col = 0; col < 10; col++) {
        target[target.length - 1][col] = {
          value: '',
          hasError: false,
        };
      }
    }

    return target;
  }

  private handleDataBeforeInit(response: FormItemMeasuredProjectInfo) {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProjectInfo;

    if (!data.measuredValueFormat) {
      data.measuredValueFormat = FimpMeasuredValueFormat.NUMERIC;
    }

    const detectionInfoList: DetectionGroupInfo[] = [];
    if (data.measuredValue) {
      try {
        const designValues = data.measuredValue.split('\n');
        for (let i = 0; i < designValues.length; i++) {
          detectionInfoList[i] = new DetectionGroupInfo();
        }
      } catch (e) {}
    }

    if (detectionInfoList.length === 0) {
      detectionInfoList.push(new DetectionGroupInfo());
    }

    if (data.designValue) {
      try {
        const designValues = data.designValue.replace(' ', '\n').split('\n');
        for (let i = 0; i < designValues.length; i++) {
          detectionInfoList[i].designValue = designValues[i] ?? '';
        }
      } catch (e) {}
    }

    const averageValues = (data.averageValue ?? '')
      .replace(' ', '\n')
      .split('\n');
    for (let i = 0; i < averageValues.length; i++) {
      if (data.averageValueType === FimpAverageTypeType.AVERAGE_VALUE) {
        detectionInfoList[i].avg = averageValues[i] ?? '';
      } else {
        detectionInfoList[i].exp = averageValues[i] ?? '';
      }
    }

    const measuredValues = (data.measuredValue ?? '').split('\n');
    for (let i = 0; i < measuredValues.length; i++) {
      if (data.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
        detectionInfoList[i].realValDisplayList = this.parseRealData(
          measuredValues[i]
        );
      } else {
        detectionInfoList[i].realValTxt = measuredValues[i] ?? '';
      }
    }
    if (data.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT) {
      const deviationValues = (data.deviationValue ?? '').split('\n');
      for (let i = 0; i < deviationValues.length; i++) {
        detectionInfoList[i].biasValTxt = deviationValues[i] ?? '';
      }
    }

    const partValues = (data.partValue ?? '').split('\n');
    for (let i = 0; i < partValues.length; i++) {
      detectionInfoList[i].part = partValues[i] ?? '';
    }

    const quantitySpec = (data.quantitySpec ?? '').split('\n');
    for (let i = 0; i < quantitySpec.length; i++) {
      detectionInfoList[i].spec1 = quantitySpec[i].split('/Φ')[1] ?? '';
      detectionInfoList[i].spec2 = quantitySpec[i].split('/Φ')[0] ?? '';
    }

    this.detectionInfoList = detectionInfoList;
    this.record = data;
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    setTimeout(() => {
      this.submit();
    }, 100);
  }

  private handleDataBeforeConfirm(
    response: FormItemMeasuredProjectInfo
  ): FormItemMeasuredProjectInfo {
    console.log('before');
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProjectInfo;
    data.designValue = this.detectionInfoList
      .map((item) => item.designValue ?? '')
      .join('\n');

    const groupRealValues: string[] = [];
    const groupDevValues: string[] = [];
    const groupAvgValues: string[] = [];
    const groupExpValues: string[] = [];
    for (const info of this.detectionInfoList) {
      const realValue: string[] = [];
      const devValues: string[] = [];
      for (const row of info.realValDisplayList) {
        for (const cell of row) {
          if (cell.value !== '') {
            realValue.push(cell.value);
            devValues.push(cell.value);
          }
        }
      }
      groupRealValues.push(realValue.join(' '));
      groupDevValues.push(devValues.join(' '));
      groupAvgValues.push(info.avg ?? '');
      groupExpValues.push(info.exp ?? '');
    }
    data.measuredValue = groupRealValues.join('\n');
    data.deviationValue = groupDevValues.join('\n');
    data.partValue = this.detectionInfoList
      .map((item) => item.part ?? '')
      .join('\n');
    data.quantitySpec = this.detectionInfoList
      .map((item) => item.spec2 + '/Φ' + item.spec1)
      .join('\n');

    let remarkValue = this.detectionInfoList
      .map((item) => item.designValue ?? '')
      .join('\n');

    if (remarkValue.replaceAll('\n', '').length === 0) {
      remarkValue = '';
    }

    data.remarkValue = remarkValue;
    data.qaRate = '';
    data.refReportMerge = '';
    data.qualifiedJudge = '';

    if (groupExpValues.join('').length > 0) {
      data.averageValue = groupExpValues.join('\n');
      data.averageValueType = FimpAverageTypeType.TYPICAL_VALUE;
    } else {
      data.averageValue = groupAvgValues.join('\n');
      data.averageValueType = FimpAverageTypeType.AVERAGE_VALUE;
    }

    return data;
  }

  get canConfirm() {
    return this.record.editable;
  }

  private submit() {
    const data = this.handleDataBeforeConfirm(this.record);
    this.data.onConfirm!(data);
  }
}
