import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NzTreeNode } from 'src/app/modules/shared/models/tree-node.model';
import { FormFlowInfo } from '../../models/form/form-flow-info.model';
import { UserInfo } from '../../models/form/user-info.model';
import { User } from '../../models/staff/user.model';
import { GlobalService } from '../../providers/global.service';

@Component({
  selector: 'user-select-popover',
  templateUrl: './user-select-popover.component.html',
  styleUrls: ['./user-select-popover.component.scss'],
})
export class UserSelectPopoverComponent {
  @Input() required = false;

  @Input() disabled = false;

  @Input() editable = true;

  @Input() showTime = true;

  @ViewChild('myCascader') myCascader!: TemplateRef<any>;
  currentUser: User = new User();

  loginUser: User = this.globalService.userInfo;

  cascaderValue: string[] = [];

  autoFocus: boolean = true;

  userMap: { [key: string]: User } = {};

  init = false;

  @Input()
  set userList(value: User[]) {
    this._userList = value;
    this.userMap = {};
    for (const user of value) {
      this.userMap[`${user.postId}#${user.id}`] = user;
    }
  }

  get userList(): User[] {
    return this._userList;
  }

  _userList: User[] = [];

  @Output()
  userInfoChange: EventEmitter<UserInfo> = new EventEmitter<UserInfo>();

  @Input()
  set userInfo(value: UserInfo) {
    this._user = value;
    //  console.log('userInfo', value);
    // this.prepare();
  }

  get userInfo(): UserInfo {
    return this._user;
  }

  _user: UserInfo = new UserInfo();

  @Input()
  set flow(value: FormFlowInfo) {
    this._flow = value;
    //  console.log('flowInfo', value);
  }

  get flow(): FormFlowInfo {
    return this._flow;
  }

  _flow: FormFlowInfo = new FormFlowInfo();

  @Input()
  set archiveTreeNode(value: NzTreeNode[]) {
    this._archiveTreeNode = value;
    this.prepare();
  }

  get archiveTreeNode(): NzTreeNode[] {
    return this._archiveTreeNode;
  }

  _archiveTreeNode: NzTreeNode[] = [];
  postTreeNodeFull: NzTreeNode[] = [];

  constructor(private globalService: GlobalService) {
    // console.log('constructor');
    this.userInfo.auto = false;
  }

  ngOnInit(): void {}
  visibleChange(visible: boolean) {
    if (visible) {
      setTimeout(() => {
        this.myCascader.elementRef.nativeElement.click();
        setTimeout(() => {
          const elemenets = document.getElementsByClassName(
            'cdk-overlay-connected-position-bounding-box'
          );
          if (elemenets && elemenets.length > 0) {
            const element = elemenets[elemenets.length - 1];
            const childs = element.getElementsByClassName(
              'ant-cascader-menu-item-active'
            );
            if (childs && childs.length > 0 && childs.length < 4) {
              (childs[childs.length - 1] as any).click();
            }
          }
        }, 100);
      }, 100);
    }
  }
  private prepare() {
    if (this._archiveTreeNode.length === 0 || this._userList.length === 0) {
      return;
    }
    console.log('prepare');

    const node: NzTreeNode[] = [];
    // TODO 暂时注释，后面有用
    const orgFilter = `${this._flow.organizationType}#${
      this._flow.organizationId ?? ''
    }`;

    // let orgFilter = `${this._flow.organizationType}#`;
    // if (this.flow.postKey) {
    //   const ids = this.flow.postKey.split('#');
    //   if (+ids[1] === -99) {
    //     orgFilter = `${ids[0]}#`
    //   }
    // }
    for (const org of this._archiveTreeNode) {
      if (this._flow.organizationId && org.value === orgFilter) {
        node.push(org);
      } else if (`${org.value}`.includes(orgFilter)) {
        node.push(org);
      }
    }
    if (this.flow.organizationType) {
      this.postTreeNodeFull = node;
    } else {
      this.postTreeNodeFull = this.archiveTreeNode;
    }

    // TODO: 最好还是验证一下
    // TODO： 其他数据都准备好了。这里需要根据流程信息去匹配对应的岗位、部门、单位
    // TODO： 根据流程信息去匹配对应的角色
    // 逻辑如下：
    // 1. 如果存在UserInfo，则根据UserInfo去匹配对应的岗位、部门、单位
    // 2. 如果不存在UserInfo，判断存是否在OrgId，如果存在，仅从该OrgId下的人员中选择，否则从单位类型为OrgType的人员中选择
    //  console.log('postTreeNodeFull', this.postTreeNodeFull);
    if (!this.postTreeNodeFull || this.postTreeNodeFull.length === 0) {
      this.userInfo.orgLabel = '没找到单位';
      return;
    }
    if (this.userInfo && this.userInfo.userId) {
      // console.log(this.userInfo);

      if (!!this.userInfo.departmentName) {
        this.userInfo.nameLabel =
          this.userInfo.userName! +
          (this.userInfo.performance ? '[履约]' : '') +
          (this.userInfo.relatedSupervision ? '[对口专监]' : '') +
          (this.userInfo.supervision ? '[专监]' : '') +
          (this.userInfo.sealHolder ? '[持章人]' : '');
        this.userInfo.orgLabel =
          (this.userInfo.organizationShortName!
            ? this.userInfo.organizationShortName!
            : this.userInfo.organizationName!) +
          (this.userInfo.departmentName
            ? '\\' + this.userInfo.departmentName
            : '') +
          (this.userInfo.postName
            ? '\\' +
              (this.userInfo.postPerformance
                ? this.userInfo.postName + '[履约]'
                : this.userInfo.postName)
            : '');
      } else {
        const user =
          this.userMap[`${this.userInfo.postId}#${this.userInfo.userId}`];
        if (user && user.id) {
          this.chooseUser(user, user.departmentId);
        } else {
          console.log('用户列表中暂无此人信息');
        }
      }

      if (!this.userInfo.operateTime && this.userInfo.createTime!) {
        const currentTime = Date.now();
        const hours =
          (currentTime - this.userInfo.createTime!) / (1000 * 60 * 60);
        const day = Math.floor(hours / 24);
        if (day > 0) {
          const hour = hours % 24;
          this.userInfo.retention =
            '滞留 ' + day + '天' + hour.toFixed(0) + '小时';
        } else {
          const hour = Math.floor(hours);
          const minutes = ((hours - hour) * 60).toFixed(0);
          this.userInfo.retention = '滞留 ' + hour + '小时' + minutes + '分';
        }
      }
      this.cascaderValue = [
        `${this.userInfo.organizationType}#${this.userInfo.organizationId}`,
        `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}`,
        `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}`,
        `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}#${this.userInfo.userId}`,
      ];
    } else {
      if (this.userInfo.auto) {
        if (!this.userInfo.userId) {
          this.cascaderValue = [];
        }
        return;
      }
      if (this.flow.departmentId && +this.flow.departmentId === -99) {
        if (this.loginUser.organizationType === this.flow.organizationType) {
          for (const org of this.postTreeNodeFull) {
            // const org = this.postTreeNodeFull[0];
            const ids = org.value.split('#');
            let orgId = ids[ids.length - 1];
            if (+orgId === +this.loginUser.organizationId!) {
              this.chooseUser(this.loginUser, this.loginUser.departmentId);
              this.cascaderValue = [
                `${this.userInfo.organizationType}#${this.userInfo.organizationId}`,
                `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}`,
                `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}`,
                `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}#${this.userInfo.userId}`,
              ];
              this.userInfoChange.emit(this.userInfo);
            }
          }
        }
        if (!this.userInfo.userId) {
          this.cascaderValue = [];
        }
        return;
      }
      const cascaderValue = [];
      let find = false;

      if (this.postTreeNodeFull.length === 1) {
        const org = this.postTreeNodeFull[0];
        const ids = org.value.split('#');
        let orgId = ids[ids.length - 1];
        cascaderValue.push(org.value);
        for (const depart of org.children!) {
          if (find) break;
          const ids = depart.value.split('#');
          const departId = ids[ids.length - 1];
          if (+this.flow.organizationId!) {
            for (const post of depart.children!) {
              const ids = post.value.split('#');
              const postId = ids[ids.length - 1];
              if (post.label === '文件签收人') {
                if (
                  post.children!.length === 1 &&
                  post.children![0].value !== '-'
                ) {
                  const user = post.children![0];
                  const ids = user.value.split('#');
                  const userId = ids[ids.length - 1];
                  this.chooseUser(
                    this.userMap[`${postId}#${userId}`],
                    departId
                  );
                  this.cascaderValue = [
                    `${this.userInfo.organizationType}#${this.userInfo.organizationId}`,
                    `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}`,
                    `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}`,
                    `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}#${this.userInfo.userId}`,
                  ];
                  this.userInfoChange.emit(this.userInfo);
                } else {
                  cascaderValue.push(depart.value);
                  cascaderValue.push(post.value);
                  this.cascaderValue = cascaderValue;

                  this.userInfo.organizationId = orgId;
                  this.userInfo.organizationName = org.label;
                  this.userInfo.organizationShortName = org.label;
                  this.userInfo.departmentId = departId;
                  this.userInfo.departmentName = depart.label;
                  this.userInfo.postId = postId;
                  this.userInfo.postName = post.label;
                  this.userInfo.orgLabel =
                    this.userInfo.organizationShortName! +
                    '\\' +
                    this.userInfo.departmentName! +
                    '\\' +
                    this.userInfo.postName!;
                }
                find = true;
              }
            }
          } else {
            if (+departId === +this.flow.departmentId!) {
              cascaderValue.push(depart.value);
              if (depart.children! && depart.children!.length > 0) {
                if (+this.flow.postId! > 0) {
                  for (const post of depart.children!) {
                    if (find) break;
                    const ids = post.value.split('#');
                    const postId = ids[ids.length - 1];

                    if (+postId === +this.flow.postId!) {
                      if (
                        post.children!.length === 1 &&
                        post.children![0].value !== '-'
                      ) {
                        const user = post.children![0];
                        const ids = user.value.split('#');
                        const userId = ids[ids.length - 1];
                        this.chooseUser(
                          this.userMap[`${postId}#${userId}`],
                          departId
                        );
                        this.cascaderValue = [
                          `${this.userInfo.organizationType}#${this.userInfo.organizationId}`,
                          `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}`,
                          `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}`,
                          `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}#${this.userInfo.userId}`,
                        ];
                        this.userInfoChange.emit(this.userInfo);
                      } else {
                        cascaderValue.push(post.value);
                        this.cascaderValue = cascaderValue;
                        this.userInfo.organizationId = orgId;
                        this.userInfo.organizationName = org.label;
                        this.userInfo.organizationShortName = org.label;
                        this.userInfo.departmentId = departId;
                        this.userInfo.departmentName = depart.label;
                        this.userInfo.postId = postId;
                        this.userInfo.postName = post.label;
                        this.userInfo.orgLabel =
                          this.userInfo.organizationShortName! +
                          '\\' +
                          this.userInfo.departmentName! +
                          '\\' +
                          this.userInfo.postName!;
                      }
                      find = true;
                    }
                  }
                } else {
                  this.cascaderValue = cascaderValue;
                  this.userInfo.organizationId = orgId;
                  this.userInfo.organizationName = org.label;
                  this.userInfo.organizationShortName = org.label;
                  this.userInfo.departmentId = departId;
                  this.userInfo.departmentName = depart.label;
                  this.userInfo.orgLabel =
                    this.userInfo.organizationShortName! +
                    '\\' +
                    this.userInfo.departmentName!;
                }
                find = true;
              }
            }
          }
        }
        if (!find) {
          this.userInfo.organizationId = orgId;
          this.userInfo.organizationName = org.label;
          this.userInfo.organizationShortName = org.label;
          this.userInfo.orgLabel = this.userInfo.organizationShortName!;
        }
      } else {
        if (this.loginUser.organizationType !== this.flow.organizationType) {
          this.cascaderValue = [];
        } else {
          let isLy = false;
          let findPost = false;
          for (const depart of this.postTreeNodeFull[0].children!) {
            if (findPost) break;
            for (const post of depart.children!) {
              if (findPost) break;
              const ids = post.value.split('#');
              const postId = ids[ids.length - 1];
              if (+postId === +this.flow.postId!) {
                findPost = true;
                if (post.label!.indexOf('履约') > 0) {
                  isLy = true;
                }
                break;
              }
            }
          }
          if (isLy) {
            const hasLyOrgs = [];
            for (const org of this.postTreeNodeFull) {
              let isLogin = false;
              const ids = org.value.split('#');
              const orgId = ids[ids.length - 1];
              if (orgId === this.loginUser.organizationId) {
                isLogin = true;
              }
              let find = false;
              for (const depart of org.children!) {
                if (find) break;
                for (const post of depart.children!) {
                  if (find) break;
                  const ids = post.value.split('#');
                  const postId = ids[ids.length - 1];
                  if (+postId === +this.flow.postId!) {
                    if (post.children![0].value !== '-') {
                      const item = {
                        cas: [org, depart, post],
                        isLogin: isLogin,
                      };
                      hasLyOrgs.push(item);
                      find = true;
                      break;
                    }
                  }
                }
              }
            }
            switch (hasLyOrgs.length) {
              case 1:
                this.lyHandle(hasLyOrgs[0]);
                break;
              default:
                let find = false;
                for (const item of hasLyOrgs) {
                  if (find) break;
                  if (item.isLogin) {
                    find = true;
                    this.lyHandle(item);
                    break;
                  }
                }
                if (!find) {
                  this.cascaderValue = [];
                }
                break;
            }
          } else {
            this.normalHandle();
          }
        }
      }
    }
    // console.log('prepare');
    // TODO: 选完人员后，需要将用户信息传递出去
    // this.chooseUser(this.currentUser);
  }

  normalHandle() {
    let find = false;
    const cascaderValue = [];
    for (const org of this.postTreeNodeFull) {
      if (find) break;
      const ids = org.value.split('#');
      let orgId = ids[ids.length - 1];
      if (+orgId === +this.loginUser.organizationId!) {
        for (const depart of org.children!) {
          if (find) break;
          const ids = depart.value.split('#');
          const departId = ids[ids.length - 1];
          if (+departId === +this.flow.departmentId!) {
            cascaderValue.push(org.value);
            cascaderValue.push(depart.value);
            if (+this.flow.departmentId! === -98) {
              if (
                depart.children! &&
                depart.children!.length === 1 &&
                depart.children[0].value !== '-'
              ) {
                const user = depart.children![0];
                const ids = user.value.split('#');
                const postId = ids[ids.length - 2];
                const userId = ids[ids.length - 1];
                this.chooseUser(this.userMap[`${postId}#${userId}`], departId);
                this.cascaderValue = [
                  `${this.userInfo.organizationType}#${this.userInfo.organizationId}`,
                  `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}`,
                  `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}`,
                  `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}#${this.userInfo.userId}`,
                ];
                this.userInfoChange.emit(this.userInfo);
              } else {
                this.cascaderValue = cascaderValue;
                this.userInfo.organizationId = orgId;
                this.userInfo.organizationName = org.label;
                this.userInfo.organizationShortName = org.label;
                this.userInfo.departmentId = departId;
                this.userInfo.departmentName = depart.label;
                this.userInfo.orgLabel =
                  this.userInfo.organizationShortName! +
                  '\\' +
                  this.userInfo.departmentName!;
              }
              find = true;
            } else {
              if (depart.children! && depart.children!.length > 0) {
                if (+this.flow.postId! > 0) {
                  for (const post of depart.children!) {
                    if (find) break;
                    const ids = post.value.split('#');
                    const postId = ids[ids.length - 1];

                    if (+postId === +this.flow.postId!) {
                      if (
                        post.children!.length === 1 &&
                        !post.children![0].disabled
                      ) {
                        const user = post.children![0];
                        const ids = user.value.split('#');
                        const userId = ids[ids.length - 1];
                        this.chooseUser(
                          this.userMap[`${postId}#${userId}`],
                          departId
                        );
                        this.cascaderValue = [
                          `${this.userInfo.organizationType}#${this.userInfo.organizationId}`,
                          `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}`,
                          `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}`,
                          `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}#${this.userInfo.userId}`,
                        ];
                        this.userInfoChange.emit(this.userInfo);
                      } else {
                        cascaderValue.push(post.value);
                        this.cascaderValue = cascaderValue;
                        this.userInfo.organizationId = orgId;
                        this.userInfo.organizationName = org.label;
                        this.userInfo.organizationShortName = org.label;
                        this.userInfo.departmentId = departId;
                        this.userInfo.departmentName = depart.label;
                        this.userInfo.postId = postId;
                        this.userInfo.postName = post.label;
                        this.userInfo.orgLabel =
                          this.userInfo.organizationShortName! +
                          '\\' +
                          this.userInfo.departmentName! +
                          '\\' +
                          this.userInfo.postName!;
                      }
                      find = true;
                    }
                  }
                } else {
                  this.cascaderValue = cascaderValue;
                  this.userInfo.organizationId = orgId;
                  this.userInfo.organizationName = org.label;
                  this.userInfo.organizationShortName = org.label;
                  this.userInfo.departmentId = departId;
                  this.userInfo.departmentName = depart.label;
                  this.userInfo.orgLabel =
                    this.userInfo.organizationShortName! +
                    '\\' +
                    this.userInfo.departmentName!;
                }
                find = true;
              }
            }
          }
        }
      }
    }
  }

  lyHandle(item: any) {
    const cascaderValue = [];
    const org = item.cas[0];
    const depart = item.cas[1];
    const post = item.cas[2];
    const ids = post.value.split('#');
    const orgId = ids[1];
    const departId = ids[2];
    const postId = ids[ids.length - 1];
    cascaderValue.push(org);
    cascaderValue.push(depart);
    if (post.children!.length === 1 && post.children![0].value !== '-') {
      const user = post.children![0];
      const ids = user.value.split('#');
      const userId = ids[ids.length - 1];
      const departId = ids[ids.length - 2];
      this.chooseUser(this.userMap[`${postId}#${userId}`], departId);
      this.cascaderValue = [
        `${this.userInfo.organizationType}#${this.userInfo.organizationId}`,
        `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}`,
        `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}`,
        `${this.userInfo.organizationType}#${this.userInfo.organizationId}#${this.userInfo.departmentId}#${this.userInfo.postId}#${this.userInfo.userId}`,
      ];
      this.userInfoChange.emit(this.userInfo);
    } else {
      cascaderValue.push(post.value);
      this.cascaderValue = cascaderValue;
      this.userInfo.organizationId = orgId;
      this.userInfo.organizationName = org.label;
      this.userInfo.organizationShortName = org.label;
      this.userInfo.departmentId = departId;
      this.userInfo.departmentName = depart.label;
      this.userInfo.postId = postId;
      this.userInfo.postName = post.label;
      this.userInfo.orgLabel =
        this.userInfo.organizationShortName! +
        '\\' +
        this.userInfo.departmentName! +
        '\\' +
        this.userInfo.postName!;
    }
  }

  chooseUser(user: User, departId?: number) {
    if (!user) return;
    this.currentUser = user;
    const userInfo = new UserInfo();
    userInfo.userId = this.currentUser.id;
    userInfo.userName = this.currentUser.name;
    userInfo.organizationType = this.currentUser.organizationType;
    userInfo.organizationId = this.currentUser.organizationId;
    userInfo.organizationName = this.currentUser.organizationName;
    userInfo.organizationShortName = this.currentUser.organizationShortName;
    userInfo.departmentId = this.currentUser.departmentId;
    userInfo.departmentName = this.currentUser.departmentName;
    userInfo.postId = this.currentUser.postId;
    userInfo.postName = this.currentUser.postName;
    userInfo.performance = this.currentUser.performance;
    userInfo.postPerformance = this.currentUser.postPerformance;
    // -98 印章持有人
    userInfo.sealHolder = departId === -98;
    // -96 专监
    userInfo.supervision = departId === -96;
    // -95 对口专监
    userInfo.relatedSupervision = departId === -95;
    userInfo.nameLabel =
      userInfo.userName! +
      (userInfo.performance ? '[履约]' : '') +
      (userInfo.relatedSupervision ? '[对口专监]' : '') +
      (userInfo.supervision ? '[专监]' : '') +
      (userInfo.sealHolder ? '[持章人]' : '');
    userInfo.orgLabel =
      (userInfo.organizationShortName!
        ? userInfo.organizationShortName!
        : userInfo.organizationName!) +
      (userInfo.departmentName ? '\\' + userInfo.departmentName : '') +
      (userInfo.postName
        ? '\\' +
          (userInfo.postPerformance
            ? userInfo.postName + '[履约]'
            : userInfo.postName)
        : '');
    this.userInfo = userInfo;
  }

  onChanges(values: string[]): void {
    // this.visible = false;
    if (values.length === 0) {
      const userInfo = new UserInfo();
      userInfo.auto = true;
      this.userInfoChange.emit(userInfo);
      return;
    }
    const userValue = values[values.length - 1];
    if (userValue) {
      const ids = userValue.split('#');
      let find = false;
      for (const org of this.postTreeNodeFull) {
        if (find) return;
        if (ids.length === 2) {
          const orgId = ids[ids.length - 1];
          const orgIds = org.value.split('#');
          if (orgId === orgIds[1]) {
            const userInfo = new UserInfo();
            userInfo.organizationId = +orgId;
            userInfo.organizationName = org.label;
            userInfo.organizationShortName = org.label;
            userInfo.nameLabel = '请选择';
            userInfo.orgLabel = org.label;
            this.userInfo = userInfo;
            find = true;
          }
        } else if (ids.length === 3) {
          const orgId = ids[1];
          const departId = ids[2];
          const orgIds = org.value.split('#');
          if (orgId === orgIds[1]) {
            for (const depart of org.children!) {
              const departIds = depart.value.split('#');
              if (departId === departIds[2]) {
                const userInfo = new UserInfo();
                userInfo.organizationId = +orgId;
                userInfo.organizationName = org.label;
                userInfo.organizationShortName = org.label;
                userInfo.departmentId = +departId;
                userInfo.departmentName = depart.label;
                userInfo.nameLabel = '请选择';
                userInfo.orgLabel = org.label + '\\' + depart.label;
                this.userInfo = userInfo;
                find = true;
              }
            }
          }
        } else if (ids.length === 4) {
          const orgId = ids[1];
          const departId = ids[2];
          const postId = ids[3];
          const orgIds = org.value.split('#');
          if (orgId === orgIds[1]) {
            for (const depart of org.children!) {
              const departIds = depart.value.split('#');
              if (departId === departIds[2]) {
                for (const post of depart.children!) {
                  const postIds = post.value.split('#');
                  if (postId === postIds[3]) {
                    const userInfo = new UserInfo();
                    userInfo.organizationId = +orgId;
                    userInfo.organizationName = org.label;
                    userInfo.organizationShortName = org.label;
                    userInfo.departmentId = +departId;
                    userInfo.departmentName = depart.label;
                    userInfo.postId = +postId;
                    userInfo.postName = post.label;
                    userInfo.nameLabel = '请选择';
                    userInfo.orgLabel =
                      org.label + '\\' + depart.label + '\\' + post.label;
                    this.userInfo = userInfo;
                    find = true;
                  }
                }
              }
            }
          }
        }
      }
      if (ids.length === 5) {
        const departId = +ids[2];
        const userId = +ids[4];
        const postId = +ids[3];
        this.chooseUser(this.userMap[`${postId}#${userId}`], departId);
        // this.currentUser = this.userMap[`${postId}#${userId}`];
        // const userInfo = new UserInfo();
        // userInfo.userId = this.currentUser.id;
        // userInfo.userName = this.currentUser.name;
        // userInfo.organizationType = this.currentUser.organizationType;
        // userInfo.organizationId = this.currentUser.organizationId;
        // userInfo.organizationName = this.currentUser.organizationName;
        // userInfo.organizationShortName = this.currentUser.organizationShortName;
        // userInfo.departmentId = this.currentUser.departmentId;
        // userInfo.departmentName = this.currentUser.departmentName;
        // userInfo.postId = this.currentUser.postId;
        // userInfo.postName = this.currentUser.postName;
        // userInfo.performance = this.currentUser.performance;
        // userInfo.postPerformance = this.currentUser.postPerformance;
        //  -98 印章持有人
        // userInfo.sealHolder = departId === -98;
        //  -96 专监
        // userInfo.supervision = departId === -96;
        //  -95 对口专监
        // userInfo.relatedSupervision = departId === -95;
        // userInfo.nameLabel = userInfo.userName! + (userInfo.performance ? '[履约]' : '') + (userInfo.relatedSupervision ? '[对口专监]' : '') + (userInfo.supervision ? '[专监]' : '') + (userInfo.sealHolder ? '[持章人]' : '');
        // userInfo.orgLabel = (userInfo.organizationShortName! ? userInfo.organizationShortName! : userInfo.organizationName!) + (userInfo.departmentName ? ("\\" + userInfo.departmentName) : '') + (userInfo.postName ? ("\\" + (userInfo.postPerformance ? (userInfo.postName + '[履约]') : userInfo.postName)) : '');
        // this.userInfo = userInfo;
        // this.userInfo.editAble = true;
        if (departId > 0) {
          // TODO: 此处会导致用户数据的重新创建
          this.userInfoChange.emit(this.userInfo);
        } else {
          setTimeout(() => {
            this.cascaderValue = [
              `${this.currentUser.organizationType}#${this.currentUser.organizationId}`,
              `${this.currentUser.organizationType}#${this.currentUser.organizationId}#${this.currentUser.departmentId}`,
              `${this.currentUser.organizationType}#${this.currentUser.organizationId}#${this.currentUser.departmentId}#${this.currentUser.postId}`,
              `${this.currentUser.organizationType}#${this.currentUser.organizationId}#${this.currentUser.departmentId}#${this.currentUser.postId}#${this.currentUser.id}`,
            ];
            this.userInfoChange.emit(this.userInfo);
          }, 100);
        }
      }
    }
  }
}
