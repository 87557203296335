import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuAudit } from 'src/app/modules/shared/models/menu/menu-audit.model';
import { MenuType } from 'src/app/modules/shared/models/menu/menu-type.enum';
import { Menu } from 'src/app/modules/shared/models/menu/menu.model';
import { ProjectItemType } from 'src/app/modules/shared/models/project/project-item-type.enum';
import { ProjectSectionItem } from 'src/app/modules/shared/models/project/project-section-item.model';
import { User } from 'src/app/modules/shared/models/staff/user.model';
import {
  EventService,
  EventType,
} from 'src/app/modules/shared/providers/event.service';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { OmMenuService } from 'src/app/modules/shared/services/om-menu.service';
import { ProjectSectionItemService } from 'src/app/modules/shared/services/project-section-item.service';
import { OrganizationTypeMap } from './../../../shared/models/staff/organization-type.enum';
import { OmUserPersonService } from './../../../shared/services/om-user-person.service';
import { StaffPostService } from './../../../shared/services/staff-post.service';

import { zip } from 'rxjs';
import { WorkFlowUserTaskSummary } from 'src/app/modules/shared/models/form/work-flow-user-task-summary.model';
import { PlatUser } from 'src/app/modules/shared/models/staff/plat-user.model';
import { Post } from 'src/app/modules/shared/models/staff/post.model';
import { StaffOrganizationService } from 'src/app/modules/shared/services/staff-organization.service';
import { StaffUserService } from 'src/app/modules/shared/services/staff-user.service';
import { WorkFlowUserService } from 'src/app/modules/shared/services/work-flow-user.service';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  subsciption = () => this.getSummary();

  isCollapsed = false;

  openIndex = -1;

  userInfo?: User;

  postInfo?: Post;

  auditUrlMap: { [key: string]: MenuAudit } = {};

  fullMenuList: {
    title: string;
    icon?: string;
    open: boolean;
    children: { title: string; url: string }[];
  }[] = [];

  count = 0;

  OrganizationTypeMap = OrganizationTypeMap;

  mit = '';

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private events: EventService,
    private omMenuService: OmMenuService,
    private projectSectionItemService: ProjectSectionItemService,
    private staffOrganizationService: StaffOrganizationService,
    private staffPostService: StaffPostService,
    private staffUserService: StaffUserService,
    private omUserPersonService: OmUserPersonService,
    private workFlowUserService: WorkFlowUserService
  ) {
    this.userInfo = this.globalService.userInfo;
    this.getPostInfo();
    this.getUserInfo();
    this.mit = this.globalService.mit;
  }

  get orgInfo() {
    return this.globalService.orgInfo;
  }

  ngOnDestroy(): void {
    this.events.destroyListener(EventType.SummaryChange, this.subsciption);
  }

  getSummary() {
    this.workFlowUserService
      .getTaskSummary()
      .subscribe((res: WorkFlowUserTaskSummary[]) => {
        this.count = 0;
        for (const item of res) {
          this.count += !!item.waitNum ? +item.waitNum : 0;
        }
      });
  }

  private getPostInfo() {
    if (this.userInfo?.postId) {
      this.staffPostService.findById(this.userInfo.postId).subscribe((res) => {
        this.postInfo = res;
      });
    }
    this.staffPostService
      .findAll({
        type: null,
      })
      .subscribe((res) => {
        console.log('take post');
        const postMap: { [key: string]: Post } = {};
        for (const post of res ?? []) {
          postMap[post.id!] = post;
        }
        let hasBeam = false;
        if (
          this.globalService.userInfo.departmentName?.includes('梁场') ||
          this.globalService.userInfo.departmentName2?.includes('梁场')
        ) {
          hasBeam = true;
        }
        for (const post of this.userInfo?.otherPosts ?? []) {
          if (
            !!postMap[post.postId!] &&
            postMap[post.postId!].departmentName?.includes('梁场')
          ) {
            hasBeam = true;
            break;
          }
        }
        this.globalService.hasBeam = hasBeam;
      });
  }

  private getUserInfo() {
    zip(
      this.omUserPersonService.getPlatUser(),
      this.staffUserService.findById(this.globalService.userInfo.userId!)
    ).subscribe((responses: [PlatUser, User]) => {
      this.globalService.userInfo = Object.assign(responses[0], responses[1]);
    });
  }

  onOpen(index: number) {
    this.openIndex = index;
  }

  private prepareMenu() {
    this.omMenuService.getUserMenus().subscribe((res: Menu[]) => {
      const newVer = Md5.hashStr(JSON.stringify(res));
      if (this.globalService.auditVersion !== newVer) {
        const auditMenuMap: { [key: string]: MenuAudit } = {};
        const menuList: {
          title: string;
          icon?: string;
          open: boolean;
          children: { title: string; url: string }[];
        }[] = [];
        for (const menu of res) {
          const parent: {
            title: string;
            icon?: string;
            open: boolean;
            children: { title: string; url: string }[];
          } = {
            title: menu.name!,
            icon: menu.icon,
            open: false,
            children: [],
          };
          for (const child of menu.child ?? []) {
            const url =
              child.type === MenuType.FORM
                ? `${child.url}/list/${child.id}`
                : child.url!;
            parent.children.push({
              title: child.name!,
              url,
            });
            if (child.menuAudits) {
              for (const audit of child.menuAudits) {
                auditMenuMap[audit.menuId!] = audit;
                this.auditUrlMap[url] = audit;
              }
            }
          }
          menuList.push(parent);
        }
        this.globalService.auditVersion = newVer;
        this.globalService.menuIdAuditsMap = auditMenuMap;
        this.globalService.urlAuditMap = this.auditUrlMap;
        this.globalService.menuList = menuList;
        this.fullMenuList = menuList;
      } else {
        this.auditUrlMap = this.globalService.urlAuditMap!;
        this.fullMenuList = this.globalService.menuList;
      }
      this.events.broadcast(EventType.AuditChange);
    });
  }

  ngOnInit() {
    this.prepareMenu();
    this.prepareWbsTree();
    this.events.on(EventType.SummaryChange, this.subsciption);
    this.getSummary();
  }

  resetSearchConfig(url: string) {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      window.open(url, '_blank');
      return;
    }
    this.globalService.resetSearchConfig();
  }

  private prepareWbsTree() {
    this.staffOrganizationService
      .findById(this.globalService.userInfo.organizationId!)
      .subscribe((response) => {
        this.globalService.orgInfo = response;
        this.projectSectionItemService
          .tree({
            projectId: this.globalService.projectId,
            type: ProjectItemType.SECTION,
          })
          .subscribe((response: ProjectSectionItem) => {
            const newVer = Md5.hashStr(JSON.stringify(response));
            if (this.globalService.wbsTreeRootVersion !== newVer) {
              this.globalService.wbsTreeRootVersion = newVer;
              this.globalService.setWbsTreeRoot(response);
              this.events.broadcast(EventType.WbsChangeEvent);
            }
          });
        this.projectSectionItemService
          .tree({
            projectId: this.globalService.projectId,
            type: ProjectItemType.FX,
          })
          .subscribe((response: ProjectSectionItem) => {
            const newVer = Md5.hashStr(JSON.stringify(response));
            if (this.globalService.wbsTitleTreeVersion !== newVer) {
              this.globalService.wbsTitleTreeVersion = newVer;
              this.globalService.setWbsTitleTree(response);
              this.events.broadcast(EventType.WbsTitleChangeEvent);
            }
          });
      });
  }

  getIconByName(name: string) {
    if (name === '工程施工') {
      return 'security-scan';
    }
    if (name === '工程办公') {
      return 'form';
    }
    if (name === '工程监理') {
      return 'alert';
    }

    return '';
  }

  getPrefixByName(name: string) {
    if (name === '工程施工') {
      return '/construction';
    }
    if (name === '工程办公') {
      return '/paperwork';
    }
    if (name === '工程监理') {
      return '/supervision';
    }

    return '/custom';
  }

  logout() {
    this.globalService.logout();
  }

  home() {
    this.router.navigateByUrl('/home');
  }

  homePage() {
    window.open('http://104.hzsksc.com:9191/homePage');
  }
}
