import { Component, Input } from '@angular/core';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';
import { Form } from 'src/app/modules/shared/models/form/form.model';
import { SubFormRouterListRequest } from 'src/app/modules/shared/models/form/sub-form-router-list-request.model';
import { SubFormRouter } from 'src/app/modules/shared/models/form/sub-form-router.model';
import {
  ProjectItemType,
  ProjectItemTypeMap,
} from 'src/app/modules/shared/models/project/project-item-type.enum';
import { ProjectSectionItem } from 'src/app/modules/shared/models/project/project-section-item.model';
import { ProjectSectionUnitListRequest } from 'src/app/modules/shared/models/project/project-section-unit-list-request.model';
import { ProjectSectionUnit } from 'src/app/modules/shared/models/project/project-section-unit.model';
import { NzTreeNode } from 'src/app/modules/shared/models/tree-node.model';
import {
  FormCodeWbsHistory,
  GlobalService,
} from 'src/app/modules/shared/providers/global.service';
import { ProjectSectionItemService } from 'src/app/modules/shared/services/project-section-item.service';
import { ProjectSubformService } from 'src/app/modules/shared/services/project-sub-form.service';
import { ProjectWbsService } from 'src/app/modules/shared/services/project-wbs.service';

@Component({
  selector: 'app-dialog-sub-form-choose',
  templateUrl: './dialog-sub-form-choose.component.html',
  styleUrls: ['./dialog-sub-form-choose.component.scss'],
})
export class DialogSubFormChooseComponent {
  @Input()
  data!: DialogConfig<DialogSubFormChooseData>;

  editing: { [name: string]: boolean } = {};

  unitCascaderValue: any[] = [];

  layerCascaderValue: any[] = [];

  type: ProjectItemType = ProjectItemType.SECTION;

  record!: DialogSubFormChooseData;

  subFormList: SubFormRouter[] = [];
  ProjectItemTypeMap = ProjectItemTypeMap;

  selecedIndex = -1;
  history: FormCodeWbsHistory[] = [];

  needSegment = false;
  layer = false;

  unitTreeNodes: NzTreeNode[] = [];

  onUnitCascaderValueChange(event: any[], isInit?: boolean) {
    this.unitCascaderValue = event;
    this.selecedIndex = -1;
    this.subFormList = [];
    if (!isInit) {
      this.layerCascaderValue = [];
    }
    if (this.unitCascaderValue.length > 0) {
      this.searchFormList(
        this.unitCascaderValue[this.unitCascaderValue.length - 1]
      );
    }
  }

  private searchFormList(sectionItemId: number) {
    this.projectSectionItemService
      .findById(sectionItemId)
      .subscribe((response: ProjectSectionItem) => {
        if (response) {
          console.log(response);
          const form = this.data.data!.form!;
          if (
            (form.code?.includes('GL128') ||
              form.code?.includes('GL127') ||
              form.code?.includes('JL040') ||
              form.code?.includes('JL041')) &&
            response.isLJ
          ) {
            this.needSegment = true;
            this.fetchSegement(response.projectTypeCode === '2001.001.001.002');
          }

          this.projectSubformService
            .findAll<SubFormRouterListRequest>({
              typeCode: response.projectTypeCode,
              typeFormCode: this.record!.formCode?.trim(),
              projectId: this.globalService.projectId,
            })
            .subscribe((response: SubFormRouter[]) => {
              this.subFormList = [];
              for (const item of response ?? []) {
                if (item.formId) {
                  this.subFormList.push(item);
                }
              }
              if (this.subFormList.length === 1) {
                this.selecedIndex = 0;
              }
            });
        }
      });
  }

  private fetchSegement(layer: boolean) {
    this.layer = layer;
    const searchRequest = new ProjectSectionUnitListRequest();
    searchRequest.projectId = this.globalService.projectId;
    searchRequest.sectionItemType = layer
      ? ProjectItemType.LAYER
      : ProjectItemType.SEGMENT;
    searchRequest.sectionId = this.unitCascaderValue[0];
    searchRequest.sectionSectionItemId =
      this.unitCascaderValue[this.unitCascaderValue.length - 1];

    this.projectWbsService
      .findAll(searchRequest)
      .subscribe((response: ProjectSectionUnit[]) => {
        const tree: NzTreeNode[] = [];
        const segments: { [key: string]: NzTreeNode } = {};
        response.sort((a, b) => {
          if (a.segmentCode === b.segmentCode) {
            if (a.code === b.code) {
              return 0;
            }
            if (!a.code) {
              return 1;
            }
            if (!b.code) {
              return -1;
            }
            return a.code!.localeCompare(b.code!);
          }
          return a.segmentCode!.localeCompare(b.segmentCode!);
        });
        for (const item of response) {
          if (!layer) {
            item.segmentCode = item.code;
          }
          if (!segments[item.segmentCode!]) {
            segments[item.segmentCode!] = {
              label: `${item.segmentCode}`,
              value: layer ? item.segmentCode : item.sectionItemId,
              children: layer ? [] : undefined,
              isLeaf: !layer,
            };
          }
          if (layer) {
            segments[item.segmentCode!].children!.push({
              label: item.code,
              value: item.sectionItemId,
              isLeaf: true,
            });
          }
        }
        for (const key in segments) {
          if (segments.hasOwnProperty(key)) {
            tree.push(segments[key]);
          }
        }
        if (layer) {
          for (const item of tree) {
            item.children = [item.children![item.children!.length - 1]];
          }
        }
        this.unitTreeNodes = tree;
      });
  }

  constructor(
    private projectSectionItemService: ProjectSectionItemService,
    private projectSubformService: ProjectSubformService,
    private projectWbsService: ProjectWbsService,
    private globalService: GlobalService
  ) {}

  private handleOldHistory() {
    const history = this.globalService.getFormCodeWbsHistory(
      this.data.data!.formCode!
    );
    if (!!history) {
      const newHistory: FormCodeWbsHistory[] = [];
      for (const item of history) {
        if (item.length === 0) {
          continue;
        }
        newHistory.push(item);
      }
      this.globalService.clearFormCodeWbsHistory(this.data.data!.formCode!);
      this.globalService.setFormCodeWbsHistoryV2(
        this.data.data!.formCode!,
        newHistory
      );
    }
  }

  ngOnInit() {
    this.handleOldHistory();
    if (this.data.data) {
      // this.type = this.data.data;
      this.record = this.data.data;
      if (this.record.sectionItemId) {
        this.searchFormList(this.record.sectionItemId);
      } else {
        this.history =
          this.globalService.getFormCodeWbsHistoryV2(
            this.data.data.formCode!
          ) ?? [];
      }
    }
  }

  chooseHistory(index: number) {
    const history = JSON.parse(
      JSON.stringify(this.history[index])
    ) as FormCodeWbsHistory;
    this.unitCascaderValue = history.unitCascaderValue ?? [];
    if (!!history.segmentCode || !!history.layerCode) {
      const v: any[] = [];
      if (history.segmentCode) {
        v.push(history.segmentCode);
      }
      if (history.layerCode) {
        v.push(history.layerCode);
      }
      this.layerCascaderValue = v;
    }
    this.selecedIndex = -1;
    this.subFormList = [];
    if (this.unitCascaderValue.length > 0) {
      this.searchFormList(
        this.unitCascaderValue[this.unitCascaderValue.length - 1]
      );
    }
  }

  onBlur() {
    this.editing = {};
  }

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.submit();
  }

  get canConfirm() {
    if (this.needSegment && this.layerCascaderValue.length === 0) {
      return false;
    }
    return (
      (this.unitCascaderValue.length > 0 || this.record.sectionItemId) &&
      this.selecedIndex > -1
    );
  }

  private submit() {
    const result: DialogSubFormChooseResult = {
      formId: this.subFormList[this.selecedIndex].formId,
    };
    if (this.record.sectionItemId) {
      result.sectionItemId = this.record.sectionItemId;
    } else if (this.unitCascaderValue) {
      result.sectionItemId =
        this.unitCascaderValue[this.unitCascaderValue.length - 1];
      result.sectionId = this.unitCascaderValue[0];

      if (this.needSegment) {
        result.sectionItemId =
          this.layerCascaderValue[this.layerCascaderValue.length - 1];
      }

      let contain = false;
      for (const item of this.history) {
        if (
          JSON.stringify(item.unitCascaderValue) ===
          JSON.stringify(this.unitCascaderValue)
        ) {
          if (this.needSegment) {
            contain =
              item.layerCode === this.layerCascaderValue[1] &&
              item.segmentCode === this.layerCascaderValue[0];
          } else {
            contain = true;
          }
        }
        if (contain) break;
      }
      if (!contain) {
        const history: FormCodeWbsHistory = {
          unitCascaderValue: this.unitCascaderValue,
        };
        if (this.needSegment) {
          history.segmentCode = this.layerCascaderValue[0];
          history.layerCode = this.layerCascaderValue[1];
        }
        this.history.push(history);
        this.history = this.history.slice(-5);
        this.globalService.setFormCodeWbsHistoryV2(
          this.data.data!.formCode!,
          this.history
        );
      }
    }
    this.data.onConfirm!(result);
  }
}

export class DialogSubFormChooseData {
  sectionItemId?: number;
  formCode?: string;
  form?: Form;
}

export class DialogSubFormChooseResult {
  sectionId?: number;
  sectionItemId?: number;
  formId?: number;
}
