import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormItemMeasuredProject } from '../models/form/form-item-measured-project.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormItemMeasuredProjectSpad } from '../models/form/form-item-measured-project-spad.model';

@Injectable({
  providedIn: 'root',
})
export class OmFormItemMeasuredProjectService extends RequestService<FormItemMeasuredProject> {
  static PATH = '/om/form/item/measuredProject';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(
      httpClient,
      OmFormItemMeasuredProjectService.PATH,
      globalService.debugUrl
    );
  }

  parseSpadExpression(
    expression: string
  ): Observable<FormItemMeasuredProjectSpad> {
    return this.httpClient.post<FormItemMeasuredProjectSpad>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${OmFormItemMeasuredProjectService.PATH}/parseSpadExpression`,
      {
        expression,
      }
    );
  }
}
