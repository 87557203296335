import { Component, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';
import { CheckSignAuthRequest } from 'src/app/modules/shared/models/staff/check-sign-auth-request.model';
import { SignAuthModeMap } from 'src/app/modules/shared/models/staff/sign-auth-mode.enum';
import { OmUserPersonService } from 'src/app/modules/shared/services/om-user-person.service';
import { OpenApiSignOrderService } from 'src/app/modules/shared/services/open-api-signorder.service';
@Component({
  selector: 'app-dialog-sign-pwd',
  templateUrl: './dialog-sign-pwd.component.html',
  styleUrls: ['./dialog-sign-pwd.component.scss'],
})
export class DialogSignPwdComponent {
  @Input() data!: DialogConfig<CheckSignAuthRequest>;

  SignAuthModeMap = SignAuthModeMap;
  m: string = '';
  title: string = '';
  countdown = 60;
  constructor(
    private omUserPersonService: OmUserPersonService,
    private openApiSignOrderService: OpenApiSignOrderService,
    private message: NzMessageService
  ) {}

  resend() {
    if (this.data.data?.token) {
      this.openApiSignOrderService
        .phoneSmsCodeResend(this.data.data.authFlowId!)
        .subscribe((res) => {
          this.startCountdown();
          if (res) {
            this.message.info('验证码已发送');
          } else {
            this.message.error('验证码发送失败');
          }
        });
    } else {
      this.omUserPersonService
        .phoneSmsCodeResend(this.data.data!.authFlowId!)
        .subscribe((res) => {
          this.startCountdown();
          if (res) {
            this.message.info('验证码已发送');
          } else {
            this.message.error('验证码发送失败');
          }
        });
    }
  }

  startCountdown() {
    this.countdown = 60;
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  ngOnInit() {
    this.title = '请输入验证码';
    this.startCountdown();
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    if (this.data.data?.token) {
      this.openApiSignOrderService
        .checkSignAuthInfo({
          signAuthMode: this.data.data?.signAuthMode,
          signPwd: this.m,
          token: this.data.data.token,
          authFlowId: this.data.data.authFlowId,
        })
        .subscribe((res) => {
          if (res) {
            this.data.onConfirm!();
          }
        });
    } else {
      this.omUserPersonService
        .checkSignAuthInfo({
          signAuthMode: this.data.data?.signAuthMode,
          signPwd: this.m,
          authFlowId: this.data.data?.authFlowId,
        })
        .subscribe((res) => {
          if (res) {
            this.data.onConfirm!();
          }
        });
    }
  }
}
