<div class="dialog">
    <div class="dialog-header">【{{record.keywords}}】- 实测值
    </div>
    <div class="dialog-body">

        <div class="grid" *ngFor="let info of detectionInfoList; let groupIndex = index;">
            <div class="row">
                <div class="column background-page" [style.width]="120 + 'px'">
                    实测值
                </div>
                <div class="column background-primary color-white clickable" [style.width]="40 + 'px'"
                    (click)="addGroup()">
                    +
                </div>
                <div class="column background-page color-grey" [style.width]="160 + 'px'"
                    *ngIf="detectionInfoList.length === 1">
                    多部位或多设计值按+
                </div>
                <div class="column background-page" [style.width]="60 + 'px'" *ngIf="detectionInfoList.length > 1">
                    对应部位
                </div>
                <div class="column background-page" [style.width]="100 + 'px'" *ngIf="detectionInfoList.length > 1">
                    <input class="color-primary" type="text" [placeholder]="'输入部位名称'" [(ngModel)]="info.part"
                        [disabled]="!record.editable">
                </div>
                <div class="column background-page text-right" [style.width]="100 + 'px'">
                    设计值(mm):
                </div>
                <div class="column background-page" [style.width]="100 + 'px'">
                    <input class="color-primary" type="text" [placeholder]="'输入设计值'" [(ngModel)]="info.designValue"
                        [disabled]="!record.editable">
                </div>
                <div class="column background-page text-right" [style.width]="100 + 'px'">
                    规格(直径Φ)：
                </div>
                <div class="column background-module" [style.width]="100 + 'px'">
                    <input class="color-primary" type="text" [placeholder]="'输入设计值'" [(ngModel)]="info.spec1"
                        [disabled]="!record.editable">
                </div>
                <div class="column background-page text-right" [style.width]="100 + 'px'">
                    根数：
                </div>
                <div class="column background-page" [style.width]="100 + 'px'">
                    <input class="color-primary" type="text" [(ngModel)]="info.spec2" [disabled]="!record.editable">
                </div>
                <div class="column background-primary color-white clickable" [style.width]="100 + 'px'"
                    (click)="clearGroup(groupIndex)" *ngIf="detectionInfoList.length === 1">
                    × 清空数值
                </div>
                <div class="column background-primary color-white clickable" [style.width]="100 + 'px'"
                    (click)="removeGroup(groupIndex)" *ngIf="detectionInfoList.length > 1">
                    - 删除本组
                </div>
            </div>
            <div class="row">
                <div class="column background-module" [style.width]="20 + 'px'" [style.height]="20 + 'px'">

                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    1
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    2
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    3
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    4
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    5
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    6
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    7
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    8
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    9
                </div>
                <div class="column background-module" [style.width]="100 + 'px'" [style.height]="20 + 'px'">
                    10
                </div>
            </div>
            <div class="row real{{groupIndex}}" *ngFor="let valrow of info.realValDisplayList; let rowIdx = index;">
                <div class="column background-module" [style.width]="20 + 'px'">
                    {{rowIdx + 1}}
                </div>
                <div class="column background-module" *ngFor="let item of valrow; let colIdx = index;"
                    [style.width]="100 + 'px'">
                    <input class="center {{item.hasError ? 'color-danger' : 'color-primary'}}" type="text"
                        [(ngModel)]="item.value" (ngModelChange)="onRealChange(groupIndex, rowIdx, colIdx)"
                        [style.height]="'100%'" (blur)="onRealBlur(groupIndex, rowIdx, colIdx)"
                        (keydown)="onRealInputKeyDown($event, groupIndex , rowIdx, colIdx)"
                        [disabled]="!record.editable">
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <button nz-button nzType="default" (click)="cancel()">取消</button>
        <!-- <span class="color-light" *ngIf="record.editable">实测值（个数：{{totalCount}}；合格率：{{passRate}}%）</span> -->
        <span class="color-danger" *ngIf="!record.editable">{{record.message}}</span>
        <button nz-button nzType="primary" (click)="confirm()" [disabled]="!canConfirm">确认</button>
    </div>
</div>