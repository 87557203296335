import { OrganizationType } from './../models/staff/organization-type.enum';
import { Pipe, PipeTransform } from '@angular/core';
import { Post } from '../models/staff/post.model';
import { Organization } from '../models/staff/organization.model';

@Pipe({
  name: 'orgTypeFilter',
})
export class OrgTypeFilterPipe implements PipeTransform {
  transform(
    items: Organization[],
    organizationType?: OrganizationType
  ): Organization[] {
    if (organizationType === undefined || organizationType === null) {
      return items;
    }
    return items.filter((item) => item.type === organizationType);
  }
}
