<div class="dialog">
    <div class="dialog-header">
        附件『呈现形式』选择
    </div>
    <div class="dialog-body">
        <div class="grid">
            <div class="row">
                <div class="column background-page" [style.width]="40 + 'px'">
                    序号
                </div>
                <div class="column background-page" [style.width]="120 + 'px'">
                    呈现形式
                </div>
                <div class="column background-page" [style.width]="400 + 'px'">
                    附件
                </div>
            </div>
            <div class="row" *ngFor="let item of data.data?.attachs ?? []; let i = index">
                <div class="column" [style.width]="40 + 'px'">
                    {{i + 1}}
                </div>
                <div class="column" [style.width]="120 + 'px'">
                    <label nz-checkbox [(ngModel)]="item.mergeIntoMainFile"
                        [nzDisabled]="item.fileType === FileType.OTHER">合并入主文件
                    </label>
                </div>
                <div class="column text-left" [style.width]="400 + 'px'">
                    <format-data contentStr="{{item.fileName}}" patternStr="E+390"></format-data>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <button nz-button nzType="default" (click)="cancel()">取消</button>
        <button nz-button nzType="primary" (click)="confirm()">确认</button>
    </div>
</div>