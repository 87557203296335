export enum FormHeaderSortType {
  NONE = 'NONE',
  ASC = 'ASC',
  DESC = 'DESC',
}

export const FormHeaderSortTypeMap = {
  [FormHeaderSortType.NONE]: '无',
  [FormHeaderSortType.ASC]: '升序',
  [FormHeaderSortType.DESC]: '降序',
};

export const FormHeaderSortTypeList = [
  {
    value: FormHeaderSortType.NONE,
    label: FormHeaderSortTypeMap[FormHeaderSortType.NONE],
  },
  {
    value: FormHeaderSortType.ASC,
    label: FormHeaderSortTypeMap[FormHeaderSortType.ASC],
  },
  {
    value: FormHeaderSortType.DESC,
    label: FormHeaderSortTypeMap[FormHeaderSortType.DESC],
  },
];
