import { Component, ViewChild } from '@angular/core';
import { zip } from 'rxjs';
import { HostDirective } from 'src/app/modules/shared/directives/host.directive';
import { LoginInfo } from 'src/app/modules/shared/models/login/login-info.model';
import { OrganizationProjectListRequest } from 'src/app/modules/shared/models/staff/organization-project-list-request.model';
import { OrganizationProject } from 'src/app/modules/shared/models/staff/organization-project.model';
import { Organization } from 'src/app/modules/shared/models/staff/organization.model';
import { PlatUser } from 'src/app/modules/shared/models/staff/plat-user.model';
import { User } from 'src/app/modules/shared/models/staff/user.model';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { OmUserPersonService } from 'src/app/modules/shared/services/om-user-person.service';
import { StaffOrganizationProjectService } from 'src/app/modules/shared/services/staff-organization-project.service';
import { StaffOrganizationService } from 'src/app/modules/shared/services/staff-organization.service';
import { StaffUserService } from 'src/app/modules/shared/services/staff-user.service';

@Component({
  selector: 'app-open-home',
  templateUrl: './open-home.component.html',
  styleUrls: ['./open-home.component.scss'],
})
export class OpenHomeComponent {
  @ViewChild(HostDirective, { static: true }) hiddenHost!: HostDirective;

  query = '';

  mit = '';

  hasError = false;

  errorHint = '';

  workFlowKey = '';

  constructor(
    private globalService: GlobalService,
    private omUserPersonService: OmUserPersonService,
    private staffUserService: StaffUserService,
    private staffOrganizationService: StaffOrganizationService,
    private staffOrganizationProjectService: StaffOrganizationProjectService
  ) {}

  // http://localhost:4200/#/open-home?token=4F4D6C4E474FF214FCB8695834C97844&workFlowKey=5e17d8d2daa9f1bae60ed74591f6b742&redirectFail=xxx

  ngOnInit(): void {
    this.query = window.location.href.split('?')[1] ?? '';
    this.loginWithoutPassword();
  }

  loginWithoutPassword(): void {
    const params = new URLSearchParams(this.query);
    if (!params.get('token')) {
      this.hasError = true;
      this.errorHint = '无效的Token';
      return;
    }
    if (!params.get('workFlowKey')) {
      this.hasError = true;
      this.errorHint = '没找到有效的签字文件';
      return;
    }
    this.omUserPersonService
      .loginWithoutPwd(params.get('token') ?? '')
      .subscribe((loginOutInfo: LoginInfo) => {
        console.log(loginOutInfo);
        if (loginOutInfo) {
          this.globalService.token = loginOutInfo.token!;
          this.workFlowKey = params.get('workFlowKey')!;
          this.prepareUserInfo(loginOutInfo);
        } else {
          this.hasError = true;
          this.errorHint = '登录失败';
        }
      });
  }

  private prepareUserInfo(info: LoginInfo) {
    zip(
      this.omUserPersonService.getPlatUser(),
      this.staffUserService.findById(info.uid!),
      this.staffOrganizationService.findById(info.organizationId!),
      this.staffOrganizationProjectService.findAll<OrganizationProjectListRequest>(
        {
          organizationId: info.organizationId,
          projectId: this.globalService.projectId,
        }
      )
    ).subscribe(
      (responses: [PlatUser, User, Organization, OrganizationProject[]]) => {
        this.globalService.userInfo = Object.assign(responses[0], responses[1]);
        const org = responses[2];
        if (responses[3].length > 0) {
          org.projectSections = responses[3][0].projectSections;
        }
        this.globalService.orgInfo = org;
        this.globalService.openLogin(info);
        location.href = '#/custom-form/sign/' + this.workFlowKey;
      }
    );
  }
}
