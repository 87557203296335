import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DevUIModule } from 'ng-devui';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DialogFormZjConfigComponent } from './biz-zj-zp/dialog-form-zj-config/dialog-form-zj-config.component';
import { DialogFormZjFillComponent } from './biz-zj-zp/dialog-form-zj-fill/dialog-form-zj-fill.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomSignComponent } from './components/custom-sign/custom-sign.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DetectionDesignComponent } from './components/detection-design/detection-design.component';
import { DetectionRealComponent } from './components/detection-real/detection-real.component';
import { DialogWbsChooseComponent } from './components/dialog-wbs-choose/dialog-wbs-choose.component';
import { FilePreviewEntryComponent } from './components/file-preview-entry/file-preview-entry.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FlowPositionCascaderComponent } from './components/flow-position-cascader/flow-position-cascader.component';
import { FormPreviewComponent } from './components/form-preview/form-preview.component';
import { FormatDataComponent } from './components/format-data/format-data.component';
import { GridProComponent } from './components/grid-pro/grid-pro.component';
import { LocationCascaderComponent } from './components/location-cascader/location-cascader.component';
import { MeasuredDisplayComponent } from './components/measured-display/measured-display.component';
import { ProcedureFileComponent } from './components/procedure-file/procedure-file.component';
import { SpreadsheetFormComponent } from './components/spreadsheet-form/spreadsheet-form.component';
import { SpreadsheetLiteComponent } from './components/spreadsheet-lite/spreadsheet-lite.component';
import { TableProComponent } from './components/table-pro/table-pro.component';
import { UserSelectPopoverComponent } from './components/user-select-popover/user-select-popover.component';
import { WbsCascaderComponent } from './components/wbs-cascader/wbs-cascader.component';
import { WbsUnitCascaderComponent } from './components/wbs-unit-cascader/wbs-unit-cascader.component';
import { AmountFormatDirective } from './directives/amount-format.directive';
import { DetectionBiasValDirective } from './directives/detection-bias-val.directive';
import { DetectionDesignValDirective } from './directives/detection-design-val.directive';
import { DetectionPassValDirective } from './directives/detection-pass-val.directive';
import { DetectionRealValDirective } from './directives/detection-real-val.directive';
import { DigitalFormatDirective } from './directives/digital-format.directive';
import { FloatFormatDirective } from './directives/float-format.directive';
import { FormulaFormatDirective } from './directives/formula-format.directive';
import { HostDirective } from './directives/host.directive';
import { MouseLeaveDirective } from './directives/mouse-leave.directive';
import { NumberFormatDirective } from './directives/number-format.directive';
import { SafeHtmlPipe } from './directives/safe-html.pipe';
import { SafeResourceUrlPipe } from './directives/safe-resource-url.pipe';
import { CopyReportFilterPipe } from './pipes/copy-report-filter.pipe';
import { CounterSignFilterPipe } from './pipes/counter-sign-filter.pipe';
import { FormCopyFilterPipe } from './pipes/form-copy-filter.pipe';
import { HistoryFilterPipe } from './pipes/history-filter.pipe';
import { OrgTypeFilterPipe } from './pipes/org-type-filter.pipe';
import { PrccFilterPipe } from './pipes/prcc-filter.pipe';
import { ProjectTypeExtFilterPipe } from './pipes/project-type-ext-filter.pipe';
import { TableItemFilterPipe } from './pipes/table-item-filter.pipe';
import { AuthGuard } from './providers/auth.guard';
import { BreadcrumbService } from './providers/breadcrumb.service';
import { EventService } from './providers/event.service';
import { GlobalService } from './providers/global.service';
import { LocalStorageService } from './providers/local-storage.service';
import { RequestInterceptor } from './providers/request/request.interceptor';
import { SocketHandler } from './providers/socket-handler.service';
import { CommonService } from './services/common.service';
import { DeviceTypeService } from './services/device-type.service';
import { DeviceService } from './services/device.service';
import { InspectionJgItemFileService } from './services/inspection-jg-item-file.service';
import { InspectionJgItemService } from './services/inspection-jg-item.service';
import { InspectionMaterialService } from './services/inspection-material.service';
import { InspectionMonthlyService } from './services/inspection-monthly.service';
import { InspectionProjectService } from './services/inspection-project.service';
import { InspectionQaCertificateService } from './services/inspection-qa-certificate.service';
import { InspectionQualityRegulationService } from './services/inspection-quality-regulation.service';
import { InspectionQuarterlyService } from './services/inspection-quarterly.service';
import { InspectionUniqualifiedService } from './services/inspection-uniqualified.service';
import { InspectionUnknownpartService } from './services/inspection-unknownpart.service';
import { MaterialBatchService } from './services/material-batch.service';
import { MaterialManufacturerService } from './services/material-manufacturer.service';
import { MaterialModelService } from './services/material-model.service';
import { MaterialTypeService } from './services/material-type.service';
import { MaterialWhService } from './services/material-wh.service';
import { OmDepartmentService } from './services/om-department.service';
import { OmFormCodeSnService } from './services/om-form-code-sn.service';
import { OmFormCodeService } from './services/om-form-code.service';
import { OmFormEntryService } from './services/om-form-entry.service';
import { OmFormHeaderService } from './services/om-form-header.service';
import { OmFormItemAttachmentService } from './services/om-form-item-attachment.service';
import { OmFormItemFlowService } from './services/om-form-item-flow.service';
import { OmFormItemMeasuredProjectService } from './services/om-form-item-measured-project.service';
import { OmFormItemService } from './services/om-form-item.service';
import { OmFormParamService } from './services/om-form-param.service';
import { OmFormService } from './services/om-form.service';
import { OmIotDeviceDataService } from './services/om-iot-device-data.service';
import { OmIotDeviceTypeService } from './services/om-iot-device-type.service';
import { OmIotDeviceService } from './services/om-iot-device.service';
import { OmLaboratoryService } from './services/om-laboratory.service';
import { OmMenuAuditService } from './services/om-menu-audit.service';
import { OmMenuFormService } from './services/om-menu-form.service';
import { OmMenuService } from './services/om-menu.service';
import { OmPostService } from './services/om-post.service';
import { OmUserOrgSealDataService } from './services/om-user-org-sealdata.service';
import { OmUserOrgService } from './services/om-user-org.service';
import { OmUserPersonSealDataService } from './services/om-user-person-sealdata.service';
import { OmUserPersonService } from './services/om-user-person.service';
import { OpenApiSignOrderService } from './services/open-api-signorder.service';
import { ProjectContractService } from './services/project-contract.service';
import { ProjectFormService } from './services/project-form.service';
import { ProjectPlanService } from './services/project-plan.service';
import { ProjectProgressService } from './services/project-progress.service';
import { ProjectRuleRegulationService } from './services/project-rule-regulation.service';
import { ProjectSectionItemService } from './services/project-section-item.service';
import { ProjectSectionUnitFormFileService } from './services/project-section-unit-form-file.service';
import { ProjectSectionUnitFormService } from './services/project-section-unit-form.service';
import { ProjectSectionService } from './services/project-section.service';
import { ProjectSubformService } from './services/project-sub-form.service';
import { ProjectTypeExtService } from './services/project-type-ext.service';
import { ProjectTypeService } from './services/project-type.service';
import { ProjectWbsService } from './services/project-wbs.service';
import { ProjectService } from './services/project.service';
import { StaffDepartmentService } from './services/staff-department.service';
import { StaffOrganizationMemberService } from './services/staff-organization-member.service';
import { StaffOrganizationService } from './services/staff-organization.service';
import { StaffPostService } from './services/staff-post.service';
import { StaffUserService } from './services/staff-user.service';
import { WorkFlowTaskService } from './services/work-flow-task.service';
import { WorkFlowUserService } from './services/work-flow-user.service';
import { WorkReportFormService } from './services/work-report-form.service';
import { WorkReportService } from './services/work-report.service';
import { DialogFormJs604FillComponent } from './biz-zj-zp/dialog-form-js604-fill/dialog-form-js604-fill.component';

@NgModule({
  declarations: [
    FormatDataComponent,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    TableProComponent,
    AlertDialogComponent,
    HostDirective,
    AmountFormatDirective,
    FloatFormatDirective,
    DigitalFormatDirective,
    FormulaFormatDirective,
    NumberFormatDirective,
    BreadcrumbComponent,
    LocationCascaderComponent,
    MouseLeaveDirective,
    DateRangeComponent,
    FilePreviewEntryComponent,
    FilePreviewComponent,
    SpreadsheetLiteComponent,
    SpreadsheetFormComponent,
    ProcedureFileComponent,
    FormPreviewComponent,
    WbsCascaderComponent,
    FlowPositionCascaderComponent,
    TableItemFilterPipe,
    OrgTypeFilterPipe,
    ProjectTypeExtFilterPipe,
    WbsUnitCascaderComponent,
    DialogWbsChooseComponent,
    GridProComponent,
    DetectionDesignValDirective,
    DetectionBiasValDirective,
    DetectionRealValDirective,
    DetectionPassValDirective,
    DetectionDesignComponent,
    DetectionRealComponent,
    CustomSignComponent,
    FormCopyFilterPipe,
    UserSelectPopoverComponent,
    HistoryFilterPipe,
    CounterSignFilterPipe,
    PrccFilterPipe,
    DialogFormZjConfigComponent,
    DialogFormZjFillComponent,
    MeasuredDisplayComponent,
    CopyReportFilterPipe,
    DialogFormJs604FillComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzCascaderModule,
    NzIconModule,
    NzPopoverModule,
    NzTimePickerModule,
    NzDatePickerModule,
    NzDropDownModule,
    NzMessageModule,
    NzPaginationModule,
    NzCheckboxModule,
    NzRadioModule,
    NzLayoutModule,
    NzToolTipModule,
    NzButtonModule,
    NzTabsModule,
    NzSelectModule,
    NzInputModule,
    DevUIModule,
    PdfViewerModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NzCascaderModule,
    NzPaginationModule,
    NzLayoutModule,
    NzToolTipModule,
    NzTabsModule,
    NzMenuModule,
    NzDropDownModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzPopoverModule,
    NzRadioModule,
    NzIconModule,
    NzButtonModule,
    NzSelectModule,
    NzInputModule,
    PdfViewerModule,
    FormatDataComponent,
    FlowPositionCascaderComponent,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    FormCopyFilterPipe,
    HistoryFilterPipe,
    CounterSignFilterPipe,
    CopyReportFilterPipe,
    PrccFilterPipe,
    TableProComponent,
    AlertDialogComponent,
    HostDirective,
    AmountFormatDirective,
    FloatFormatDirective,
    DigitalFormatDirective,
    NumberFormatDirective,
    FormulaFormatDirective,
    DetectionDesignValDirective,
    DetectionBiasValDirective,
    DetectionRealValDirective,
    DetectionPassValDirective,
    DetectionDesignComponent,
    DetectionRealComponent,
    CustomSignComponent,
    TableItemFilterPipe,
    OrgTypeFilterPipe,
    ProjectTypeExtFilterPipe,
    BreadcrumbComponent,
    LocationCascaderComponent,
    MouseLeaveDirective,
    DateRangeComponent,
    FilePreviewEntryComponent,
    FilePreviewComponent,
    ProcedureFileComponent,
    GridProComponent,
    SpreadsheetLiteComponent,
    SpreadsheetFormComponent,
    FormPreviewComponent,
    WbsCascaderComponent,
    WbsUnitCascaderComponent,
    DialogWbsChooseComponent,
    UserSelectPopoverComponent,
    DevUIModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        TableItemFilterPipe,
        OrgTypeFilterPipe,
        GlobalService,
        BreadcrumbService,
        LocalStorageService,
        EventService,
        AuthGuard,
        SocketHandler,
        // 接口服务
        CommonService,
        DeviceTypeService,
        DeviceService,
        OmFormCodeService,
        InspectionJgItemService,
        InspectionJgItemFileService,
        InspectionMaterialService,
        InspectionMonthlyService,
        InspectionProjectService,
        InspectionQaCertificateService,
        InspectionQualityRegulationService,
        InspectionQuarterlyService,
        InspectionUniqualifiedService,
        InspectionUnknownpartService,
        MaterialBatchService,
        MaterialManufacturerService,
        MaterialModelService,
        MaterialTypeService,
        MaterialWhService,
        OmDepartmentService,
        OmFormEntryService,
        OmFormItemAttachmentService,
        OmFormItemMeasuredProjectService,
        OmFormItemFlowService,
        OmFormItemService,
        OmFormParamService,
        OmFormService,
        OmFormCodeService,
        OmFormHeaderService,
        OmFormCodeSnService,
        OmIotDeviceDataService,
        OmIotDeviceTypeService,
        OmIotDeviceService,
        OmLaboratoryService,
        OmMenuAuditService,
        OmMenuFormService,
        OmMenuService,
        OmPostService,
        ProjectTypeExtService,
        OmUserPersonSealDataService,
        OmUserPersonService,
        OmUserOrgSealDataService,
        OmUserOrgService,
        OpenApiSignOrderService,
        ProjectContractService,
        ProjectFormService,
        ProjectSubformService,
        ProjectPlanService,
        ProjectProgressService,
        ProjectRuleRegulationService,
        ProjectSectionItemService,
        ProjectSectionUnitFormService,
        ProjectSectionUnitFormFileService,
        ProjectSectionService,
        ProjectTypeService,
        ProjectWbsService,
        ProjectService,
        StaffDepartmentService,
        StaffOrganizationService,
        StaffOrganizationMemberService,
        StaffPostService,
        StaffUserService,
        WorkFlowTaskService,
        WorkFlowUserService,
        WorkReportFormService,
        WorkReportService,
        // 拦截器
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        },
      ],
    };
  }
}
