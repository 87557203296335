import { Component, Input } from '@angular/core';
import { DialogConfig } from 'src/app/modules/shared/components/alert-dialog/dialog-config';
import { FileType } from 'src/app/modules/shared/models/form/file-type.enum';
import { WorkReport } from 'src/app/modules/shared/models/form/work-report.model';

@Component({
  selector: 'app-dialog-attach-display-form',
  templateUrl: './dialog-attach-display-form.component.html',
  styleUrls: ['./dialog-attach-display-form.component.scss'],
})
export class DialogAttachDisplayFormComponent {
  @Input()
  data!: DialogConfig<WorkReport>;

  FileType = FileType;

  constructor() {}

  ngOnInit() {}

  isNaN(content: number) {
    return window.isNaN(content);
  }

  cancel() {
    this.data.onCancel();
  }

  confirm() {
    this.data.onConfirm!(this.data.data);
  }

  get canConfirm() {
    return true;
  }
}
