import { DetectionInfo } from '../../components/spreadsheet-form/spreadsheet-form.component';
import { TableColumnOption } from '../../components/table-pro/table.config';
import { FimpAverageTypeType } from '../../models/form/fimp-average-type-type.enum';
import { FimpMeasuredValueFormat } from '../../models/form/fimp-measured-value-format.enum';
import { FormItemMeasuredProjectInfo } from '../../models/form/form-item-measured-project-info.model';
import {
  FormItemMeasuredProjectRefParamType,
  FormItemMeasuredProjectRefParamTypeOptions,
} from '../../models/form/form-item-measured-project-ref-param-type.enum';
import {
  FormItemMeasuredProjectSpadType,
  FormItemMeasuredProjectSpadTypeOptions,
} from '../../models/form/form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectSpad } from '../../models/form/form-item-measured-project-spad.model';
import { WorkReportMeasuredValueInfo } from '../../models/form/work-report-measured-value-info.model';
import { ProjectSectionUnitForm } from '../../models/project/project-section-unit-form.model';
import { GlobalService } from '../../providers/global.service';
import { ProjectSectionUnitFormService } from '../../services/project-section-unit-form.service';
import { WorkReportService } from '../../services/work-report.service';
import {
  DetectionGroupInfo,
  DetectionMeasureInfo,
} from './dialog-form-zj-fill.component';

export class MeasuredProjectUtil {
  editing: { [name: string]: boolean } = {};

  FormItemMeasuredProjectSpadType = FormItemMeasuredProjectSpadType;

  record: FormItemMeasuredProjectInfo = new FormItemMeasuredProjectInfo();

  FormItemMeasuredProjectSpadTypeOptions =
    FormItemMeasuredProjectSpadTypeOptions;

  FormItemMeasuredProjectRefParamTypeOptions =
    FormItemMeasuredProjectRefParamTypeOptions;

  descList: string[] = [];

  detectionInfoList: DetectionGroupInfo[] = [];

  currentSpad = new FormItemMeasuredProjectSpad();

  FormItemMeasuredProjectRefParamType = FormItemMeasuredProjectRefParamType;

  // recommandRemark = '';

  // realVal1List: { value: string; hasError: boolean }[][] = [];

  // realVal2List: { value: string; hasError: boolean }[][] = [];

  isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  selectIndex?: number;
  biasStr?: string;
  biasError?: boolean = false;

  showExp = false;

  totalCount = 0;
  passRate = 0;

  special = false;

  showD1 = false;
  showD2 = false;
  showDe = false;
  precision = 0;

  // onExpChange() {
  //   if (!!this.exp1 || !!this.exp2) {
  //     this.showExp = true;
  //   } else {
  //     this.showExp = false;
  //   }
  // }

  // 特殊情况
  CXX: TableColumnOption[] = [
    {
      label: 'C15(≥15Mpa)',
      value: '≥15',
    },
    {
      label: 'C20(≥20Mpa)',
      value: '≥20',
    },
    {
      label: 'C25(≥25Mpa)',
      value: '≥25',
    },
    {
      label: 'C30(≥30Mpa)',
      value: '≥30',
    },
    {
      label: 'C35(≥35Mpa)',
      value: '≥35',
    },
    {
      label: 'C40(≥40Mpa)',
      value: '≥40',
    },
    {
      label: 'C45(≥45Mpa)',
      value: '≥45',
    },
    {
      label: 'C50(≥50Mpa)',
      value: '≥50',
    },
    {
      label: 'C55(≥55Mpa)',
      value: '≥55',
    },
    {
      label: 'C60(≥60Mpa)',
      value: '≥60',
    },
    {
      label: 'C65(≥65Mpa)',
      value: '≥65',
    },
    {
      label: 'C70(≥70Mpa)',
      value: '≥70',
    },
    {
      label: 'C75(≥75Mpa)',
      value: '≥75',
    },
    {
      label: 'C80(≥80Mpa)',
      value: '≥80',
    },
  ];

  MXX: TableColumnOption[] = [
    {
      label: 'M5(≥5Mpa)',
      value: '≥5',
    },
    {
      label: 'M7.5(≥7.5Mpa)',
      value: '≥7.5',
    },
    {
      label: 'M10(≥10Mpa)',
      value: '≥10',
    },
    {
      label: 'M15(≥15Mpa)',
      value: '≥15',
    },
    {
      label: 'M20(≥20Mpa)',
      value: '≥20',
    },
    {
      label: 'M25(≥25Mpa)',
      value: '≥25',
    },
    {
      label: 'M30(≥30Mpa)',
      value: '≥30',
    },
    {
      label: 'M35(≥35Mpa)',
      value: '≥35',
    },
    {
      label: 'M40(≥40Mpa)',
      value: '≥40',
    },
    {
      label: 'M45(≥45Mpa)',
      value: '≥45',
    },
    {
      label: 'M50(≥50Mpa)',
      value: '≥50',
    },
    {
      label: 'M55(≥55Mpa)',
      value: '≥55',
    },
  ];

  ZS: TableColumnOption[] = [
    {
      label: '≥Ⅱ类',
      value: '≥-2',
    },
  ];

  ZSV: TableColumnOption[] = [
    {
      label: 'Ⅰ类',
      value: '-1',
    },
    {
      label: 'Ⅱ类',
      value: '-2',
    },
    {
      label: 'Ⅲ类',
      value: '-3',
    },
  ];

  // 桩身完整性
  isZS = false;

  specialOptions: TableColumnOption[] = [];

  workReportList: ProjectSectionUnitForm[] = [];

  workReportMeasuredValueInfos: WorkReportMeasuredValueInfo[] = [];

  detectionMeasureInfo = new DetectionMeasureInfo();

  constructor() {}

  private calcAll() {
    let totalCount = 0;
    let passCount = 0;
    let maxR = 0;
    if (this.biasError) {
      for (const info of this.detectionInfoList) {
        for (const row of info.realValDisplayList) {
          for (const cell of row) {
            cell.hasError = false;
          }
        }
      }
    } else if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC
    ) {
      for (const info of this.detectionInfoList) {
        if (info.biasError) continue;
        let countGroup = 0;
        let sumReal = 0;
        for (const row of info.realValDisplayList) {
          for (const col of row) {
            if (col.value !== '') {
              if (col.value.includes('.')) {
                maxR = Math.max(maxR, col.value.split('.')[1].length);
              }
              countGroup++;
              sumReal += +col.value;
              if (+col.value >= info.valMin! && +col.value <= info.valMax!) {
                col.hasError = false;
                passCount++;
              } else {
                col.hasError = true;
              }
            } else {
              col.hasError = false;
            }
          }
        }
        totalCount += countGroup;
        const pow = Math.pow(10, maxR);
        info.avg =
          countGroup === 0
            ? ''
            : `${Math.round((sumReal / countGroup) * pow) / pow}`;
      }
    } else if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS101
    ) {
      let total = 0;
      for (const info of this.workReportMeasuredValueInfos) {
        if (!this.detectionMeasureInfo.biasError) {
          if (
            this.detectionMeasureInfo.biasRealValMin! <=
              +info.deviationValue! &&
            this.detectionMeasureInfo.biasRealValMax! >= +info.deviationValue!
          ) {
            passCount++;
          }
        }
        total += +info.deviationValue!;
      }
      totalCount = this.workReportMeasuredValueInfos.length;
      this.detectionMeasureInfo.avg = this.record.averageValue;
      // this.detectionMeasureInfo.avg = `${
      //   totalCount > 0 ? Math.round(total / totalCount) : 0
      // }`;
    } else if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_JS103
    ) {
      let total = 0;
      for (const info of this.workReportMeasuredValueInfos) {
        if (!this.detectionMeasureInfo.biasError) {
          if (
            this.detectionMeasureInfo.biasRealValMin! <=
              +info.deviationValue! &&
            this.detectionMeasureInfo.biasRealValMax! >= +info.deviationValue!
          ) {
            passCount++;
          }
        }
        for (const item of info.children ?? []) {
          if (item.key === 'deviationElevation') {
            total += +item.value!;
          }
        }
      }
      totalCount = this.workReportMeasuredValueInfos.length;
      this.detectionMeasureInfo.avg = this.record.averageValue;
      // this.detectionMeasureInfo.avg = `${
      //   totalCount > 0 ? Math.round(total / totalCount) : 0
      // }`;
    }

    // 根据下标进行计算
    this.totalCount = totalCount;
    this.passRate =
      totalCount === 0 ? 0 : Math.round((passCount / totalCount) * 10000) / 100;
  }

  getResult(
    response: FormItemMeasuredProjectInfo
  ): Promise<FormItemMeasuredProjectInfo> {
    return new Promise((resolve) => {
      const data = JSON.parse(
        JSON.stringify(response)
      ) as FormItemMeasuredProjectInfo;

      if (!data.measuredValueFormat) {
        if (data.keywords?.includes('偏位')) {
          data.measuredValueFormat = FimpMeasuredValueFormat.SEE_JS101;
        } else if (data.keywords?.includes('偏差')) {
          data.measuredValueFormat = FimpMeasuredValueFormat.SEE_JS103;
        } else {
          data.measuredValueFormat = FimpMeasuredValueFormat.NUMERIC;
        }
      }

      console.log(data);

      const detectionInfoList: DetectionGroupInfo[] = [];
      if (data.measuredValue) {
        try {
          const designValues = data.measuredValue.split('\n');
          for (let i = 0; i < designValues.length; i++) {
            detectionInfoList[i] = new DetectionGroupInfo();
          }
        } catch (e) {}
      }

      if (detectionInfoList.length === 0) {
        detectionInfoList.push(new DetectionGroupInfo());
      }

      if (data.designValue) {
        try {
          const designValues = data.designValue.replace(' ', '\n').split('\n');
          for (let i = 0; i < designValues.length; i++) {
            detectionInfoList[i].designValue = designValues[i] ?? '';
          }
        } catch (e) {}
      }

      const averageValues = (data.averageValue ?? '')
        .replace(' ', '\n')
        .split('\n');
      for (let i = 0; i < averageValues.length; i++) {
        if (data.averageValueType === FimpAverageTypeType.AVERAGE_VALUE) {
          detectionInfoList[i].avg = averageValues[i] ?? '';
        } else {
          detectionInfoList[i].exp = averageValues[i] ?? '';
        }
      }

      const measuredValues = (data.measuredValue ?? '').split('\n');
      for (let i = 0; i < measuredValues.length; i++) {
        if (data.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
          detectionInfoList[i].realValDisplayList = this.parseRealData(
            measuredValues[i]
          );
        } else {
          detectionInfoList[i].realValTxt = measuredValues[i] ?? '';
        }
      }
      if (data.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT) {
        const deviationValues = (data.deviationValue ?? '').split('\n');
        for (let i = 0; i < deviationValues.length; i++) {
          detectionInfoList[i].biasValTxt = deviationValues[i] ?? '';
        }
      }

      const partValues = (data.partValue ?? '').split('\n');
      for (let i = 0; i < partValues.length; i++) {
        detectionInfoList[i].part = partValues[i] ?? '';
      }

      if (data.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT) {
        data.refReportMerge = `${data.refReportMerge}` === 'true';
      } else {
        data.refReportMerge = '';
      }

      this.detectionInfoList = detectionInfoList;
      this.record = data;

      if (
        this.record.keywords?.includes('混凝土强度') &&
        !this.record.keywords.includes('泡沫')
      ) {
        this.special = true;
        this.specialOptions = this.CXX;
      }
      if (
        this.record.keywords?.includes('砂浆强度') ||
        this.record.keywords?.includes('浆体强度')
      ) {
        this.special = true;
        this.specialOptions = this.MXX;
      }
      if (
        this.record.keywords?.includes('混凝土抗压强度或砂浆强度') ||
        this.record.keywords?.includes('混凝土或砂浆强度')
      ) {
        this.special = true;
        this.specialOptions = [...this.CXX, ...this.MXX];
      }
      if (this.record.keywords?.includes('桩身完整性')) {
        this.isZS = true;
      }

      let selected = false;

      if (
        (this.isZS || this.special) &&
        this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC
      ) {
        if (this.isZS) {
          this.record.spadValue = '≥-2';
        }
        if (this.special) {
          this.record.spadValue = '≥设计值';
        }
      } else if (data.spadValues && data.spadValues.length > 0) {
        for (let i = 0; i < data.spadValues.length; i++) {
          if (data.spadValues[i].selected) {
            selected = true;
            setTimeout(() => {
              // 为了解决ngModelChange的问题，延迟100ms
              this.selectBias(i);
              return resolve(this.handleDataBeforeConfirm(this.record));
            }, 100);
            break;
          }
        }
      }
    });
  }

  // 再次进入编辑页面后，做一次实测数据的解析。将实测数据放到对应的位置，并且绘制表格
  private parseRealData(
    measuredValue: string
  ): { value: string; hasError: boolean }[][] {
    let realValList: { value: string; hasError: boolean }[][] = [[]];
    if (!!measuredValue) {
      // const measuredValues = measuredValue
      //   .replaceAll('Ⅰ类', '-1')
      //   .replaceAll('Ⅱ类', '-2')
      //   .replaceAll('Ⅲ类', '-3');
      let mList: string[] = [];
      mList = measuredValue
        .replaceAll('Ⅰ类', '-1')
        .replaceAll('Ⅱ类', '-2')
        .replaceAll('Ⅲ类', '-3')
        .split(' ');
      let rowCount = Math.ceil(mList.length / 10);
      if (rowCount < 1) {
        rowCount = 1;
      }
      if (mList.length % 10 === 0) {
        rowCount++;
      }
      for (let row = 0; row < rowCount; row++) {
        realValList[row] = [];
        for (let col = 0; col < 10; col++) {
          realValList[row][col] = {
            value: mList[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
    } else {
      for (let col = 0; col < 10; col++) {
        realValList[0][col] = {
          value: '',
          hasError: false,
        };
      }
    }
    return realValList;
  }

  // 通过用户选择规定值或允许偏差值，计算实际值
  selectBias(index: number) {
    if (!this.record.editAble) return;
    for (const item of this.record.spadValues!) {
      item.selected = false;
    }
    this.record.spadValues![index].selected = true;
    this.selectIndex = index;
    this.currentSpad = JSON.parse(
      JSON.stringify(this.record.spadValues![index])
    );
    this.biasStr = `${
      !!this.currentSpad.spadValue
        ? this.currentSpad.spadValue
        : this.currentSpad.spadDesc
    }${
      this.record.spadValues![index].keywords
        ? `(${this.record.spadValues![index].keywords})`
        : ''
    }`;
    if (
      this.currentSpad.refParamType &&
      // (this.currentSpad.refParamValue === undefined ||
      //   this.currentSpad.refParamValue === '') &&
      this.record.refParamValue !== undefined &&
      this.record.refParamValue !== ''
    ) {
      this.currentSpad.refParamValue = this.record.refParamValue;
    }
    this.parseBiasStr();
    this.calcAll();
  }

  // onRefValueChange() {
  //   if (
  //     this.currentSpad.refParamType ===
  //     FormItemMeasuredProjectRefParamType.DESIGN
  //   ) {
  //     this.currentSpad.refParamValue = this.record.designValue1 ?? '';
  //   }
  //   this.parseBiasStr();
  //   this.calcAll();
  // }

  // 用户输入bias信息后，进行解析。在初始化的时候也会做一次解析
  // onBiasChange() {
  //   if (!this.detectionInfo.biasStr) {
  //     this.detectionInfo.biasError = true;
  //     this.detectionInfo.selectIndex = -1;
  //     this.calcAll();
  //     return;
  //   }
  //   if (this.record.spadValues && this.record.spadValues.length > 0) {
  //     for (let i = 0; i < this.record.spadValues.length; i++) {
  //       const item = this.record.spadValues[i];
  //       if (
  //         this.detectionInfo.biasStr ===
  //         `${item.spadValue}${item.keywords ? `(${item.keywords})` : ''}`
  //       ) {
  //         if (this.detectionInfo.selectIndex !== i) {
  //           this.selectBias(i);
  //         }
  //         return;
  //       }
  //     }
  //   }

  //   this.detectionInfo.selectIndex = -1;
  //   // 丢给服务端判断是否是符合标准的表达式，然后进行解析
  //   this.omFormItemMeasuredProjectService
  //     .parseSpadExpression(this.detectionInfo.biasStr)
  //     .subscribe((response) => {
  //       if (response) {
  //         for (const item of this.record.spadValues ?? []) {
  //           item.selected = false;
  //         }
  //         this.detectionInfo.selectIndex = -1;
  //         this.currentSpad = response;
  //         this.currentSpad.selected = true;
  //         this.currentSpad.userDefined = true;
  //         this.parseBiasStr();
  //         this.calcAll();
  //       }
  //     });
  // }

  /**
   * 解析完整的bias信息。核心是将数据解析成对应的min和max
   *
   * @private
   * @return {*}
   * @memberof DialogFormZjFillComponent
   */
  private parseBiasStr() {
    this.biasError = true;
    for (const info of this.detectionInfoList) {
      info.biasError = true;
    }
    // this.record.remarkValue = this.detectionInfoList
    //   .map((item) => item.designValue ?? '')
    //   .join('\n');
    // this.record.remarkValue = this.detectionInfoList
    //   .map((item) => item.designValue ?? '')
    //   .join('\n');
    if (!this.biasStr) {
      return;
    }
    if (
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.USER_FILLIN ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.NOT_REQUIREMENT
    ) {
      for (const info of this.detectionInfoList) {
        info.biasError = false;
      }
      // this.record.remarkValue = '';
      // 用户自填项，或者无需填写。我们认为这是无法识别的信息。因此无法通过这个信息进行合格率的计算。对于这种情况，我们认为解析信息有误的。
      return;
    }
    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
    ) {
      // 允许偏差值。我们认为这是可以识别的信息。因此可以通过这个信息进行合格率的计算。
      // 允许偏差：±n 或『+n, -m』
      // this.recommandRemark = `${this.record.designValue1 ?? ''}${
      //   this.record.designValue2 ? ` ${this.record.designValue2}` : ''
      // }`;
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      try {
        // 允许偏差有两种情况，一种是±，一种是最大值和最小值
        let biasMin = 0;
        let biasMax = 0;
        if (this.currentSpad.spadValue!.includes('±')) {
          const bias = this.currentSpad.spadValue!.replace('±', '');
          this.precision = bias.includes('.') ? bias.split('.')[1].length : 0;
          if (this.currentSpad.refParamSymbol) {
            const abs = +eval(
              bias.replace(
                this.currentSpad.refParamSymbol,
                this.currentSpad.refParamValue!
              )
            );
            // 纯展示计算所得。没用
            this.currentSpad.refParamSpadValue = '±' + abs;
            biasMin = -abs;
            biasMax = abs;
          } else {
            biasMin = -+bias;
            biasMax = +bias;
          }
        }

        // if (!this.record.designValue1) {
        //   // 如果没有设计值，那么无法计算最大值和最小值
        //   return;
        // }
        for (const info of this.detectionInfoList) {
          if (!info.designValue) {
            continue;
          }
          if (this.currentSpad.spadValue!.includes(',')) {
            const bias = this.currentSpad.spadValue!.split(',');
            biasMin = Math.min(+bias[0], +bias[1]);
            biasMax = Math.max(+bias[0], +bias[1]);
            this.precision = biasMax.toString().includes('.')
              ? biasMax.toString().split('.')[1].length
              : 0;
          }
          info.valMin = +info.designValue! + biasMin;
          info.valMax = +info.designValue! + biasMax;
          info.biasError = false;
        }

        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = biasMin;
        this.detectionMeasureInfo.biasRealValMax = biasMax;
        this.detectionMeasureInfo.biasError = false;
        // 到这里确定没有错误
        this.biasError = false;
      } catch (e) {
        return;
      }
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≥', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      // if (!this.special) {
      //   try {
      //     if (!isNaN(+bias)) {
      //       this.record.designValue1 = bias;
      //     }
      //   } catch (e) {}
      // }

      if (this.currentSpad.refParamSymbol) {
        console.log(this.currentSpad.refParamSymbol);
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.refParamSpadValue = '≥' + Math.round(abs * 100) / 100;
        for (const info of this.detectionInfoList) {
          info.valMin = +abs;
          info.valMax = Infinity;
          info.biasError = false;
        }
        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = +abs;
        this.detectionMeasureInfo.biasRealValMax = Infinity;
        this.detectionMeasureInfo.biasError = false;
      } else {
        for (const info of this.detectionInfoList) {
          info.valMin = +bias;
          info.valMax = Infinity;
          info.biasError = false;
        }
        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = +bias;
        this.detectionMeasureInfo.biasRealValMax = Infinity;
        this.detectionMeasureInfo.biasError = false;
      }
      this.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE
    ) {
      // ≤ 规定值：≤ n

      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≤', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      // if (!this.special) {
      //   try {
      //     if (!isNaN(+bias)) {
      //       this.record.designValue1 = bias;
      //     }
      //   } catch (e) {}
      // }

      if (this.currentSpad.refParamSymbol) {
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.refParamSpadValue = '≤' + Math.round(abs * 100) / 100;
        for (const info of this.detectionInfoList) {
          info.valMin = -Infinity;
          info.valMax = +abs;
          info.biasError = false;
        }
        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = -Infinity;
        this.detectionMeasureInfo.biasRealValMax = +abs;
        this.detectionMeasureInfo.biasError = false;
      } else {
        for (const info of this.detectionInfoList) {
          info.valMin = -Infinity;
          info.valMax = +bias;
          info.biasError = false;
        }
        this.detectionMeasureInfo = new DetectionMeasureInfo();
        this.detectionMeasureInfo.biasRealValMin = -Infinity;
        this.detectionMeasureInfo.biasRealValMax = +bias;
        this.detectionMeasureInfo.biasError = false;
      }

      this.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE
    ) {
      // ~ 规定值：~ n
      const bias = this.currentSpad
        .spadValue!.replace('~', ' ')
        .replace('～', ' ')
        .split(' ');
      const min = Math.min(+bias[0], +bias[1]);
      const max = Math.max(+bias[0], +bias[1]);
      for (const info of this.detectionInfoList) {
        info.valMin = min;
        info.valMax = max;
        info.biasError = false;
      }
      this.biasError = false;

      this.detectionMeasureInfo = new DetectionMeasureInfo();
      this.detectionMeasureInfo.biasRealValMin = min;
      this.detectionMeasureInfo.biasRealValMax = max;
      this.detectionMeasureInfo.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≥' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≥' + this.record.designValue2 : ''}`;

      // ≥ 设计值
      // if (
      //   this.record.designValue1 === '' ||
      //   this.record.designValue1 === undefined ||
      //   isNaN(+this.record.designValue1)
      // ) {
      //   // 如果没有设计值，那么无法计算最大值和最小值
      //   this.biasError = true;
      //   return;
      // }
      for (const info of this.detectionInfoList) {
        info.biasError = true;
        if (this.currentSpad.refParamSymbol) {
          try {
            const value = +eval(
              this.currentSpad
                .spadValue!.replace('≥', '')
                .replace('设计值', info.designValue!)
                .replace(
                  this.currentSpad.refParamSymbol,
                  this.currentSpad.refParamValue!
                )
            );
            if (isNaN(value)) {
              continue;
            }
            info.valMax = Infinity;
            info.valMin = value;
            info.biasError = false;
          } catch (e) {
            continue;
          }
        } else {
          if (
            info.designValue === '' ||
            info.designValue === undefined ||
            isNaN(
              +info.designValue
                .replace('Ⅰ类', '-1')
                .replace('Ⅱ类', '-2')
                .replace('Ⅲ类', '-3')
                .replace('C', '')
                .replace('M', '')
            )
          ) {
            continue;
          }
          info.valMin = +info.designValue
            .replace('Ⅰ类', '-1')
            .replace('Ⅱ类', '-2')
            .replace('Ⅲ类', '-3')
            .replace('C', '')
            .replace('M', '');
          info.valMax = Infinity;
          info.biasError = false;
        }
      }
      this.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≤' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≤' + this.record.designValue2 : ''}`;
      // ≤ 设计值
      for (const info of this.detectionInfoList) {
        info.biasError = true;
        if (this.currentSpad.refParamSymbol) {
          try {
            const value = +eval(
              this.currentSpad
                .spadValue!.replace('≤', '')
                .replace('设计值', info.designValue!)
                .replace(
                  this.currentSpad.refParamSymbol,
                  this.currentSpad.refParamValue!
                )
            );
            if (isNaN(value)) {
              continue;
            }
            info.valMin = -Infinity;
            info.valMax = value;
            info.biasError = false;
          } catch (e) {
            continue;
          }
        } else {
          if (
            info.designValue === '' ||
            info.designValue === undefined ||
            isNaN(
              +info.designValue
                .replace('Ⅰ类', '-1')
                .replace('Ⅱ类', '-2')
                .replace('Ⅲ类', '-3')
                .replace('C', '')
                .replace('M', '')
            )
          ) {
            // 如果没有设计值，那么无法计算最大值和最小值
            info.biasError = true;
            continue;
          }
          info.valMin = -Infinity;
          info.valMax = +info.designValue
            .replace('Ⅰ类', '-1')
            .replace('Ⅱ类', '-2')
            .replace('Ⅲ类', '-3')
            .replace('C', '')
            .replace('M', '');
          info.biasError = false;
        }
      }
      this.biasError = false;
    }
  }
  // isNaN(content: number) {
  //   return window.isNaN(content);
  // }

  // cancel() {
  //   this.data.onCancel();
  // }

  // confirm() {
  //   this.submit();
  // }

  private handleDataBeforeConfirm(
    response: FormItemMeasuredProjectInfo
  ): FormItemMeasuredProjectInfo {
    const data = JSON.parse(
      JSON.stringify(response)
    ) as FormItemMeasuredProjectInfo;
    data.designValue = this.detectionInfoList
      .map((item) => item.designValue ?? '')
      .join('\n');

    if (this.currentSpad.userDefined) {
      data.spadValues?.push(this.currentSpad);
    } else {
      data.spadValues![this.selectIndex!] = this.currentSpad;
    }

    const groupRealValues: string[] = [];
    const groupDevValues: string[] = [];
    const groupAvgValues: string[] = [];
    const groupExpValues: string[] = [];
    for (const info of this.detectionInfoList) {
      const realValue: string[] = [];
      const devValues: string[] = [];
      if (this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
        for (const row of info.realValDisplayList) {
          for (const cell of row) {
            if (cell.value !== '') {
              realValue.push(cell.value);
              if (
                this.currentSpad.spadType !==
                FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
              ) {
                devValues.push(cell.value);
              } else {
                if (!info.designValue) {
                  devValues.push('');
                } else {
                  const value = +cell.value - +info.designValue!;
                  if (value > 0) {
                    devValues.push(`+${value.toFixed(this.precision)}`);
                  } else {
                    devValues.push(`${value.toFixed(this.precision)}`);
                  }
                }
              }
            }
          }
        }
        groupRealValues.push(realValue.join(' '));
        groupDevValues.push(devValues.join(' '));
      }
      if (this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT) {
        groupRealValues.push(info.realValTxt ?? '');
        groupDevValues.push(info.biasValTxt ?? '');
      }
      groupAvgValues.push(info.avg ?? '');
      groupExpValues.push(info.exp ?? '');
    }
    data.measuredValue = groupRealValues.join('\n');
    data.deviationValue = groupDevValues.join('\n');
    data.partValue = this.detectionInfoList
      .map((item) => item.part ?? '')
      .join('\n');

    let remarkValue = this.detectionInfoList
      .map((item) => item.designValue ?? '')
      .join('\n');

    if (remarkValue.replaceAll('\n', '').length === 0) {
      remarkValue = '';
    }

    if (
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE
    ) {
      if (remarkValue.length === 0 && groupRealValues.join('').length > 0) {
        remarkValue = this.currentSpad.spadValue ?? '';
        if (!!this.currentSpad.refParamSymbol) {
          remarkValue = !!this.currentSpad.refParamValue
            ? this.currentSpad.refParamSpadValue ?? ''
            : '';
        }
      }
    }

    data.remarkValue = remarkValue;

    data.refParamType = this.currentSpad.refParamType;
    data.refParamName = this.currentSpad.refParamName;
    data.refParamSymbol = this.currentSpad.refParamSymbol;
    data.refParamVariable = this.currentSpad.refParamVariable;
    data.refParamValue = this.currentSpad.refParamValue;
    data.spadDesc = this.currentSpad.spadDesc;
    data.spadValue = this.currentSpad.spadValue;
    data.spadType = this.currentSpad.spadType;
    if (this.record.measuredValueFormat === FimpMeasuredValueFormat.NUMERIC) {
      data.qaRate =
        groupRealValues.join('').length > 0 ? this.passRate + '' : '';
    } else if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT
    ) {
      data.qaRate = '';
    }
    if (
      this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_REPORT
    ) {
      data.refReportMerge = `${data.refReportMerge}`;
      data.measuredValue = '见报告';
      data.deviationValue = '见报告';
    } else {
      data.refReportMerge = '';
    }
    // if (this.record.measuredValueFormat === FimpMeasuredValueFormat.SEE_TXT) {
    //   data.deviationValue = data.measuredValue;
    // }
    data.qualifiedJudge = groupRealValues.join('').length > 0 ? '合格' : '';
    // data.remarkValue = `${data.designValue1 ?? ''}${
    //   data.designValue2 ? ` ${data.designValue2}` : ''
    // }`;

    if (groupExpValues.join('').length > 0) {
      data.averageValue = groupExpValues.join('\n');
      data.averageValueType = FimpAverageTypeType.TYPICAL_VALUE;
    } else {
      data.averageValue = groupAvgValues.join('\n');
      data.averageValueType = FimpAverageTypeType.AVERAGE_VALUE;
    }
    if (data.keywords?.includes('桩身完整性')) {
      data.remarkValue = data.remarkValue
        ?.replace('-1', 'Ⅰ类')
        .replace('-2', 'Ⅱ类')
        .replace('-3', 'Ⅲ类');
      data.averageValue = '';
      data.measuredValue = data.measuredValue
        .replaceAll('-1', 'Ⅰ类')
        .replaceAll('-2', 'Ⅱ类')
        .replaceAll('-3', 'Ⅲ类');
      data.deviationValue = data.measuredValue;
    }
    return data;
  }

  // get canConfirm() {
  //   return true;
  // }

  // copy(str: string) {
  //   copy(str);
  //   this.nzMessage.info(`已复制${str}到剪贴板`);
  // }

  // private submit() {
  //   const data = this.handleDataBeforeConfirm(this.record);
  //   this.data.onConfirm!(data);
  // }
}
