export enum WorkReportStatus {
  DRAFT = 'DRAFT',
  WAIT_SIGN = 'WAIT_SIGN',
  WAIT_SEAL = 'WAIT_SEAL',
  WAIT_COUNTER_SIGN = 'WAIT_COUNTER_SIGN',
  WAIT_CREATED = 'WAIT_CREATED',
  WAIT_IMPROVED = 'WAIT_IMPROVED',
  WAIT_REPLY = 'WAIT_REPLY',
  REJECT_SIGN = 'REJECT_SIGN',
  REJECT_SEAL = 'REJECT_SEAL',
  CANCLE = 'CANCLE',
  EXPIRE = 'EXPIRE',
  FAIL = 'FAIL',
  FINISH = 'FINISH',
}

export const WorkReportStatusMap = {
  [WorkReportStatus.DRAFT]: '修改稿',
  [WorkReportStatus.WAIT_SIGN]: '待签字',
  [WorkReportStatus.WAIT_SEAL]: '待盖章',
  [WorkReportStatus.WAIT_COUNTER_SIGN]: '待会签',
  [WorkReportStatus.WAIT_REPLY]: '待回复',
  [WorkReportStatus.WAIT_CREATED]: '待创建',
  [WorkReportStatus.WAIT_IMPROVED]: '待完善',
  [WorkReportStatus.CANCLE]: '已撤回',
  [WorkReportStatus.EXPIRE]: '已过期',
  [WorkReportStatus.REJECT_SIGN]: '已退回',
  [WorkReportStatus.REJECT_SEAL]: '已拒盖',
  [WorkReportStatus.FAIL]: '失败',
  [WorkReportStatus.FINISH]: '完成',
};

export const WorkReportStatusColorMap = {
  [WorkReportStatus.DRAFT]: '',
  [WorkReportStatus.WAIT_SIGN]: 'color-primary',
  [WorkReportStatus.WAIT_SEAL]: 'color-primary',
  [WorkReportStatus.WAIT_REPLY]: 'color-primary',
  [WorkReportStatus.WAIT_COUNTER_SIGN]: 'color-primary',
  [WorkReportStatus.WAIT_CREATED]: 'color-primary',
  [WorkReportStatus.WAIT_IMPROVED]: 'color-primary',
  [WorkReportStatus.CANCLE]: 'color-danger',
  [WorkReportStatus.EXPIRE]: 'color-danger',
  [WorkReportStatus.REJECT_SIGN]: 'color-danger',
  [WorkReportStatus.REJECT_SEAL]: 'color-danger',
  [WorkReportStatus.FAIL]: 'color-danger',
  [WorkReportStatus.FINISH]: 'color-light',
};

export const WorkReportStatusList = [
  {
    label: WorkReportStatusMap[WorkReportStatus.DRAFT],
    value: WorkReportStatus.DRAFT,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.WAIT_SIGN],
    value: WorkReportStatus.WAIT_SIGN,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.WAIT_SEAL],
    value: WorkReportStatus.WAIT_SEAL,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.WAIT_COUNTER_SIGN],
    value: WorkReportStatus.WAIT_COUNTER_SIGN,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.WAIT_REPLY],
    value: WorkReportStatus.WAIT_REPLY,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.WAIT_CREATED],
    value: WorkReportStatus.WAIT_CREATED,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.WAIT_IMPROVED],
    value: WorkReportStatus.WAIT_IMPROVED,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.CANCLE],
    value: WorkReportStatus.CANCLE,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.EXPIRE],
    value: WorkReportStatus.EXPIRE,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.REJECT_SIGN],
    value: WorkReportStatus.REJECT_SIGN,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.REJECT_SEAL],
    value: WorkReportStatus.REJECT_SEAL,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.FAIL],
    value: WorkReportStatus.FAIL,
  },
  {
    label: WorkReportStatusMap[WorkReportStatus.FINISH],
    value: WorkReportStatus.FINISH,
  },
];
