import { ListRequest } from '../../providers/request/list-request.model';
import { ProjectItemType } from './project-item-type.enum';
export class ProjectSectionUnitListRequest extends ListRequest {
  projectId?: number;
  sectionId?: number;
  sectionIds?: Array<number>;
  sectionItemId?: number;
  sectionItemType?: ProjectItemType;
  hasLJ?: boolean;
  code?: string;
  fullCode?: string;
  projectTypeCode?: string;
  projectTypeName?: string;
  name?: string;
  highwayLevel?: string;
  mark?: string;
  constructionOrgName?: string;
  mileage?: string;
  dwMileage?: string;
  data1?: number;
  data2?: number;
  data3?: number;
  dwCode?: string;
  dwName?: string;
  subDwCode?: string;
  subDwName?: string;
  fbCode?: string;
  fbName?: string;
  subFbCode?: string;
  subFbName?: string;
  fxCode?: string;
  fxName?: string;
  sub1FxCode?: string;
  sub1FxName?: string;
  sub2FxCode?: string;
  sub2FxName?: string;
  sectionCode?: string;
  sectionName?: string;
  sectionSectionItemId?: number;
  segmentCode?: string;
  segmentName?: string;
  segmentSectionItemId?: number;
  partitionCode?: string;
  partitionName?: string;
  partitionSectionItemId?: number;
  comment?: string;
  inRecycleBin?: boolean;
}
