import { Component } from '@angular/core';
import { TableColumnOption } from '../../components/table-pro/table.config';
import { FormItemMeasuredProjectAverageTypeType } from '../../models/form/form-item-measured-project-average-type-type.enum';
import { FormItemMeasuredProjectInfo } from '../../models/form/form-item-measured-project-info.model';
import {
  FormItemMeasuredProjectRefParamType,
  FormItemMeasuredProjectRefParamTypeOptions,
} from '../../models/form/form-item-measured-project-ref-param-type.enum';
import {
  FormItemMeasuredProjectSpadType,
  FormItemMeasuredProjectSpadTypeOptions,
} from '../../models/form/form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectSpad } from '../../models/form/form-item-measured-project-spad.model';
import { DetectionInfo } from './dialog-form-zj-fill.component';

export class MeasuredProjectUtil {
  editing: { [name: string]: boolean } = {};

  FormItemMeasuredProjectSpadType = FormItemMeasuredProjectSpadType;

  record: FormItemMeasuredProjectInfo = new FormItemMeasuredProjectInfo();

  FormItemMeasuredProjectSpadTypeOptions =
    FormItemMeasuredProjectSpadTypeOptions;

  FormItemMeasuredProjectRefParamTypeOptions =
    FormItemMeasuredProjectRefParamTypeOptions;

  descList: string[] = [];

  detectionInfo = new DetectionInfo();

  currentSpad = new FormItemMeasuredProjectSpad();

  // recommandRemark = '';

  realVal1List: { value: string; hasError: boolean }[][] = [];

  realVal2List: { value: string; hasError: boolean }[][] = [];

  isMac = /macintosh|mac os x/i.test(navigator.userAgent);

  showExp = false;

  totalCount = 0;
  avg1 = '';
  avg2 = '';
  exp1 = '';
  exp2 = '';
  passRate = 0;

  special = false;

  showD1 = false;
  showD2 = false;

  onExpChange() {
    if (!!this.exp1 || !!this.exp2) {
      this.showExp = true;
    } else {
      this.showExp = false;
    }
  }

  FormItemMeasuredProjectRefParamType = FormItemMeasuredProjectRefParamType;

  // 特殊情况
  CXX: TableColumnOption[] = [
    {
      label: 'C15(≥15Mpa)',
      value: '≥15',
    },
    {
      label: 'C20(≥20Mpa)',
      value: '≥20',
    },
    {
      label: 'C25(≥25Mpa)',
      value: '≥25',
    },
    {
      label: 'C30(≥30Mpa)',
      value: '≥30',
    },
    {
      label: 'C35(≥35Mpa)',
      value: '≥35',
    },
    {
      label: 'C40(≥40Mpa)',
      value: '≥40',
    },
    {
      label: 'C45(≥45Mpa)',
      value: '≥45',
    },
    {
      label: 'C50(≥50Mpa)',
      value: '≥50',
    },
    {
      label: 'C55(≥55Mpa)',
      value: '≥55',
    },
    {
      label: 'C60(≥60Mpa)',
      value: '≥60',
    },
    {
      label: 'C65(≥65Mpa)',
      value: '≥65',
    },
    {
      label: 'C70(≥70Mpa)',
      value: '≥70',
    },
    {
      label: 'C75(≥75Mpa)',
      value: '≥75',
    },
    {
      label: 'C80(≥80Mpa)',
      value: '≥80',
    },
  ];

  MXX: TableColumnOption[] = [
    {
      label: 'M5(≥5Mpa)',
      value: '≥5',
    },
    {
      label: 'M7.5(≥7.5Mpa)',
      value: '≥7.5',
    },
    {
      label: 'M10(≥10Mpa)',
      value: '≥10',
    },
    {
      label: 'M15(≥15Mpa)',
      value: '≥15',
    },
    {
      label: 'M20(≥20Mpa)',
      value: '≥20',
    },
    {
      label: 'M25(≥25Mpa)',
      value: '≥25',
    },
    {
      label: 'M30(≥30Mpa)',
      value: '≥30',
    },
  ];

  ZS: TableColumnOption[] = [
    {
      label: '≥Ⅱ类',
      value: '≥-2',
    },
  ];

  ZSV: TableColumnOption[] = [
    {
      label: 'Ⅰ类',
      value: '-1',
    },
    {
      label: 'Ⅱ类',
      value: '-2',
    },
    {
      label: 'Ⅲ类',
      value: '-3',
    },
  ];

  // 桩身完整性
  // isZS = false;

  // specialOptions: TableColumnOption[] = [];

  // specialPlaceHolder = '';

  constructor() {}

  // init(data: FormItemMeasuredProjectInfo) {
  // this.record = this.handleDataBeforeInit(data!);
  // if (this.record.keywords?.includes('混凝土强度')) {
  //   this.special = true;
  //   this.specialOptions = this.CXX;
  //   this.specialPlaceHolder = '请选择混凝土强度';
  // }
  // if (this.record.keywords?.includes('砂浆强度')) {
  //   this.special = true;
  //   this.specialOptions = this.MXX;
  //   this.specialPlaceHolder = '请选择砂浆强度';
  // }
  // if (this.record.keywords?.includes('桩身完整性')) {
  //   this.special = true;
  //   this.specialOptions = this.ZS;
  //   this.specialPlaceHolder = '请选择类型';
  //   this.isZS = true;
  // }
  // }

  private calcAll() {
    let totalCount1 = 0;
    let totalCount2 = 0;
    let passCount = 0;
    let sumReal1 = 0;
    let sumReal2 = 0;

    let maxR = 0;

    if (this.detectionInfo.biasError) {
      for (const row of this.realVal1List) {
        for (const cell of row) {
          cell.hasError = false;
        }
      }
      for (const row of this.realVal2List) {
        for (const cell of row) {
          cell.hasError = false;
        }
      }
    } else {
      for (const row of this.realVal1List) {
        for (const col of row) {
          if (col.value !== '') {
            if (col.value.includes('.')) {
              maxR = Math.max(maxR, col.value.split('.')[1].length);
            }
            totalCount1++;
            sumReal1 += +col.value;
            if (
              +col.value >= this.detectionInfo.val1Min! &&
              +col.value <= this.detectionInfo.val1Max!
            ) {
              col.hasError = false;
              passCount++;
            } else {
              col.hasError = true;
            }
          } else {
            col.hasError = false;
          }
        }
      }
      for (const row of this.realVal2List) {
        for (const col of row) {
          if (col.value !== '') {
            if (col.value.includes('.')) {
              maxR = Math.max(maxR, col.value.split('.')[1].length);
            }
            totalCount2++;
            sumReal2 += +col.value;
            if (
              +col.value >= this.detectionInfo.val2Min! &&
              +col.value <= this.detectionInfo.val2Max!
            ) {
              col.hasError = false;
              passCount++;
            } else {
              col.hasError = true;
            }
          } else {
            col.hasError = false;
          }
        }
      }
    }

    // 根据下标进行计算

    let totalCount = totalCount1 + totalCount2;
    this.totalCount = totalCount;
    this.passRate =
      totalCount === 0 ? 0 : Math.round((passCount / totalCount) * 10000) / 100;
    const pow = Math.pow(10, maxR);
    this.avg1 =
      totalCount1 === 0
        ? ''
        : `${Math.round((sumReal1 / totalCount1) * pow) / pow}`;
    this.avg2 =
      totalCount2 === 0
        ? ''
        : `${Math.round((sumReal2 / totalCount2) * pow) / pow}`;
  }

  getResult(
    response: FormItemMeasuredProjectInfo
  ): Promise<FormItemMeasuredProjectInfo> {
    return new Promise((resolve) => {
      const data = JSON.parse(
        JSON.stringify(response)
      ) as FormItemMeasuredProjectInfo;
      if (data.designValue) {
        try {
          const designValues = data.designValue.split(' ');
          data.designValue1 = !!designValues[0].trim()
            ? designValues[0].trim()
            : '';
          data.designValue2 = !!designValues[1].trim()
            ? designValues[1].trim()
            : '';
        } catch (e) {}
      }
      if (
        data.averageValueType ===
        FormItemMeasuredProjectAverageTypeType.AVERAGE_VALUE
      ) {
        const averageValues = (data.averageValue ?? '').split(' ');
        this.avg1 = averageValues[0] ?? '';
        this.avg2 = averageValues[1] ?? '';
        this.showExp = false;
      } else {
        const expressionValues = (data.averageValue ?? '').split(' ');
        this.exp1 = expressionValues[0] ?? '';
        this.exp2 = expressionValues[1] ?? '';
        this.showExp = true;
      }
      this.parseRealData(data.measuredValue);
      this.record = data;
      if (data.spadValues && data.spadValues.length > 0) {
        for (let i = 0; i < data.spadValues.length; i++) {
          if (data.spadValues[i].selected) {
            setTimeout(() => {
              // 为了解决ngModelChange的问题，延迟100ms
              this.selectBias(i);
              return resolve(this.handleBeforeConfirm());
            }, 100);
            break;
          }
        }
      }
    });
  }

  // 再次进入编辑页面后，做一次实测数据的解析。将实测数据放到对应的位置，并且绘制表格
  private parseRealData(measuredValue?: string) {
    if (!!measuredValue) {
      const measuredValues = measuredValue
        .replaceAll('Ⅰ类', '-1')
        .replaceAll('Ⅱ类', '-2')
        .replaceAll('Ⅲ类', '-3')
        .split('\n');
      const m1 = measuredValues[0] ?? '';
      const m2 = measuredValues[1] ?? '';
      let m1List: string[] = [];
      let m2List: string[] = [];
      m1List = m1.split(' ');
      let rowCount = Math.ceil(m1List.length / 10);
      if (rowCount < 1) {
        rowCount = 1;
      }
      if (m1List.length % 10 === 0) {
        rowCount++;
      }
      for (let row = 0; row < rowCount; row++) {
        this.realVal1List[row] = [];
        for (let col = 0; col < 10; col++) {
          this.realVal1List[row][col] = {
            value: m1List[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
      m2List = m2.split(' ');
      rowCount = Math.ceil(m2List.length / 10);
      if (rowCount < 1) {
        rowCount = 1;
      }
      if (m2List.length % 10 === 0) {
        rowCount++;
      }
      for (let row = 0; row < rowCount; row++) {
        this.realVal2List[row] = [];
        for (let col = 0; col < 10; col++) {
          this.realVal2List[row][col] = {
            value: m2List[row * 10 + col] ?? '',
            hasError: false,
          };
        }
      }
    } else {
      this.realVal1List = [[]];
      this.realVal2List = [[]];
      for (let col = 0; col < 10; col++) {
        this.realVal1List[0][col] = {
          value: '',
          hasError: false,
        };
        this.realVal2List[0][col] = {
          value: '',
          hasError: false,
        };
      }
    }
  }

  // 通过用户选择规定值或允许偏差值，计算实际值
  selectBias(index: number) {
    for (const item of this.record.spadValues!) {
      item.selected = false;
    }
    this.record.spadValues![index].selected = true;
    this.detectionInfo.selectIndex = index;
    this.detectionInfo.biasStr = `${this.record.spadValues![index].spadValue}${
      this.record.spadValues![index].keywords
        ? `(${this.record.spadValues![index].keywords})`
        : ''
    }`;
    this.currentSpad = JSON.parse(
      JSON.stringify(this.record.spadValues![index])
    );

    if (
      this.currentSpad.refParamType &&
      // (this.currentSpad.refParamValue === undefined ||
      //   this.currentSpad.refParamValue === '') &&
      this.record.refParamValue !== undefined &&
      this.record.refParamValue !== ''
    ) {
      this.currentSpad.refParamValue = this.record.refParamValue;
    }
    this.parseBiasStr();
    this.calcAll();
  }

  // onRefValueChange() {
  //   if (
  //     this.currentSpad.refParamType ===
  //     FormItemMeasuredProjectRefParamType.DESIGN
  //   ) {
  //     this.currentSpad.refParamValue = this.record.designValue1 ?? '';
  //   }
  //   this.parseBiasStr();
  //   this.calcAll();
  // }

  // 用户输入bias信息后，进行解析。在初始化的时候也会做一次解析
  // onBiasChange() {
  //   if (!this.detectionInfo.biasStr) {
  //     this.detectionInfo.biasError = true;
  //     this.detectionInfo.selectIndex = -1;
  //     this.calcAll();
  //     return;
  //   }
  //   if (this.record.spadValues && this.record.spadValues.length > 0) {
  //     for (let i = 0; i < this.record.spadValues.length; i++) {
  //       const item = this.record.spadValues[i];
  //       if (
  //         this.detectionInfo.biasStr ===
  //         `${item.spadValue}${item.keywords ? `(${item.keywords})` : ''}`
  //       ) {
  //         if (this.detectionInfo.selectIndex !== i) {
  //           this.selectBias(i);
  //         }
  //         return;
  //       }
  //     }
  //   }

  //   this.detectionInfo.selectIndex = -1;
  //   // 丢给服务端判断是否是符合标准的表达式，然后进行解析
  //   this.omFormItemMeasuredProjectService
  //     .parseSpadExpression(this.detectionInfo.biasStr)
  //     .subscribe((response) => {
  //       if (response) {
  //         for (const item of this.record.spadValues ?? []) {
  //           item.selected = false;
  //         }
  //         this.detectionInfo.selectIndex = -1;
  //         this.currentSpad = response;
  //         this.currentSpad.selected = true;
  //         this.currentSpad.userDefined = true;
  //         this.parseBiasStr();
  //         this.calcAll();
  //       }
  //     });
  // }

  /**
   * 解析完整的bias信息。核心是将数据解析成对应的min和max
   *
   * @private
   * @return {*}
   * @memberof DialogFormZjFillComponent
   */
  private parseBiasStr() {
    this.detectionInfo.biasError = true;
    // this.recommandRemark = `${this.record.designValue1 ?? ''}${
    //   this.record.designValue2 ? ` ${this.record.designValue2}` : ''
    // }`;
    // this.record.remarkValue = this.recommandRemark;
    if (!this.detectionInfo.biasStr) {
      // this.recommandRemark = '';
      // this.record.remarkValue = this.recommandRemark;
      return;
    }
    if (
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.USER_FILLIN ||
      this.currentSpad.spadType ===
        FormItemMeasuredProjectSpadType.NOT_REQUIREMENT
    ) {
      // this.recommandRemark = '';
      // this.record.remarkValue = this.recommandRemark;
      // 用户自填项，或者无需填写。我们认为这是无法识别的信息。因此无法通过这个信息进行合格率的计算。对于这种情况，我们认为解析信息有误的。
      return;
    }
    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
    ) {
      // 允许偏差值。我们认为这是可以识别的信息。因此可以通过这个信息进行合格率的计算。
      // 允许偏差：±n 或『+n, -m』
      // this.recommandRemark = `${this.record.designValue1 ?? ''}${
      //   this.record.designValue2 ? ` ${this.record.designValue2}` : ''
      // }`;
      // this.record.remarkValue = this.recommandRemark;
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      try {
        // 允许偏差有两种情况，一种是±，一种是最大值和最小值
        let biasMin = 0;
        let biasMax = 0;
        if (this.currentSpad.spadValue!.includes('±')) {
          const bias = this.currentSpad.spadValue!.replace('±', '');
          if (this.currentSpad.refParamSymbol) {
            console.log(this.currentSpad.refParamSymbol);
            const abs = +eval(
              bias.replace(
                this.currentSpad.refParamSymbol,
                this.currentSpad.refParamValue!
              )
            );
            // 纯展示。没用
            this.currentSpad.calcDisplay = '±' + abs;
            biasMin = -abs;
            biasMax = abs;
          } else {
            biasMin = -+bias;
            biasMax = +bias;
          }
        }

        if (!this.record.designValue1) {
          // 如果没有设计值，那么无法计算最大值和最小值
          return;
        }
        if (this.currentSpad.spadValue!.includes(',')) {
          const bias = this.currentSpad.spadValue!.split(',');
          biasMin = Math.min(+bias[0], +bias[1]);
          biasMax = Math.max(+bias[0], +bias[1]);
        }
        this.detectionInfo.val1Min = +this.record.designValue1! + biasMin;
        this.detectionInfo.val1Max = +this.record.designValue1! + biasMax;
        if (
          this.record.designValue2 !== undefined &&
          this.record.designValue2 !== '' &&
          !isNaN(+this.record.designValue2)
        ) {
          this.detectionInfo.val2Min = +this.record.designValue2! + biasMin;
          this.detectionInfo.val2Max = +this.record.designValue2! + biasMax;
        }
        // 到这里确定没有错误
        this.detectionInfo.biasError = false;
      } catch (e) {
        return;
      }
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_SPECIFIED_VALUE
    ) {
      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }

      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≥', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      try {
        if (!isNaN(+bias) && !this.record.designValue1) {
          this.record.designValue1 = bias;
        }
      } catch (e) {}

      if (this.currentSpad.refParamSymbol) {
        console.log(this.currentSpad.refParamSymbol);
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.calcDisplay = '≥' + abs;
        this.detectionInfo.val1Min = +abs;
        this.detectionInfo.val1Max = Infinity;
        this.detectionInfo.val2Min = +abs;
        this.detectionInfo.val2Max = Infinity;
      } else {
        this.detectionInfo.val1Min = +bias;
        this.detectionInfo.val1Max = Infinity;
        this.detectionInfo.val2Min = +bias;
        this.detectionInfo.val2Max = Infinity;
      }

      this.detectionInfo.biasError = false;

      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_SPECIFIED_VALUE
    ) {
      // ≤ 规定值：≤ n

      if (
        this.currentSpad.refParamSymbol &&
        (this.currentSpad.refParamValue === '' ||
          this.currentSpad.refParamValue === undefined ||
          isNaN(+this.currentSpad.refParamValue))
      ) {
        return;
      }
      // ≥ 规定值：≥ n
      const bias = this.currentSpad
        .spadValue!.replace('≤', '')
        .replace('MIN', 'Math.min')
        .replace('MAX', 'Math.max');

      try {
        if (!isNaN(+bias) && !this.record.designValue1) {
          this.record.designValue1 = bias;
        }
      } catch (e) {}

      if (this.currentSpad.refParamSymbol) {
        console.log(this.currentSpad.refParamSymbol);
        const abs = +eval(
          bias.replace(
            this.currentSpad.refParamSymbol,
            this.currentSpad.refParamValue!
          )
        );
        // 纯展示。没用
        this.currentSpad.calcDisplay = '≤' + abs;
        this.detectionInfo.val1Min = -Infinity;
        this.detectionInfo.val1Max = +abs;
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +abs;
      } else {
        this.detectionInfo.val1Min = -Infinity;
        this.detectionInfo.val1Max = +bias;
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +bias;
      }

      this.detectionInfo.biasError = false;
      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.RANGE_SPECIFIED_VALUE
    ) {
      // ~ 规定值：~ n
      const bias = this.currentSpad
        .spadValue!.replace('~', ' ')
        .replace('～', ' ')
        .split(' ');
      const min = Math.min(+bias[0], +bias[1]);
      const max = Math.max(+bias[0], +bias[1]);
      this.detectionInfo.val1Min = min;
      this.detectionInfo.val1Max = max;
      this.detectionInfo.val2Min = min;
      this.detectionInfo.val2Max = max;
      this.detectionInfo.biasError = false;

      // this.recommandRemark = `${this.currentSpad.spadValue}`;
      // this.record.remarkValue = this.recommandRemark;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.GTE_DESIGN_VALUE
    ) {
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≥' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≥' + this.record.designValue2 : ''}`;
      // this.record.remarkValue = this.recommandRemark;
      // ≥ 设计值
      if (
        this.record.designValue1 === '' ||
        this.record.designValue1 === undefined ||
        isNaN(+this.record.designValue1)
      ) {
        // 如果没有设计值，那么无法计算最大值和最小值
        this.detectionInfo.biasError = true;
        return;
      }
      this.detectionInfo.val1Min = +this.record.designValue1!;
      this.detectionInfo.val1Max = Infinity;

      if (
        this.record.designValue2 !== undefined &&
        this.record.designValue2 !== '' &&
        !isNaN(+this.record.designValue2)
      ) {
        this.detectionInfo.val2Min = +this.record.designValue2!;
        this.detectionInfo.val2Max = Infinity;
      }
      this.detectionInfo.biasError = false;
    }

    if (
      this.currentSpad.spadType ===
      FormItemMeasuredProjectSpadType.LTE_DESIGN_VALUE
    ) {
      // this.recommandRemark = `${
      //   this.record.designValue1 ? '≤' + this.record.designValue1 : ''
      // }${this.record.designValue2 ? ' ≤' + this.record.designValue2 : ''}`;
      // this.record.remarkValue = this.recommandRemark;
      // ≤ 设计值
      if (
        this.record.designValue1 === '' ||
        this.record.designValue1 === undefined ||
        isNaN(+this.record.designValue1)
      ) {
        // 如果没有设计值，那么无法计算最大值和最小值
        this.detectionInfo.biasError = true;
        return;
      }
      this.detectionInfo.val1Min = -Infinity;
      this.detectionInfo.val1Max = +this.record.designValue1!;

      if (
        this.record.designValue2 !== undefined &&
        this.record.designValue2 !== '' &&
        !isNaN(+this.record.designValue2)
      ) {
        this.detectionInfo.val2Min = -Infinity;
        this.detectionInfo.val2Max = +this.record.designValue2!;
      }
      this.detectionInfo.biasError = false;
    }
  }

  // isNaN(content: number) {
  //   return window.isNaN(content);
  // }

  // cancel() {
  //   this.data.onCancel();
  // }

  // confirm() {
  //   this.submit();
  // }

  private handleBeforeConfirm(): FormItemMeasuredProjectInfo {
    const data = JSON.parse(
      JSON.stringify(this.record)
    ) as FormItemMeasuredProjectInfo;
    data.designValue = `${!!data.designValue1 ? data.designValue1 : ''} ${
      !!data.designValue2 ? data.designValue2 : ''
    }`;
    if (this.currentSpad.userDefined) {
      data.spadValues?.push(this.currentSpad);
    } else {
      data.spadValues![this.detectionInfo.selectIndex!] = this.currentSpad;
    }
    const realValues1 = [];
    const devValues1 = [];
    for (const row of this.realVal1List) {
      for (const cell of row) {
        if (cell.value !== '') {
          realValues1.push(cell.value);
          if (
            this.currentSpad.spadType !==
            FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
          ) {
            devValues1.push(cell.value);
          } else {
            devValues1.push(+cell.value - +data.designValue1!);
          }
        }
      }
    }
    const realValues2 = [];
    const devValues2 = [];
    if (
      this.record.designValue2 !== '' &&
      this.record.designValue2 !== undefined
    ) {
      for (const row of this.realVal2List) {
        for (const cell of row) {
          if (cell.value !== '') {
            realValues2.push(cell.value);
            if (
              this.currentSpad.spadType !==
              FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
            ) {
              devValues2.push(cell.value);
            } else {
              devValues2.push(+cell.value - +data.designValue2!);
            }
          }
        }
      }
    }
    data.measuredValue = `${realValues1.join(' ')}${
      realValues2.length > 0 ? '\n' + realValues2.join(' ') : ''
    }`;
    data.deviationValue = `${devValues1.join(' ')}${
      devValues2.length > 0 ? '\n' + devValues2.join(' ') : ''
    }`;

    data.refParamType = this.currentSpad.refParamType;
    data.refParamName = this.currentSpad.refParamName;
    data.refParamSymbol = this.currentSpad.refParamSymbol;
    data.refParamVariable = this.currentSpad.refParamVariable;
    data.refParamValue = this.currentSpad.refParamValue;
    data.spadDesc = this.currentSpad.spadDesc;
    data.spadValue = this.currentSpad.spadValue;
    data.spadType = this.currentSpad.spadType;
    data.qaRate = this.passRate + '%';
    data.qualifiedJudge = '合格';
    data.remarkValue = `${data.designValue1 ?? ''}${
      data.designValue2 ? ` ${data.designValue2}` : ''
    }`;

    if (!!this.exp1 || !!this.exp2) {
      data.averageValue = `${this.exp1}${this.exp2 ? ` ${this.exp2}` : ''}`;
      data.averageValueType =
        FormItemMeasuredProjectAverageTypeType.TYPICAL_VALUE;
    } else {
      data.averageValue = `${this.avg1}${this.avg2 ? ` ${this.avg2}` : ''}`;
      data.averageValueType =
        FormItemMeasuredProjectAverageTypeType.AVERAGE_VALUE;
    }
    return data;
  }

  // get canConfirm() {
  //   return true;
  // }

  // copy(str: string) {
  //   copy(str);
  //   this.nzMessage.info(`已复制${str}到剪贴板`);
  // }

  // private submit() {
  //   const data = this.handleDataBeforeConfirm(this.record);
  //   this.data.onConfirm!(data);
  // }
}
