import { Component, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import 'moment/locale/zh-cn';
import { LoadingService, ModalService, TransformableElement } from 'ng-devui';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, zip } from 'rxjs';

import {
  AlertDialogComponent,
  AlertDialogType,
} from 'src/app/modules/shared/components/alert-dialog/alert-dialog.component';
import { FilePreviewTypeListMap } from 'src/app/modules/shared/components/file-preview-entry/file-preview-entry.component';
import { TableColumnOption } from 'src/app/modules/shared/components/table-pro/table.config';
import { FileCountersignStatus } from 'src/app/modules/shared/models/form/file-countersign-status.enum';
import { FileSource } from 'src/app/modules/shared/models/form/file-source.enum';
import { FileType } from 'src/app/modules/shared/models/form/file-type.enum';
import { FlowInfo } from 'src/app/modules/shared/models/form/flow-info.model';
import { FormCopiesType } from 'src/app/modules/shared/models/form/form-copies-type.enum';
import { FormFlowInfo } from 'src/app/modules/shared/models/form/form-flow-info.model';
import { FormFlowSearchRequest } from 'src/app/modules/shared/models/form/form-flow-search-request.model';
import {
  FormFlowType,
  FormFlowTypeMap,
} from 'src/app/modules/shared/models/form/form-flow-type.enum';
import { FormFlow } from 'src/app/modules/shared/models/form/form-flow.model';
import { FormItemMeasuredProjectInfo } from 'src/app/modules/shared/models/form/form-item-measured-project-info.model';
import { FormItemWidgetType } from 'src/app/modules/shared/models/form/form-item-widget-type.enum';
import { FormPagesType } from 'src/app/modules/shared/models/form/form-pages-type.enum';
import { InitWorkReportRequest } from 'src/app/modules/shared/models/form/init-work-report-request.model';
import { SignPosType } from 'src/app/modules/shared/models/form/sign-pos-type.enum';
import { UserInfo } from 'src/app/modules/shared/models/form/user-info.model';
import { WidgetData } from 'src/app/modules/shared/models/form/widget-data.model';
import {
  WorkFlowActionType,
  WorkFlowActionTypeChangeableList,
  WorkFlowActionTypeMap,
} from 'src/app/modules/shared/models/form/work-flow-action-type.enum';
import { WorkFlowBatchInfo } from 'src/app/modules/shared/models/form/work-flow-batch-info.model';
import { WorkFlowBatchSignRequest } from 'src/app/modules/shared/models/form/work-flow-batch-sign-request.model';
import { WorkFlowFileInfo } from 'src/app/modules/shared/models/form/work-flow-file-info.model';
import { WorkFlowInfo } from 'src/app/modules/shared/models/form/work-flow-info.model';
import { WorkFlowSignFieldInfo } from 'src/app/modules/shared/models/form/work-flow-sign-field-info.model';
import {
  WorkFlowSignFieldType,
  WorkFlowSignFieldTypeMap,
} from 'src/app/modules/shared/models/form/work-flow-sign-field-type.enum';
import { WorkFlowSignerInfo } from 'src/app/modules/shared/models/form/work-flow-signer-info.model';
import { WorkFlowSignerTaskType } from 'src/app/modules/shared/models/form/work-flow-signer-task-type.enum';
import {
  WorkFlowHistoryStatusMap,
  WorkFlowStatus,
  WorkFlowStatusMap,
} from 'src/app/modules/shared/models/form/work-flow-status.enum';
import { WorkFlowTaskInfo } from 'src/app/modules/shared/models/form/work-flow-task-info.model';
import { WorkFlowTask } from 'src/app/modules/shared/models/form/work-flow-task.model';
import { WorkReportAttach } from 'src/app/modules/shared/models/form/work-report-attach.model';
import { WorkReportDocType } from 'src/app/modules/shared/models/form/work-report-doc-type.enum';
import { WorkReport } from 'src/app/modules/shared/models/form/work-report.model';
import { ProjectSectionItem } from 'src/app/modules/shared/models/project/project-section-item.model';
import {
  ProjectSectionUnitFormType,
  ProjectSectionUnitFormTypeMap,
  ProjectSectionUnitFormTypeMapLong,
} from 'src/app/modules/shared/models/project/project-section-unit-form-type.enum';
import { DepartmentListRequest } from 'src/app/modules/shared/models/staff/department-list-request.model';
import { Department } from 'src/app/modules/shared/models/staff/department.model';
import { OrganizationCategory } from 'src/app/modules/shared/models/staff/organization-category.enum';
import { OrganizationExtListRequest } from 'src/app/modules/shared/models/staff/organization-ext-list-request.model';
import { OrganizationListRequest } from 'src/app/modules/shared/models/staff/organization-list-request.model';
import { OrganizationMember } from 'src/app/modules/shared/models/staff/organization-member.model';
import { OrganizationType } from 'src/app/modules/shared/models/staff/organization-type.enum';
import { Organization } from 'src/app/modules/shared/models/staff/organization.model';
import { Post } from 'src/app/modules/shared/models/staff/post.model';
import { SignAuthInfo } from 'src/app/modules/shared/models/staff/sign-auth-info.model';
import { UserListRequest } from 'src/app/modules/shared/models/staff/user-list-request.model';
import { User } from 'src/app/modules/shared/models/staff/user.model';
import { NzTreeNode } from 'src/app/modules/shared/models/tree-node.model';
import {
  EventService,
  EventType,
} from 'src/app/modules/shared/providers/event.service';
import { GlobalService } from 'src/app/modules/shared/providers/global.service';
import { FileResponse } from 'src/app/modules/shared/providers/request/file-response.model';
import { CommonService } from 'src/app/modules/shared/services/common.service';
import { OmFormItemFlowService } from 'src/app/modules/shared/services/om-form-item-flow.service';
import { OmFormItemService } from 'src/app/modules/shared/services/om-form-item.service';
import { OmUserOrgService } from 'src/app/modules/shared/services/om-user-org.service';
import { OmUserPersonService } from 'src/app/modules/shared/services/om-user-person.service';
import { ProjectSectionItemService } from 'src/app/modules/shared/services/project-section-item.service';
import { ProjectSubformService } from 'src/app/modules/shared/services/project-sub-form.service';
import { StaffDepartmentService } from 'src/app/modules/shared/services/staff-department.service';
import { StaffOrganizationMemberService } from 'src/app/modules/shared/services/staff-organization-member.service';
import { StaffOrganizationService } from 'src/app/modules/shared/services/staff-organization.service';
import { StaffPostService } from 'src/app/modules/shared/services/staff-post.service';
import { StaffUserService } from 'src/app/modules/shared/services/staff-user.service';
import { WorkFlowTaskService } from 'src/app/modules/shared/services/work-flow-task.service';
import { WorkFlowUserService } from 'src/app/modules/shared/services/work-flow-user.service';
import { WorkReportService } from 'src/app/modules/shared/services/work-report.service';
import { environment } from 'src/environments/environment';
import { DialogCounterSignRejectComponent } from '../../../dialogs/dialog-counter-sign-reject/dialog-counter-sign-reject.component';
import { DialogSignPwdComponent } from '../../../dialogs/operations-manage/dialog-sign-pwd/dialog-sign-pwd.component';
import { WorkReportFileCountersignService } from './../../../../shared/services/work-report-file-countersign.service';

@Component({
  selector: 'app-custom-menu-form-sign',
  templateUrl: './custom-menu-form-sign.component.html',
  styleUrls: ['./custom-menu-form-sign.component.scss'],
})
export class CustomMenuFormSignComponent {
  hideLeft = false;

  hideRight = false;

  isDebug = false;

  isDevOrg = false;

  isOp = false;

  FilePreviewTypeListMap = FilePreviewTypeListMap;
  FileType = FileType;
  transformableImageElementRef!: TransformableElement;

  WorkFlowSignFieldType = WorkFlowSignFieldType;

  currentAttachFileId: string | undefined = '';

  currentSignFileId: string | undefined = '';

  WorkFlowHistoryStatusMap = WorkFlowHistoryStatusMap;

  WorkFlowActionTypeMap = WorkFlowActionTypeMap;

  FormFlowType = FormFlowType;

  FormFlowTypeMap = FormFlowTypeMap;

  environment = environment;

  postTreeNodeFull: NzTreeNode[] = [];

  orgTypeMap: { [key: string]: Organization[] } = {};

  userList: User[] = [];

  disabledAddress: string[] = [];

  FormPagesType = FormPagesType;

  projectWidgetData: WidgetData[] = [];

  orgList: Organization[] = [];

  orgMap: { [key: string]: Organization } = {};
  postMap: { [key: string]: Post } = {};
  userData: User[] = [];

  orgMemberMap: { [key: string]: OrganizationMember } = {};
  orgMemberInfoMap: { [key: string]: OrganizationMember[] } = {};

  rMap: { [key: string]: boolean } = {};
  fileCode: string = '';

  index = 0;

  pdfZoom = 1;
  pdfPages = 1;
  pdfTotal = 0;

  inited = false;

  centerWidth = 0;

  WorkFlowSignFieldTypeMap = WorkFlowSignFieldTypeMap;

  currentDragItem: WorkFlowSignFieldInfo | undefined = undefined;

  currentDragFieldPage: number | undefined = -1;
  currentDragFieldIndex: number | undefined = -1;

  currentWorkFlowInfo: WorkFlowInfo | undefined;
  workFlowInfoList: Array<WorkFlowInfo> = [];
  countersignFlow: WorkFlowInfo | undefined;

  FileReceiveReplyStatus = FileCountersignStatus;

  WorkReportDocType = WorkReportDocType;

  freeSigner: WorkFlowSignerInfo | undefined = undefined;

  normalWidgetMap: { [key: string]: Array<Array<WorkFlowSignFieldInfo>> } = {};

  minDateMap: { [key: string]: Date | undefined } = {};

  currentWidgetMap: { [key: string]: Array<Array<WorkFlowSignFieldInfo>> } = {};

  attachFileListMap: { [key: string]: Array<WorkFlowFileInfo> } = {};

  SignPosType = SignPosType;

  currentFileType = FileType.PDF;

  workFlowMap: { [key: string]: WorkFlowInfo } = {};

  dateFieldCache: { [key: string]: WorkFlowSignFieldInfo } = {};

  waitAddressList: string[] = [];

  workFlowKey: string | undefined;
  resp: any = {};

  historyList: WorkFlowTaskInfo[] = [];

  hideHistoryFlowId: number[] = [];

  flowEditMode = false;

  WorkFlowActionTypeChangeableList = WorkFlowActionTypeChangeableList;

  WorkFlowStatus = WorkFlowStatus;

  WorkFlowStatusMap = WorkFlowStatusMap;

  batchCount = 0;

  preparedIds: string[] = [];
  preparedSignIds: string[] = [];

  FileCountersignStatus = FileCountersignStatus;

  workReport?: WorkReport = undefined;

  projectInspection = false;

  projectInspectionTitle = '';

  // 施工记录（质保资料）
  workFlowInfoListC: Array<WorkFlowInfo> = [];
  cN: number = 0;
  cHide: boolean = false;
  // 监理记录（旁站与抽检）
  workFlowInfoListS: Array<WorkFlowInfo> = [];
  sN: number = 0;
  sHide: boolean = false;
  // 施工自检（工地试验室）
  workFlowInfoListCS: Array<WorkFlowInfo> = [];
  csN: number = 0;
  csHide: boolean = false;
  // 监理抽检（中心试验室）
  workFlowInfoListSS: Array<WorkFlowInfo> = [];
  ssN: number = 0;
  ssHide: boolean = false;

  FormCopiesType = FormCopiesType;

  inFlow = false;

  breakShield = false;

  reloadKeyflow = '';

  is027 = false;

  formItemMeasuredProjects: Array<FormItemMeasuredProjectInfo> = [];

  ProjectSectionUnitFormType = ProjectSectionUnitFormType;

  disabledDate = (current: Date): boolean => {
    if (!this.minDateMap[this.currentWorkFlowInfo!.workFlowId!]) return false;
    return (
      differenceInCalendarDays(
        current,
        this.minDateMap[this.currentWorkFlowInfo!.workFlowId!]!
      ) < 0
    );
  };
  constructor(
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private workFlowUserService: WorkFlowUserService,
    private omUserPersonService: OmUserPersonService,
    private workFLowTaskService: WorkFlowTaskService,
    private message: NzMessageService,
    private commonService: CommonService,
    private omUserOrgService: OmUserOrgService,
    private staffOrganizationService: StaffOrganizationService,
    private staffOrganizationMemberService: StaffOrganizationMemberService,
    private staffDepartmentService: StaffDepartmentService,
    private staffPostService: StaffPostService,
    private staffUserService: StaffUserService,
    private globalService: GlobalService,
    private omFormItemService: OmFormItemService,
    private workReportService: WorkReportService,
    private workReportFileCountersignService: WorkReportFileCountersignService,
    private projectSectionItemService: ProjectSectionItemService,
    private loadingService: LoadingService,
    private projectSubformService: ProjectSubformService,
    private omFormItemFlowService: OmFormItemFlowService,
    private events: EventService
  ) {
    this.isDevOrg =
      this.globalService.userInfo.organizationType ===
      OrganizationType.DEVELOPMENT;
    this.isOp = location.href.indexOf('/custom-form/admin') > -1;
  }

  get myUserInfo() {
    return this.globalService.userInfo;
  }

  @HostListener('window:message', ['$event'])
  handleMessage($event: any) {
    if ($event.origin !== location.origin) {
      return;
    }
    if (
      $event.data.type === 'redir' &&
      $event.data.data === window.location.href
    ) {
      console.log($event.data);
      this.resp = $event.data;
      if (this.workReport) {
        this.ngOnInit(this.currentWorkFlowInfo?.workFlowKey);
      } else if (this.currentWorkFlowInfo?.workFlowKey) {
        this.ngOnInit(this.currentWorkFlowInfo?.workFlowKey);
      }
    }
  }

  ngOnDestroy(): void {
    this.events.destroyListener(
      EventType.BREAK_SHIELD,
      this.breakShieldSubsciption
    );
  }

  breakShieldSubsciption = (v: boolean) => {
    this.breakShield = v;
  };

  ngOnInit(workFlowKey?: string): void {
    this.events.on(EventType.BREAK_SHIELD, this.breakShieldSubsciption);
    this.workFlowKey = this.activatedRoute.snapshot.params['id'];
    this.normalWidgetMap = {};
    this.currentWidgetMap = {};
    this.workFlowMap = {};
    this.dateFieldCache = {};
    this.waitAddressList = [];
    this.rMap = {};
    if (!workFlowKey) {
      workFlowKey = this.workFlowKey;
    }
    console.log('workFlowKey', workFlowKey);
    this.workFlowUserService
      .getWorkFlowInfoByWorkFlowKey(workFlowKey!)
      .subscribe((response: WorkFlowInfo) => {
        if (!response) {
          return;
        }
        this.prepareWorkFlowInfo(response, workFlowKey!);
      });
  }

  private getSectionItem(sectionItemId: number) {
    this.projectSectionItemService
      .findById(sectionItemId!)
      .subscribe((response: ProjectSectionItem) => {
        if (response) {
          this.projectInspectionTitle = `${response.name} [${response.fullCode}]`;
          this.is027 = response.projectTypeCode === '2001.001.005.027';
          this.fileCode = response.fullCode!;
        }
      });
  }

  private prepareWorkFlowInfo(workFlowInfo: WorkFlowInfo, workFlowKey: string) {
    this.currentWorkFlowInfo = undefined;
    this.projectInspection = false;
    if (workFlowInfo.sameGroupList && workFlowInfo.sameGroupList.length > 0) {
      const obs$: Observable<WorkFlowInfo>[] = [];
      for (const item of workFlowInfo.sameGroupList ?? []) {
        obs$.push(
          this.workFlowUserService.getWorkFlowInfoByWorkFlowKey(
            item.workFlowKey!
          )
        );
      }
      zip(obs$).subscribe((data: WorkFlowInfo[]) => {
        this.workFlowInfoListC = [];
        this.cN = 0;
        this.workFlowInfoListS = [];
        this.sN = 0;
        this.workFlowInfoListCS = [];
        this.csN = 0;
        this.workFlowInfoListSS = [];
        this.ssN = 0;
        this.workFlowInfoList = data.sort(
          (a, b) => (b.fileReceive ? 1 : 0) - (a.fileReceive ? 1 : 0)
        );
        this.handleWorkFlowInfos();
        for (let index = 0; index < this.workFlowInfoList.length; index++) {
          const item = this.workFlowInfoList[index];
          if (item.fileCountersign) {
            this.countersignFlow = item;
          }
          if (item.projectInspection && !this.projectInspection) {
            this.projectInspection = true;
            this.getSectionItem(item.sectionItemId!);
          }
          switch (item.formSource) {
            case ProjectSectionUnitFormType.CONSTRUCTION_RECORD:
              this.workFlowInfoListC.push(item);
              if (item.status) {
                this.cN++;
              }
              break;
            case ProjectSectionUnitFormType.SUPERVISE_RECORD:
              this.workFlowInfoListS.push(item);
              if (item.status) {
                this.sN++;
              }
              break;
            case ProjectSectionUnitFormType.CONSTRUCTION_SELF_CHECK:
              this.workFlowInfoListCS.push(item);
              if (item.status) {
                this.csN++;
              }
              break;
            case ProjectSectionUnitFormType.SUPERVISE_SPOT_CHECK:
              this.workFlowInfoListSS.push(item);
              if (item.status) {
                this.ssN++;
              }
              break;
          }
          if (this.currentWorkFlowInfo === undefined) {
            if (item.workFlowKey === workFlowKey) {
              if (item.fileCountersign && !this.isDevOrg) {
                continue;
              }
              this.chooseMain(item.workFlowKey!);
              document.title = !!this.currentWorkFlowInfo!.fileGroupName
                ? this.currentWorkFlowInfo!.fileGroupName
                : this.currentWorkFlowInfo!.workFlowTitle ?? '';
              this.initWidget();
            }
          }
        }

        // 之所以要再次循环，原因是可能存在一种情况，url中的workFlowKey是会签单，但是非建设单位的用户无法看到，所以无法选中要再次补选
        if (this.currentWorkFlowInfo === undefined) {
          for (let i = 0; i < this.workFlowInfoList.length; i++) {
            const item = this.workFlowInfoList[i];
            if (item.fileCountersign && !this.isDevOrg) {
              continue;
            }
            this.chooseMain(item.workFlowKey!);
            document.title = !!this.currentWorkFlowInfo!.fileGroupName
              ? this.currentWorkFlowInfo!.fileGroupName
              : this.currentWorkFlowInfo!.workFlowTitle ?? '';
            this.initWidget();
            break;
          }
        }
      });
    } else {
      this.workFlowInfoList = [workFlowInfo];
      if (workFlowInfo.fileCountersign) {
        this.countersignFlow = workFlowInfo;
      }
      if (workFlowInfo.projectInspection) {
        this.projectInspection = true;
      }
      if (workFlowInfo.sectionItemId) {
        this.getSectionItem(workFlowInfo.sectionItemId);
      }
      this.handleWorkFlowInfos();
      this.chooseMain(workFlowInfo.workFlowKey!);
      document.title = !!this.currentWorkFlowInfo!.fileGroupName
        ? this.currentWorkFlowInfo!.fileGroupName
        : this.currentWorkFlowInfo!.workFlowTitle ?? '';
      this.initWidget();
    }
  }

  private handleWorkFlowInfos() {
    this.batchCount = 0;
    for (const item of this.workFlowInfoList) {
      item.waitSign = false;
      for (const signer of item.signers ?? []) {
        // TODO: 不知道为啥要怎么改。先注释掉
        // if (
        //   item.status === WorkFlowStatus.CANCLE ||
        //   item.status === WorkFlowStatus.REJECT
        // ) {
        //   signer.workFlowStatus = item.status;
        // }
        if (signer.workFlowStatus === WorkFlowStatus.WAIT) {
          item.waitSign = true;
          break;
        }
      }
      this.workFlowMap[item.workFlowId!] = item;
      // 加入了批量签署的逻辑
      if (item.operateEnable && item.step === FormFlowType.SIGN) {
        this.batchCount++;
      }

      item.myFill = false;
      item.willMyTurn = false;
      if (!item.fileGroupCountersignStatus) {
        item.fileGroupCountersignStatus = item.fileCountersignStatus;
      }
      for (const flow of item.formFlows ?? []) {
        let maxTime = 0;
        for (const user of flow.userInfoList ?? []) {
          if (
            flow.step === FormFlowType.FILL_IN &&
            user.userId === this.globalService.userInfo.userId
          ) {
            item.myFill = true;
          }
          if (
            user.editAble &&
            user.userId === this.globalService.userInfo.userId
          ) {
            item.willMyTurn = true;
          }
          if (
            !flow.editAble &&
            user.editAble &&
            user.userId === this.globalService.userInfo.userId
          ) {
            flow.myTurn = true;
          }
          if (user.editAble) {
            flow.canSign = true;
          }
          if (user.operateTime) {
            maxTime = Math.max(maxTime, user.operateTime);
          } else {
            maxTime = Date.now();
          }
          flow.maxTime = maxTime;
        }
        flow.userInfoList?.sort((a, b) => {
          let aDate = a.operateTime ?? Date.now();
          let bDate = b.operateTime ?? Date.now();
          return aDate - bDate;
        });
        if (!flow.userInfoList || flow.userInfoList.length === 0) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          userInfo.organizationType = flow.organizationType;
          userInfo.organizationId = flow.organizationId;
          userInfo.departmentId = flow.departmentId;
          userInfo.postId = flow.postId;
          flow.userInfoList = [userInfo];
        }
      }
      // item.formFlows?.sort((a, b) => {
      //   if (a.execOrder !== b.execOrder) {
      //     return a.execOrder! - b.execOrder!;
      //   } else {
      //     return a.maxTime! - b.maxTime!;
      //   }
      // });
    }
  }

  private getOrganizationDepartment(
    appendSectionId?: number | undefined
  ): void {
    const projectSections = this.currentWorkFlowInfo!.sectionId
      ? [
          {
            sectionId: this.currentWorkFlowInfo!.sectionId,
          },
        ]
      : undefined;
    if (appendSectionId) {
      projectSections?.push({
        sectionId: appendSectionId,
      });
    }
    zip(
      this.staffOrganizationService.findAll<OrganizationListRequest>({
        projectId: this.globalService.projectId,
        projectSections,
      }),
      this.staffDepartmentService.findAll<DepartmentListRequest>({
        projectId: this.globalService.projectId,
      }),
      this.staffPostService.findAll({ type: null }),
      this.omFormItemService.getWidgetData({
        projectId: this.globalService.projectId,
        sectionId: this.currentWorkFlowInfo!.sectionId,
        type: FormItemWidgetType.PROJECT,
      }),
      this.staffUserService.findAll<UserListRequest>({
        projectSections,
        projectId: this.globalService.projectId,
        // hasPartTimeJob: true,
      }),
      this.staffOrganizationMemberService.findAll({
        projectId: this.globalService.projectId,
        projectSections,
      }),
      this.omUserOrgService.findAll<OrganizationExtListRequest>({
        projectId: this.globalService.projectId,
        projectSections,
      })
    ).subscribe(
      ([orgs, departments, posts, widgetData, users, orgMembers, orgExts]) => {
        // 0. 在构造树之前获取其他数据
        this.userList = users;
        this.projectWidgetData = widgetData ?? [];
        // 1. 计算履约人员数量
        const orgSealUserIdMap: { [key: string]: number[] } = {};
        const orgSealUserMap: { [key: string]: User[] } = {};
        for (const orgExt of orgExts ?? []) {
          if (orgExt.sealUsers && orgExt.sealUsers.length > 0) {
            orgSealUserIdMap[orgExt.organizationId!] = orgExt.sealUsers.map(
              (info) => info.userId!
            );
          }
        }
        const performanceCountMap: { [key: string]: number } = {};
        const postUserMap: { [key: string]: User[] } = {};
        for (const user of users) {
          if (user.performance) {
            performanceCountMap[
              `${user.organizationId}#${user.departmentId}#${user.postId}`
            ] = performanceCountMap[
              `${user.organizationId}#${user.departmentId}#${user.postId}`
            ]
              ? performanceCountMap[
                  `${user.organizationId}#${user.departmentId}#${user.postId}`
                ] + 1
              : 1;
          }
          if (!postUserMap[user.postId!]) {
            postUserMap[user.postId!] = [];
          }
          postUserMap[user.postId!].push(user);
          if (orgSealUserIdMap[user.organizationId!]?.includes(user.id!)) {
            if (!orgSealUserMap[user.organizationId!]) {
              orgSealUserMap[user.organizationId!] = [];
            }
            let exIdx = -1;
            for (
              let i = 0;
              i < orgSealUserMap[user.organizationId!].length;
              i++
            ) {
              if (orgSealUserMap[user.organizationId!][i].id === user.id) {
                exIdx = i;
                break;
              }
            }
            if (exIdx === -1) {
              orgSealUserMap[user.organizationId!].push(user);
            } else {
              if (user.mainPost) {
                orgSealUserMap[user.organizationId!][exIdx] = user;
              }
            }
          }
        }
        // 2. 获取机构成员数据
        for (const org of orgMembers ?? []) {
          this.orgMemberMap[org.memberId!] = org;
        }

        // 3. 构造树

        const orgMap: { [key: string]: Organization } = {};
        for (const org of orgs) {
          org.departmentList = [];
          orgMap[org.id!] = org;
        }
        // TODO: 可能出现一种情况，建设单位拉不到

        const departmentMap: { [key: string]: Department } = {};
        const departMapByOrgType: { [key: string]: Department[] } = {};
        for (const department of departments) {
          if (!departMapByOrgType[department.organizationType!]) {
            departMapByOrgType[department.organizationType!] = [];
          }
          department.postList = [];
          departmentMap[department.id!] = department;
          departMapByOrgType[department.organizationType!].push(department);
        }

        const postMap: { [key: string]: Post } = {};
        for (const post of posts) {
          if (post.departmentId && departmentMap[post.departmentId!]) {
            // TODO 建设单位似乎没有部门
            departmentMap[post.departmentId!].postList!.push(post);
          }
          postMap[post.id!] = post;
        }

        for (const department of departments) {
          if (
            !!department.organizationId &&
            orgMap[department.organizationId!]
          ) {
            orgMap[department.organizationId!].departmentList!.push(department);
          }
        }

        this.orgList = orgs;

        const treeNodesForAll: NzTreeNode[] = [];
        for (const org of orgs) {
          if (org.category === OrganizationCategory.INSTITUTION) {
            continue;
          }
          if (org.type === OrganizationType.CONSTRUCTION && this.is027) {
            let isCurrentSectionOrg = false;
            for (const section of org.projectSections ?? []) {
              if (
                `${section.sectionId}` ===
                `${this.currentWorkFlowInfo!.sectionId}`
              ) {
                isCurrentSectionOrg = true;
                break;
              }
            }
            if (
              !isCurrentSectionOrg &&
              `${org.id}` !== `${this.globalService.orgInfo.id}`
            ) {
              continue;
            }
          }
          // ORG层的值结构为 单位类型#单位ID
          const tNode: NzTreeNode = {
            value: `${org.type}#${org.id}`,
            label: org.shortName ?? org.name,
            children: [],
          };
          for (const department of departMapByOrgType[org.type!] ?? []) {
            const dNode: NzTreeNode = {
              value: `${org.type}#${org.id}#${department.id}`,
              label: department.name,
              children: [],
            };
            for (const post of department.postList ?? []) {
              if (post.name === '专监' || post.name === '对口专监') {
                continue;
              }
              const pNode: NzTreeNode = {
                value: `${org.type}#${org.id}#${department.id}#${post.id}`,
                label: `${post.name}${post.performance ? '[履约]' : ''}`,
                children: [],
              };
              for (const user of postUserMap[post.id!] ?? []) {
                if (
                  user.organizationId === org.id &&
                  user.departmentId === department.id
                ) {
                  pNode.children?.push({
                    value: `${org.type}#${org.id}#${department.id}#${post.id}#${user.id}`,
                    label: `${user.name}${post.performance ? '[履约]' : ''}`,
                    isLeaf: true,
                  });
                }
              }
              if (pNode.children?.length === 0) {
                pNode.children?.push({
                  value: `-`,
                  label: `请添加人员`,
                  isLeaf: true,
                  disabled: true,
                });
              }
              dNode.children!.push(pNode);
            }
            if (dNode.children!.length > 0) {
              tNode.children!.push(dNode);
            }
          }

          // 特殊处理单位类型
          if (org.type === OrganizationType.SUPERVISE) {
            // 专监 -96
            const zjNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-96`,
              label: '专监',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.SUPERVISE
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (
                  post.name &&
                  (post.name.indexOf('专监') > -1 ||
                    post.name.indexOf('试验检测工程师') > -1) &&
                  post.name !== '对口专监' &&
                  post.name !== '专监'
                ) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-96#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-96#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  zjNode.children!.push(pNode);
                }
              }
            }
            if (zjNode.children!.length > 0) {
              tNode.children!.push(zjNode);
            }
            // 对口专监 -95
            const dkNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-95`,
              label: '对口专监',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.SUPERVISE
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (
                  post.name &&
                  (post.name.indexOf('专监') > -1 ||
                    post.name.indexOf('试验检测工程师') > -1) &&
                  post.name !== '对口专监' &&
                  post.name !== '专监'
                ) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-95#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-95#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  dkNode.children!.push(pNode);
                }
              }
            }
            if (dkNode.children!.length > 0) {
              tNode.children!.push(dkNode);
            }
          }

          // 履约人员 -97
          if (org.type === OrganizationType.CONSTRUCTION) {
            const pfNode: NzTreeNode = {
              value: `${org.type}#${org.id}#-97`,
              label: '履约',
              children: [],
            };
            for (const department of departMapByOrgType[
              OrganizationType.CONSTRUCTION
            ] ?? []) {
              for (const post of department.postList ?? []) {
                if (!!post.performance) {
                  const pNode: NzTreeNode = {
                    value: `${org.type}#${org.id}#-97#${post.id}`,
                    label: `${post.name}${post.performance ? '[履约]' : ''}`,
                    children: [],
                  };
                  for (const user of postUserMap[post.id!] ?? []) {
                    if (
                      user.organizationId === org.id &&
                      user.departmentId === department.id
                    ) {
                      pNode.children?.push({
                        value: `${org.type}#${org.id}#-97#${post.id}#${user.id}`,
                        label: `${user.name}${
                          post.performance ? '[履约]' : ''
                        }`,
                        isLeaf: true,
                      });
                    }
                  }
                  if (pNode.children?.length === 0) {
                    pNode.children?.push({
                      value: `-`,
                      label: `请添加人员`,
                      isLeaf: true,
                      disabled: true,
                    });
                  }
                  pfNode.children!.push(pNode);
                }
              }
            }
            if (pfNode.children!.length > 0) {
              tNode.children!.push(pfNode);
            }
          }

          // 持章人 -98
          const czNode: NzTreeNode = {
            value: `${org.type}#${org.id}#-98`,
            label: '持章人',
            children: [],
          };
          for (const user of orgSealUserMap[org.id!] ?? []) {
            czNode.children?.push({
              value: `${org.type}#${org.id}#-98#${user.postId}#${user.id}`,
              label: `${user.name}${
                postMap[user.postId!]?.performance ? '[履约]' : ''
              }`,
              isLeaf: true,
            });
          }
          if (czNode.children?.length === 0) {
            czNode.children?.push({
              value: `-`,
              label: `请添加人员`,
              isLeaf: true,
              disabled: true,
            });
          }

          tNode.children!.push(czNode);

          if (tNode.children!.length > 0) {
            treeNodesForAll.push(tNode);
          }
        }
        this.postTreeNodeFull = treeNodesForAll;
        console.log('postTreeNodeFull', this.postTreeNodeFull);
      }
    );
  }

  reply() {
    this.workReportService
      .reply(this.currentWorkFlowInfo?.workOrderId!)
      .subscribe((orderId: number) => {
        this.workReportService
          .getWorkFlowKeyById(orderId)
          .subscribe((workFlowKey: string) => {
            window.open(`#/custom-form/sign/${workFlowKey}`, '_self');
            location.reload();
          });
      });
  }

  userInfoChange(user: UserInfo, flowIdx: number, userIdx: number) {
    console.log('userInfoChange');
    const flow = this.currentWorkFlowInfo!.formFlows![flowIdx];
    flow.userInfoList![userIdx] = user;
    if (
      flow.actionType === WorkFlowActionType.ANY ||
      flow.actionType === WorkFlowActionType.ALL
    ) {
      if (user.userId) {
        if (flow.userInfoList!.length === userIdx + 1) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          flow.userInfoList!.push(userInfo);
        }
      } else {
        flow.userInfoList!.splice(userIdx, 1);
        if (
          flow.userInfoList!.length === 0 ||
          !!flow.userInfoList![flow.userInfoList!.length - 1].userId
        ) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          flow.userInfoList!.push(userInfo);
        }
      }
    }
    this.calcExecOrderSpan();
  }

  private initWidget() {
    this.freeSigner = undefined;
    for (const item of this.currentWorkFlowInfo!.signers ?? []) {
      if (
        item.workFlowStatus === WorkFlowStatus.WAIT &&
        item.signPosType === SignPosType.FREE_LOCATION
      ) {
        this.freeSigner = item;
        break;
      }
    }
    for (const workFlowInfo of this.workFlowInfoList) {
      const normalFields: Array<WorkFlowSignFieldInfo> = [];
      let currentSigners: WorkFlowSignerInfo[] = [];
      for (const item of workFlowInfo.signers ?? []) {
        if (item.workFlowStatus === WorkFlowStatus.WAIT) {
          currentSigners.push(item);
        }
        if (item.workFlowStatus === WorkFlowStatus.FINISH) {
          normalFields.push(...item.signFields!);
        }
      }
      this.normalWidgetMap[workFlowInfo.workFlowId!] = [];
      this.minDateMap[workFlowInfo.workFlowId!] = undefined;
      this.currentWidgetMap[workFlowInfo.workFlowId!] = [];
      this.attachFileListMap[workFlowInfo.workFlowId!] = [];
      if (!!workFlowInfo.signFiles) {
        this.normalWidgetMap[workFlowInfo.workFlowId!].fill(
          [],
          0,
          workFlowInfo.signFiles!.length
        );
      } else {
        // console.warn('signFiles 不存在的记录:', workFlowInfo);
      }
      let minDate: Date | undefined = undefined;
      for (const item of normalFields) {
        const currentPage = +item.signFieldPosition!.posPage! - 1;
        if (!this.normalWidgetMap[workFlowInfo.workFlowId!][currentPage]) {
          this.normalWidgetMap[workFlowInfo.workFlowId!][currentPage] = [];
        }
        this.normalWidgetMap[workFlowInfo.workFlowId!][currentPage].push(item);
        if (item.signFieldType === WorkFlowSignFieldType.DATE_FIELD) {
          this.dateFieldCache[item.address!] = item;
          // 缓存数据处理
          if (
            !!item.content &&
            item.content.indexOf('=') === -1 &&
            item.content.indexOf('~') === -1
          ) {
            item.content = item.content
              .replace('年', '.')
              .replace('月', '.')
              .replace('日', '');
            item.contentDate = moment(item.content, 'YYYY.M.D')
              .toDate()
              .getTime();
            if (
              item.contentDate &&
              (!minDate || item.contentDate > minDate.getTime())
            ) {
              minDate = new Date(item.contentDate);
            }
            // 查看历史
            this.onFieldDateChange(item);
          }
        }
      }
      this.minDateMap[workFlowInfo.workFlowId!] = minDate;
      // console.warn('minDateMap', this.minDateMap);
      for (const currentSigner of currentSigners) {
        for (const item of currentSigner?.signFields ?? []) {
          item.flowId = currentSigner.flowId;
          item.flowType = currentSigner.flowType;
          item.signNodeOrder = currentSigner.signNodeOrder;
          item.singerOrder = currentSigner.singerOrder;

          const currentPage = +item.signFieldPosition!.posPage! - 1;
          if (!this.currentWidgetMap[workFlowInfo.workFlowId!][currentPage]) {
            this.currentWidgetMap[workFlowInfo.workFlowId!][currentPage] = [];
          }
          try {
            item.dataSourceObj = JSON.parse(item.dataSource!);
            if (item.dataSourceObj.key) {
              item.dataSourceObj.group = item.dataSourceObj.key;
            }
          } catch (e) {}
          if (item.signFieldType === WorkFlowSignFieldType.DATE_FIELD) {
            this.dateFieldCache[item.address!] = item;
            console.log('initWidget', item);
            // 特殊情况特殊处理 1
            if (!!item.content) {
              if (item.content.toLocaleUpperCase() === 'TODAY') {
                item.contentDate = Date.now();
                if (
                  item.contentDate &&
                  this.minDateMap[workFlowInfo.workFlowId!] &&
                  new Date(item.contentDate).getTime() <
                    new Date(
                      this.minDateMap[workFlowInfo.workFlowId!]!
                    ).getTime()
                ) {
                  item.contentDate = new Date(
                    this.minDateMap[workFlowInfo.workFlowId!]!
                  ).getTime();
                }
                this.onFieldDateChange(item);
              } else if (
                item.content.indexOf('=') === -1 &&
                item.content.indexOf('~') === -1
              ) {
                item.contentDate = moment(
                  item.content,
                  item.dataSource ?? 'YYYY年MM月DD日'
                )
                  .toDate()
                  .getTime();
                if (
                  item.contentDate &&
                  !this.minDateMap[workFlowInfo.workFlowId!]
                ) {
                  this.minDateMap[workFlowInfo.workFlowId!] = new Date(
                    item.contentDate
                  );
                }
                if (
                  item.contentDate &&
                  this.minDateMap[workFlowInfo.workFlowId!] &&
                  new Date(item.contentDate).getTime() <
                    new Date(
                      this.minDateMap[workFlowInfo.workFlowId!]!
                    ).getTime()
                ) {
                  item.contentDate = new Date(
                    this.minDateMap[workFlowInfo.workFlowId!]!
                  ).getTime();
                }
                this.onFieldDateChange(item);
              } else {
                this.waitAddressList.push(
                  item.content.replace('=', '').replace('~', '')
                );
              }
            } else {
              if (this.minDateMap[workFlowInfo.workFlowId!]) {
                item.contentDate = new Date(
                  this.minDateMap[workFlowInfo.workFlowId!]!
                ).getTime();
                this.onFieldDateChange(item);
              }
            }
          }
          if (item.signFieldType === WorkFlowSignFieldType.RADIO_FIELD) {
            try {
              item.dataSourceObj = JSON.parse(item.dataSource!);
              if (item.content === item.dataSourceObj.value) {
                this.rMap[item.dataSourceObj.group] = item.dataSourceObj.value;
              }
            } catch (e) {}
          }
          this.currentWidgetMap[workFlowInfo.workFlowId!][currentPage].push(
            item
          );
        }
      }
    }

    this.changeDateWithWaitAddress();
    this.checkSignPrepared();
    this.inited = true;
    setTimeout(() => {
      this.elementRef.nativeElement.parentElement.style.background =
        'transparent';
      this.resize();
      this.changePreview(0);
    }, 200);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.resize();
    }, 100);
    window.addEventListener('resize', () => {
      this.resize();
    });
  }

  hideLeftChange(v: boolean) {
    this.hideLeft = v;
    this.resize();
  }

  hideRightChange(v: boolean) {
    this.hideRight = v;
    this.resize();
  }

  downloadFirst(workflowInfo: WorkFlowInfo) {
    if (workflowInfo.signFiles && workflowInfo.signFiles.length > 0) {
      this.download(workflowInfo.signFiles[0].fileId!);
    }
  }

  download(fileId: string) {
    if (!fileId) {
      return;
    }
    const downloadLink = document.createElement('a');
    const fileUrl = environment.fileHost + fileId!;
    downloadLink.href = fileUrl;
    downloadLink.target = '_blank';
    downloadLink.download = fileId ?? '';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  downloadGroup(type: ProjectSectionUnitFormType) {
    const ids: number[] = [];
    for (const item of this.workFlowInfoList) {
      if (item.formSource === type && !!item.workOrderId) {
        ids.push(item.workOrderId!);
      }
    }
    if (ids.length === 0) {
      return;
    }
    const now = new Date();
    const fileDate = `${now.getFullYear()}${(now.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now
      .getHours()
      .toString()
      .padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}`;
    this.workReportService
      .downloadFileByIds({
        ids,
        fileName: `${fileDate}.pdf`,
      })
      .then((resp) => {
        resp.arrayBuffer().then((data) => {
          const blob = new Blob([data], {
            type: 'application/pdf',
          });
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.target = '_blank';
          downloadLink.download = `${this.fileCode}-${ProjectSectionUnitFormTypeMapLong[type]}-${fileDate}.pdf`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
        console.log('downloadGroup');
      });
  }

  getEventMessage(event: any) {
    console.log('getEventMessage', event);
  }

  read(fileId: string) {
    window.open(environment.fileHost + fileId, '_blank');
  }

  trig(history: WorkFlowTask) {
    const cur = JSON.parse(JSON.stringify(this.historyList));
    for (const item of cur) {
      if (item.workFlowId === history.workFlowId) {
        item.hide = !item.hide;
      }
    }
    this.historyList = cur;
  }

  private resize() {
    const width = document.body.clientWidth;
    let c = width;
    if (!this.hideLeft) {
      c -= 300;
    }
    if (!this.hideRight) {
      c -= 310;
    }
    this.centerWidth = c;
  }

  private changeDateWithWaitAddress() {
    for (const key in this.currentWidgetMap) {
      for (const page of this.currentWidgetMap[key]) {
        for (const field of page ?? []) {
          if (field.signFieldType === WorkFlowSignFieldType.DATE_FIELD) {
            console.log('changeDateWithWaitAddress', field);
            if (
              !!field.content &&
              (field.content.indexOf('=') === 0 ||
                field.content.indexOf('~') === 0)
            ) {
              const dateField =
                this.dateFieldCache[
                  field.content.replace('=', '').replace('~', '')
                ];
              if (!!dateField && !!dateField.contentDate) {
                field.contentDate = dateField.contentDate;

                if (
                  field.contentDate &&
                  this.minDateMap[key] &&
                  new Date(field.contentDate).getTime() <
                    new Date(this.minDateMap[key]!).getTime()
                ) {
                  field.contentDate = new Date(this.minDateMap[key]!).getTime();
                }
                this.onFieldDateChange(field);
              }
            }
          }
        }
      }
    }
  }

  chooseMain(workFlowKey: string, manual?: boolean) {
    if (!manual) {
      // 不是手动，就要帮助定位
      setTimeout(() => {
        const item = document.getElementById(workFlowKey);
        if (item) {
          item.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 100);
      // } else {
      //   if (this.workReport) {
      //     this.events.broadcast(
      //       EventType.SAVE_RECORD,
      //       this.workReport.workFlowKey
      //     );
      //     if (
      //       !this.workReport.id &&
      //       (this.currentWorkFlowInfo?.status === WorkFlowStatus.CANCLE ||
      //         this.currentWorkFlowInfo?.status === WorkFlowStatus.REJECT)
      //     ) {
      //       // 弹窗：正在保存中，请稍侯
      //       this.reloadKeyflow = workFlowKey;
      //       const loading = this.loadingService.open({
      //         message: '正在保存中，请稍侯',
      //       });
      //       return;
      //     }
      //   }
      // 如果是手动，当前有workReport
    }
    // this.events.broadcast(EventType.STOP_AUTO_SAVE);
    this.workReport = undefined;
    this.editModeChange(false);
    let index = 0;
    for (let i = 0; i < this.workFlowInfoList.length; i++) {
      if (this.workFlowInfoList[i].workFlowKey === workFlowKey) {
        index = i;
        break;
      }
    }
    this.currentWorkFlowInfo = this.workFlowInfoList[index];
    if (this.currentWorkFlowInfo.status === WorkFlowStatus.DRAFT) {
      //   let editable = false;
      //   for (const flow of this.currentWorkFlowInfo.formFlows ?? []) {
      //     if (flow.step === FormFlowType.FILL_IN) {
      //       if (flow.postId) {
      //         if (
      //           flow.postId === this.globalService.userInfo.postId &&
      //           flow.organizationId === this.globalService.userInfo.organizationId
      //         ) {
      //           editable = true;
      //           break;
      //         }
      //       } else if (flow.departmentId) {
      //         if (
      //           flow.departmentId === this.globalService.userInfo.departmentId &&
      //           flow.organizationId === this.globalService.userInfo.organizationId
      //         ) {
      //           editable = true;
      //           break;
      //         }
      //       } else {
      //         if (
      //           flow.organizationId === this.globalService.userInfo.organizationId
      //         ) {
      //           editable = true;
      //           break;
      //         }
      //       }
      //     }
      //   }
      //   if (!editable) {
      //     this.message.warning('没有权限编辑/创建本表单');
      //   } else {
      this.edit();
      //   }
      return;
    }
    if (
      !this.currentWorkFlowInfo.status &&
      !this.currentWorkFlowInfo.workOrderId
    ) {
      //   let addable = true;
      //   for (const flow of this.currentWorkFlowInfo.formFlows ?? []) {
      //     if (flow.step === FormFlowType.FILL_IN) {
      //       if (flow.postId) {
      //         if (
      //           flow.postId === this.globalService.userInfo.postId &&
      //           flow.organizationType ===
      //             this.globalService.userInfo.organizationType
      //         ) {
      //           addable = true;
      //           break;
      //         }
      //       } else if (flow.departmentId) {
      //         if (
      //           flow.departmentId === this.globalService.userInfo.departmentId &&
      //           flow.organizationType ===
      //             this.globalService.userInfo.organizationType
      //         ) {
      //           addable = true;
      //           break;
      //         }
      //       } else {
      //         if (
      //           flow.organizationType ===
      //           this.globalService.userInfo.organizationType
      //         ) {
      //           addable = true;
      //           break;
      //         }
      //       }
      //     }
      //   }
      //   if (!addable) {
      //     this.message.warning('您没有权限创建本表单');
      //   } else {
      this.add();
      //   }
      return;
    }
    console.log('chooseMain', this.currentWorkFlowInfo);

    this.currentAttachFileId = undefined;
    this.currentFileType = FileType.PDF;
    this.freeSigner = undefined;
    for (const item of this.currentWorkFlowInfo.signers ?? []) {
      if (
        item.workFlowStatus === WorkFlowStatus.WAIT &&
        item.signPosType === SignPosType.FREE_LOCATION
      ) {
        this.freeSigner = item;
        break;
      }
    }
    this.inFlow = false;

    // !! 特殊处理 JTYS104
    let JTYS104fillOrgId = undefined;
    let filler027OrgId = undefined;
    for (const flow of this.currentWorkFlowInfo.formFlows ?? []) {
      if (this.currentWorkFlowInfo.formCode === 'JTYS104') {
        if (flow.step === FormFlowType.FILL_IN) {
          for (const user of flow.userInfoList ?? []) {
            JTYS104fillOrgId = user.organizationId;
            break;
          }
        }
        if (
          flow.organizationType === OrganizationType.CONSTRUCTION &&
          flow.step !== FormFlowType.FILL_IN
        ) {
          flow.organizationId = undefined;
        }
      }
      if (this.is027) {
        if (flow.step === FormFlowType.FILL_IN) {
          for (const user of flow.userInfoList ?? []) {
            filler027OrgId = user.organizationId;
            break;
          }
        }
      }
    }
    // !! 特殊处理
    if (JTYS104fillOrgId) {
      this.staffOrganizationService
        .findById(JTYS104fillOrgId)
        .subscribe((org) => {
          console.log('JTYS104fillOrgId', org);
          for (const section of org.projectSections ?? []) {
            this.currentWorkFlowInfo!.sectionId = section.sectionId;
            break;
          }
          this.getHistory();
          this.calcExecOrderSpan();
          this.getOrganizationDepartment();
        });
    } else if (this.is027) {
      this.staffOrganizationService
        .findById(filler027OrgId!)
        .subscribe((org) => {
          console.log('filler027', org);
          // this.currentWorkFlowInfo!.sectionId = user.sectionId;
          let appendSectionId: number | undefined;
          for (const section of org.projectSections ?? []) {
            if (
              `${this.currentWorkFlowInfo?.sectionId}` !==
                `${section.sectionId}` &&
              !section.beamYardSectionTag
            ) {
              appendSectionId = section.sectionId;
            }
            break;
          }
          this.getHistory();
          this.calcExecOrderSpan();
          this.getOrganizationDepartment(appendSectionId);
        });
    } else {
      this.getHistory();
      this.calcExecOrderSpan();
      this.getOrganizationDepartment();
    }
  }

  private getHistory() {
    this.workFLowTaskService
      .listWorkFlowTaskByWorkOrderId(this.currentWorkFlowInfo?.workOrderId!)
      .subscribe((res: WorkFlowTaskInfo[]) => {
        this.historyList = res;
        for (const flow of this.historyList) {
          flow.execOrderSpan = undefined;
          flow.nameLabel = flow.executorName;
          if (flow.executorProperties) {
            const list = flow.executorProperties.split(';');
            for (const property of list) {
              const str = '[' + property + ']';
              flow.nameLabel = flow.nameLabel + str;
            }
          }
        }
        let idx = this.historyList.length - 1;

        while (idx > 0) {
          const flow = this.historyList[idx];
          const prev = this.historyList[idx - 1];

          if (flow.execOrder === prev.execOrder) {
            prev.execOrderSpan = (flow.execOrderSpan ?? 1) + 1;
            flow.execOrderSpan = undefined;
          }
          idx--;
        }
        for (let i = 0; i < this.historyList.length; i++) {
          const cur = this.historyList[i];
          const next = this.historyList[i + 1];
          cur.hide = true;
          if (cur.workFlowId !== next?.workFlowId) {
            cur.hiddenTrigger = true;
          }
        }
      });
  }

  editModeChange(value: boolean) {
    if (value === this.flowEditMode) {
      return;
    }
    this.flowEditMode = value;
    if (this.flowEditMode) {
      for (const flow of this.currentWorkFlowInfo!.formFlows!) {
        if (
          flow.actionType === WorkFlowActionType.ANY ||
          flow.actionType === WorkFlowActionType.ALL
        ) {
          let flowFinish = true;
          if (flow.editAble) {
            flowFinish = false;
          }
          for (const user of flow.userInfoList ?? []) {
            if (user.editAble) {
              flowFinish = false;
            }
          }
          if (!flowFinish) {
            if (
              !flow.userInfoList ||
              flow.userInfoList.length === 0 ||
              !!flow.userInfoList[flow.userInfoList.length - 1].userId
            ) {
              const userInfo = new UserInfo();
              userInfo.auto = true;
              flow.userInfoList!.push(userInfo);
            }

            // const userInfo = new UserInfo();
            // userInfo.auto = true;
            // flow.userInfoList!.push(userInfo);
          }
        }
      }
      this.calcExecOrderSpan();
    } else {
      this.workFlowUserService
        .getWorkFlowInfo(this.currentWorkFlowInfo!.workFlowId!)
        .subscribe((response: WorkFlowInfo) => {
          if (response) {
            this.currentWorkFlowInfo!.formFlows = response.formFlows;
            for (let i = 0; i < this.workFlowInfoList.length; i++) {
              if (
                this.workFlowInfoList[i].workFlowId ===
                this.currentWorkFlowInfo!.workFlowId
              ) {
                this.workFlowInfoList[i].formFlows = response.formFlows;
              }
            }
            this.handleWorkFlowInfos();
            // !! 特殊处理 JTYS104
            if (this.currentWorkFlowInfo!.formCode === 'JTYS104') {
              for (const flow of this.currentWorkFlowInfo!.formFlows ?? []) {
                if (
                  flow.organizationType === OrganizationType.CONSTRUCTION &&
                  flow.step !== FormFlowType.FILL_IN
                ) {
                  flow.organizationId = undefined;
                }
              }
            }
            this.calcExecOrderSpan();
          }
        });
    }
  }

  private calcExecOrderSpan() {
    if (
      !this.currentWorkFlowInfo!.formFlows ||
      this.currentWorkFlowInfo!.formFlows?.length === 0
    ) {
      return;
    }
    for (const flow of this.currentWorkFlowInfo!.formFlows) {
      flow.execOrderSpan = undefined;
      flow.execOrderSpanHeight = undefined;
    }
    let idx = this.currentWorkFlowInfo!.formFlows.length - 1;

    while (idx > 0) {
      const flow = this.currentWorkFlowInfo!.formFlows[idx];
      const prev = this.currentWorkFlowInfo!.formFlows[idx - 1];

      if (flow.execOrder === prev.execOrder) {
        prev.execOrderSpanHeight =
          (flow.execOrderSpanHeight ?? flow.userInfoList!.length) +
          prev.userInfoList!.length;
        flow.execOrderSpanHeight = undefined;
        console.log('calcExecOrderSpan: ', prev.execOrderSpanHeight);

        prev.execOrderSpan = (flow.execOrderSpan ?? 1) + 1;
        flow.execOrderSpan = undefined;
      }
      idx--;
    }
    let jump = 0;
    let execOrder = 0;
    for (
      let index = 0;
      index < this.currentWorkFlowInfo!.formFlows.length;
      index++
    ) {
      const flow = this.currentWorkFlowInfo!.formFlows[index];
      if (jump > 0) {
        jump--;
        flow.execOrder = execOrder - 1;
        continue;
      }
      if (flow.execOrderSpan) {
        flow.execOrder = execOrder;
        jump = flow.execOrderSpan - 1;
        execOrder++;
        continue;
      } else {
        flow.execOrder = execOrder;
      }
      execOrder++;
    }
  }

  removeFlow(index: number) {
    const flow = this.currentWorkFlowInfo!.formFlows![index];
    if (
      (flow.step !== FormFlowType.CC && flow.step !== FormFlowType.PRESENT) ||
      (flow.newAdded && flow.auto)
    ) {
      return;
    }
    if (
      flow?.newAdded ||
      (!flow?.newAdded &&
        flow?.step !== FormFlowType.PRESENT &&
        flow?.step !== FormFlowType.CC)
    ) {
    }
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除' + (flow?.step === FormFlowType.CC ? '抄送' : '呈递'),
        content: '确认删除？',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.confirm,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          results.modalInstance.hide();
          this.currentWorkFlowInfo!.formFlows!.splice(index, 1);
          this.calcExecOrderSpan();
        },
      },
    });
  }

  addPrCC(
    auto: boolean,
    isCC: boolean,
    index: number,
    id?: number,
    org?: OrganizationMember,
    musted?: boolean
  ) {
    const flow = new FormFlowInfo();
    flow.step = isCC ? FormFlowType.CC : FormFlowType.PRESENT;
    flow.newAdded = true;
    flow.enable = true;
    flow.formEnable = true;
    flow.editAble = true;
    flow.userEditAble = true;
    flow.auto = auto;
    flow.musted = musted;
    flow.formId = this.currentWorkFlowInfo!.formFlows![0].formId;
    flow.tableId = this.currentWorkFlowInfo!.formFlows![0].tableId;
    flow.firstRow = this.currentWorkFlowInfo!.formFlows![0].firstRow;
    flow.firstCol = this.currentWorkFlowInfo!.formFlows![0].firstCol;
    flow.address = this.currentWorkFlowInfo!.formFlows![0].address;
    flow.signTaskType = WorkFlowSignerTaskType.ALL;
    if (id) {
      flow.organizationId = id;
      flow.organizationType = this.orgList.find((o) => +o.id! === +id!)?.type;
    }
    if (this.currentWorkFlowInfo!.formFlows) {
      if (index === -1) {
        const last =
          this.currentWorkFlowInfo!.formFlows[
            this.currentWorkFlowInfo!.formFlows.length - 1
          ];
        flow.execOrder = last.execOrder! + 1;
        this.currentWorkFlowInfo!.formFlows.push(flow);
      } else {
        const prev = this.currentWorkFlowInfo!.formFlows[index];
        const next = this.currentWorkFlowInfo!.formFlows[index + 1];

        flow.execOrder = prev.execOrder! + 1;
        let actNum = 1;
        if (prev && next && prev.execOrder === next.execOrder) {
          actNum += 1;
        }
        for (
          let i = index + 1;
          i < this.currentWorkFlowInfo!.formFlows.length;
          i++
        ) {
          this.currentWorkFlowInfo!.formFlows[i].execOrder! += actNum;
        }
        this.currentWorkFlowInfo!.formFlows.splice(index + 1, 0, flow);
      }
    } else {
      this.currentWorkFlowInfo!.formFlows = [flow];
      flow.execOrder = 1;
    }

    if (org?.userId) {
      this.staffUserService.findById(org.userId).subscribe((res: User) => {
        const userInfo = new UserInfo();
        userInfo.organizationType = res.organizationType;
        userInfo.organizationId = res.organizationId;
        userInfo.departmentId = res.departmentId;
        userInfo.postId = res.postId;
        userInfo.userId = res.id;
        userInfo.userName = res.name;
        userInfo.performance = res.performance;
        userInfo.postPerformance = res.postPerformance;
        flow.userInfoList = [userInfo];
      });
    } else {
      flow.userInfoList = [new UserInfo()];
    }
    this.calcExecOrderSpan();
  }

  actionTypeChange(index: number) {
    console.log('actionTypeChange: ', index);
    // 修改执行顺序
    const flow = this.currentWorkFlowInfo!.formFlows![index]!;
    const prev = this.currentWorkFlowInfo!.formFlows![index - 1];
    const next = this.currentWorkFlowInfo!.formFlows![index + 1];
    let actNum = 0;
    if (flow.actionType !== WorkFlowActionType.SIGN_SYNC) {
      if (next && next.execOrder === flow.execOrder) {
        actNum += 1;
      }
      if (prev && prev.execOrder === flow.execOrder) {
        actNum += 1;
        this.currentWorkFlowInfo!.formFlows![index].execOrder!++;
      }
    } else {
      if (
        next &&
        next.execOrder !== flow.execOrder &&
        next.actionType === WorkFlowActionType.SIGN_SYNC
      ) {
        actNum -= 1;
      }
      if (
        prev &&
        prev.execOrder !== flow.execOrder &&
        prev.actionType === WorkFlowActionType.SIGN_SYNC
      ) {
        actNum -= 1;
        this.currentWorkFlowInfo!.formFlows![index].execOrder!--;
      }
    }

    for (
      let i = index + 1;
      i < (this.currentWorkFlowInfo!.formFlows!.length ?? 0);
      i++
    ) {
      this.currentWorkFlowInfo!.formFlows![i].execOrder! += actNum;
    }

    // 修改人员信息

    if (
      flow.actionType !== WorkFlowActionType.ALL &&
      flow.actionType !== WorkFlowActionType.ANY
    ) {
      if (
        this.currentWorkFlowInfo!.formFlows![index].userInfoList?.length! > 1
      ) {
        this.currentWorkFlowInfo!.formFlows![index].userInfoList = [
          this.currentWorkFlowInfo!.formFlows![index].userInfoList![0],
        ];
      }
    } else {
      if (
        this.currentWorkFlowInfo!.formFlows![index].userInfoList?.length === 1
      ) {
        const user =
          this.currentWorkFlowInfo!.formFlows![index].userInfoList![0];
        if (user.userId) {
          const userInfo = new UserInfo();
          userInfo.auto = true;
          this.currentWorkFlowInfo!.formFlows![index].userInfoList?.push(
            userInfo
          );
        }
      }
    }
    this.calcExecOrderSpan();
  }

  merge(index: number) {
    return;
    const flow = this.currentWorkFlowInfo!.formFlows![index];
    const next = this.currentWorkFlowInfo!.formFlows![index + 1];
    if (!next) {
      return;
    }
    next.execOrder = flow!.execOrder;
    for (
      let i = index + 2;
      i < (this.currentWorkFlowInfo!.formFlows!.length ?? 0);
      i++
    ) {
      this.currentWorkFlowInfo!.formFlows![i].execOrder! -= 1;
    }
    this.calcExecOrderSpan();
  }

  split(index: number) {
    return;
    for (
      let i = index + 1;
      i < (this.currentWorkFlowInfo!.formFlows!.length ?? 0);
      i++
    ) {
      this.currentWorkFlowInfo!.formFlows![i].execOrder! += 1;
    }
    this.calcExecOrderSpan();
  }

  chooseAttach(index: number) {
    this.currentAttachFileId =
      this.currentWorkFlowInfo!.attachFiles![index].fileId!;
    this.currentFileType =
      this.currentWorkFlowInfo!.attachFiles![index].fileType!;
  }

  previewAttachment(fileId: string) {
    window.open(environment.fileHost + fileId, '_blank');
  }

  onFileNameChange(index: number) {
    this.attachFileListMap[this.currentWorkFlowInfo!.workFlowId!] = JSON.parse(
      JSON.stringify(this.currentWorkFlowInfo?.appendFiles ?? [])
    );
    this.currentWorkFlowInfo!.appendFiles![index].editing = false;
  }

  uploadAppend(event: any) {
    const files: FileList = event.target['files'];
    const fileList = Array.from(files);
    zip(
      fileList.map((file: File) => {
        let needConvertToPdf = false;
        const fileName = file.name.toLowerCase();
        if (
          fileName.includes('doc') ||
          fileName.includes('docx') ||
          fileName.includes('xls') ||
          fileName.includes('xlsx') ||
          fileName.includes('ppt') ||
          fileName.includes('pptx')
        ) {
          needConvertToPdf = true;
        }
        return this.commonService.uploadFileWithoutProgress(
          file,
          needConvertToPdf
        );
      })
    ).subscribe((res: FileResponse[]) => {
      event.target['value'] = '';
      // TODO 待补充
      if (this.currentWorkFlowInfo!.appendFiles === undefined) {
        this.currentWorkFlowInfo!.appendFiles = [];
      }
      for (const url of res) {
        this.currentWorkFlowInfo!.appendFiles.push({
          fileName: url.fileName,
          // fileSource: FileSource.MANUAL,
          fileId: url.pdfFileId ? url.pdfFileId : url.fileId,
          fileType: url.pdfFileId ? FileType.PDF : url.fileType,
        });
      }
      this.attachFileListMap[this.currentWorkFlowInfo!.workFlowId!] =
        JSON.parse(JSON.stringify(this.currentWorkFlowInfo?.appendFiles ?? []));
    });
  }

  dismiss() {
    if (this.transformableImageElementRef) {
      this.transformableImageElementRef.removeElementListener();
    }
    if (window.opener) {
      window.opener.postMessage(this.resp);
    }
    window.close();
  }

  onFieldDateChange(field: WorkFlowSignFieldInfo) {
    if (field.contentDate === undefined) {
      return;
    }
    const d = new Date(field.contentDate);
    if (!field.content?.includes('~')) {
      field.content =
        field.signFieldPosition!.signFieldWidth! > 80
          ? `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
          : `${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`;
      field.format =
        field.signFieldPosition!.signFieldWidth! > 80
          ? `YYYY年M月D日`
          : 'YYYY.M.D';
    }
    if (this.waitAddressList.indexOf(field.address!) !== -1) {
      this.changeDateWithWaitAddress();
    }
    if (this.currentWorkFlowInfo?.formPagesType === FormPagesType.SCALABLE) {
      for (const key in this.currentWidgetMap) {
        for (const page of this.currentWidgetMap[key]) {
          for (const item of page ?? []) {
            if (
              item.signFieldType === WorkFlowSignFieldType.DATE_FIELD &&
              item.address === field.address
            ) {
              item.content = field.content;
              item.contentDate = field.contentDate;
            }
          }
        }
      }
    }
    this.checkSignPrepared();
  }

  sealChange(field: WorkFlowSignFieldInfo) {
    if (field.officeSealImgFileIdList!.length === 1) {
      return;
    }
    const curImg = field.sealFieldImgFileId;
    const i = field.officeSealImgFileIdList?.findIndex(
      (item) => item === curImg
    );
    if (i === field.officeSealImgFileIdList!.length - 1) {
      field.sealFieldImgFileId = field.officeSealImgFileIdList![0];
    } else {
      field.sealFieldImgFileId = field.officeSealImgFileIdList![i! + 1];
    }
    this.checkSignPrepared();
  }

  copyableCheck(field: WorkFlowSignFieldInfo) {
    if (this.currentWorkFlowInfo?.formPagesType === FormPagesType.SCALABLE) {
      for (const key in this.currentWidgetMap) {
        for (const page of this.currentWidgetMap[key]) {
          for (const item of page ?? []) {
            if (
              item.signFieldType === WorkFlowSignFieldType.REMARK_FIELD &&
              item.address === field.address
            ) {
              item.content = field.content;
            }
          }
        }
      }
    }
    this.checkSignPrepared();
  }

  onInput($event: any, field: WorkFlowSignFieldInfo, page: number) {
    field.content = $event.target['textContent']!;
    field.signFieldPosition!.signFieldHeight = $event.target.clientHeight;
    this.rePosition(field, page);
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  onDrop(event: any, pageIndex: number) {
    event.preventDefault();
    // console.log(event.draggable);
    // if (!event.draggable) {
    //   return;
    // }
    if (this.currentDragFieldPage === -1 || this.currentDragFieldIndex === -1) {
      if (this.currentDragItem === undefined) {
        return;
      }
      this.currentDragItem.signFieldPosition = {
        posPage: (pageIndex + 1).toString(),
        top: event.offsetY - this.currentDragItem.fieldHeight! / 2,
        left: event.offsetX - this.currentDragItem.fieldWidth! / 2,
        signFieldWidth: this.currentDragItem.fieldWidth,
        signFieldHeight: this.currentDragItem.fieldHeight,
        signFieldFontName: this.currentDragItem.fieldFontName,
        signFieldFontSize: this.currentDragItem.fieldFontSize,
      };
      this.currentDragItem.fileId =
        this.currentWorkFlowInfo!.signFiles![0].fileId;
      this.currentDragItem.content = '';
      this.currentDragItem.dataSource = '';
      this.currentDragItem.dataSourceObj = undefined;
      this.currentDragItem.dragable = true;
      this.currentDragItem.flowId = this.freeSigner?.flowId;
      this.currentDragItem.flowType = this.freeSigner?.flowType;
      this.currentDragItem.signNodeOrder = this.freeSigner?.signNodeOrder;
      this.currentDragItem.singerOrder = this.freeSigner?.singerOrder;
      if (
        !this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][pageIndex]
      ) {
        this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][
          pageIndex
        ] = [];
      }
      if (
        this.currentDragItem.signFieldType === WorkFlowSignFieldType.DATE_FIELD
      ) {
        this.currentDragItem.contentDate = Date.now();
        this.onFieldDateChange(this.currentDragItem);
      }
      this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][
        pageIndex
      ].push(this.currentDragItem);
      this.currentDragItem = undefined;
      this.changeDateWithWaitAddress();
    } else {
      const field =
        this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][
          this.currentDragFieldPage!
        ][this.currentDragFieldIndex!];
      if (event.target.parentElement.className.indexOf('sign-file') !== -1) {
        field.signFieldPosition!.top =
          event.offsetY - field.signFieldPosition!.signFieldHeight! / 2;
        field.signFieldPosition!.left =
          event.offsetX - field.signFieldPosition!.signFieldWidth! / 2;
        this.rePosition(field, pageIndex);
      }
      this.currentDragFieldPage = -1;
      this.currentDragFieldIndex = -1;
    }
  }

  private rePosition(field: WorkFlowSignFieldInfo, pageIndex: number) {
    if (field.signFieldPosition!.top! < 0) {
      field.signFieldPosition!.top = 0;
    }
    if (field.signFieldPosition!.left! < 0) {
      field.signFieldPosition!.left = 0;
    }
    if (
      field.signFieldPosition!.top! +
        field.signFieldPosition!.signFieldHeight! >
      this.currentWorkFlowInfo!.signFiles![0].imageInfos![pageIndex].pdfHeight!
    ) {
      field.signFieldPosition!.top =
        this.currentWorkFlowInfo!.signFiles![0].imageInfos![pageIndex]
          .pdfHeight! - field.signFieldPosition!.signFieldHeight!;
    }
    if (
      field.signFieldPosition!.left! +
        field.signFieldPosition!.signFieldWidth! >
      this.currentWorkFlowInfo!.signFiles![0].imageInfos![pageIndex].pdfWidth!
    ) {
      field.signFieldPosition!.left =
        this.currentWorkFlowInfo!.signFiles![0].imageInfos![pageIndex]
          .pdfWidth! - field.signFieldPosition!.signFieldWidth!;
    }
  }

  onDragStart(item: WorkFlowSignFieldInfo) {
    this.currentDragItem = JSON.parse(JSON.stringify(item));
  }

  onDragableMoveStart(page: number, index: number, dragable?: boolean) {
    if (!!dragable) {
      this.currentDragFieldPage = page;
      this.currentDragFieldIndex = index;
    }
  }

  removeDragable(page: number, index: number) {
    this.currentWidgetMap[this.currentWorkFlowInfo!.workFlowId!][page].splice(
      index,
      1
    );
  }

  onPageLoad(event: any) {
    this.pdfTotal = event.source.pdfDocument._pdfInfo.numPages;
    this.zoomIn();
    this.zoomOut();
    this.resize();
  }

  prePage() {
    if (this.pdfPages > 1) {
      this.pdfPages--;
    }
  }

  nextPage() {
    if (this.pdfPages < this.pdfTotal) {
      this.pdfPages++;
    }
  }

  originSize() {
    this.pdfZoom = 1;
  }

  zoomIn() {
    this.pdfZoom += 0.1;
    if (this.pdfZoom > 2) {
      this.pdfZoom = 2;
    }
  }

  zoomOut() {
    this.pdfZoom -= 0.1;
    if (this.pdfZoom < 0.5) {
      this.pdfZoom = 0.5;
    }
  }

  next() {
    throw new Error('Method not implemented.');
  }

  canConfirmSign() {
    return true;
  }

  signing = false;

  sign(isAll: boolean) {
    if (this.signing) {
      this.message.info('签字已经提交，请稍候...');
      return;
    }
    if (this.preparedIds.length === 0) {
      return;
    }
    if (
      this.currentWorkFlowInfo?.fileCountersign &&
      !this.preparedIds.includes(
        this.currentWorkFlowInfo!.workFlowId!.toString()
      )
    ) {
      return;
    }
    if (!isAll) {
      const content = this.signErrorHint(false);
      if (!!content) {
        const result = this.modalService.open({
          backdropCloseable: false,
          component: AlertDialogComponent,
          onClose: () => {},
          data: {
            title: '签字未完成',
            content,
            confirmBtnText: '确认',
            cancelBtnText: '确认',
            contentStyle: 'danger',
            type: AlertDialogType.simple,
            onCancel: () => {
              console.log('cancel');
              result.modalInstance.hide();
            },
          },
        });
        return;
      }
    }
    this.omUserPersonService
      .getSignAuthInfo()
      .subscribe((info: SignAuthInfo) => {
        if (info.needSignAuth) {
          this.showPwdDialog(info, isAll);
        } else {
          this.signComfirm(isAll);
        }
      });
  }

  private showPwdDialog(info: SignAuthInfo, isAll: boolean) {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: DialogSignPwdComponent,
      onClose: () => {},
      data: {
        data: {
          signAuthMode: info.signAuthMode,
        },
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          this.signComfirm(isAll);
          results.modalInstance.hide();
        },
      },
    });
  }

  private signComfirm(isAll: boolean) {
    this.signing = true;
    const req = this.handleBeforeSign(isAll);
    const results = this.loadingService.open();
    this.workFlowUserService
      .batchConfirmSign(req)
      .subscribe((data: boolean) => {
        this.signing = false;
        this.inited = false;
        results.loadingInstance.close();
        for (const info of req.workFlowBatchInfos ?? []) {
          if (this.attachFileListMap[info.workFlowId!]) {
            this.attachFileListMap[info.workFlowId!] = [];
          }
        }
        this.ngOnInit(this.currentWorkFlowInfo!.workFlowKey!);
      });
  }

  removeAttachment(index: number) {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '删除附件',
        content: '确认删除？',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.confirm,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          results.modalInstance.hide();
          this.currentWorkFlowInfo?.appendFiles?.splice(index, 1);
          this.attachFileListMap[this.currentWorkFlowInfo!.workFlowId!] =
            JSON.parse(
              JSON.stringify(this.currentWorkFlowInfo?.appendFiles ?? [])
            );
        },
      },
    });
  }

  checkSignPrepared() {
    let ids = Object.keys(this.currentWidgetMap);
    this.preparedIds = [];
    this.preparedSignIds = [];

    const flowMap: { [key: string]: WorkFlowInfo } = {};
    for (const flow of this.workFlowInfoList) {
      flowMap[flow.workFlowId!] = flow;
    }

    for (const id of ids) {
      let hasSignSeal = false;
      let remarkEmpty = false;
      let radioEmpty = false;
      let dateFilled = true;
      if (!flowMap[id].waitSign) {
        continue;
      }
      for (const pW of this.currentWidgetMap[id]) {
        if (!pW) {
          continue;
        }
        for (const field of pW) {
          if (
            field.signFieldType === WorkFlowSignFieldType.SIGN_FIELD ||
            field.signFieldType === WorkFlowSignFieldType.SEAL_FIELD
          ) {
            hasSignSeal = true;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.REMARK_FIELD &&
            !!field.musted &&
            !field.content
          ) {
            remarkEmpty = true;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.DATE_FIELD &&
            !!field.musted &&
            !field.contentDate
          ) {
            dateFilled = false;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.RADIO_FIELD &&
            !!field.musted
          ) {
            if (this.rMap[field.dataSourceObj.group] === undefined) {
              radioEmpty = true;
            }
          }
        }
      }
      if (!hasSignSeal || remarkEmpty || radioEmpty || !dateFilled) {
      } else {
        this.preparedIds.push(id);
        if (flowMap[id].step === FormFlowType.SIGN) {
          this.preparedSignIds.push(id);
        }
      }
    }
  }

  signErrorHint(isAll: boolean) {
    let title = '';
    let ids = Object.keys(this.currentWidgetMap);
    if (!isAll) {
      ids = [this.currentWorkFlowInfo!.workFlowId!.toString()];
    }

    const flowMap: { [key: string]: WorkFlowInfo } = {};
    for (const flow of this.workFlowInfoList) {
      flowMap[flow.workFlowId!] = flow;
    }

    for (const id of ids) {
      let hasSignSeal = false;
      let remarkEmpty = false;
      let radioEmpty = false;
      let dateFilled = true;
      if (!flowMap[id].waitSign) {
        continue;
      }
      for (const pW of this.currentWidgetMap[id]) {
        if (!pW) {
          continue;
        }
        for (const field of pW) {
          if (
            field.signFieldType === WorkFlowSignFieldType.SIGN_FIELD ||
            field.signFieldType === WorkFlowSignFieldType.SEAL_FIELD
          ) {
            hasSignSeal = true;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.REMARK_FIELD &&
            !!field.musted &&
            !field.content
          ) {
            remarkEmpty = true;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.DATE_FIELD &&
            !!field.musted &&
            !field.contentDate
          ) {
            dateFilled = false;
          }
          if (
            field.signFieldType === WorkFlowSignFieldType.RADIO_FIELD &&
            !!field.musted
          ) {
            if (this.rMap[field.dataSourceObj.group] === undefined) {
              radioEmpty = true;
            }
          }
        }
      }
      if (!hasSignSeal) {
        title = `${
          isAll ? '[' + this.workFlowMap[id].signFiles![0].fileName + ']' : ''
        }请至少拖拽一处您的签字或盖章`;
      }
      if (remarkEmpty) {
        title = `${
          isAll ? '[' + this.workFlowMap[id].signFiles![0].fileName + ']' : ''
        }请填写完所有的备注`;
      }
      if (radioEmpty) {
        title = `${
          isAll ? '[' + this.workFlowMap[id].signFiles![0].fileName + ']' : ''
        }请勾选完所有的单选框`;
      }
      if (!dateFilled) {
        title = `${
          isAll ? '[' + this.workFlowMap[id].signFiles![0].fileName + ']' : ''
        }请填写完所有的日期`;
      }
      if (title !== '') {
        console.log(id);
        break;
      }
    }
    return title;
  }

  handleBeforeSign(isAll: boolean): WorkFlowBatchSignRequest {
    const obj = new WorkFlowBatchSignRequest();
    obj.workFlowBatchInfos = [];

    // let ids = this.preparedIds;
    let ids = this.preparedSignIds;
    if (!isAll) {
      ids = [this.currentWorkFlowInfo!.workFlowId!.toString()];
    }
    for (const id of ids) {
      const info = new WorkFlowBatchInfo();
      info.workFlowId = +id;
      info.flowInfos = [] as FlowInfo[];
      info.attachFiles = this.attachFileListMap[id] ?? [];

      const flowInfoMap: { [key: string]: FlowInfo } = {};
      for (const pW of this.currentWidgetMap[id]) {
        if (!pW) {
          continue;
        }
        for (const field of pW) {
          if (!flowInfoMap[field.flowId!]) {
            flowInfoMap[field.flowId!] = new FlowInfo();
            flowInfoMap[field.flowId!].flowId = field.flowId;
            flowInfoMap[field.flowId!].flowType = field.flowType;
            flowInfoMap[field.flowId!].signNodeOrder = field.signNodeOrder;
            flowInfoMap[field.flowId!].singerOrder = field.singerOrder;
            flowInfoMap[field.flowId!].signFields = [];
          }
          const item = JSON.parse(
            JSON.stringify(field)
          ) as WorkFlowSignFieldInfo;
          if (item.signFieldType === WorkFlowSignFieldType.DATE_FIELD) {
            try {
              const d = new Date(item.contentDate!);

              item.content =
                item.signFieldPosition!.signFieldWidth! > 80
                  ? `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
                  : `${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`;
              field.format =
                field.signFieldPosition!.signFieldWidth! > 80
                  ? `YYYY年M月D日`
                  : 'YYYY.M.D';
            } catch (e) {}
            console.log(item);
          }
          if (item.signFieldType === WorkFlowSignFieldType.RADIO_FIELD) {
            if (
              this.rMap[item.dataSourceObj.group] !== undefined &&
              item.dataSourceObj.value.toString() ===
                this.rMap[item.dataSourceObj.group].toString()
            ) {
              item.content = '☑';
            } else {
              item.content = '□';
            }
          }
          flowInfoMap[field.flowId!].signFields!.push(item);
        }
      }
      for (const key in flowInfoMap) {
        info.flowInfos.push(flowInfoMap[key]);
      }
      if (info.flowInfos.length > 0) {
        obj.workFlowBatchInfos.push(info);
      }
    }
    return obj;
  }

  changePreview(index: number) {
    this.index = index;
    this.pdfZoom = 1;
    this.pdfPages = 1;
    if (this.transformableImageElementRef) {
      this.transformableImageElementRef.removeElementListener();
    }
    setTimeout(() => {
      this.resize();
      const e = this.getImgElement();
      if (e) {
        let scale = 1;
        if (e.clientHeight > e.clientWidth) {
          scale = (document.body.clientHeight - 40) / e.clientHeight;
        } else {
          scale = this.centerWidth / e.clientWidth;
        }
        if (scale > 1) {
          scale = 1;
        }
        this.transformableImageElementRef = new TransformableElement(e, {
          zoom: scale,
        });
      }
    }, 200);
  }

  private getImgElement(): HTMLElement {
    return this.elementRef.nativeElement.querySelector('img.preview');
  }

  reject() {
    if (this.signing) {
      return;
    }
    if (!this.currentWorkFlowInfo?.fileCountersign) {
      const results = this.modalService.open({
        backdropCloseable: false,
        component: AlertDialogComponent,
        onClose: () => {},
        data: {
          title: '退回',
          content: '请输入退回理由',
          cancelBtnText: '取消',
          confirmBtnText: '确认',
          contentStyle: 'danger',
          type: AlertDialogType.text,
          onCancel: () => {
            results.modalInstance.hide();
          },
          onConfirm: (msg: string) => {
            if (!!msg) {
              this.workFlowUserService
                .rejectSign({
                  workFlowId: this.currentWorkFlowInfo?.workFlowId,
                  rejectReason: msg,
                })
                .subscribe((data: boolean) => {
                  this.ngOnInit(this.currentWorkFlowInfo?.workFlowKey);
                });
              results.modalInstance.hide();
            }
          },
        },
      });
    } else {
      const tableColomnOptions: TableColumnOption[] = [];
      for (const workFlow of this.workFlowInfoList) {
        if (!workFlow.fileCountersign) {
          tableColomnOptions.push({
            label: workFlow.workFlowTitle!,
            value: workFlow.workFlowId!,
          });
        }
      }
      const results = this.modalService.open({
        backdropCloseable: false,
        component: DialogCounterSignRejectComponent,
        onClose: () => {},
        data: {
          data: {
            options: tableColomnOptions,
            workFlowId: this.currentWorkFlowInfo?.workFlowId,
          },
          onCancel: () => {
            results.modalInstance.hide();
          },
          onConfirm: () => {
            results.modalInstance.hide();
            this.ngOnInit(this.currentWorkFlowInfo?.workFlowKey);
          },
        },
      });
    }
  }

  // TODO 待执行的所有功能都在这里

  pressSign() {
    this.workFlowUserService
      .getUrgeSignMsg(this.currentWorkFlowInfo?.workFlowId!)
      .subscribe((content: string) => {
        const results = this.modalService.open({
          backdropCloseable: false,
          component: AlertDialogComponent,
          onClose: () => {},
          data: {
            title: '催签',
            content: content,
            cancelBtnText: '取消',
            confirmBtnText: '发送短信',
            contentStyle: 'primary',
            type: AlertDialogType.confirm,
            onCancel: () => {
              results.modalInstance.hide();
            },
            onConfirm: () => {
              this.workFlowUserService
                .urgeSign(this.currentWorkFlowInfo?.workFlowId!)
                .subscribe((data: boolean) => {
                  results.modalInstance.hide();
                });
            },
          },
        });
      });
  }

  rollBack(history: WorkFlowTask) {
    // TODO 回退
  }

  submitFlow() {
    const result = this.checkFlowSubmitable();
    if (!result) {
      this.message.error('请填写完整的信息流程');
      return;
    }
    const flowInfo = this.handleBeforeSubmit();
    this.workFlowUserService
      .batchChangeSign({
        workFlowId: flowInfo.workFlowId,
        formFlows: flowInfo.formFlows,
        changeReason: '-',
      })
      .subscribe((data: boolean) => {
        if (data) {
          //   this.editModeChange(false);
          window.open(`#/custom-form/sign/${flowInfo.workFlowKey}`, '_self');
          location.reload();
        }
      });
  }

  private checkFlowSubmitable() {
    const flowInfo = JSON.parse(
      JSON.stringify(this.currentWorkFlowInfo)
    ) as WorkFlowInfo;
    for (const flow of flowInfo.formFlows ?? []) {
      console.log(flow);
      if (flow.musted || !flow.editAble) {
        if (!flow.userInfoList || flow.userInfoList.length === 0) {
          return false;
        }
        if (!flow.userInfoList[0].userId && flow.musted) {
          return false;
        }
      }
    }
    return true;
  }

  private handleBeforeSubmit() {
    const flowInfo = JSON.parse(
      JSON.stringify(this.currentWorkFlowInfo)
    ) as WorkFlowInfo;
    for (const flow of flowInfo.formFlows ?? []) {
      if (
        flow.actionType === WorkFlowActionType.ANY ||
        flow.actionType === WorkFlowActionType.ALL
      ) {
        flow.userInfoList = (flow.userInfoList ?? []).filter((u) => !!u.userId);
      }
      flow.userIds = [];
      for (const user of flow.userInfoList ?? []) {
        if (user.userId) {
          flow.userIds.push(user.userId);
        }
      }
      // flow.userInfoList = (flow.userInfoList ?? []).filter((u) => !!u.userId);
    }
    return flowInfo;
  }

  cancel() {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '撤回',
        content: '请输入撤回理由',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.text,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: (msg: string) => {
          if (!!msg) {
            this.workFlowUserService
              .cancleSign({
                workFlowId: this.currentWorkFlowInfo?.workFlowId!,
                cancleReason: msg,
              })
              .subscribe((data: boolean) => {
                this.ngOnInit(this.currentWorkFlowInfo?.workFlowKey);
              });
            results.modalInstance.hide();
          }
        },
      },
    });
  }

  remove(byAdmin?: boolean) {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: this.currentWorkFlowInfo?.fileCountersign
          ? '删除会签单'
          : '删除',
        content: this.currentWorkFlowInfo?.fileCountersign
          ? '会签单删除后，主文件依然保留。<br> 确认删除？'
          : '删除后，此报表所有记录均被删除！',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.prompt,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: () => {
          if (!byAdmin) {
            this.removeRecord();
          } else {
            this.removeByAdmin();
          }
          results.modalInstance.hide();
        },
      },
    });
  }

  removeByAdmin() {
    this.workReportService
      .removeByAdmin(this.currentWorkFlowInfo?.workOrderId!)
      .subscribe((data: number) => {
        if (data) {
          if (this.workFlowInfoList.length === 0) {
            this.dismiss();
          } else {
            for (const workFlowInfo of this.workFlowInfoList) {
              if (
                workFlowInfo.workOrderId !==
                  this.currentWorkFlowInfo?.workOrderId &&
                !workFlowInfo.fileCountersign
              ) {
                this.ngOnInit(workFlowInfo.workFlowKey);
                return;
              }
            }
            this.dismiss();
          }
        }
      });
  }

  removeRecord() {
    this.workReportService
      .remove(this.currentWorkFlowInfo?.workOrderId!)
      .subscribe((data: number) => {
        if (data) {
          if (this.workFlowInfoList.length === 0) {
            this.dismiss();
          } else {
            for (const workFlowInfo of this.workFlowInfoList) {
              if (
                workFlowInfo.workOrderId !==
                  this.currentWorkFlowInfo?.workOrderId &&
                !workFlowInfo.fileCountersign
              ) {
                this.ngOnInit(workFlowInfo.workFlowKey);
                return;
              }
            }
            this.dismiss();
          }
        }
      });
  }

  counterSignStart() {
    // TODO : 发起会签
    if (this.currentWorkFlowInfo?.fileGroupId) {
      this.workReportFileCountersignService
        .submitByFileGroupId(this.currentWorkFlowInfo?.fileGroupId)
        .subscribe((data: number) => {
          if (data) {
            this.workFlowUserService
              .getWorkFlowInfo(data)
              .subscribe((response: WorkFlowInfo) => {
                if (!response) {
                  return;
                }
                this.ngOnInit(response.workFlowKey);
              });
          }
        });
    } else {
      this.workReportFileCountersignService
        .submitById(this.currentWorkFlowInfo?.workOrderId!)
        .subscribe((data: number) => {
          if (data) {
            this.workFlowUserService
              .getWorkFlowInfo(data)
              .subscribe((response: WorkFlowInfo) => {
                if (!response) {
                  return;
                }
                this.ngOnInit(response.workFlowKey);
              });
          }
        });
    }
  }

  counterSignCancel() {
    const results = this.modalService.open({
      backdropCloseable: false,
      component: AlertDialogComponent,
      onClose: () => {},
      data: {
        title: '无需会签',
        content: '此报表不走会签，<br/>直接走后续流程？',
        cancelBtnText: '取消',
        confirmBtnText: '确认',
        contentStyle: 'danger',
        type: AlertDialogType.confirm,
        onCancel: () => {
          results.modalInstance.hide();
        },
        onConfirm: (msg: string) => {
          if (this.currentWorkFlowInfo?.fileGroupId) {
            this.workReportFileCountersignService
              .setNotNeedByFileGroupId(this.currentWorkFlowInfo?.fileGroupId)
              .subscribe((data: boolean) => {
                if (data) {
                  results.modalInstance.hide();
                  this.ngOnInit(this.currentWorkFlowInfo?.workFlowKey);
                }
              });
          } else {
            this.workReportFileCountersignService
              .notNeedById(this.currentWorkFlowInfo?.workOrderId!)
              .subscribe((data: boolean) => {
                if (data) {
                  results.modalInstance.hide();
                  this.ngOnInit(this.currentWorkFlowInfo?.workFlowKey);
                }
              });
          }
        },
      },
    });
  }

  private openInTab(url: string) {
    const target = window.open(url, '_blank');
    setTimeout(() => {
      target?.postMessage({
        type: 'redir',
        data: window.location.href,
      });
    }, 1000);
  }

  addCopy(form: WorkFlowInfo, sectionId: number, sectionItemId: number) {
    this.omFormItemFlowService
      .findAll<FormFlowSearchRequest>({
        formId: form!.formId,
      })
      .subscribe((flows: FormFlow[]) => {
        for (const flow of flows) {
          if (flow.step === FormFlowType.FILL_IN) {
            if (
              flow.organizationType &&
              this.globalService.userInfo.organizationType !==
                flow.organizationType
            ) {
              // 没有权限
              this.message.error('没有添加本表单的权限');
              return;
            }
            if (
              flow.departmentId &&
              `${flow.departmentId}` !== '-1' &&
              this.globalService.userInfo.departmentId !== flow.departmentId &&
              this.globalService.userInfo.departmentId2 !== flow.departmentId
            ) {
              // 没有权限
              this.message.error('没有添加本表单的权限');
              return;
            }
            if (
              flow.postId &&
              `${flow.postId}` !== '-1' &&
              this.globalService.userInfo.postId !== flow.postId &&
              this.globalService.userInfo.postId2 !== flow.postId
            ) {
              // 没有权限
              this.message.error('没有添加本表单的权限');
              return;
            }
            // 如果是同表多份，则添加一份
            const cache = {
              projectId: this.globalService.projectId,
              sectionId,
              sectionItemId,
              formId: form.formId!,
              formStarted: form.formStarted,
              formCompleted: form.formCompleted,
              formSource: form.formSource,
            };
            this.workReportService.init(cache).subscribe((data: WorkReport) => {
              this.workReportService
                .create(data)
                .subscribe((report: WorkReport) => {
                  // !! 此处可能会报错： 编号重复
                  if (!!report && report.workFlowKey) {
                    this.globalService.breakSheild = true;
                    this.openInTab(`#/custom-form/sign/${report.workFlowKey!}`);
                  }
                });
            });
          }
        }
      });
    return;
  }

  add() {
    const results = this.loadingService.open();
    const info = new InitWorkReportRequest();
    info.projectId = this.globalService.projectId;
    info.sectionId = this.currentWorkFlowInfo!.sectionId;
    info.sectionItemId = this.currentWorkFlowInfo!.sectionItemId;
    info.formId = this.currentWorkFlowInfo!.formId;
    info.formStarted = this.currentWorkFlowInfo!.formStarted;
    info.formCompleted = this.currentWorkFlowInfo!.formCompleted;
    info.formSource = this.currentWorkFlowInfo!.formSource;
    this.workReportService.init(info).subscribe((data: WorkReport) => {
      results.loadingInstance.close();
      if (data) {
        if (this.is027) {
          data.is027 = true;
        }
        this.workReport = data;
        this.reportAttach = JSON.parse(
          JSON.stringify(this.workReport?.attachs ?? [])
        );
        // setTimeout(() => {
        //   if (this.workReport) {
        //     this.events.broadcast(
        //       EventType.START_AUTO_SAVE,
        //       this.workReport.workFlowKey
        //     );
        //   }
        // }, 300);
      }
    });
  }

  edit() {
    const results = this.loadingService.open();
    if (this.currentWorkFlowInfo?.status === WorkFlowStatus.DRAFT) {
      this.workReportService
        .findById(this.currentWorkFlowInfo?.workOrderId!)
        .subscribe((data: WorkReport) => {
          results.loadingInstance.close();
          if (data) {
            if (this.is027) {
              data.is027 = true;
            }
            this.workReport = data;
            this.reportAttach = JSON.parse(
              JSON.stringify(this.workReport?.attachs ?? [])
            );
            // setTimeout(() => {
            //   if (this.workReport) {
            //     this.events.broadcast(
            //       EventType.START_AUTO_SAVE,
            //       this.workReport.workFlowKey
            //     );
            //   }
            // }, 300);
          }
        });
    } else {
      this.workReportService
        .copyById(this.currentWorkFlowInfo?.workOrderId!)
        .subscribe((data: WorkReport) => {
          this.globalService.breakSheild = true;
          results.loadingInstance.close();
          if (data) {
            if (this.is027) {
              data.is027 = true;
            }
            this.workReport = data;
            this.reportAttach = JSON.parse(
              JSON.stringify(this.workReport?.attachs ?? [])
            );
            // setTimeout(() => {
            //   if (this.workReport) {
            //     this.events.broadcast(
            //       EventType.START_AUTO_SAVE,
            //       this.workReport.workFlowKey
            //     );
            //   }
            // }, 300);
          }
        });
    }
  }

  startFlow(workFlowKey: string) {
    // TODO: 能不能不要刷新页面
    this.reportAttach = [];
    window.open(`#/custom-form/sign/${workFlowKey}`, '_self');
    location.reload();
  }

  closeFlow(workFlowKey: string) {
    if (this.workFlowInfoList.length === 1) {
      window.close();
    }
    for (const item of this.workFlowInfoList) {
      if (item.workFlowKey !== workFlowKey) {
        // TODO: 能不能不要刷新页面
        this.reportAttach = [];
        window.open(`#/custom-form/sign/${item.workFlowKey}`, '_self');
        location.reload();
        break;
      }
    }
  }

  saveFlow(workFlowKey: string) {
    // TODO: 能不能不要刷新页面
    this.reportAttach = [];
    const target = !!this.reloadKeyflow ? this.reloadKeyflow : workFlowKey;
    // window.open(`#/custom-form/sign/${target}`, '_self');
    // // TODO: 能不能不要刷新页面
    // if (this.workReport && this.workReport.workFlowKey === target) {
    //   this.globalService.breakSheild = true;
    // }
    // location.reload();
    // TODO

    for (const flow of this.workFlowInfoList) {
      if (flow.workFlowKey === target) {
        console.log(flow);
        if (flow.status !== WorkFlowStatus.DRAFT) {
          this.ngOnInit(flow.workFlowKey);
        } else {
          // this.chooseMain(target);
          this.workReportService
            .findById(flow.workOrderId!)
            .subscribe((data: WorkReport) => {
              if (data) {
                this.workReport = data;
                // this.formItemMeasuredProjects =
                //   data.form?.formItemMeasuredProjects ?? [];
              }
            });
        }
        return;
      }
    }
    this.ngOnInit(target);
  }

  cancelDraftFlow() {
    this.workReport = undefined;
    this.reportAttach = [];
  }

  reportAttach: Array<WorkReportAttach> = [];

  uploadReportAppend(event: any) {
    const files: FileList = event.target['files'];
    const fileList = Array.from(files);
    zip(
      fileList.map((file: File) => {
        let needConvertToPdf = false;
        const fileName = file.name.toLowerCase();
        if (
          fileName.includes('doc') ||
          fileName.includes('docx') ||
          fileName.includes('xls') ||
          fileName.includes('xlsx') ||
          fileName.includes('ppt') ||
          fileName.includes('pptx')
        ) {
          needConvertToPdf = true;
        }
        return this.commonService.uploadFileWithoutProgress(
          file,
          needConvertToPdf
        );
      })
    ).subscribe((res: FileResponse[]) => {
      event.target['value'] = '';
      if (this.workReport!.attachs === undefined) {
        this.workReport!.attachs = [];
      }
      for (const url of res) {
        this.workReport!.attachs!.push({
          fileName: url.fileName,
          fileSource: FileSource.MANUAL,
          fileId: url.pdfFileId ? url.pdfFileId : url.fileId,
          fileType: url.pdfFileId ? FileType.PDF : url.fileType,
        });
      }
      this.reportAttach = JSON.parse(
        JSON.stringify(this.workReport!.attachs ?? [])
      );
    });
  }
  removeReportAttachment(index: number) {
    this.workReport!.attachs!.splice(index, 1);
    this.reportAttach = JSON.parse(
      JSON.stringify(this.workReport!.attachs ?? [])
    );
  }
  onReportFileNameChange(index: number) {
    this.workReport!.attachs![index].editing = false;
    this.reportAttach = JSON.parse(
      JSON.stringify(this.workReport!.attachs ?? [])
    );
  }
}

export class FormFileSignConfig {
  workFlowId!: number;
  disabled?: boolean = false;
  onDismiss!: () => void;
}
