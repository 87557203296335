import { RecordBase } from '../../providers/request/record-base.model';
import { FimpAverageTypeType } from './fimp-average-type-type.enum';
import { FimpMeasuredValueFillType } from './fimp-measured-value-fill-type.enum';
import { FimpMeasuredValueFormat } from './fimp-measured-value-format.enum';
import { FormItemMeasuredProjectOptions } from './form-item-measured-project-options.enum';
import { FormItemMeasuredProjectSpadType } from './form-item-measured-project-spad-type.enum';
import { FormItemMeasuredProjectSpad } from './form-item-measured-project-spad.model';
import { FormItemMeasuredProjectStatus } from './form-item-measured-project-status.enum';

export class FormItemMeasuredProject extends RecordBase {
  formId?: number;
  formItemId?: number;
  formTableId?: number;
  formItemAddress?: string;
  formItemColumnNum?: number;
  itemNo?: string;
  options?: FormItemMeasuredProjectOptions;
  category?: string;
  keywords?: string;
  spadSearchType?: string;
  spadKeywordsDesc?: string;
  spadDesc?: string;
  spadType?: FormItemMeasuredProjectSpadType;
  spadValues?: Array<FormItemMeasuredProjectSpad>;
  mfValue?: string;
  measuredValueFormat?: FimpMeasuredValueFormat;
  measuredValueFillType?: FimpMeasuredValueFillType;
  measuredValue?: string;
  remarkValue?: string;
  designValue?: string;
  quantitySpec?: string;
  averageValueType?: FimpAverageTypeType;
  averageValue?: string;
  qaRate?: string;
  qualifiedJudge?: string;
  sortOrder?: number;
  status?: FormItemMeasuredProjectStatus;
  comment?: string;
  // 前端自用
  designValue1?: string;
  designValue2?: string;
  count?: number;
  defaultIndex?: number;
  isJL?: boolean;
}
