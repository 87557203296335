export enum OrganizationType {
  DESIGN = 'DESIGN',
  DEVELOPMENT = 'DEVELOPMENT',
  CONSTRUCTION = 'CONSTRUCTION',
  SUPERVISE = 'SUPERVISE',
  SPOT_CHECK = 'SPOT_CHECK',
  JG_CHECK = 'JG_CHECK',
  COMPLETED_CHECK = 'COMPLETED_CHECK',
  OPERATE = 'OPERATE',
  ALL = 'ALL',
}

export const OrganizationTypeOrderList = [
  OrganizationType.DESIGN,
  OrganizationType.DEVELOPMENT,
  OrganizationType.CONSTRUCTION,
  OrganizationType.SUPERVISE,
  OrganizationType.SPOT_CHECK,
  OrganizationType.JG_CHECK,
  OrganizationType.COMPLETED_CHECK,
  OrganizationType.OPERATE,
  OrganizationType.ALL,
];

export const OrganizationTypeMap = {
  [OrganizationType.DEVELOPMENT]: '建设单位',
  [OrganizationType.CONSTRUCTION]: '施工单位',
  [OrganizationType.DESIGN]: '设计单位',
  [OrganizationType.SUPERVISE]: '监理单位',
  [OrganizationType.SPOT_CHECK]: '中心实验室',
  [OrganizationType.JG_CHECK]: '交工检测单位',
  [OrganizationType.COMPLETED_CHECK]: '竣工检测单位',
  [OrganizationType.OPERATE]: '运营单位',
  [OrganizationType.ALL]: '综合',
};

export const OrganizationTypeList = [
  {
    value: OrganizationType.DESIGN,
    label: OrganizationTypeMap[OrganizationType.DESIGN],
  },
  {
    value: OrganizationType.DEVELOPMENT,
    label: OrganizationTypeMap[OrganizationType.DEVELOPMENT],
  },
  {
    value: OrganizationType.CONSTRUCTION,
    label: OrganizationTypeMap[OrganizationType.CONSTRUCTION],
  },
  {
    value: OrganizationType.SUPERVISE,
    label: OrganizationTypeMap[OrganizationType.SUPERVISE],
  },
  {
    value: OrganizationType.SPOT_CHECK,
    label: OrganizationTypeMap[OrganizationType.SPOT_CHECK],
  },
  {
    value: OrganizationType.JG_CHECK,
    label: OrganizationTypeMap[OrganizationType.JG_CHECK],
  },
  {
    value: OrganizationType.COMPLETED_CHECK,
    label: OrganizationTypeMap[OrganizationType.COMPLETED_CHECK],
  },
  {
    value: OrganizationType.OPERATE,
    label: OrganizationTypeMap[OrganizationType.OPERATE],
  },
  {
    value: OrganizationType.ALL,
    label: OrganizationTypeMap[OrganizationType.ALL],
  },
];

export const OrganizationTypeShortMap = {
  [OrganizationType.DEVELOPMENT]: '建设',
  [OrganizationType.CONSTRUCTION]: '施工',
  [OrganizationType.DESIGN]: '设计',
  [OrganizationType.SUPERVISE]: '监理',
  [OrganizationType.SPOT_CHECK]: '试验',
  [OrganizationType.JG_CHECK]: '交工',
  [OrganizationType.COMPLETED_CHECK]: '竣工',
  [OrganizationType.OPERATE]: '信息',
  [OrganizationType.ALL]: '综合',
};

export const OrganizationTypeShortList = [
  {
    value: OrganizationType.DESIGN,
    label: OrganizationTypeShortMap[OrganizationType.DESIGN],
  },
  {
    value: OrganizationType.DEVELOPMENT,
    label: OrganizationTypeShortMap[OrganizationType.DEVELOPMENT],
  },
  {
    value: OrganizationType.CONSTRUCTION,
    label: OrganizationTypeShortMap[OrganizationType.CONSTRUCTION],
  },
  {
    value: OrganizationType.SUPERVISE,
    label: OrganizationTypeShortMap[OrganizationType.SUPERVISE],
  },
  {
    value: OrganizationType.SPOT_CHECK,
    label: OrganizationTypeShortMap[OrganizationType.SPOT_CHECK],
  },
  {
    value: OrganizationType.JG_CHECK,
    label: OrganizationTypeShortMap[OrganizationType.JG_CHECK],
  },
  {
    value: OrganizationType.COMPLETED_CHECK,
    label: OrganizationTypeShortMap[OrganizationType.COMPLETED_CHECK],
  },
  {
    value: OrganizationType.OPERATE,
    label: OrganizationTypeShortMap[OrganizationType.OPERATE],
  },
  {
    value: OrganizationType.ALL,
    label: OrganizationTypeShortMap[OrganizationType.ALL],
  },
];
