import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExcelTable } from '../models/form/excel-table.model';
import { WorkReportUploadResponse } from '../models/form/work-report-upload-response.model';
import { GlobalService } from '../providers/global.service';
import { RequestService } from '../providers/request/request.service';
import { Observable } from 'rxjs';
import { ListEnumValuesRequest } from '../models/form/list-enum-values-request.model';
import { InitWorkReportRequest } from '../models/form/init-work-report-request.model';
import { SignUrlInfo } from '../models/form/sign-url-info.model';
import { WorkFlowRefectSignRequest } from '../models/form/work-flow-refect-sign-request.model';
import { WorkReportDocType } from '../models/form/work-report-doc-type.enum';
import { WorkReport } from '../models/form/work-report.model';
import { FormFlowInfo } from '../models/form/form-flow-info.model';
import { EditableWorkReportRequest } from '../models/form/editable-work-report-request.model';
import { EditableWorkReportResponse } from '../models/form/editable-work-report-response.model';
import { WorkReportMeasuredValueInfo } from '../models/form/work-report-measured-value-info.model';
import { ParseJsFormRequest } from '../models/form/parse-js-form-request.model';
import { FormItemMeasuredProjectInfo } from '../models/form/form-item-measured-project-info.model';
import { CopyRequest } from '../models/form/copy-request.model';
import { DownloadFileByIdsRequest } from '../models/form/download-file-by-ids-request.model';
import { WorkReportAggregateSearchRequest } from '../models/form/work-report-aggregate-search-request.model';
import { WorkReportMatchDesignValueRequest } from '../models/form/work-report-match-design-value-request.model';
import { WorkReportMatchDesignValueResponse } from '../models/form/work-report-match-design-value-response.model';
import { WorkReportImportRequest } from '../models/form/work-report-import-request.model';

@Injectable({
  providedIn: 'root',
})
export class WorkReportImportService extends RequestService<WorkReport> {
  static PATH = '/work/report/import';
  constructor(
    public override httpClient: HttpClient,
    private globalService: GlobalService
  ) {
    super(httpClient, WorkReportImportService.PATH, globalService.debugUrl);
  }

  upload(req: WorkReportImportRequest) {
    return this.httpClient.post<any>(
      `${
        this.globalService.debugUrl
          ? this.globalService.debugUrl
          : environment.host
      }${WorkReportImportService.PATH}/upload`,
      req
    );
  }
}
