<nz-layout>
	<nz-content class="table-container">
		<div class="table-wrapper">
			<table-pro [config]="config!" [currentPage]="pager.pageIndex" [currentPageSize]="pager.pageSize"
				[tableData]="recordList" (calcResultChange)="calcResultChange($event)"
				(firstSortOrderKeyChange)="firstSortOrderKeyChange($event)"
				(queryPanelStateChange)="queryPanelStateChange($event)" (resetClick)="resetSearch($event)"
				(searchClick)="searchClick($event)" (checkChange)="checkChange($event)" [stickyTop]="40"
				(afterEdit)="afterEdit($event)">
				<div class="grid overview">
					<div class="row">
						<div class="column">
							<h3 class="color-primary">{{currentMenu?.name ?? ''}}</h3>
						</div>
					</div>

					<div class="action-bar" *ngIf="currentMenu?.name !== '监理指令回复'">
						<button *ngIf="menuFormList.length === 1" class="color-primary" nz-button nzType="text"
							(click)="add(menuFormList[0])"
							[disabled]="!auditsWriteableMap[menuFormList[0].menuId!]">+{{currentMenu?.name ?? ''}}
						</button>
						<button class="color-primary" nz-button nzType="text" nz-dropdown [nzDropdownMenu]="menu"
							[nzPlacement]="'bottomRight'" *ngIf="menuFormList.length > 1">
							+{{currentMenu?.name ?? ''}}
						</button>
						<nz-dropdown-menu #menu="nzDropdownMenu">
							<ul nz-menu>
								<li *ngFor="let menuForm of menuFormList" nz-menu-item (click)="add(menuForm)"
									[nzDisabled]="!auditsWriteableMap[menuForm.menuId!]">
									{{menuForm.formCode}} <span class="color-primary">{{menuForm.formName}}</span>
								</li>

							</ul>
						</nz-dropdown-menu>
					</div>
				</div>
			</table-pro>

		</div>

	</nz-content>
	<nz-footer class="footer">
		<nz-pagination [nzPageIndex]="pager.pageIndex" [(nzPageSize)]="pager.pageSize"
			[nzPageSizeOptions]="pager.pageSizeOptions" [nzTotal]="pager.total" nzShowQuickJumper
			[nzShowSizeChanger]="true" [nzShowQuickJumper]="true" (nzPageIndexChange)="pageIndexChange($event)"
			(nzPageSizeChange)="pageSizeChange($event)">
		</nz-pagination>
	</nz-footer>
</nz-layout>

<ng-template appHost></ng-template>