export enum ProjectItemType {
  PROJECT_SECTION = 'PROJECT_SECTION',
  DW = 'DW',
  SUB_DW = 'SUB_DW',
  FB = 'FB',
  SUB_FB = 'SUB_FB',
  FX = 'FX',
  SUB1_FX = 'SUB1_FX',
  SUB2_FX = 'SUB2_FX',
  SECTION = 'SECTION',
  SEGMENT = 'SEGMENT',
  PARTITION = 'PARTITION',
  LAYER = 'LAYER',
}
export const ProjectItemTypeIndexList = [
  ProjectItemType.PROJECT_SECTION, // 0
  ProjectItemType.DW, // 1
  ProjectItemType.SUB_DW, // 2
  ProjectItemType.FB, // 3
  ProjectItemType.SUB_FB, // 4
  ProjectItemType.FX, // 5
  ProjectItemType.SUB1_FX, // 6
  ProjectItemType.SUB2_FX, // 7
  ProjectItemType.SECTION, // 8
  ProjectItemType.SEGMENT, // 9
  ProjectItemType.PARTITION, // 10
  ProjectItemType.LAYER, // 11
];

export const ProjectItemTypeMap = {
  [ProjectItemType.PROJECT_SECTION]: '标段',
  [ProjectItemType.DW]: '单位工程',
  [ProjectItemType.SUB_DW]: '单位工程单元',
  [ProjectItemType.FB]: '分部工程',
  [ProjectItemType.SUB_FB]: '分部工程单元',
  [ProjectItemType.FX]: '分项工程',
  [ProjectItemType.SUB1_FX]: '一级分项工程',
  [ProjectItemType.SUB2_FX]: '二级分项工程',
  [ProjectItemType.SECTION]: '分项工程单元',
  [ProjectItemType.SEGMENT]: '段',
  [ProjectItemType.PARTITION]: '区',
  [ProjectItemType.LAYER]: '层',
};
export const ProjectItemTypeShortMap = {
  [ProjectItemType.PROJECT_SECTION]: '标段',
  [ProjectItemType.DW]: '单位',
  [ProjectItemType.SUB_DW]: '单位工程单元',
  [ProjectItemType.FB]: '分部',
  [ProjectItemType.SUB_FB]: '子分部',
  [ProjectItemType.FX]: '分项',
  [ProjectItemType.SUB1_FX]: '一级分项',
  [ProjectItemType.SUB2_FX]: '二级分项',
  [ProjectItemType.SECTION]: '分项工程单元',
  [ProjectItemType.SEGMENT]: '段',
  [ProjectItemType.PARTITION]: '区',
  [ProjectItemType.LAYER]: '层',
};

export const ProjectItemTypeList = [
  {
    value: ProjectItemType.PROJECT_SECTION,
    label: ProjectItemTypeMap[ProjectItemType.PROJECT_SECTION],
  },
  {
    value: ProjectItemType.DW,
    label: ProjectItemTypeMap[ProjectItemType.DW],
  },
  {
    value: ProjectItemType.SUB_DW,
    label: ProjectItemTypeMap[ProjectItemType.SUB_DW],
  },
  {
    value: ProjectItemType.FB,
    label: ProjectItemTypeMap[ProjectItemType.FB],
  },
  {
    value: ProjectItemType.SUB_FB,
    label: ProjectItemTypeMap[ProjectItemType.SUB_FB],
  },
  {
    value: ProjectItemType.FX,
    label: ProjectItemTypeMap[ProjectItemType.FX],
  },
  {
    value: ProjectItemType.SUB1_FX,
    label: ProjectItemTypeMap[ProjectItemType.SUB1_FX],
  },
  {
    value: ProjectItemType.SUB2_FX,
    label: ProjectItemTypeMap[ProjectItemType.SUB2_FX],
  },
  {
    value: ProjectItemType.SECTION,
    label: ProjectItemTypeMap[ProjectItemType.SECTION],
  },
  {
    value: ProjectItemType.SEGMENT,
    label: ProjectItemTypeMap[ProjectItemType.SEGMENT],
  },
  {
    value: ProjectItemType.PARTITION,
    label: ProjectItemTypeMap[ProjectItemType.PARTITION],
  },
  {
    value: ProjectItemType.LAYER,
    label: ProjectItemTypeMap[ProjectItemType.LAYER],
  },
];

export const ProjectItemTypeShortList = [
  {
    value: ProjectItemType.PROJECT_SECTION,
    label: ProjectItemTypeMap[ProjectItemType.PROJECT_SECTION],
  },
  {
    value: ProjectItemType.DW,
    label: ProjectItemTypeMap[ProjectItemType.DW],
  },
  {
    value: ProjectItemType.SUB_DW,
    label: ProjectItemTypeMap[ProjectItemType.SUB_DW],
  },
  {
    value: ProjectItemType.FB,
    label: ProjectItemTypeMap[ProjectItemType.FB],
  },
  {
    value: ProjectItemType.SUB_FB,
    label: ProjectItemTypeMap[ProjectItemType.SUB_FB],
  },
  {
    value: ProjectItemType.FX,
    label: ProjectItemTypeMap[ProjectItemType.FX],
  },
  {
    value: ProjectItemType.SECTION,
    label: ProjectItemTypeMap[ProjectItemType.SECTION],
  },
  {
    value: ProjectItemType.SEGMENT,
    label: ProjectItemTypeMap[ProjectItemType.SEGMENT],
  },
  {
    value: ProjectItemType.PARTITION,
    label: ProjectItemTypeMap[ProjectItemType.PARTITION],
  },
  {
    value: ProjectItemType.LAYER,
    label: ProjectItemTypeMap[ProjectItemType.LAYER],
  },
];
