<div class="grid" *ngIf="isMeasured">
	<div class="row" *ngFor="let row of realVal1List" [style.height]="h + '%'">
		<div *ngFor="let col of row" class="column {{col.hasError ? 'color-danger' : ''}}"
			[style.fontSize]="(valueShow?.newFontSize ?? fontSize) + 'pt'">
			{{special ? spMap[col.value] : currentSpad.spadType === FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
			&& isZp && record.designValue1 !== undefined && record.designValue1 !== '' && col.value !== undefined &&
			col.value !== '' ? ((+col.value > +record.designValue1) ? '+' : '' ) +(+col.value -
			+record.designValue1!).toFixed(precision) : col.value}}
		</div>
	</div>
	<div class="row" *ngFor="let row of realVal2List">
		<div *ngFor="let col of row" class="column {{col.hasError ? 'color-danger' : ''}}"
			[style.fontSize]="(valueShow?.newFontSize ?? fontSize) + 'pt'">
			{{special ? spMap[col.value] : currentSpad.spadType === FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
			&& isZp && record.designValue2 !== undefined && record.designValue2 !== ''&& col.value !== undefined &&
			col.value !== '' ? ((+col.value > +record.designValue2) ? '+' : '' ) +(+col.value -
			+record.designValue2!).toFixed(precision) : col.value}}
		</div>
	</div>
</div>

<!-- <div class="grid" *ngIf="isMeasured && realVal1List.length <= 1 && realVal2List.length <= 1 && !record.designValue2">
	<div class="row">
		<div *ngFor="let col of realVal1List[0]" class="column {{col.hasError ? 'color-danger' : ''}}">
			{{special ? spMap[col.value] : currentSpad.spadType === FormItemMeasuredProjectSpadType.ALLOWABLE_DEVIATION
			&& isZp && record.designValue1 !== undefined && record.designValue1 !== '' && col.value !== undefined &&
			col.value !== ''?((+col.value > +record.designValue1) ? '+' : '' ) +(+col.value -
			+record.designValue1!).toFixed(precision) : col.value}}
		</div>
	</div>
</div> -->

<p *ngIf="!isMeasured" [style.fontSize]="(valueShow?.newFontSize ?? fontSize) + 'pt'"
	[innerHTML]="valueShow?.value?.replaceAll('\n', '<br/>') ?? '' | safeHtml">
</p>